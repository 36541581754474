import { OleeApiURL, ProductApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

export class ProductService {
  static getNewProduct = () => {
    return {
      id: null,
      name: null,
      titleId: null,
      description: null,
      informationDescription: null,
      ingredientDescription: null,
      usesDescription: null,
      image_url: null,
      price: 0,
      originalPrice: 0,
      discountPercent: 0,
      promoText: null,
      category: null,
      images: [],
      colorTags: BaseService.getInitialColorList(),
      isHot: false,
      isNew: false,
      saleInfo: {},
      styleConfig: {},
      hashTags: null,
      seoTitle: null,
      seoKeyWords: null,
      seoDescription: null,
      orderNumber: 0,
      isDeleted: false,
    };
  };

  static getProductInput = (product) => {
    const productInput = {
      id: product.id,
      name: product.name,
      titleId: product.titleId,
      description: product.description,
      informationDescription: product.informationDescription,
      ingredientDescription: product.ingredientDescription,
      usesDescription: product.usesDescription,
      price: product.price,
      originalPrice: product.originalPrice,
      discountPercent: product.discountPercent,
      promoText: product.promoText,
      imageId: product.image ? product.image.id : null,
      imageIds: product.images
        ? product.images.map((t) => {
            return t.id;
          })
        : [],
      colorTags: JSON.stringify(product.colorTags),
      categoryId: product.category ? product.category.id : null,
      isNew: product.isNew,
      saleInfo: JSON.stringify(product.saleInfo),
      styleConfig: JSON.stringify(product.styleConfig),
      isHot: product.isHot,
      hashTags: product.hashTags,
      seoTitle: product.seoTitle,
      seoKeyWords: product.seoKeyWords,
      seoDescription: product.seoDescription,
      orderNumber: product.orderNumber,
      isDeleted: product.isDeleted,
    };
    return productInput;
  };

  // static checkProductTitleIsAvailable = (titleId) => {
  //   return fetch(
  //     `${OleeApiURL}${ProductApiUrl}/is_available_title_id/${titleId}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   ).then((res) => res.json());
  // };

  static getProductList = () => {
    // const loginData = AuthenticationService.getLoginData();
    // if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
    //     return fetch(`${OleeApiURL}${ProductApiUrl}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${loginData.token}`
    //         }
    //     })
    //     .then(res => res.json());
    // } else {
    //     return;
    // }

    return fetch(`${OleeApiURL}${ProductApiUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getProductListByCategory = (category) => {
    // const loginData = AuthenticationService.getLoginData();
    // if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
    //     return fetch(`${OleeApiURL}${ProductApiUrl}?category=${category.id}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${loginData.token}`
    //         }
    //     })
    //     .then(res => res.json());
    // } else {
    //     return;
    // }

    return fetch(`${OleeApiURL}${ProductApiUrl}?category=${category.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getProductDetailByTitleId = (titleId) => {
    return fetch(`${OleeApiURL}${ProductApiUrl}/title/${titleId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getProductDetail = (productId) => {
    // const loginData = AuthenticationService.getLoginData();
    // if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
    //     return fetch(`${OleeApiURL}${ProductApiUrl}/${productId}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${loginData.token}`
    //         }
    //     })
    //     .then(res => res.json());
    // } else {
    //     return;
    // }

    return fetch(`${OleeApiURL}${ProductApiUrl}/${productId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static insertProduct = (product) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      // const bodyJson = JSON.stringify({
      //     name: product.name,
      //     titleId: product.titleId,
      //     description: product.description,
      //     informationDescription: product.informationDescription,
      //     ingredientDescription: product.ingredientDescription,
      //     usesDescription: product.usesDescription,
      //     imageId: product.imageId,
      //     price: product.price,
      //     originalPrice: product.originalPrice,
      //     discountPercent:product.discountPercent,
      //     promoText: product.promoText,
      //     categoryId: product.categoryId,
      //     imageIds: product.imageIds,
      //     isNew: product.isNew,
      //     isHot: product.isHot,
      //     hashTags: product.hashTags,
      //     seoTitle: product.seoTitle,
      //     seoKeyWords: product.seoKeyWords,
      //     seoDescription: product.seoDescription
      // });

      const bodyJson = JSON.stringify(product);
      return fetch(`${OleeApiURL}${ProductApiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static updateProduct = (product) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      // const bodyJson = JSON.stringify({
      //     id: product.id,
      //     name: product.name,
      //     titleId: product.titleId,
      //     description: product.description,
      //     informationDescription: product.informationDescription,
      //     ingredientDescription: product.ingredientDescription,
      //     usesDescription: product.usesDescription,
      //     imageId: product.imageId,
      //     price: product.price,
      //     originalPrice: product.originalPrice,
      //     discountPercent: product.discountPercent,
      //     promoText: product.promoText,
      //     categoryId: product.categoryId,
      //     imageIds: product.imageIds,
      //     isNew: product.isNew,
      //     isHot: product.isHot,
      //     hashTags: product.hashTags,
      //     seoTitle: product.seoTitle,
      //     seoKeyWords: product.seoKeyWords,
      //     seoDescription: product.seoDescription
      // });

      const bodyJson = JSON.stringify(product);
      return fetch(`${OleeApiURL}${ProductApiUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static deleteProduct = (product) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${ProductApiUrl}/${product.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static cartProductCookieName = "productCart";
}
