import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./cart-page.css";
import "../components/public/form-components.css";
import { BaseService } from "../services/base-service";
import { SaleRequestsService } from "../services/sale-requests-service";
import {
  FormNumberInput,
  FormTextInput,
  FormTextAreaInput,
  FormButton,
  FormHR,
} from "../components/public/form-components";
import { Link } from "react-router-dom";
import { RequestStatus } from "../constants";

export class CartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productItemsInCart: this.props.productItemsInCart,
      updateCartTimerFunction: null,
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      saleRequestsNote: "",
      customerPhoneTouch: false,
      customerPhoneError: null,
      customerPhoneErrorMessage: "Xin vui lòng để lại số điện thoại",
      insertSaleRequestsSuccess: false,
      target: "_blank",
      isOpenVoucherInput: true,
      voucherCode: '',
      validVoucher: null,
      isSubmitSaleRequest: false,
      isResetVoucher: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.productItemsInCart_GetRequestStatus !==
      nextProps.productItemsInCart_GetRequestStatus
    ) {
      this.setState({
        productItemsInCart: nextProps.productItemsInCart,
      });
    }

    if (
      this.props.insertSaleRequestsRequestStatus !==
      nextProps.insertSaleRequestsRequestStatus &&
      nextProps.insertSaleRequestsRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState(
        {
          productItemsInCart: [],
          customerName: "",
          customerAddress: "",
          customerPhone: "",
          saleRequestsNote: "",
          insertSaleRequestsSuccess: true,
        },
        () => {
          this.props.updateProductItemInCart([]);
        }
      );
    }

    if (this.props.voucherStatusRequestStatus !== nextProps.voucherStatusRequestStatus &&
      nextProps.voucherStatusRequestStatus === RequestStatus.RQ_SUCCESS) {
      let voucher = null;
      let isValid = false;
      const voucherStatus = nextProps.voucherStatus;
      if (voucherStatus && voucherStatus.isValid) {
        isValid = true;
        voucher = voucherStatus.voucher;
      }
      this.setState({ validVoucher: voucher, isResetVoucher: false }, () => {
        if (this.state.isSubmitSaleRequest) {
          this.setState({ isSubmitSaleRequest: false }, () => {
            if (isValid) this.submitSaleRequest();
          })
        }
      });
    }
  }

  updateCart = () => {
    this.props.updateProductItemInCart(this.state.productItemsInCart);
  };

  handleOnRemoveProductItem = (productItemId) => {
    clearTimeout(this.state.updateCartTimerFunction);
    const idx = this.state.productItemsInCart.findIndex(
      (t) => t.productItem.id === productItemId
    );
    if (idx !== -1) {
      this.setState(
        (state) => {
          const productItemsInCart = state.productItemsInCart;
          productItemsInCart.splice(idx, 1);
          return {
            productItemsInCart: productItemsInCart,
          };
        },
        () => {
          this.setState({
            updateCartTimerFunction: setTimeout(() => {
              this.updateCart();
            }, 1000),
          });
        }
      );
    }
  };

  handleOnChangeAmountOfProductItem = (productItemId, amount) => {
    clearTimeout(this.state.updateCartTimerFunction);
    const idx = this.state.productItemsInCart.findIndex(
      (t) => t.productItem.id === productItemId
    );
    if (idx !== -1) {
      this.setState(
        (state) => {
          const productItemsInCart = state.productItemsInCart;
          productItemsInCart[idx].amount = amount;
          return {
            productItemsInCart: productItemsInCart,
          };
        },
        () => {
          this.setState({
            updateCartTimerFunction: setTimeout(() => {
              this.updateCart();
            }, 1000),
          });
        }
      );
    }
  };

  validCustomerPhone = (callBack) => {
    this.setState(
      {
        customerPhoneError: BaseService.stringIsNullOrWhiteSpace(
          this.state.customerPhone
        )
          ? this.state.customerPhoneErrorMessage
          : null,
      },
      () => {
        if (callBack) callBack();
      }
    );
  };

  submitSaleRequest = () => {
    const saleRequestsTypeInfo = SaleRequestsService.getSaleRequestsTypeInfo(
      "SALE_REQUEST_1_DAT_HANG"
    );
    let saleRequestsInput = SaleRequestsService.getNewSaleRequests();
    saleRequestsInput.name = saleRequestsTypeInfo.text;
    saleRequestsInput.content = JSON.stringify({
      products: this.state.productItemsInCart,
      customer: {
        name: this.state.customerName,
        phone: this.state.customerPhone,
        address: this.state.customerAddress,
      },
      note: this.state.saleRequestsNote,
    });
    saleRequestsInput.type = saleRequestsTypeInfo.value;
    saleRequestsInput.status = BaseService.getRecordStatus(0);
    this.props.insertSaleRequests(saleRequestsInput, this.state.validVoucher);
  }

  handleOnClickSendProductRequest = () => {
    this.setState(
      {
        customerPhoneTouch: true,
      },
      () => {
        this.validCustomerPhone(() => {
          if (!this.state.customerPhoneError) {
            this.setState({ isSubmitSaleRequest: true }, () => {
              const voucher = this.state.validVoucher;
              if (voucher) {
                this.props.checkVoucherStatus(voucher.code);
              } else {
                this.submitSaleRequest();
              }
            });
          }
        });
      }
    );
  };

  handleOnChangeCustomerPhone = (value) => {
    this.setState(
      {
        customerPhone: value,
        customerPhoneTouch: true,
      },
      () => {
        this.validCustomerPhone();
      }
    );
  };

  handleOnChangeCustomerName = (value) => {
    this.setState({
      customerName: value,
    });
  };

  handleOnChangeCustomerAddress = (value) => {
    this.setState({
      customerAddress: value,
    });
  };

  getSubmitFormElement = () => {
    const element = (
      <>
        <div className="cart-page-submit-form-wrapper-div">
          <div className="cart-page-submit-form-title-div">
            Cảm ơn bạn đã tin dùng kem sạch. Xin vui lòng cung cấp số điện thoại
            hoặc điền đầy đủ thông tin để đội ngũ chuyên viên tư vấn da sẵn sàng
            liên hệ, tư vấn ngay cho bạn.
          </div>
          <FormTextInput
            value={this.state.customerPhone}
            onChange={this.handleOnChangeCustomerPhone}
            labelText="Số điện thoại:"
            error={
              this.state.customerPhoneTouch && this.state.customerPhoneError
                ? this.state.customerPhoneError
                : null
            }
            isRequired={true}
          />
          <FormTextInput
            value={this.state.customerName}
            onChange={this.handleOnChangeCustomerName}
            labelText="Họ tên"
            labelName="name"
          />
          <FormTextAreaInput
            value={this.state.customerAddress}
            onChange={this.handleOnChangeCustomerAddress}
            labelText="Địa chỉ giao hàng:"
            labelName="address"
          />
          <br />
          <FormHR />
          <div className="cart-page-button-wrapper-div">
            <FormButton
              text={
                this.props.insertSaleRequestsRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.state.isSubmitSaleRequest
                  ? "Đang gửi ..."
                  : "Đặt hàng"
              }
              onClick={this.handleOnClickSendProductRequest}
              faIconClass="fas fa-shopping-cart"
              isSubmitButton={true}
            />
          </div>
          <br />
          <br />
          <Link to="/danh-sach-san-pham" className="form-link">
            Tiếp tục mua sắm
          </Link>
        </div>
      </>
    );
    return element;
  };

  getProductsTotalPrice = () => {
    let res = 0;
    this.state.productItemsInCart.forEach((item) => {
      res += item.productItem.price * item.amount;
    });
    return res;
  };

  getProductItemElement = (item) => {
    const voucher = this.state.validVoucher;
    const element = (
      <>
        <div className="cart-page-product-item-wrapper-div">
          <Row>
            <Col xs={3}>
              <Link
                target={this.state.target}
                className="cart-page-product-item-link"
                // to={`/san-pham/${BaseService.transformVNIToHyphenCase(
                //   item.productItem.categoryName
                // )}/${item.productItem.titleId}`}
                to={`/${item.productItem.titleId}`}
              >
                <img
                  alt=""
                  className="cart-page-product-item-img"
                  src={item.productItem.imgURL}
                />
              </Link>
            </Col>
            <Col xs={9} className="cart-page-product-item-info-wrapper-div">
              <div className="cart-page-product-item-content-info-bottom-side-div">
                <div
                  className="cart-page-product-item-content-info-delete-button-div"
                  onClick={() => {
                    this.handleOnRemoveProductItem(item.productItem.id);
                  }}
                >
                  Xóa
                </div>
              </div>
              <div className="cart-page-product-item-content-div">
                <div className="cart-page-product-item-content-name-div">
                  <Link
                    target={this.state.target}
                    className="cart-page-product-item-link"
                    // to={`/san-pham/${BaseService.transformVNIToHyphenCase(
                    //   item.productItem.categoryName
                    // )}/${item.productItem.titleId}`}
                    to={`/${item.productItem.titleId}`}
                  >
                    {item.productItem.name}
                  </Link>
                </div>
                <div className="cart-page-product-item-content-info-div">
                  <div className="cart-page-product-item-content-info-left-side-div">
                    <FormNumberInput
                      value={item.amount}
                      min="1"
                      onChange={(value) => {
                        this.handleOnChangeAmountOfProductItem(
                          item.productItem.id,
                          value
                        );
                      }}
                    />
                  </div>
                  <div>
                    <div className="cart-page-product-item-content-price-div">
                      {BaseService.formatNumber(item.productItem.price)}đ
                    </div>
                    {
                      !voucher &&
                      <div className="cart-page-product-item-content-info-sale-info">
                        {item.productItem.saleInfo &&
                          item.productItem.saleInfo.sale11 === true ? (
                          <>
                            <span className="cart-page-product-item-content-info-sale-sale11-span">
                              Khuyến mãi mua 1 tặng 1
                            </span>
                          </>
                        ) : (
                          <>
                            {item.productItem.price <
                              item.productItem.originalPrice ? (
                              <>
                                <span className="cart-page-product-item-content-info-sale-original-price-span">
                                  {BaseService.formatNumber(
                                    item.productItem.originalPrice
                                  )}
                                  đ
                                </span>
                                <span className="cart-page-product-item-content-info-sale-column-span">
                                  |
                                </span>
                                <span className="cart-page-product-item-content-info-sale-discount-percent-span">
                                  {" "}
                                  -{item.productItem.discountPercent}%
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="cart-page-product-item-content-info-sale-sale11-span">
                              {item.productItem.promoText}
                            </div>
                          </>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
    return element;
  };

  handleOnChangeSaleRequestsNote = (value) => {
    this.setState({
      saleRequestsNote: value,
    });
  };

  renderVoucherStatus = () => {
    let element = <></>;
    if (this.props.voucherStatusRequestStatus === RequestStatus.RQ_REQUEST) {
      element = <div>Đang kiểm tra ...</div>
    } else if (this.props.voucherStatusRequestStatus === RequestStatus.RQ_SUCCESS || this.props.voucherStatusRequestStatus === RequestStatus.RQ_ERROR) {
      if (this.state.voucherCode.length > 0 && !this.state.isResetVoucher) {
        const { isValid } = this.props.voucherStatus;
        if (isValid) {
          const voucher = this.state.validVoucher;
          element = <div className="cart-page-voucher-info-div">Voucher giảm {voucher ? BaseService.formatNumber(voucher.amount) : 0}đ</div>;
        } else {
          element = <div className="cart-page-voucher-info-div cart-page-voucher-info-invaid-div">Voucher không hợp lệ hoặc đã được sử dụng.</div>;
        }
      }
    }
    return element;
  }

  timeout = null;
  renderVoucherElement = () => {
    const element = <>
      <div className="cart-page-voucher-wrapper-div">
        {
          !this.state.isOpenVoucherInput ?
            <div className="cart-page-voucher-show-hide-input-button-wrapper-div">
              <i class="fas fa-tag"></i> <span onClick={() => { this.setState(state => ({ isOpenVoucherInput: true })) }} className="cart-page-voucher-show-hide-input-button-span">Nhập mã voucher tại đây</span>.
            </div> :
            <>
              <div className="cart-page-voucher-show-hide-input-wrapper-div cart-page-button-wrapper-div">
                <FormTextInput
                  value={this.state.voucherCode}
                  onChange={(value) => this.setState(state => ({ voucherCode: value }))}
                  onBlur={() => {
                    this.timeout = null;
                    this.timeout = setTimeout(() => {
                      const voucherCode = this.state.voucherCode;
                      const voucher = this.state.validVoucher;
                      if (voucherCode.length > 0) {
                        if (voucher) this.setState({ voucherCode: voucher.code })
                      } else {
                        this.setState({ validVoucher: null, isResetVoucher: true })
                      }
                    }, 1000);
                  }}
                  labelText="Mã voucher"
                  placeholder="ABCD01234567"
                />
                <FormButton
                  text='Áp dụng'
                  onClick={() => {
                    const voucherCode = this.state.voucherCode;
                    if (!BaseService.stringIsNullOrWhiteSpace(voucherCode)) {
                      this.setState({ validVoucher: null, isResetVoucher: true }, () => {
                        this.props.checkVoucherStatus(voucherCode);
                      })
                    }
                  }}
                  faIconClass="fas fa-tag"
                />
              </div>
              {this.renderVoucherStatus()}
            </>
        }
      </div>
    </>;
    return element;
  }

  rederTotalElement = () => {
    const voucher = this.state.validVoucher;
    let total = this.getProductsTotalPrice();
    if (voucher) {
      total = total - voucher.amount;
      if (total < 0) total = 0;
    }
    const element = <>
      <div>Tổng cộng:</div>
      {
        this.state.isOpenVoucherInput &&
        <div className="cart-page-voucher-new-total-div">
          {
            voucher ?
              <>{BaseService.formatNumber(this.getProductsTotalPrice())} - {BaseService.formatNumber(voucher.amount)}</> :
              <></>
          }
        </div>
      }
      <div className="cart-page-total-div">
        {BaseService.formatNumber(total)}đ
      </div>
    </>;
    return element;
  }

  getTotalElement = () => {
    const element = (
      <>
        <div className="cart-page-total-wrapper-div">
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              {this.renderVoucherElement()}
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <div>
                Ghi chú
                <FormTextAreaInput
                  value={this.state.saleRequestsNote}
                  onChange={this.handleOnChangeSaleRequestsNote}
                />
              </div>
              {this.rederTotalElement()}
            </Col>
          </Row>
        </div>
      </>
    );
    return element;
  };

  getCartListElement = () => {
    const element = (
      <>
        <Container>
          <Row>
            <Col>
              <div className="cart-page-title">
                Giỏ hàng ({this.state.productItemsInCart.length} sản phẩm)
              </div>
            </Col>
          </Row>
          {this.state.productItemsInCart.length > 0 ? (
            <Row>
              <Col md={7} lg={8}>
                {this.state.productItemsInCart.map((item) =>
                  this.getProductItemElement(item)
                )}
                {this.getTotalElement()}
              </Col>
              <Col md={5} lg={4}>
                {this.getSubmitFormElement()}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div>
                  Hiện chưa có sản phẩm trong giỏ.
                  <br />
                  <Link to="/danh-sach-san-pham" className="form-link">
                    Tiếp tục mua sắm
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
    return element;
  };

  insertSaleRequestsSuccessElement = () => {
    const element = (
      <>
        <Container>
          <Row>
            <Col>
              <div className="cart-page-title">
                Giỏ hàng ({this.state.productItemsInCart.length} sản phẩm)
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                Đơn đặt hàng của bạn đã được gửi thành công. Bộ phận kinh doanh
                của Olee sẽ liên hệ bạn trong thời gian sớm nhất.
                <br />
                <Link to="/danh-sach-san-pham" className="form-link">
                  Tiếp tục mua sắm
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div className="cart-page-cart-list-element-wrapper-div">
          {this.state.insertSaleRequestsSuccess
            ? this.insertSaleRequestsSuccessElement()
            : this.getCartListElement()}
        </div>
      </>
    );
    return html;
  }
}
