import React from "react";
import "./admin-layout-menu.css";
//
import { BaseService } from "../../services/base-service";
import { AdminLayoutService } from "../../services/admin-layout-service";
import { Link, NavLink } from "react-router-dom";

export class AdminLayoutMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminMenuList: AdminLayoutService.getAdminMenuList(),
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const AdminLayoutMenuTopDivInnerHeight = document.getElementById(
      "AdminLayoutMenuTopDiv"
    ).offsetHeight;
    // console.log('AdminLayoutMenuTopDivInnerHeight', AdminLayoutMenuTopDivInnerHeight);
    const AdminLayoutMenuUserDivInnerHeight = document.getElementById(
      "AdminLayoutMenuUserDiv"
    ).offsetHeight;
    // console.log('AdminLayoutMenuUserDivInnerHeight', AdminLayoutMenuUserDivInnerHeight);
    const AdminLayoutMenuBottomDivInnerHeight = document.getElementById(
      "AdminLayoutMenuBottomDiv"
    ).offsetHeight;
    // console.log('AdminLayoutMenuBottomDivInnerHeight', AdminLayoutMenuBottomDivInnerHeight);
    //
    const height =
      window.innerHeight -
      (AdminLayoutMenuTopDivInnerHeight +
        AdminLayoutMenuUserDivInnerHeight +
        AdminLayoutMenuBottomDivInnerHeight);
    // console.log('height', height);
    document.getElementById("AdminLayoutAdminMenuListDiv").style.height =
      height + "px";
  };

  logout = () => {
    this.props.logout();
  };

  getNumberOfNewAdvisoryElement = () => {
    let getSaleRequestsListAdvisoryRequestResult = [];
    if (this.props.getSaleRequestsListAdvisoryRequestResult) {
      getSaleRequestsListAdvisoryRequestResult = this.props.getSaleRequestsListAdvisoryRequestResult.filter(
        (t) => t.status === "RECORD_STATUS_0_NEW"
      );
    }
    const element = (
      <>
        {getSaleRequestsListAdvisoryRequestResult.length > 0 ? (
          <div className="admin-layout-menu-number-of-new-record-div">
            {getSaleRequestsListAdvisoryRequestResult.length}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getNumberOfNewOrderElement = () => {
    let getSaleRequestsListOrderRequestResult = [];
    if (this.props.getSaleRequestsListOrderRequestResult) {
      getSaleRequestsListOrderRequestResult = this.props.getSaleRequestsListOrderRequestResult.filter(
        (t) => t.status === "RECORD_STATUS_0_NEW"
      );
    }
    const element = (
      <>
        {getSaleRequestsListOrderRequestResult.length > 0 ? (
          <div className="admin-layout-menu-number-of-new-record-div">
            {getSaleRequestsListOrderRequestResult.length}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div className="admin-layout-menu-container-div">
          {this.props.isAdminMenuCollapse ? (
            <div
              className="admin-layout-menu-collpase-button-div"
              onClick={this.props.collpaseAdminMenu}
            >
              <i className="fas fa-caret-right admin-layout-menu-top-collapse-div"></i>
            </div>
          ) : (
            <></>
          )}
          <div
            id="AdminLayoutMenuTopDiv"
            className="admin-layout-menu-top-div"
            onClick={this.props.collpaseAdminMenu}
          >
            <i className="fas fa-caret-left admin-layout-menu-top-collapse-div"></i>
          </div>
          <div className="admin-layout-menu-middle-div">
            <div
              id="AdminLayoutMenuUserDiv"
              className="admin-layout-menu-user-div"
            >
              <div className="admin-layout-menu-user-profile-image-div">
                <i className="far fa-user-circle admin-layout-menu-user-default-profile-image-div"></i>
              </div>
              <div className="admin-layout-menu-user-name-div">
                {this.props.loginData ? this.props.loginData.name : ""}
              </div>
            </div>
            <div
              id="AdminLayoutAdminMenuListDiv"
              className="admin-layout-admin-menu-list-div"
            >
              {this.state.adminMenuList.map((adminMenuItem, index) => (
                <React.Fragment key={index}>
                  <NavLink
                    to={adminMenuItem.link}
                    className="admin-layout-admin-menu-link"
                  >
                    <i
                      className={`${adminMenuItem.iconClass} admin-layout-admin-menu-link-icon-i`}
                    ></i>
                    <div>{adminMenuItem.text}</div>
                    {adminMenuItem.link === "/quan-tri/yeu-cau-tu-van" ? (
                      this.getNumberOfNewAdvisoryElement()
                    ) : (
                      <></>
                    )}
                    {adminMenuItem.link === "/quan-tri/don-dat-hang" ? (
                      this.getNumberOfNewOrderElement()
                    ) : (
                      <></>
                    )}
                  </NavLink>
                </React.Fragment>
              ))}
              <div
                className="admin-layout-admin-menu-link"
                onClick={this.logout}
              >
                <i className="fas fa-sign-out-alt admin-layout-admin-menu-link-icon-i"></i>
                <div>Thoát</div>
              </div>
            </div>
          </div>
          <div
            id="AdminLayoutMenuBottomDiv"
            className="admin-layout-menu-bottom-div"
          >
            <div className="admin-layout-menu-bottom-logo-div">
              <Link to="/">{BaseService.getLogo()}</Link>
            </div>
          </div>
        </div>
      </>
    );
    return html;
  }
}
