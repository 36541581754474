import React from "react";
import "./typical-face-detail.css";
//
import "./typical-face-list-distributor.css";
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../public/loading-component";
import { Container, Row, Col } from "react-bootstrap";
import { BaseService } from "../../services/base-service";

export class TypicalFaceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getTypicalFaceDetailElement = () => {
    const element = (
      <>
        <Container className="typical-face-detail-container-div">
          <Row className="typical-face-detail-row">
            <Col md={6}>
              <img
                className="typical-face-img"
                alt=""
                src={BaseService.getImageUrlFromServer(
                  this.props.typicalFaceDetail.image.id,
                  this.props.typicalFaceDetail.image.name,
                  this.props.typicalFaceDetail.image.url
                )}
              />
            </Col>
            <Col md={6}>
              <div className="typical-face-detail-name-div">
                {this.props.typicalFaceDetail.name}
                {/* <h1 className='typical-face-detail-name-h1'>{this.props.typicalFaceDetail.name}</h1> */}
                {/* <div dangerouslySetInnerHTML={{ __html: this.props.typicalFaceDetail.name }}></div> */}
              </div>
              <div
                className="typical-face-detail-description-div"
                dangerouslySetInnerHTML={{
                  __html: this.props.typicalFaceDetail.description,
                }}
              ></div>
              {!BaseService.stringIsNullOrWhiteSpace(
                this.props.typicalFaceDetail.url
              ) ? (
                <a
                  href={this.props.typicalFaceDetail.url}
                  target={this.state.target}
                  className="typical-face-facebook-a"
                >
                  <i className="fab fa-facebook"></i> Xem thêm
                </a>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="typical-face-detail-content-div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.typicalFaceDetail.content,
                  }}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div>
          {this.props.typicalFaceDetailRequestStatus ===
            RequestStatus.RQ_SUCCESS ? (
            this.getTypicalFaceDetailElement()
          ) : (
            <LoadingComponent />
          )}
        </div>
      </>
    );
    return html;
  }
}
