import { connect } from "react-redux";
import { homePageSelectors } from "../redux/selectors";
import { homePageActions, webUserLayoutActions } from "../redux/actions";
import { HomePage } from "../pages/home-page";

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps', ownProps);
  return {
    windowWidth: ownProps.windowWidth,
    imageSliderImagesListRequestStatus: homePageSelectors.getImageSliderImagesListRequestStatus(
      state
    ),
    imageSliderImagesList: homePageSelectors.getImageSliderImagesList(state),
    serviceListRequestStatus: homePageSelectors.getServiceListRequestStatus(
      state
    ),
    serviceList: homePageSelectors.getServiceList(state),
    productCategoryListRequestStatus: homePageSelectors.getProductCategoryListRequestStatus(
      state
    ),
    productCategoryList: homePageSelectors.getProductCategoryList(state),
    productListRequestStatus: homePageSelectors.getProductListRequestStatus(
      state
    ),
    productList: homePageSelectors.getProductList(state),
    videoListRequestStatus: homePageSelectors.getVideoListRequestStatus(state),
    videoList: homePageSelectors.getVideoList(state),
    policyListRequestStatus: homePageSelectors.getPolicyListRequestStatus(
      state
    ),
    policyList: homePageSelectors.getPolicyList(state),
    typicalFaceListRequestStatus: homePageSelectors.getTypicalFaceListRequestStatus(
      state
    ),
    typicalFaceStarList: homePageSelectors.getTypicalFaceStarList(state),
    typicalFaceDistributorList: homePageSelectors.getTypicalFaceDistributorList(
      state
    ),
    eventListRequestStatus: homePageSelectors.getEventListRequestStatus(state),
    eventList: homePageSelectors.getEventList(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadImageSliderImagesList: () => {
    dispatch(homePageActions.loadImageSliderImagesList());
  },

  loadServicesList: () => {
    dispatch(homePageActions.loadServicesList());
  },

  loadProductCategoryList: () => {
    dispatch(homePageActions.loadProductCategoryList());
  },

  loadProductList: () => {
    dispatch(homePageActions.loadProductList());
  },

  loadVideoList: () => {
    dispatch(homePageActions.loadVideoList());
  },

  loadPolicyList: () => {
    dispatch(homePageActions.loadPolicyList());
  },

  loadTypicalFaceList: () => {
    dispatch(homePageActions.loadTypicalFaceList());
  },

  loadEventList: () => {
    dispatch(homePageActions.loadEventList());
  },

  addProductItemToCart: (productItem) => {
    dispatch(webUserLayoutActions.addProductItemToCart(productItem));
  },
});

const HomePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);

export default HomePageContainer;
