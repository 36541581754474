export const RequestStatus = {
  RQ_NEW: "RQ_NEW",
  RQ_REQUEST: "RQ_REQUEST",
  RQ_SUCCESS: "RQ_SUCCESS",
  RQ_ERROR: "RQ_ERROR",
};

export const EventStatus = {
  ES_NONSCHEDULED: "ES_NONSCHEDULED",
  ES_SCHEDULED: "ES_SCHEDULED",
  ES_COMPLETE: "ES_COMPLETE",
};

export const SiteName = "Olee - Mỹ phẩm sạch";

export const AuthLoginAPIURL = "/api/auth/login";

export const BannerApiUrl = "/api/banners";
export const BannerItemApiUrl = "/api/banner_items";
export const CategoryApiUrl = "/api/categories";
export const ProductApiUrl = "/api/products";
export const TypicalFaceApiUrl = "/api/typical_faces";
export const TagsApiUrl = "/api/tags";
export const ImageApiUrl = "/api/images";
export const VideoApiUrl = "/api/videos";
export const NewsApiUrl = "/api/news";
export const SaleRequestsApiUrl = "/api/sale_requests";
export const UIConfigsApiUrl = "/api/ui_configs";
export const VoucherApiUrl = "/api/vouchers";

export const Messages = {
  REQUEST_ERROR: "Đã xảy ra lỗi, xin vui lòng thử lại sau.",
  LOGIN_ERROR:
    "Không thể đăng nhập, xin vui lòng kiểm tra lại thông tin đăng nhập.",
  LOGIN_USERNAME_REQUIRE: "Tên đăng nhập không được rỗng",
  LOGIN_PASSWORD_REQUIRE: "Mật khẩu không được rỗng",
};

export const LocalStorageName = {
  LOGIN_DATA: "login-data",
};

// *****

const configDev = {
  // apiUrl: "http://213.190.4.226:5000",
  // apiUrl: "http://103.142.25.105:5000",
  apiUrl: "https://api.myphamsevennine.com",
};

const configPro = {
  // apiUrl: "http://103.142.25.105:5000",
  apiUrl: "https://api.myphamsevennine.com",
};

export const OleeApiURL =
  process.env.NODE_ENV === "development" ? configDev.apiUrl : configPro.apiUrl;
