import React from 'react';
import './admin-typical-face-page.css';
// 
import { Tabs, Tab } from 'react-bootstrap';
import { AdminTypicalFaceList } from '../components/admin-layout/admin-typical-face-list';

export class AdminTypicalFacePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    getAdminTypicalList = (isHotFace) => {
        const element = <>
            <AdminTypicalFaceList
                windowWidth={this.props.windowWidth}
                typicalFaceListRequestStatus={this.props.typicalFaceListRequestStatus}
                typicalFaceList={this.props.typicalFaceList}
                typicalFaceDetailRequestStatus={this.props.typicalFaceDetailRequestStatus}
                typicalFaceDetail={this.props.typicalFaceDetail}
                insertTypicalFaceRequestStatus={this.props.insertTypicalFaceRequestStatus}
                insertedTypicalFace={this.props.insertedTypicalFace}
                updateTypicalFaceRequestStatus={this.props.updateTypicalFaceRequestStatus}
                updatedTypicalFace={this.props.updatedTypicalFace}
                updateTypicalFaceOrderNumberRequestStatus={this.props.updateTypicalFaceOrderNumberRequestStatus}
                updatedTypicalFaceOrderNumber={this.props.updatedTypicalFaceOrderNumber}
                updateTypicalFaceIsDeletedRequestStatus={this.props.updateTypicalFaceIsDeletedRequestStatus}
                updatedTypicalFaceIsDeleted={this.props.updatedTypicalFaceIsDeleted}
                deleteTypicalFaceRequestStatus={this.props.deleteTypicalFaceRequestStatus}
                deletedTypicalFace={this.props.deletedTypicalFace}        
                errorMessage={this.props.typicalFaceErrorMessage}
                // 
                getTypicalFaceList={this.props.getTypicalFaceList}
                getTypicalFaceDetail={this.props.getTypicalFaceDetail}
                insertTypicalFace={this.props.insertTypicalFace}
                updateTypicalFace={this.props.updateTypicalFace}
                updateTypicalFace_OrderNumber={this.props.updateTypicalFace_OrderNumber}
                updateTypicalFace_IsDeleted={this.props.updateTypicalFace_IsDeleted}
                deleteTypicalFace={this.props.deleteTypicalFace}
                // 
                isHotFace={isHotFace}
            />
        </>;
        return element;
    }

    render() {
        const html = <>
            <div className='tabs-container-div'>
                <Tabs>
                    <Tab eventKey='typicalFaceHotFaceList' title='Nghệ sĩ'>
                        {this.getAdminTypicalList(true)}
                    </Tab>
                    <Tab eventKey='typicalFaceList' title='Nhà phân phối'>
                        {this.getAdminTypicalList(false)}
                    </Tab>
                </Tabs>
            </div>
        </>;
        return (
            html
        );
    }
}