import { connect } from "react-redux";
import { AdminUIConfigsPage } from "../pages/admin-ui-configs-page";
import {
  getInsertUIConfigsRequestResult,
  getInsertUIConfigsRequestStatus,
  getUIConfigsDetailRequestResult,
  getUIConfigsDetailRequestStatus,
  getUIConfigsListRequestResult,
  getUIConfigsListRequestStatus,
  getUpdateUIConfigsRequestResult,
  getUpdateUIConfigsRequestStatus,
} from "../redux/selectors/ui-configs-page-selectors";
import { uiConfigsPageActions } from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    uiConfigsListRequestStatus: getUIConfigsListRequestStatus(state),
    uiConfigsListRequestResult: getUIConfigsListRequestResult(state),
    //
    uiConfigsDetailRequestStatus: getUIConfigsDetailRequestStatus(state),
    uiConfigsDetailRequestResult: getUIConfigsDetailRequestResult(state),
    //
    insertUIConfigsRequestStatus: getInsertUIConfigsRequestStatus(state),
    insertUIConfigsRequestResult: getInsertUIConfigsRequestResult(state),
    //
    updateUIConfigsRequestStatus: getUpdateUIConfigsRequestStatus(state),
    updateUIConfigsRequestResult: getUpdateUIConfigsRequestResult(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUIConfigsList: () => {
    dispatch(uiConfigsPageActions.getUIConfigsList());
  },

  getUIConfigsDetail: (uiConfigsId) => {
    dispatch(uiConfigsPageActions.getUIConfigsDetail(uiConfigsId));
  },

  insertUIConfigs: (uiConfigs) => {
    dispatch(uiConfigsPageActions.insertUIConfigs(uiConfigs));
  },

  updateUIConfigs: (uiConfigs) => {
    dispatch(uiConfigsPageActions.updateUIConfigs(uiConfigs));
  },
});

const AdminUIConfigsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUIConfigsPage);

export default AdminUIConfigsContainer;
