import {
  productpage_GET_PRODUCT_LIST_REQUEST,
  productpage_GET_PRODUCT_LIST_SUCCESS,
  productpage_GET_PRODUCT_LIST_ERROR,
  productpage_GET_PRODUCT_DETAIL_REQUEST,
  productpage_GET_PRODUCT_DETAIL_SUCCESS,
  productpage_GET_PRODUCT_DETAIL_ERROR,
  productpage_INSERT_PRODUCT_REQUEST,
  productpage_INSERT_PRODUCT_SUCCESS,
  productpage_INSERT_PRODUCT_ERROR,
  productpage_UPDATE_PRODUCT_REQUEST,
  productpage_UPDATE_PRODUCT_SUCCESS,
  productpage_UPDATE_PRODUCT_ERROR,
  productpage_UPDATE_PRODUCT_ORDER_NUMBER_REQUEST,
  productpage_UPDATE_PRODUCT_ORDER_NUMBER_SUCCESS,
  productpage_UPDATE_PRODUCT_ORDER_NUMBER_ERROR,
  productpage_UPDATE_PRODUCT_IS_DELETED_REQUEST,
  productpage_UPDATE_PRODUCT_IS_DELETED_SUCCESS,
  productpage_UPDATE_PRODUCT_IS_DELETED_ERROR,
  productpage_DELETE_PRODUCT_REQUEST,
  productpage_DELETE_PRODUCT_SUCCESS,
  productpage_DELETE_PRODUCT_ERROR,
  productpage_GET_ALL_HASHTAGS_REQUEST,
  productpage_GET_ALL_HASHTAGS_SUCCESS,
  productpage_GET_ALL_HASHTAGS_ERROR,
  productpage_GET_PRODUCT_LIST_BY_CATEGORY_REQUEST,
  productpage_GET_PRODUCT_LIST_BY_CATEGORY_SUCCESS,
  productpage_GET_PRODUCT_LIST_BY_CATEGORY_ERROR,
} from "../actions/product-page-actions";
import { RequestStatus } from "../../constants";

const initialState = {
  productListRequestStatus: RequestStatus.RQ_NEW,
  productList: [],
  //
  productDetailRequestStatus: RequestStatus.RQ_NEW,
  productDetail: null,
  productDetail_originName: null,
  //
  insertProductRequestStatus: RequestStatus.RQ_NEW,
  insertedProduct: null,
  //
  updateProductRequestStatus: RequestStatus.RQ_NEW,
  updatedProduct: null,
  //
  updateProductOrderNumberRequestStatus: RequestStatus.RQ_NEW,
  updatedProductOrderNumber: null,
  //
  updateProductIsDeletedRequestStatus: RequestStatus.RQ_NEW,
  updatedProductIsDeleted: null,
  //
  deleteProductRequestStatus: RequestStatus.RQ_NEW,
  deletedProduct: null,
  //
  errorMessage: null,
  //
  allHashTagsRequestStatus: RequestStatus.RQ_NEW,
  allHashTags: null,
  //
  productListByCategoryRequestStatus: RequestStatus.RQ_NEW,
  productListByCategory: [],
};

export function productPageReducer(state = initialState, actions) {
  switch (actions.type) {
    case productpage_GET_PRODUCT_LIST_REQUEST: {
      return {
        ...state,
        productListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_GET_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productListRequestStatus: RequestStatus.RQ_SUCCESS,
        productList: actions.payload.productList,
      };
    }
    case productpage_GET_PRODUCT_LIST_ERROR: {
      return {
        ...state,
        productListRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_GET_PRODUCT_DETAIL_REQUEST: {
      return {
        ...state,
        productDetailRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_GET_PRODUCT_DETAIL_SUCCESS: {
      const productDetail = actions.payload.productDetail;
      return {
        ...state,
        productDetailRequestStatus: RequestStatus.RQ_SUCCESS,
        productDetail: productDetail,
        productDetail_originName: productDetail.name,
      };
    }
    case productpage_GET_PRODUCT_DETAIL_ERROR: {
      return {
        ...state,
        productDetailRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case productpage_INSERT_PRODUCT_REQUEST: {
      return {
        ...state,
        insertProductRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_INSERT_PRODUCT_SUCCESS: {
      return {
        ...state,
        insertProductRequestStatus: RequestStatus.RQ_SUCCESS,
        insertedProduct: actions.payload.insertedProduct,
      };
    }
    case productpage_INSERT_PRODUCT_ERROR: {
      return {
        ...state,
        insertProductRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_UPDATE_PRODUCT_REQUEST: {
      return {
        ...state,
        updateProductRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        updateProductRequestStatus: RequestStatus.RQ_SUCCESS,
        updatedProduct: actions.payload.updatedProduct,
      };
    }
    case productpage_UPDATE_PRODUCT_ERROR: {
      return {
        ...state,
        updateProductRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_UPDATE_PRODUCT_ORDER_NUMBER_REQUEST: {
      return {
        ...state,
        updateProductOrderNumberRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_UPDATE_PRODUCT_ORDER_NUMBER_SUCCESS: {
      return {
        ...state,
        updateProductOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
        updatedProductOrderNumber: actions.payload.updatedProduct,
      };
    }
    case productpage_UPDATE_PRODUCT_ORDER_NUMBER_ERROR: {
      return {
        ...state,
        updateProductOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_UPDATE_PRODUCT_IS_DELETED_REQUEST: {
      return {
        ...state,
        updateProductIsDeletedRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_UPDATE_PRODUCT_IS_DELETED_SUCCESS: {
      return {
        ...state,
        updateProductIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
        updatedProductIsDeleted: actions.payload.updatedProduct,
      };
    }
    case productpage_UPDATE_PRODUCT_IS_DELETED_ERROR: {
      return {
        ...state,
        updateProductIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_DELETE_PRODUCT_REQUEST: {
      return {
        ...state,
        deleteProductRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        deleteProductRequestStatus: RequestStatus.RQ_SUCCESS,
        deletedProduct: actions.payload.deletedProduct,
      };
    }
    case productpage_DELETE_PRODUCT_ERROR: {
      return {
        ...state,
        deleteProductRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_GET_ALL_HASHTAGS_REQUEST: {
      return {
        ...state,
        allHashTagsRequestStatus: RequestStatus.RQ_REQUEST,
        allHashTags: null,
      };
    }
    case productpage_GET_ALL_HASHTAGS_SUCCESS: {
      return {
        ...state,
        allHashTagsRequestStatus: RequestStatus.RQ_SUCCESS,
        allHashTags: actions.payload.allHashTags,
      };
    }
    case productpage_GET_ALL_HASHTAGS_ERROR: {
      return {
        ...state,
        allHashTagsRequestStatus: RequestStatus.RQ_ERROR,
        errorMessage: actions.payload.errorMessage,
      };
    }
    case productpage_GET_PRODUCT_LIST_BY_CATEGORY_REQUEST: {
      return {
        ...state,
        productListByCategoryRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case productpage_GET_PRODUCT_LIST_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        productListByCategoryRequestStatus: RequestStatus.RQ_SUCCESS,
        productListByCategory: actions.payload.productListByCategory,
      };
    }
    case productpage_GET_PRODUCT_LIST_BY_CATEGORY_ERROR: {
      return {
        ...state,
        productListByCategoryRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    default: {
      return state;
    }
  }
}
