import {
    loginpage_LOGIN_REQUEST,
    loginpage_LOGIN_SUCCESS,
    loginpage_LOGIN_ERROR,
    loginpage_GET_LOGIN_DATA_REQUEST,
    loginpage_GET_LOGIN_DATA_SUCCESS,
    loginpage_CLEAR_LOGIN_DATA_REQUEST,
    loginpage_CLEAR_LOGIN_DATA_SUCCESS
} from '../actions/login-page-actions';
import {
    RequestStatus
} from '../../constants';

const initialState  = {
    loginRequestStatus: RequestStatus.RQ_NEW,
    loginData: null,
    errorMessage: null,
    // 
    getLoginDataRequestStatus: RequestStatus.RQ_NEW,
    // 
    clearLoginDataRequestStatus: RequestStatus.RQ_NEW
    // 
}

export function loginPageReducer(state = initialState, actions) {
    switch(actions.type) {
        case loginpage_LOGIN_REQUEST: {
            return {
                ...state,
                loginRequestStatus: RequestStatus.RQ_REQUEST,
                loginData: null,
                errorMessage: null
            }
        }
        case loginpage_LOGIN_SUCCESS: {
            return {
                ...state,
                loginRequestStatus: RequestStatus.RQ_SUCCESS,
                loginData: actions.payload.loginData
            }
        }
        case loginpage_LOGIN_ERROR: {
            return {
                ...state,
                loginRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case loginpage_GET_LOGIN_DATA_REQUEST: {
            return {
                ...state,
                getLoginDataRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case loginpage_GET_LOGIN_DATA_SUCCESS: {
            return {
                ...state,
                getLoginDataRequestStatus: RequestStatus.RQ_SUCCESS,
                loginData: actions.payload.loginData
            }
        }
        case loginpage_CLEAR_LOGIN_DATA_REQUEST: {
            return {
                ...state,
                clearLoginDataRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case loginpage_CLEAR_LOGIN_DATA_SUCCESS: {
            return {
                ...state,
                clearLoginDataRequestStatus: RequestStatus.RQ_SUCCESS,
                loginData: actions.payload.loginData
            }
        }
        default: {
            return state;
        }
    }
}