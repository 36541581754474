import { connect } from "react-redux";
import ContactFormPage from "../pages/contact-form-page";
import { webUserLayoutSelectors } from "../redux/selectors";
// import { BaseService } from "../services/base-service";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    footerInfoData: webUserLayoutSelectors.getFooterInfoData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({});

const ContactPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormPage);

export default ContactPageContainer;
