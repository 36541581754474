import {
  LOAD_NAV_MENU_LIST_REQUEST,
  LOAD_NAV_MENU_LIST_SUCCESS,
  LOAD_NAV_MENU_LIST_ERROR,
  LOAD_FOOTER_INFO_REQUEST,
  LOAD_FOOTER_INFO_SUCCESS,
  LOAD_FOOTER_INFO_ERROR,
  WUL_GET_PRODUCT_ITEMS_IN_CART_REQUEST,
  WUL_GET_PRODUCT_ITEMS_IN_CART_SUCCESS,
  WUL_ADD_PRODUCT_ITEM_TO_CART_REQUEST,
  WUL_ADD_PRODUCT_ITEM_TO_CART_SUCCESS,
  WUL_UPDATE_PRODUCT_ITEM_IN_CART_REQUEST,
  WUL_UPDATE_PRODUCT_ITEM_IN_CART_SUCCESS,
  WUL_GET_SALE_REQUESTS_LIST_REQUEST,
  WUL_GET_SALE_REQUESTS_LIST_SUCCESS,
  WUL_GET_SALE_REQUESTS_LIST_FAILURE,
  WUL_GET_SALE_REQUESTS_DETAIL_REQUEST,
  WUL_GET_SALE_REQUESTS_DETAIL_SUCCESS,
  WUL_GET_SALE_REQUESTS_DETAIL_FAILURE,
  WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_REQUEST,
  WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_SUCCESS,
  WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_FAILURE,
  WUL_INSERT_SALE_REQUESTS_REQUEST,
  WUL_INSERT_SALE_REQUESTS_SUCCESS,
  WUL_INSERT_SALE_REQUESTS_FAILURE,
  WUL_UPDATE_SALE_REQUESTS_REQUEST,
  WUL_UPDATE_SALE_REQUESTS_SUCCESS,
  WUL_UPDATE_SALE_REQUESTS_FAILURE,
  adminlayout_CHECK_TITLE_IS_AVAILABLE_REQUEST,
  adminlayout_CHECK_TITLE_IS_AVAILABLE_SUCCESS,
  adminlayout_CHECK_TITLE_IS_AVAILABLE_FAILURE,
  adminlayout_GET_ALL_SLUGS_REQUEST,
  adminlayout_GET_ALL_SLUGS_SUCCESS,
  adminlayout_GET_ALL_SLUGS_FAILURE,
} from "../actions/web-user-layout-actions";
import { RequestStatus } from "../../constants";

const initialState = {
  navMenuListRequestStatus: RequestStatus.RQ_NEW,
  navMenuList: [],
  footerInfoRequest: RequestStatus.RQ_NEW,
  footerInfoData: null,
  // Add product item to cart
  productItemsInCart: [],
  productItemsInCart_LastestModifyTime: "",
  productItemsInCart_GetRequestStatus: RequestStatus.RQ_NEW,
  productItemsInCart_AddRequestStatus: RequestStatus.RQ_NEW,
  productItemsInCart_UpdateRequestStatus: RequestStatus.RQ_NEW,
  // Get Sale Requests List
  getSaleRequestsListRequestStatus: RequestStatus.RQ_NEW,
  getSaleRequestsListRequestResult: [],
  // Get Sale Requests Detail
  getSaleRequestsDetailRequestStatus: RequestStatus.RQ_NEW,
  getSaleRequestsDetailRequestResult: null,
  // Get Sale Requests List By Type
  getSaleRequestsListAdvisoryRequestStatus: RequestStatus.RQ_NEW,
  getSaleRequestsListAdvisoryRequestResult: [],
  getSaleRequestsListOrderRequestStatus: RequestStatus.RQ_NEW,
  getSaleRequestsListOrderRequestResult: [],
  // Insert Sale Requests
  insertSaleRequestsRequestStatus: RequestStatus.RQ_NEW,
  insertSaleRequestsRequestResult: null,
  // Update Sale Requests
  updateSaleRequestsRequestStatus: RequestStatus.RQ_NEW,
  updateSaleRequestsRequestResult: null,
  //
  titleIsAvailableRequestStatus: RequestStatus.RQ_NEW,
  titleIsAvailableRequestResult: true,
  //
  getAllSlugsRequestStatus: RequestStatus.RQ_NEW,
  getAllSlugsRequestResult: {},
};

export function webUserLayoutReducer(state = initialState, actions) {
  switch (actions.type) {
    case LOAD_NAV_MENU_LIST_REQUEST: {
      return {
        ...state,
        navMenuListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case LOAD_NAV_MENU_LIST_SUCCESS: {
      return {
        ...state,
        navMenuListRequestStatus: RequestStatus.RQ_SUCCESS,
        navMenuList: actions.payload.navMenuList,
      };
    }
    case LOAD_NAV_MENU_LIST_ERROR: {
      return {
        ...state,
        navMenuListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case LOAD_FOOTER_INFO_REQUEST: {
      return {
        ...state,
        footerInfoRequest: RequestStatus.RQ_REQUEST,
      };
    }
    case LOAD_FOOTER_INFO_SUCCESS: {
      return {
        ...state,
        footerInfoRequest: RequestStatus.RQ_SUCCESS,
        footerInfoData: actions.payload.footerInfoData,
      };
    }
    case LOAD_FOOTER_INFO_ERROR: {
      return {
        ...state,
        footerInfoRequest: RequestStatus.RQ_ERROR,
      };
    }
    case WUL_GET_PRODUCT_ITEMS_IN_CART_REQUEST: {
      return {
        ...state,
        productItemsInCart_GetRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_GET_PRODUCT_ITEMS_IN_CART_SUCCESS: {
      return {
        ...state,
        productItemsInCart_GetRequestStatus: RequestStatus.RQ_SUCCESS,
        productItemsInCart: actions.payload.productItemsInCart,
      };
    }
    case WUL_ADD_PRODUCT_ITEM_TO_CART_REQUEST: {
      return {
        ...state,
        productItemsInCart_AddRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_ADD_PRODUCT_ITEM_TO_CART_SUCCESS: {
      return {
        ...state,
        productItemsInCart_AddRequestStatus: RequestStatus.RQ_SUCCESS,
        productItemsInCart: actions.payload.productItemsInCart,
        productItemsInCart_LastestModifyTime:
          actions.payload.productItemsInCart_LastestModifyTime,
        productItem: actions.payload.productItem,
      };
    }
    case WUL_UPDATE_PRODUCT_ITEM_IN_CART_REQUEST: {
      return {
        ...state,
        productItemsInCart_UpdateRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_UPDATE_PRODUCT_ITEM_IN_CART_SUCCESS: {
      return {
        ...state,
        productItemsInCart_UpdateRequestStatus: RequestStatus.RQ_SUCCESS,
        productItemsInCart: actions.payload.productItemsInCart,
        productItemsInCart_LastestModifyTime:
          actions.payload.productItemsInCart_LastestModifyTime,
      };
    }
    case WUL_GET_SALE_REQUESTS_LIST_REQUEST: {
      return {
        ...state,
        getSaleRequestsListRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_GET_SALE_REQUESTS_LIST_SUCCESS: {
      return {
        ...state,
        getSaleRequestsListRequestStatus: RequestStatus.RQ_SUCCESS,
        getSaleRequestsListRequestResult: actions.payload.saleRequestsList,
      };
    }
    case WUL_GET_SALE_REQUESTS_LIST_FAILURE: {
      return {
        ...state,
        getSaleRequestsListRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case WUL_GET_SALE_REQUESTS_DETAIL_REQUEST: {
      return {
        ...state,
        getSaleRequestsDetailRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_GET_SALE_REQUESTS_DETAIL_SUCCESS: {
      return {
        ...state,
        getSaleRequestsDetailRequestStatus: RequestStatus.RQ_SUCCESS,
        getSaleRequestsDetailRequestResult: actions.payload.saleRequestsDetail,
      };
    }
    case WUL_GET_SALE_REQUESTS_DETAIL_FAILURE: {
      return {
        ...state,
        getSaleRequestsDetailRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_REQUEST: {
      const saleRequestsType = actions.payload.saleRequestsType;
      if (saleRequestsType === "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM") {
        return {
          ...state,
          getSaleRequestsListAdvisoryRequestStatus: RequestStatus.RQ_REQUEST,
        };
      } else {
        return {
          ...state,
          getSaleRequestsListOrderRequestStatus: RequestStatus.RQ_REQUEST,
        };
      }
    }
    case WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_SUCCESS: {
      const saleRequestsType = actions.payload.saleRequestsType;
      if (saleRequestsType === "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM") {
        return {
          ...state,
          getSaleRequestsListAdvisoryRequestStatus: RequestStatus.RQ_SUCCESS,
          getSaleRequestsListAdvisoryRequestResult:
            actions.payload.saleRequestsListByType,
        };
      } else {
        return {
          ...state,
          getSaleRequestsListOrderRequestStatus: RequestStatus.RQ_SUCCESS,
          getSaleRequestsListOrderRequestResult:
            actions.payload.saleRequestsListByType,
        };
      }
    }
    case WUL_GET_SALE_REQUESTS_LIST_BY_TYPE_FAILURE: {
      const saleRequestsType = actions.payload.saleRequestsType;

      if (saleRequestsType === "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM") {
        return {
          ...state,
          getSaleRequestsListAdvisoryRequestStatus: RequestStatus.RQ_ERROR,
        };
      } else {
        return {
          ...state,
          getSaleRequestsListOrderRequestStatus: RequestStatus.RQ_ERROR,
        };
      }
    }
    case WUL_INSERT_SALE_REQUESTS_REQUEST: {
      return {
        ...state,
        insertSaleRequestsRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_INSERT_SALE_REQUESTS_SUCCESS: {
      return {
        ...state,
        insertSaleRequestsRequestStatus: RequestStatus.RQ_SUCCESS,
        insertSaleRequestsRequestResult: actions.payload.insertedSaleRequests,
      };
    }
    case WUL_INSERT_SALE_REQUESTS_FAILURE: {
      return {
        ...state,
        insertSaleRequestsRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case WUL_UPDATE_SALE_REQUESTS_REQUEST: {
      return {
        ...state,
        updateSaleRequestsRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case WUL_UPDATE_SALE_REQUESTS_SUCCESS: {
      const updatedSaleRequests = actions.payload.updatedSaleRequests;
      const getSaleRequestsListAdvisoryRequestResult =
        state.getSaleRequestsListAdvisoryRequestResult;
      const getSaleRequestsListOrderRequestResult =
        state.getSaleRequestsListOrderRequestResult;
      if (
        updatedSaleRequests.type === "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM"
      ) {
        const idx = getSaleRequestsListAdvisoryRequestResult.findIndex(
          (t) => t.id === updatedSaleRequests.id
        );
        getSaleRequestsListAdvisoryRequestResult[idx] = updatedSaleRequests;
      } else {
        const idx = getSaleRequestsListOrderRequestResult.findIndex(
          (t) => t.id === updatedSaleRequests.id
        );
        getSaleRequestsListOrderRequestResult[idx] = updatedSaleRequests;
      }
      return {
        ...state,
        updateSaleRequestsRequestStatus: RequestStatus.RQ_SUCCESS,
        updateSaleRequestsRequestResult: updatedSaleRequests,
        getSaleRequestsListAdvisoryRequestResult: getSaleRequestsListAdvisoryRequestResult,
        getSaleRequestsListOrderRequestResult: getSaleRequestsListOrderRequestResult,
      };
    }
    case WUL_UPDATE_SALE_REQUESTS_FAILURE: {
      return {
        ...state,
        updateSaleRequestsRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case adminlayout_CHECK_TITLE_IS_AVAILABLE_REQUEST: {
      return {
        ...state,
        titleIsAvailableRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case adminlayout_CHECK_TITLE_IS_AVAILABLE_SUCCESS: {
      return {
        ...state,
        titleIsAvailableRequestStatus: RequestStatus.RQ_SUCCESS,
        titleIsAvailableRequestResult: actions.payload.titleIsAvailableRequestResult,
      };
    }
    case adminlayout_CHECK_TITLE_IS_AVAILABLE_FAILURE: {
      return {
        ...state,
        titleIsAvailableRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    case adminlayout_GET_ALL_SLUGS_REQUEST: {
      return {
        ...state,
        getAllSlugsRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case adminlayout_GET_ALL_SLUGS_SUCCESS: {
      return {
        ...state,
        getAllSlugsRequestStatus: RequestStatus.RQ_SUCCESS,
        getAllSlugsRequestResult: actions.payload.getAllSlugsRequestResult,
      };
    }
    case adminlayout_GET_ALL_SLUGS_FAILURE: {
      return {
        ...state,
        getAllSlugsRequestStatus: RequestStatus.RQ_ERROR,
      };
    }
    default: {
      return state;
    }
  }
}
