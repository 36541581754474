import React from "react";
import "./product-detail-page.css";
//
import { withRouter } from "react-router-dom";
import { ProductDetail } from "../components/web-user-layout/product-detail";
import { ProductSliderList } from "../components/web-user-layout/product-slider-list";
import { RequestStatus } from "../constants";
import { BaseService } from "../services/base-service";

class ProductDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: null,
      titleId: null,
      showRelatedProducts: true,
    };
  }

  componentDidMount() {
    // const productId = this.props.match.params.sanphamid;
    // if(productId) {
    //     this.setState({
    //         productId: productId
    //     });
    //     this.props.loadProductDetail(productId);
    // };
    const titleId = this.props.match.params.titleid;
    if (titleId) {
      this.setState({
        titleId: titleId,
      });
      this.props.getProductDetailByTitleId(titleId);
    }
    this.handleResize();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.titleid !== nextProps.match.params.titleid) {
      const titleId = nextProps.match.params.titleid;
      if (titleId) {
        this.setState(
          {
            titleId: titleId,
            showRelatedProducts: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showRelatedProducts: true,
              });
            }, 200);
          }
        );
        this.props.getProductDetailByTitleId(titleId);
      }
    }

    if (
      this.props.productDetailRequestStatus !==
        nextProps.productDetailRequestStatus &&
      nextProps.productDetailRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState({
        productId: nextProps.productDetail.id,
      });
      this.props.loadProductListByCaterogy({
        id: nextProps.productDetail.category
          ? nextProps.productDetail.category.id
          : "",
      });
    }
  }

  handleResize = () => {
    setInterval(() => {
      BaseService.setMaxHeightByClassName(
        "product-list-catrgory-background-img"
      );
      BaseService.setMaxHeightByClassName("product-list-carousel-title-div");
      // BaseService.setMaxHeightByClassName('product-list-catrgory-tag-div');
      BaseService.setMaxHeightByClassName("product-list-carousel-price-div");
      BaseService.setMaxHeightByClassName(
        "product-list-carousel-promo-text-div"
      );
      BaseService.setHeightByWidthOfClass("product-list-carousel-item-img");
    }, 500);
  };

  render() {
    const html = (
      <>
        <ProductDetail
          productId={this.state.productId}
          productDetailRequestStatus={this.props.productDetailRequestStatus}
          productDetail={this.props.productDetail}
          windowWidth={this.props.windowWidth}
          addProductItemToCart={this.props.addProductItemToCart}
          insertSaleRequestsRequestStatus={
            this.props.insertSaleRequestsRequestStatus
          }
          insertSaleRequestsRequestResult={
            this.props.insertSaleRequestsRequestResult
          }
          insertSaleRequests={this.props.insertSaleRequests}
        />
        {/* <div className="product-detail-page-same-product-div">
          {this.state.showRelatedProducts ? (
            <ProductSliderList
              title="Sản phẩm tương tự"
              windowWidth={this.props.windowWidth}
              productListByCategoryRequestStatus={
                this.props.productListByCategoryRequestStatus
              }
              productListByCategory={this.props.productListByCategory.filter(
                (t) => t.id !== this.props.productDetail.id
              )}
              addProductItemToCart={this.props.addProductItemToCart}
            />
          ) : (
            <></>
          )}
        </div> */}
        <br />
      </>
    );
    return html;
  }
}

export default withRouter(ProductDetailPage);
