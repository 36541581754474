import {
    voucherpage_GET_VOUCHER_LIST_REQUEST,
    voucherpage_GET_VOUCHER_LIST_SUCCESS,
    voucherpage_GET_VOUCHER_LIST_ERROR,
    voucherpage_CHECK_VOUCHER_STATUC_REQUEST,
    voucherpage_CHECK_VOUCHER_STATUC_SUCCESS,
    voucherpage_CHECK_VOUCHER_STATUC_ERROR
} from '../actions/voucher-page-actions';
import { RequestStatus } from '../../constants';

const initialState = {
    voucherListRequestStatus: RequestStatus.RQ_NEW,
    voucherList: [],
    // 
    voucherStatusRequestStatus: RequestStatus.RQ_NEW,
    voucherStatus: null
}

export function voucherPageReducer(state = initialState, actions) {
    switch (actions.type) {
        case voucherpage_GET_VOUCHER_LIST_REQUEST: {
            return {
                ...state,
                voucherListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case voucherpage_GET_VOUCHER_LIST_SUCCESS: {
            return {
                ...state,
                voucherListRequestStatus: RequestStatus.RQ_SUCCESS,
                voucherList: actions.payload.voucherList
            }
        }
        case voucherpage_GET_VOUCHER_LIST_ERROR: {
            return {
                ...state,
                voucherListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case voucherpage_CHECK_VOUCHER_STATUC_REQUEST: {
            return {
                ...state,
                voucherStatusRequestStatus: RequestStatus.RQ_REQUEST,
                voucherStatus: null
            }
        }
        case voucherpage_CHECK_VOUCHER_STATUC_SUCCESS: {
            return {
                ...state,
                voucherStatusRequestStatus: RequestStatus.RQ_SUCCESS,
                voucherStatus: actions.payload.voucherStatus
            }
        }
        case voucherpage_CHECK_VOUCHER_STATUC_ERROR: {
            return {
                ...state,
                voucherStatusRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        default: {
            return state;
        }
    }
}