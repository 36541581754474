import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AdminDynamicPageContent } from "./admin-dynamic-page-content";
import "./admin-menu-list.css";
import "./admin-dynamic-pages-list.css";
import { FormModal } from "../public/form-components";
import { LoadingComponent } from "../public/loading-component";
import { BaseService } from "../../services/base-service";

export class AdminDynamicPagesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminPagesConfigs: [],
      targetAdminPageConfig: null,
      modalTitle: "",
      isOpenNewAdminDynamicPage: false,
      isDataHasUpdated: false,
      isCopyUrlCompleted: false,
      copyPageId: null,
    };
  }

  componentDidMount() {
    this.setAdminDynamicPagesList(this.props.adminPagesConfigs);
  }

  componentWillReceiveProps(nextProps) {
    this.setAdminDynamicPagesList(nextProps.adminPagesConfigs);
  }

  setAdminDynamicPagesList = (adminPagesConfigs) => {
    if (adminPagesConfigs) {
      this.setState((state) => {
        return {
          adminPagesConfigs,
        };
      });
    }
  };

  handleOnClickAdminDynamicPageItem = (page) => {
    const adminDynamicPage = {
      ...page,
    };
    this.setState(
      (state) => {
        const newState = {
          modalTitle: "Chỉnh sửa trang",
          isOpenNewAdminDynamicPage: true,
          targetAdminPageConfig: adminDynamicPage,
        };
        return newState;
      },
      () => {
        this.displayAdminDynamicPageContentModal("none");
      }
    );
  };

  handleOnClickCopyURL = (pageId, text) => {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    this.setState(
      {
        isCopyUrlCompleted: true,
        copyPageId: pageId,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isCopyUrlCompleted: false,
          });
        }, 1000);
      }
    );
  };

  renderAdminDynamicPageItem = (page) => {
    const content = JSON.parse(page.content);
    const element = !BaseService.stringIsNullOrWhiteSpace(content.html) ? (
      <div>
        <div
          className="admin-dynamic-page-list-page-item-div"
          onClick={() => {
            this.handleOnClickAdminDynamicPageItem(page);
          }}
        >
          <div className="admin-dynamic-page-list-page-item-cover-div"></div>
          <div
            className="admin-dynamic-page-list-page-item-html-div"
            dangerouslySetInnerHTML={{
              __html: content.html,
            }}
          ></div>
        </div>
        <div className="admin-dynamic-page-list-page-item-name-div">
          {page.name}
        </div>
        {this.state.isCopyUrlCompleted && this.state.copyPageId === page.id ? (
          <div className="admin-dynamic-page-list-page-item-name-copy-link-div">
            Đã copy vào clipboard
          </div>
        ) : (
          <div
            id={`LinkDiv${page.id}`}
            className="admin-dynamic-page-list-page-item-name-copy-link-div"
            onClick={() => {
              this.handleOnClickCopyURL(page.id, content.url);
            }}
          >
            Copy đường dẫn
          </div>
        )}
      </div>
    ) : (
      <div className="admin-dynamic-page-list-page-item-div"></div>
    );
    return element;
  };

  renderAdminDynamicPagesList = () => {
    const element = (
      <>
        <Row>
          {this.state.adminPagesConfigs.length > 0 ? (
            this.state.adminPagesConfigs.map((page) => (
              <Col sm={6} lg={3} className='admin-dynamic-page-list-page-item-col'>
                {this.renderAdminDynamicPageItem(page)}
              </Col>
            ))
          ) : (
            <Col>
              <div>Chưa có trang</div>
            </Col>
          )}
        </Row>
      </>
    );
    return element;
  };

  handleOnClickAddAdminDynamicPage = () => {
    const newAdminDynamicPage = {
      id: null,
      name: "",
      content: "",
      orderNumber: 0,
      type: "UI_CONFIGS_2_PAGES",
      isDeleted: false,
    };
    this.setState(
      (state) => {
        const newState = {
          modalTitle: "Thêm mới trang",
          isOpenNewAdminDynamicPage: true,
          targetAdminPageConfig: newAdminDynamicPage,
        };
        return newState;
      },
      () => {
        this.displayAdminDynamicPageContentModal("none");
      }
    );
  };

  closeModal = () => {
    this.setState(
      {
        isOpenNewAdminDynamicPage: false,
        targetAdminPageConfig: null,
        isDataHasUpdated: false,
      },
      () => {
        this.displayAdminDynamicPageContentModal("block");
      }
    );
  };

  renderAdminDynamicPagesListAddButton = () => {
    const element = (
      <>
        <Row>
          <Col>
            <div
              className="admin-menu-list-add-menu-button-div"
              onClick={this.handleOnClickAddAdminDynamicPage}
            >
              Thêm trang mới
            </div>
          </Col>
        </Row>
      </>
    );
    return element;
  };

  displayAdminDynamicPageContentModal = (value) => {
    let adminDynamicPageContentModal = document.getElementById(
      "AdminUIConfigsModal"
    );
    if (adminDynamicPageContentModal) {
      value = value === "block" ? 1 : 0;
      adminDynamicPageContentModal.style.opacity = value;
    }
  };

  trackIsDataHasUpdated = (isDataHasUpdated) => {
    this.setState({
      isDataHasUpdated: isDataHasUpdated,
    });
  };

  renderAdminDynamicPageContentModal = () => {
    const element = (
      <>
        <FormModal
          id="AdminDynamicPageContentModal"
          size="xl"
          isShowModal={this.state.isOpenNewAdminDynamicPage}
          modalTitle={this.state.modalTitle}
          modalContent={
            this.state.targetAdminPageConfig ? (
              <AdminDynamicPageContent
                save={this.props.save}
                adminDynamicPage={this.state.targetAdminPageConfig}
                trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                insertUIConfigsRequestStatus={
                  this.props.insertUIConfigsRequestStatus
                }
                updateUIConfigsRequestStatus={
                  this.props.updateUIConfigsRequestStatus
                }
              />
            ) : (
              <div style={{ height: "450px" }}>
                <LoadingComponent />
              </div>
            )
          }
          isDataHasUpdated={this.state.isDataHasUpdated}
          closeModal={this.closeModal}
        />
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <Container fluid={true}>
          {this.renderAdminDynamicPagesList()}
          {this.renderAdminDynamicPagesListAddButton()}
          {this.renderAdminDynamicPageContentModal()}
        </Container>
      </>
    );
    return html;
  }
}
