import { connect } from "react-redux";
import WebUserLayout from "../layouts/web-user-layout";
import { webUserLayoutSelectors } from "../redux/selectors";
import { webUserLayoutActions } from "../redux/actions";

const mapStateToProps = (state) => {
  return {
    navMenuListRequestStatus: webUserLayoutSelectors.getNavMenuListRequestStatus(
      state
    ),
    navMenuList: webUserLayoutSelectors.getNavMenuList(state),
    productItemsInCart: webUserLayoutSelectors.getProductItemsInCart(state),
    productItemsInCart_LastestModifyTime: webUserLayoutSelectors.getProductItemsInCart_LastestModifyTime(
      state
    ),
    productItem: webUserLayoutSelectors.getProductItem(state),
    productItemsInCart_AddRequestStatus: webUserLayoutSelectors.getProductItemsInCart_AddRequestStatus(
      state
    ),
    insertSaleRequestsRequestStatus: webUserLayoutSelectors.getInsertSaleRequestsRequestStatus(
      state
    ),
    insertSaleRequestsRequestResult: webUserLayoutSelectors.getInsertSaleRequestsRequestResult(
      state
    ),
    footerInfoData: webUserLayoutSelectors.getFooterInfoData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadNavMenu: () => {
    dispatch(webUserLayoutActions.loadNavMenu());
  },

  loadFooterInfo: () => {
    dispatch(webUserLayoutActions.loadFooterInfo());
  },

  getProductItemsInCart: () => {
    dispatch(webUserLayoutActions.getProductItemsInCart());
  },

  insertSaleRequests: (saleRequests) => {
    dispatch(webUserLayoutActions.insertSaleRequests(saleRequests));
  },

  updateProductItemInCart: (productItemsInCart) => {
    dispatch(webUserLayoutActions.updateProductItemInCart(productItemsInCart));
  },
});

const WebUserLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebUserLayout);

export default WebUserLayoutContainer;
