import { connect } from 'react-redux';
import { loginPageSelectors } from '../redux/selectors';
import { loginPageActions } from '../redux/actions';
import LoginPage from '../pages/login-page';

const mapStateToProps = (state) => {
    return {
        history: {},
        loginRequestStatus: loginPageSelectors.getLoginRequestStatus(state),
        loginData: loginPageSelectors.getLoginData(state),
        errorMessage: loginPageSelectors.getLoginMessage(state),
        // 
        getLoginDataRequestStatus: loginPageSelectors.getGetLoginDataRequestStatus(state),
        // 
        clearLoginDataRequestStatus: loginPageSelectors.getClearLoginDataRequestStatus(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    login: (username, password) => {
        dispatch(loginPageActions.login(username, password));
    },

    getLoginData: () => {
        dispatch(loginPageActions.getLoginData());
    },

    clearLoginData: () => {
        dispatch(loginPageActions.clearLoginData());
    }
})

const LoginPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage)

export default LoginPageContainer;