import React from "react";
import "./admin-order-page.css";
//
import { AdminOrderList } from "../components/admin-layout/admin-order-list";

export class AdminOrderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <AdminOrderList
          windowWidth={this.props.windowWidth}
          getSaleRequestsListOrderRequestStatus={
            this.props.getSaleRequestsListOrderRequestStatus
          }
          getSaleRequestsListOrderRequestResult={
            this.props.getSaleRequestsListOrderRequestResult
          }
          updateSaleRequests={this.props.updateSaleRequests}
        />
      </>
    );
    return html;
  }
}
