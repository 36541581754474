import React from 'react';
import './admin-banner-item-detail.css';
// 
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import {
    FormTextInput,
    FormButton, FormSunEditor, FormImageUploader
} from '../public/form-components';
import { FormRadioList, FormVideo } from '../public/form-components';
import { BaseService } from '../../services/base-service';

export class FormikAdminBannerItemDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bannerItemType: [
                { text: 'Ảnh', value: 'anh' },
                { text: 'Video', value: 'video' }
            ],
            bannerItemTypeSelectedValue: (BaseService.stringIsNullOrWhiteSpace(this.props.bannerItem.videoUrl) ? ('anh') : ('video')),
            errorImage: null,
            errorVideo: null
        }
    }

    onChangeBannerItemTypeValue = (value) => {
        this.setState({
            bannerItemTypeSelectedValue: value
        })
    }

    changeBannerItemName = (value) => {
        this.props.setFieldValue('bannerItem.name', value);
    }

    blurBannerItemName = (e) => {
        this.props.setFieldTouched('errorBannerItemName', true);
    }

    changeBannerItemDescription = (value) => {
        this.props.setFieldValue('bannerItem.description', value);
    }

    changeBannerItemLinkUrl = (value) => {
        this.props.setFieldValue('bannerItem.linkUrl', value);
    }

    changeBannerItemVideoUrl = (value) => {
        if (!BaseService.stringIsNullOrWhiteSpace(value)) {
            this.setState({
                errorVideo: null
            })
        }
        this.props.setFieldValue('bannerItem.videoUrl', value);
    }

    onChangeImageInput = (res) => {
        if (res) {
            this.setState({
                errorImage: null
            })
        }
        const imageId = (res) ? (res.data.id) : (null);
        const imageName = (res) ? (res.data.name) : (null);
        let image = this.props.values.bannerItem.image;
        if (!image || image === 'none') image = {};
        image.id = imageId;
        image.name = imageName;
        this.props.setFieldValue('bannerItem.image', image);
    }

    getImageElement = () => {
        const element = <>
            <FormImageUploader
                labelText='Chọn hình ảnh'
                guide='(1920x848, < 5MB)'
                type='banner-item-image'
                imgUrl={
                    (this.props.values.bannerItem.image) ? (
                        BaseService.getImageUrlFromServer(this.props.values.bannerItem.image.id, this.props.values.bannerItem.image.name, this.props.values.bannerItem.image.url)
                    ) : (null)
                }
                onChangeImageInput={this.onChangeImageInput}
                error={(this.state.errorImage) ? (this.state.errorImage) : (null)}
            />
            <FormTextInput
                labelText='Nhập đường dẫn hình ảnh'
                value={this.props.values.bannerItem.linkUrl}
                onChange={this.changeBannerItemLinkUrl}
            />
        </>;
        return element;
    }

    getVideoElement = () => {
        const element = <>
            <FormTextInput
                labelText='Nhập đường dẫn'
                guide='(Youtube)'
                value={this.props.values.bannerItem.videoUrl}
                onChange={this.changeBannerItemVideoUrl}
                // placeholder='Đường dẫn video (VD: yotube.com/...)'
                error={(this.state.errorVideo) ? (this.state.errorVideo) : (null)}
            />
            {
                (!BaseService.stringIsNullOrWhiteSpace(this.props.values.bannerItem.videoUrl)) ? (
                    <FormVideo
                        videoUrl={this.props.values.bannerItem.videoUrl}
                        canAccess={true}
                        autoPlay={false}
                    />
                ) : (<></>)
            }
        </>;
        return element;
    }

    save = () => {
        let isValid = true;
        if (this.state.bannerItemTypeSelectedValue === 'anh') {
            if (!this.props.values.bannerItem.image || !this.props.values.bannerItem.image.id) {
                this.setState({
                    errorImage: 'Chưa chọn hình ảnh'
                });
                isValid = false;
            }
        } else {
            if (BaseService.stringIsNullOrWhiteSpace(this.props.values.bannerItem.videoUrl)) {
                this.setState({
                    errorVideo: 'Chưa nhập đường dẫn video'
                });
                isValid = false;
            }
        }
        if (!isValid) return;
        if (this.state.bannerItemTypeSelectedValue === 'anh') {
            this.props.setFieldValue('bannerItem.videoUrl', null);
        } else {
            this.props.setFieldValue('bannerItem.image', null);
        }
        this.props.handleSubmit();
    }

    render() {
        const html = <>
            <Row>
                <Col>
                    <Row>
                        <Col lg={12}>
                            <FormTextInput
                                labelText='Tên'
                                value={this.props.values.bannerItem.name}
                                onChange={this.changeBannerItemName}
                                onBlur={this.blurBannerItemName}
                                error={(this.props.touched.errorBannerItemName && this.props.errors.errorBannerItemName) ? (this.props.errors.errorBannerItemName) : (null)}
                                isRequired={true}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={12}>
                            <label className='form-components-text-input-label'>Ghi Chú</label>
                            <FormSunEditor
                                data={(this.props.values.bannerItem.description) ? (this.props.values.bannerItem.description) : ('')}
                                onChange={this.changeBannerItemDescription}
                            // isSimple={true}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={12}>
                            <FormRadioList
                                name='BannerItemType'
                                options={this.state.bannerItemType}
                                selectedValue={this.state.bannerItemTypeSelectedValue}
                                onChangeValue={this.onChangeBannerItemTypeValue}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                (this.state.bannerItemTypeSelectedValue === 'anh') ? (
                                    this.getImageElement()
                                ) : (
                                    this.getVideoElement()
                                )
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <br />
            <Row>
                <Col lg={12}>
                    <FormButton
                        // type='save'
                        text='Xác nhận'
                        onClick={this.save}
                    />
                </Col>
            </Row>
        </>;
        return (
            html
        );
    }
}

const myEnhancedForm = withFormik({
    enableReinitialize: true,

    mapPropsToValues: (props) => {
        console.log('mapPropsToValues - props', props);
        let values = {
            bannerItem: props.bannerItem,
            errorBannerItemName: null
        };
        return values;
    },

    validate: values => {
        const errors = {};

        if (BaseService.stringIsNullOrWhiteSpace(values.bannerItem.name)) {
            errors.errorBannerItemName = 'Tên ảnh/video không được rỗng';
        };

        return errors;
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(true);
        console.log('values', values);
        // let bannerItem = BannerService.getBannerItemInput(values.bannerItem);
        props.save(values.bannerItem);
    },

    displayName: 'FormikAdminBannerItemDetail'
});

export const AdminBannerItemDetail = withRouter(myEnhancedForm(FormikAdminBannerItemDetail));