import React from 'react';
import './product-slider-list.css';
// 
import { BaseService } from '../../services/base-service';
// import { RequestStatus } from '../../constants';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { ProductItem } from './product-item';

export class ProductSliderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numberOfProductItemPerSlide: 2
        }
    }

    componentDidMount() {
        const windowWidth = this.props.windowWidth;
        this.setColSize(windowWidth);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.windowWidth !== nextProps.windowWidth) {
            console.log('ProductSliderList - componentWillReceiveProps'); 
            const windowWidth = nextProps.windowWidth;
            this.setColSize(windowWidth);
        }
        console.log('nextProps.productListByCategoryRequestStatus', nextProps.productListByCategoryRequestStatus);
        // if(
        //     this.props.productListByCategoryRequestStatus !== nextProps.productListByCategoryRequestStatus && nextProps.productListByCategoryRequestStatus === RequestStatus.RQ_SUCCESS
        // ) {
            setTimeout(() => {
                console.log('ProductSliderList - componentWillReceiveProps'); 
                this.handleResize();
                window.addEventListener('resize', this.handleResize);
            }, 0);
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    setColSize = (windowWidth) => {
        let numberOfProductItemPerSlide = this.state.numberOfProductItemPerSlide;
        if (windowWidth >= 992) {
            numberOfProductItemPerSlide = 4;
        } else if (windowWidth >= 768) {
            numberOfProductItemPerSlide = 3;
        } else {
            numberOfProductItemPerSlide = 2;
        }
        console.log(windowWidth + '-' + numberOfProductItemPerSlide);
        this.setState({
            numberOfProductItemPerSlide: numberOfProductItemPerSlide
        })
    }

    getProductSliderListElement = () => {
        if(this.props.productListByCategory && this.props.productListByCategory.length > 0) {
            let sliderListItem = [];
            let index = 0;
            do {
                const productItem = this.props.productListByCategory.slice(index, index + this.state.numberOfProductItemPerSlide);
                sliderListItem.push(productItem);
                index += this.state.numberOfProductItemPerSlide;
            } while(index < this.props.productListByCategory.length);
            
            const element = <Carousel
                controls={false}
                interval={null}
                nextIcon={<i className='product-category-playback-icon fas fa-caret-right'></i>}
                prevIcon={<i className='product-category-playback-icon fas fa-caret-left'></i>}
            >
                {
                    sliderListItem.map((sliderProductList, index) => <Carousel.Item key={index}>
                        {this.getProductListItemsElement(sliderProductList)}
                    </Carousel.Item>)
                }
            </Carousel>;
            return element;

        }else {
            return <></>;
        }
    }

    getProductListItemsElement = (productList) => {
        const element = <Row className='product-list-carousel-div'>
            {
                productList.map((productItem, index) => <React.Fragment key={index}>
                    <Col xs={6} md={3} lg={3} className='product-list-carousel-item-col'>
                        <ProductItem
                            productItem={productItem}
                            addProductItemToCart={this.props.addProductItemToCart}
                        />
                    </Col>
                </React.Fragment>)
            }
        </Row>;   
        return element;
    }

    handleResize = () => {
        BaseService.setMaxHeightByClassName('product-list-catrgory-background-img');
        BaseService.setMaxHeightByClassName('product-list-carousel-title-div');
        // BaseService.setMaxHeightByClassName('product-list-catrgory-tag-div');
        BaseService.setMaxHeightByClassName('product-list-carousel-price-div');
        BaseService.setMaxHeightByClassName('product-list-carousel-promo-text-div');
    };

    render() {
        const html = <>
            <Container className='product-slider-list-container'>
                {
                    (this.props.title)?(
                        <>
                            <Row>
                                <Col>
                                    <div className='product-slider-list-title-div'>{this.props.title}</div>
                                </Col>
                            </Row>
                        </>
                    ):(<></>)
                }
                <Row>
                    <Col>
                        {
                            this.getProductSliderListElement()
                            // this.props.productListByCategory.map((product, index) => <React.Fragment key={index}>
                            //     <div>{product.title}</div>
                            // </React.Fragment>)
                        }
                    </Col>
                </Row>
            </Container>
        </>;
        return (
            html
        );
    }
}