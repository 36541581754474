const getSubState = (state) => state.uiConfigsPageReducer;

export const getUIConfigsListRequestStatus = (state) => getSubState(state).uiConfigsListRequestStatus;
export const getUIConfigsListRequestResult = (state) => getSubState(state).uiConfigsListRequestResult;

export const getUIConfigsDetailRequestStatus = (state) => getSubState(state).uiConfigsDetailRequestStatus;
export const getUIConfigsDetailRequestResult = (state) => getSubState(state).uiConfigsDetailRequestResult;

export const getInsertUIConfigsRequestStatus = (state) => getSubState(state).insertUIConfigsRequestStatus;
export const getInsertUIConfigsRequestResult = (state) => getSubState(state).insertUIConfigsRequestResult;

export const getUpdateUIConfigsRequestStatus = (state) => getSubState(state).updateUIConfigsRequestStatus;
export const getUpdateUIConfigsRequestResult = (state) => getSubState(state).updateUIConfigsRequestResult;

export const getUpdateUIConfigsOrderNumberRequestStatus = (state) => getSubState(state).updateUIConfigsOrderNumberRequestStatus;
export const getUpdateUIConfigsOrderNumberRequestResult = (state) => getSubState(state).updateUIConfigsOrderNumberRequestResult;

export const getUpdateUIConfigsIsDeletedRequestStatus = (state) => getSubState(state).updateUIConfigsIsDeletedRequestStatus;
export const getUpdateUIConfigsIsDeletedRequestResult = (state) => getSubState(state).updateUIConfigsIsDeletedRequestResult;

export const getDeleteUIConfigsRequestStatus = (state) => getSubState(state).deleteUIConfigsRequestStatus;
export const getDeleteUIConfigsRequestResult = (state) => getSubState(state).deleteUIConfigsRequestResult;