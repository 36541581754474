import {
    videopage_GET_VIDEO_LIST_REQUEST,
    videopage_GET_VIDEO_LIST_SUCCESS,
    videopage_GET_VIDEO_LIST_ERROR,
    videopage_GET_VIDEO_DETAIL_REQUEST,
    videopage_GET_VIDEO_DETAIL_SUCCESS,
    videopage_GET_VIDEO_DETAIL_ERROR,
    videopage_INSERT_VIDEO_SUCCESS,
    videopage_INSERT_VIDEO_REQUEST,
    videopage_INSERT_VIDEO_ERROR,
    videopage_UPDATE_VIDEO_REQUEST,
    videopage_UPDATE_VIDEO_SUCCESS,
    videopage_UPDATE_VIDEO_ERROR,
    videopage_UPDATE_VIDEO_ORDER_NUMBER_REQUEST,
    videopage_UPDATE_VIDEO_ORDER_NUMBER_SUCCESS,
    videopage_UPDATE_VIDEO_ORDER_NUMBER_ERROR,
    videopage_UPDATE_VIDEO_IS_DELETED_REQUEST,
    videopage_UPDATE_VIDEO_IS_DELETED_SUCCESS,
    videopage_UPDATE_VIDEO_IS_DELETED_ERROR,
    videopage_DELETE_VIDEO_REQUEST,
    videopage_DELETE_VIDEO_SUCCESS,
    videopage_DELETE_VIDEO_ERROR
} from '../actions/video-page-actions';
import {
    RequestStatus
} from '../../constants';

const initialState  = {
    videoListRequestStatus: RequestStatus.RQ_NEW,
    videoList: [],
    // 
    videoDetailRequestStatus: RequestStatus.RQ_NEW,
    videoDetail: null,
    videoDetail_originName: null,
    // 
    insertVideoRequestStatus: RequestStatus.RQ_NEW,
    insertedVideo: null,
    // 
    updateVideoRequestStatus: RequestStatus.RQ_NEW,
    updatedVideo: null,
    // 
    updateVideoOrderNumberRequestStatus: RequestStatus.RQ_NEW,
    updatedVideoOrderNumber: null,
    // 
    updateVideoIsDeletedRequestStatus: RequestStatus.RQ_NEW,
    updatedVideoIsDeleted: null,
    // 
    deleteVideoRequestStatus: RequestStatus.RQ_NEW,
    deletedVideo: null,
    // 
    errorMessage: null
}

export function videoPageReducer(state = initialState, actions) {
    switch(actions.type) {
        case videopage_GET_VIDEO_LIST_REQUEST: {
            return {
                ...state,
                videoListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_GET_VIDEO_LIST_SUCCESS: {
            return {
                ...state,
                videoListRequestStatus: RequestStatus.RQ_SUCCESS,
                videoList: actions.payload.videoList
            }
        }
        case videopage_GET_VIDEO_LIST_ERROR: {
            return {
                ...state,
                videoListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case videopage_GET_VIDEO_DETAIL_REQUEST: {
            return {
                ...state,
                videoDetailRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_GET_VIDEO_DETAIL_SUCCESS: {
            const videoDetail = actions.payload.videoDetail;
            return {
                ...state,
                videoDetailRequestStatus: RequestStatus.RQ_SUCCESS,
                videoDetail: videoDetail
            }
        }
        case videopage_GET_VIDEO_DETAIL_ERROR: {
            return {
                ...state,
                videoDetailRequestStatus: RequestStatus.RQ_ERROR
            }
        }
        case videopage_INSERT_VIDEO_REQUEST: {
            return {
                ...state,
                insertVideoRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_INSERT_VIDEO_SUCCESS: {
            return {
                ...state,
                insertVideoRequestStatus: RequestStatus.RQ_SUCCESS,
                insertedVideo: actions.payload.insertedVideo
            }
        }
        case videopage_INSERT_VIDEO_ERROR: {
            return {
                ...state,
                insertVideoRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case videopage_UPDATE_VIDEO_REQUEST: {
            return {
                ...state,
                updateVideoRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_UPDATE_VIDEO_SUCCESS: {
            return {
                ...state,
                updateVideoRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedVideo: actions.payload.updatedVideo
            }
        }
        case videopage_UPDATE_VIDEO_ERROR: {
            return {
                ...state,
                updateVideoRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case videopage_UPDATE_VIDEO_ORDER_NUMBER_REQUEST: {
            return {
                ...state,
                updateVideoOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_UPDATE_VIDEO_ORDER_NUMBER_SUCCESS: {
            return {
                ...state,
                updateVideoOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedVideoOrderNumber: actions.payload.updatedVideo
            }
        }
        case videopage_UPDATE_VIDEO_ORDER_NUMBER_ERROR: {
            return {
                ...state,
                updateVideoOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case videopage_UPDATE_VIDEO_IS_DELETED_REQUEST: {
            return {
                ...state,
                updateVideoIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_UPDATE_VIDEO_IS_DELETED_SUCCESS: {
            return {
                ...state,
                updateVideoIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedVideoIsDeleted: actions.payload.updatedVideo
            }
        }
        case videopage_UPDATE_VIDEO_IS_DELETED_ERROR: {
            return {
                ...state,
                updateVideoIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case videopage_DELETE_VIDEO_REQUEST: {
            return {
                ...state,
                deleteVideoRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case videopage_DELETE_VIDEO_SUCCESS: {
            return {
                ...state,
                deleteVideoRequestStatus: RequestStatus.RQ_SUCCESS,
                deletedVideo: actions.payload.deletedVideo
            }
        }
        case videopage_DELETE_VIDEO_ERROR: {
            return {
                ...state,
                deleteVideoRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }       
        default: {
            return state;
        }
    }
}