import React from 'react';
import './loading-component.css';
// 

export class LoadingComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div className='loading-component-container-div'>
                <i className="fas fa-spinner fa-spin"></i>
                <div>
                    Đang tải, xin vui lòng đợi trong giây lát ...
                </div>
            </div>
        </>;
        return (
            html
        );
    }
}