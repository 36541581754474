const getSubState = (state) => state.videoPageReducer;

export const getVideoListRequestStatus = (state) => getSubState(state).videoListRequestStatus;
export const getVideoList = (state) => getSubState(state).videoList;
export const getVideoDetailRequestStatus = (state) => getSubState(state).videoDetailRequestStatus;
export const getVideoDetail = (state) => getSubState(state).videoDetail;
export const getInsertVideoRequestStatus = (state) => getSubState(state).insertVideoRequestStatus;
export const getInsertedVideo = (state) => getSubState(state).insertedVideo;
export const getUpdateVideoRequestStatus = (state) => getSubState(state).updateVideoRequestStatus;
export const getUpdatedVideo = (state) => getSubState(state).updatedVideo;
export const getUpdateVideoOrderNumberRequestStatus = (state) => getSubState(state).updateVideoOrderNumberRequestStatus;
export const getUpdatedVideoOrderNumber = (state) => getSubState(state).updatedVideoOrderNumber;
export const getUpdateVideoIsDeletedRequestStatus = (state) => getSubState(state).updateVideoIsDeletedRequestStatus;
export const getUpdatedVideoIsDeleted = (state) => getSubState(state).updatedVideoIsDeleted;
export const getDeleteVideoRequestStatus = (state) => getSubState(state).deleteVideoRequestStatus;
export const getDeletedVideo = (state) => getSubState(state).deletedVideo;
export const getErrorMessage = (state) => getSubState(state).errorMessage;