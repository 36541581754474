import { connect } from "react-redux";
import { newsPageSelectors } from "../redux/selectors";
import { newsPageActions } from "../redux/actions";
import NewsDetailPage from "../pages/news-detail-page";
import { BaseService } from "../services/base-service";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    newsDetailRequestStatus: newsPageSelectors.getNewsDetailRequestStatus(
      state
    ),
    newsDetail: newsPageSelectors.getNewsDetail(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewsDetailByTitleId: (titleId) => {
    dispatch(newsPageActions.getNewsDetailByTitleId(titleId));
  },

  // getNewsDetail: (newsId) => {
  //   BaseService.setGAPageView(`/tin-tuc/${newsId}`);
  //   dispatch(newsPageActions.getNewsDetail(newsId));
  // },
});

const NewsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsDetailPage);

export default NewsPageContainer;
