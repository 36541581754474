import React from 'react';
import './color-tags-slider.css';
// 
import { BaseService } from '../../services/base-service';
import { Carousel } from 'react-bootstrap';

export class ColorTagsSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            colorTagGroups: [],
            colorTagItemSelectedId: null,
            numberOfItemPerSlide: (this.props.numberOfItemPerSlide)?(this.props.numberOfItemPerSlide):(5)
        }
    }

    componentDidMount() {
        this.setStateColorTagGroups(this.props.colorTags);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.colorTags !== nextProps.colorTags) {
            this.setStateColorTagGroups(nextProps.colorTags);
        }
    }

    setStateColorTagGroups = (colorTags) => {
        let colorTagGroups = [[]];
        let colorTagGroupLength = 1;
        let numberOfItem = 1;
        const propsColorTags =  BaseService.getOrderListWithName(this.props.colorTags).filter(item => item.id !== -1);
        propsColorTags.forEach((item, index) => {
            colorTagGroups[colorTagGroupLength - 1].push(item);
            if(index < propsColorTags.length - 1) {
                if(numberOfItem === this.state.numberOfItemPerSlide) {
                    colorTagGroups[colorTagGroupLength] = [];
                    colorTagGroupLength += 1;
                    numberOfItem = 0;
                }
                numberOfItem += 1;
            }            
        })
        let additionTempItem = this.state.numberOfItemPerSlide - colorTagGroups[colorTagGroups.length - 1].length;
        while(additionTempItem > 0) {
            colorTagGroups[colorTagGroups.length - 1].push({});
            additionTempItem -= 1;
        }
        this.setState({
            colorTagGroups: colorTagGroups
        })
    }

    handleOnClickColorTagItem = (colorTagItem) => {
        const colorTagItemSelectedId = (this.state.colorTagItemSelectedId !== colorTagItem.id)?(colorTagItem.id):(null);
        this.setState({
            colorTagItemSelectedId: colorTagItemSelectedId
        })
        this.props.handleOnClickColorTagItem(colorTagItemSelectedId);
    }

    isSelectedColorTag = (colorTagItem) => {
        let selectedClass = '';
        if(this.state.colorTagItemSelectedId === colorTagItem.id) {
            selectedClass = ' color-tags-slider-item-group-item-selected-div';
        }
        return selectedClass;
    }

    render() {
        const html = <>
            <div className='color-tags-slider-wrapper-div'>
                <Carousel id='ColorTagsSliderCarousel'
                    indicators={false}
                    interval={null}
                    nextIcon={<i className='color-tags-slider-playback-icon fas fa-caret-right'></i>}
                    prevIcon={<i className='color-tags-slider-playback-icon fas fa-caret-left'></i>}
                >
                    {
                        this.state.colorTagGroups.map((colorTagGroupItem, colorTagGroupIndex) => 
                            <Carousel.Item key={colorTagGroupIndex}>
                                <div className='color-tags-slider-item-group-div'>
                                    {
                                        colorTagGroupItem.map((colorTagItem, colorTagIndex) => <>
                                            {
                                                (colorTagItem.id)?(
                                                    <div key={colorTagIndex} className={`color-tags-slider-item-group-item-div${this.isSelectedColorTag(colorTagItem)}`}
                                                        onClick={() => { this.handleOnClickColorTagItem(colorTagItem); }}
                                                        title={colorTagItem.name}
                                                    >
                                                        <div>
                                                            <div className='color-tags-slider-item-group-item-color-div' style={{
                                                                backgroundColor: (!BaseService.stringIsNullOrWhiteSpace(colorTagItem.colorCode))?(`#${colorTagItem.colorCode}`):('#fff')
                                                            }}></div>
                                                            <div className='color-tags-slider-item-group-item-name-div'>{colorTagItem.name}</div>
                                                        </div>
                                                    </div>
                                                ):(
                                                    <div key={colorTagIndex} className={`color-tags-slider-item-group-item-div`}></div>
                                                )
                                            }
                                        </>)
                                    }
                                </div>
                            </Carousel.Item>
                        )
                    }
                </Carousel>
                {/* {
                    this.props.colorTags.map(item => <>
                        <div onClick={() => { this.handleOnClickColorTagItem(item); }}>
                            {item.name}
                        </div>
                    </>)
                } */}
            </div>
        </>;
        return (
            html
        );
    }
}