import React from "react";
import "./admin-news-page.css";
//
import { AdminNewsList } from "../components/admin-layout/admin-news-list";

export class AdminNewsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <AdminNewsList
          windowWidth={this.props.windowWidth}
          newsListRequestStatus={this.props.newsListRequestStatus}
          newsList={this.props.newsList}
          newsDetailRequestStatus={this.props.newsDetailRequestStatus}
          newsDetail={this.props.newsDetail}
          newsDetail_originName={this.props.newsDetail_originName}
          insertNewsRequestStatus={this.props.insertNewsRequestStatus}
          insertedNews={this.props.insertedNews}
          updateNewsRequestStatus={this.props.updateNewsRequestStatus}
          updatedNews={this.props.updatedNews}
          updateNewsOrderNumberRequestStatus={
            this.props.updateNewsOrderNumberRequestStatus
          }
          updatedNewsOrderNumber={this.props.updatedNewsOrderNumber}
          updateNewsIsDeletedRequestStatus={
            this.props.updateNewsIsDeletedRequestStatus
          }
          updatedNewsIsDeleted={this.props.updatedNewsIsDeleted}
          deleteNewsRequestStatus={this.props.deleteNewsRequestStatus}
          deletedNews={this.props.deletedNews}
          errorMessage={this.props.newsErrorMessage}
          //
          getNewsList={this.props.getNewsList}
          getNewsDetail={this.props.getNewsDetail}
          insertNews={this.props.insertNews}
          updateNews={this.props.updateNews}
          updateNews_OrderNumber={this.props.updateNews_OrderNumber}
          updateNews_IsDeleted={this.props.updateNews_IsDeleted}
          deleteNews={this.props.deleteNews}
          //
          titleIsAvailableRequestStatus={
            this.props.titleIsAvailableRequestStatus
          }
          titleIsAvailableRequestResult={
            this.props.titleIsAvailableRequestResult
          }
          checkTitleIsAvailable={this.props.checkTitleIsAvailable}
          //
          getAllSlugs={this.props.getAllSlugs}
        />
      </>
    );
    return html;
  }
}
