import { VideoService } from '../../services/video-service';
// import { BaseService } from '../../services/base-service';
import {
    Messages
} from '../../constants';

export const videopage_GET_VIDEO_LIST_REQUEST = 'videopage_GET_VIDEO_LIST_REQUEST';
export const videopage_GET_VIDEO_LIST_SUCCESS = 'videopage_GET_VIDEO_LIST_SUCCESS';
export const videopage_GET_VIDEO_LIST_ERROR = 'videopage_GET_VIDEO_LIST_ERROR';

export const videopage_GET_VIDEO_DETAIL_REQUEST = 'videopage_GET_VIDEO_DETAIL_REQUEST';
export const videopage_GET_VIDEO_DETAIL_SUCCESS = 'videopage_GET_VIDEO_DETAIL_SUCCESS';
export const videopage_GET_VIDEO_DETAIL_ERROR = 'videopage_GET_VIDEO_DETAIL_ERROR';

export const videopage_INSERT_VIDEO_SUCCESS = 'videopage_INSERT_VIDEO_SUCCESS';
export const videopage_INSERT_VIDEO_REQUEST = 'videopage_INSERT_VIDEO_REQUEST';
export const videopage_INSERT_VIDEO_ERROR = 'videopage_INSERT_VIDEO_ERROR';

export const videopage_UPDATE_VIDEO_REQUEST = 'videopage_UPDATE_VIDEO_REQUEST';
export const videopage_UPDATE_VIDEO_SUCCESS = 'videopage_UPDATE_VIDEO_SUCCESS';
export const videopage_UPDATE_VIDEO_ERROR = 'videopage_UPDATE_VIDEO_ERROR';

export const videopage_UPDATE_VIDEO_ORDER_NUMBER_REQUEST = 'videopage_UPDATE_VIDEO_ORDER_NUMBER_REQUEST';
export const videopage_UPDATE_VIDEO_ORDER_NUMBER_SUCCESS = 'videopage_UPDATE_VIDEO_ORDER_NUMBER_SUCCESS';
export const videopage_UPDATE_VIDEO_ORDER_NUMBER_ERROR = 'videopage_UPDATE_VIDEO_ORDER_NUMBER_ERROR';

export const videopage_UPDATE_VIDEO_IS_DELETED_REQUEST = 'videopage_UPDATE_VIDEO_IS_DELETED_REQUEST';
export const videopage_UPDATE_VIDEO_IS_DELETED_SUCCESS = 'videopage_UPDATE_VIDEO_IS_DELETED_SUCCESS';
export const videopage_UPDATE_VIDEO_IS_DELETED_ERROR = 'videopage_UPDATE_VIDEO_IS_DELETED_ERROR';

export const videopage_DELETE_VIDEO_REQUEST = 'videopage_DELETE_VIDEO_REQUEST';
export const videopage_DELETE_VIDEO_SUCCESS = 'videopage_DELETE_VIDEO_SUCCESS';
export const videopage_DELETE_VIDEO_ERROR = 'videopage_DELETE_VIDEO_ERROR';

export const getVideoList = () => {
    return(dispatch) => {
        dispatch({
            type: videopage_GET_VIDEO_LIST_REQUEST
        });

        const request = VideoService.getVideoList();
        if(request) {
            request.then(data => {
                dispatch({
                    type: videopage_GET_VIDEO_LIST_SUCCESS,
                    payload: {
                        videoList: data
                    }
                });
            }, error => {
                dispatch({
                    type: videopage_GET_VIDEO_LIST_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const getVideoDetail = (videoId) => {
    return(dispatch) => {
        dispatch({
            type: videopage_GET_VIDEO_DETAIL_REQUEST
        });

        const request = VideoService.getVideoDetail(videoId);
        if(request) {
            request.then(data => {

                const videoDetail = data;
                console.log('VideoService.getVideoDetail(videoId);', data);
                dispatch({
                    type: videopage_GET_VIDEO_DETAIL_SUCCESS,
                    payload: {
                        videoDetail: videoDetail
                    }
                });
            }, error => {
                dispatch({
                    type: videopage_GET_VIDEO_DETAIL_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }   
}

export const insertVideo = (video) => {
    return(dispatch) => {
        dispatch({
            type: videopage_INSERT_VIDEO_REQUEST
        });

        const request = VideoService.insertVideo(video);
        if(request) {
            request.then(data => {
                dispatch({
                    type: videopage_INSERT_VIDEO_SUCCESS,
                    payload: {
                        insertedVideo: data
                    }
                });
            }, error => {
                dispatch({
                    type: videopage_INSERT_VIDEO_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const updateVideo = (video) => {
    return (dispatch) => {
        return updateVideoCore(dispatch, video, videopage_UPDATE_VIDEO_REQUEST, videopage_UPDATE_VIDEO_SUCCESS, videopage_UPDATE_VIDEO_ERROR);
    }
}

export const updateVideo_OrderNumber = (videoId, value) => {
    return async (dispatch) => {
        return updateVideoCore(dispatch, {
             id: videoId,
             orderNumber: value
        } , videopage_UPDATE_VIDEO_ORDER_NUMBER_REQUEST, videopage_UPDATE_VIDEO_ORDER_NUMBER_SUCCESS, videopage_UPDATE_VIDEO_ORDER_NUMBER_ERROR);

        // const data = await VideoService.getVideoDetail(videoId);
        // if(data) {
        //     let video = VideoService.getVideoInput(data);
        //     video.orderNumber = value;
        //     return updateVideoCore(dispatch, video, videopage_UPDATE_VIDEO_ORDER_NUMBER_REQUEST, videopage_UPDATE_VIDEO_ORDER_NUMBER_SUCCESS, videopage_UPDATE_VIDEO_ORDER_NUMBER_ERROR);
        // }
    }
}

export const updateVideo_IsDeleted = (videoId, value) => {
    return async (dispatch) => {
        return updateVideoCore(dispatch, {
            id: videoId,
            isDeleted: value
        } , videopage_UPDATE_VIDEO_IS_DELETED_REQUEST, videopage_UPDATE_VIDEO_IS_DELETED_SUCCESS, videopage_UPDATE_VIDEO_IS_DELETED_ERROR);

        // const data = await VideoService.getVideoDetail(videoId);
        // if(data) {
        //     let video = VideoService.getVideoInput(data);
        //     video.isDeleted = value;
        //     return updateVideoCore(dispatch, video, videopage_UPDATE_VIDEO_IS_DELETED_REQUEST, videopage_UPDATE_VIDEO_IS_DELETED_SUCCESS, videopage_UPDATE_VIDEO_IS_DELETED_ERROR);
        // }
    }
}

const updateVideoCore = (dispatch, video, requestStatus, successStatus, errorStatus) => {
    dispatch({
        type: requestStatus
    });

    const request = VideoService.updateVideo(video);
    if(request) {
        request.then(data => {
            dispatch({
                type: successStatus,
                payload: {
                    updatedVideo: data
                }
            });
        }, error => {
            dispatch({
                type: errorStatus,
                payload: {
                    errorMessage: Messages.REQUEST_ERROR
                }
            });
        });
    }
}

export const deleteVideo = (video) => {
    return(dispatch) => {
        dispatch({
            type: videopage_DELETE_VIDEO_REQUEST
        });

        const request = VideoService.deleteVideo(video);
        if(request) {
            request.then(data => {
                dispatch({
                    type: videopage_DELETE_VIDEO_SUCCESS,
                    payload: {
                        deletedVideo: video
                    }
                });
            }, error => {
                dispatch({
                    type: videopage_DELETE_VIDEO_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}