import { combineReducers } from 'redux';
import { webUserLayoutReducer } from './web-user-layout-reducers';
import { adminLayoutReducer } from './admin-layout-reducers';
import { homePageReducer } from './home-page-reducers';
import { loginPageReducer } from './login-page-reducers';
import { bannerPageReducer } from './banner-page-reducers';
import { productPageReducer } from './product-page-reducers';
import { categoryPageReducer } from './category-page-reducers';
import { typicalFacePageReducer } from './typical-face-reducers';
import { videoPageReducer } from './video-reducers';
import { newsPageReducer } from './news-page-reducers';
import { uiConfigsPageReducer } from './ui-configs-page-reducers';
import { voucherPageReducer } from './voucher-reducers';

export default combineReducers({
    webUserLayoutReducer,
    adminLayoutReducer,
    homePageReducer,
    loginPageReducer,
    bannerPageReducer,
    productPageReducer,
    categoryPageReducer,
    typicalFacePageReducer,
    videoPageReducer,
    newsPageReducer,
    uiConfigsPageReducer,
    voucherPageReducer
})