const getSubState = (state) => state.newsPageReducer;

export const getNewsListRequestStatus = (state) => getSubState(state).newsListRequestStatus;
export const getNewsList = (state) => getSubState(state).newsList;
export const getNewsDetailRequestStatus = (state) => getSubState(state).newsDetailRequestStatus;
export const getNewsDetail = (state) => getSubState(state).newsDetail;
export const getNewsDetailOriginName = (state) => getSubState(state).newsDetail_originName;
export const getInsertNewsRequestStatus = (state) => getSubState(state).insertNewsRequestStatus;
export const getInsertedNews = (state) => getSubState(state).insertedNews;
export const getUpdateNewsRequestStatus = (state) => getSubState(state).updateNewsRequestStatus;
export const getUpdatedNews = (state) => getSubState(state).updatedNews;
export const getUpdateNewsOrderNumberRequestStatus = (state) => getSubState(state).updateNewsOrderNumberRequestStatus;
export const getUpdatedNewsOrderNumber = (state) => getSubState(state).updatedNewsOrderNumber;
export const getUpdateNewsIsDeletedRequestStatus = (state) => getSubState(state).updateNewsIsDeletedRequestStatus;
export const getUpdatedNewsIsDeleted = (state) => getSubState(state).updatedNewsIsDeleted;
export const getDeleteNewsRequestStatus = (state) => getSubState(state).deleteNewsRequestStatus;
export const getDeletedNews = (state) => getSubState(state).deletedNews;
export const getErrorMessage = (state) => getSubState(state).errorMessage;