const getSubState = (state) => state.categoryPageReducer;

export const getCategoryListRequestStatus = (state) => getSubState(state).categoryListRequestStatus;
export const getCategoryList = (state) => getSubState(state).categoryList;
export const getCategoryDetailRequestStatus = (state) => getSubState(state).categoryDetailRequestStatus;
export const getCategoryDetail = (state) => getSubState(state).categoryDetail;
export const getCategoryDetailOriginName = (state) => getSubState(state).categoryDetail_originName;
export const getInsertCategoryRequestStatus = (state) => getSubState(state).insertCategoryRequestStatus;
export const getInsertedCategory = (state) => getSubState(state).insertedCategory;
export const getUpdateCategoryRequestStatus = (state) => getSubState(state).updateCategoryRequestStatus;
export const getUpdatedCategory = (state) => getSubState(state).updatedCategory;
export const getUpdateCategoryOrderNumberRequestStatus = (state) => getSubState(state).updateCategoryOrderNumberRequestStatus;
export const getUpdatedCategoryOrderNumber = (state) => getSubState(state).updatedCategoryOrderNumber;
export const getUpdateCategoryIsDeletedRequestStatus = (state) => getSubState(state).updateCategoryIsDeletedRequestStatus;
export const getUpdatedCategoryIsDeleted = (state) => getSubState(state).updatedCategoryIsDeleted;
export const getDeleteCategoryRequestStatus = (state) => getSubState(state).deleteCategoryRequestStatus;
export const getDeletedCategory = (state) => getSubState(state).deletedCategory;
export const getErrorMessage = (state) => getSubState(state).errorMessage;