import { connect } from "react-redux";
import { CartPage } from "../pages/cart-page";
import { webUserLayoutSelectors, voucherPageSelectors } from "../redux/selectors";
import { webUserLayoutActions, voucherPageActions } from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    productItemsInCart: webUserLayoutSelectors.getProductItemsInCart(state),
    productItemsInCart_GetRequestStatus: webUserLayoutSelectors.getProductItemsInCart_GetRequestStatus(state),
    productItemsInCart_UpdateRequestStatus: webUserLayoutSelectors.getProductItemsInCart_UpdateRequestStatus(state),
    insertSaleRequestsRequestStatus: webUserLayoutSelectors.getInsertSaleRequestsRequestStatus(state),
    insertSaleRequestsRequestResult: webUserLayoutSelectors.getInsertSaleRequestsRequestResult(state),
    voucherStatusRequestStatus: voucherPageSelectors.getVoucherStatusRequestStatus(state),
    voucherStatus: voucherPageSelectors.getVoucherStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  insertSaleRequests: (saleRequests, voucher) => {
    dispatch(webUserLayoutActions.insertSaleRequests(saleRequests, voucher));
  },

  updateProductItemInCart: (productItemsInCart) => {
    dispatch(webUserLayoutActions.updateProductItemInCart(productItemsInCart));
  },

  checkVoucherStatus: (voucherCode) => {
    dispatch(voucherPageActions.checkVoucherStatus(voucherCode));
  },
});

const CartPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPage);

export default CartPageContainer;
