import React from "react";
import "./admin-typical-face-detail.css";
//
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import {
  FormTextInput,
  // FormTextAreaInput,
  FormImageUploader,
  FormButton,
  FormSunEditor,
  FormSelect,
} from "../public/form-components";
import { BaseService } from "../../services/base-service";
import { TypicalFaceService } from "../../services/typical-face-service";
import { RequestStatus } from "../../constants";
import ListOfNational from "../../catalogs/list-of-national.json";
import ListOfDistrictCity from "../../catalogs/list-of-district-city.json";

class FormikAdminTypicalFaceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allNationalAndDistrictCityList: BaseService.getNationalDistrictAndCity(),
      stateListOfNational: BaseService.getOrderListWithName(
        ListOfNational.map((item) => {
          return {
            ...item,
            id: item.code,
            orderNumber: 0,
          };
        })
      ),
      stateListOfDistrictCity: BaseService.getOrderListWithName(
        ListOfDistrictCity.map((item) => {
          return {
            ...item,
            id: item.code,
            orderNumber: 0,
          };
        })
      ),
    };
  }

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  changeTypicalFaceName = (value) => {
    this.props.setFieldValue("typicalFace.name", value);
    this.trackIsDataHasUpdated();
  };

  blurTypicalFaceName = (e) => {
    this.props.setFieldTouched("errorTypicalFaceName", true);
  };

  changeTypicalFaceDisplayName = (value) => {
    let styleConfig = this.props.values.typicalFace.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    styleConfig.displayName = value;
    this.props.setFieldValue("typicalFace.styleConfig", styleConfig);
    if (value !== originStyleConfig.displayName) {
      this.trackIsDataHasUpdated();
    }
  };

  handleOnChangeTinhThanhPho = (value) => {
    const district = this.state.allNationalAndDistrictCityList.find(
      (t) => t.code === value
    );

    let styleConfig = this.props.values.typicalFace.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    styleConfig.districtId = value;
    styleConfig.district = JSON.stringify(district);
    this.props.setFieldValue("typicalFace.styleConfig", styleConfig);
    if (value !== originStyleConfig.districtId) {
      this.trackIsDataHasUpdated();
    }
  };

  blurTypicalFaceDisplayName = () => {
    this.props.setFieldTouched("errorTypicalFaceDisplayName", true);
  };

  changeTypicalFaceDescription = (value) => {
    this.props.setFieldValue("typicalFace.description", value);
    if (value !== this.props.typicalFace.description) {
      this.trackIsDataHasUpdated();
    }
  };

  changeTypicalFaceUrl = (value) => {
    this.props.setFieldValue("typicalFace.url", value);
    this.trackIsDataHasUpdated();
  };

  changeTypicalFaceContent = (value) => {
    this.props.setFieldValue("typicalFace.content", value);
    if (value !== this.props.typicalFace.content) {
      this.trackIsDataHasUpdated();
    }
  };

  onChangeTypicalFaceImage = (res) => {
    this.props.setFieldTouched("errorImage", true);
    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    const imageURL = res ? res.data.url : null;
    let image = this.props.values.typicalFace.image;
    if (!image) image = {};
    image.id = imageId;
    image.name = imageName;
    image.url = imageURL;
    this.props.setFieldValue("typicalFace.image", image);
    this.trackIsDataHasUpdated();
  };

  save = () => {
    if (this.props.insertTypicalFaceRequestStatus === RequestStatus.RQ_REQUEST)
      return;
    let styleConfig = this.props.values.typicalFace.styleConfig;
    if (!this.props.isHotFace && !styleConfig.districtId) {
      const district = this.state.allNationalAndDistrictCityList.find(
        (t) => t.code === ListOfDistrictCity[0].code
      );

      if (!styleConfig) styleConfig = {};
      const originStyleConfig = { ...styleConfig };
      styleConfig.districtId = district.code;
      styleConfig.district = JSON.stringify(district);
      this.props.setFieldValue("typicalFace.styleConfig", styleConfig);
      if (district.code !== originStyleConfig.districtId) {
        this.trackIsDataHasUpdated();
      }
    }
    // console.log('save', this.props.values.typicalFace);
    this.props.handleSubmit();
  };

  render() {
    const html = (
      <>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormImageUploader
                  labelText="Ảnh bìa"
                  guide={this.props.isHotFace ? "(~700x663, < 2MB)" : "(< 2MB)"}
                  isRequired={true}
                  type="typical-face-background"
                  imgUrl={
                    this.props.values.typicalFace.image
                      ? BaseService.getImageUrlFromServer(
                        this.props.values.typicalFace.image.id,
                        this.props.values.typicalFace.image.name,
                        this.props.values.typicalFace.image.url
                      )
                      : null
                  }
                  onChangeImageInput={this.onChangeTypicalFaceImage}
                  error={
                    this.props.touched.errorImage &&
                      this.props.errors.errorImage
                      ? this.props.errors.errorImage
                      : null
                  }
                />
              </Col>
            </Row>
            <br />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Tên"
                  value={this.props.values.typicalFace.name}
                  onChange={this.changeTypicalFaceName}
                  onBlur={this.blurTypicalFaceName}
                  error={
                    this.props.touched.errorTypicalFaceName &&
                      this.props.errors.errorTypicalFaceName
                      ? this.props.errors.errorTypicalFaceName
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormSunEditor
                  labelText="Tên hiển thị"
                  data={
                    this.props.values.typicalFace.styleConfig &&
                      this.props.values.typicalFace.styleConfig.displayName
                      ? this.props.values.typicalFace.styleConfig.displayName
                      : ""
                  }
                  onChange={this.changeTypicalFaceDisplayName}
                  onBlur={this.blurTypicalFaceDisplayName}
                  error={
                    this.props.touched.errorTypicalFaceDisplayName &&
                      this.props.errors.errorTypicalFaceDisplayName
                      ? this.props.errors.errorTypicalFaceDisplayName
                      : null
                  }
                  height={"180"}
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row
              className={`${this.props.isHotFace ? " admin-typical-face-hot-face-row" : ""
                }`}
            >
              <Col lg={12}>
                <FormSelect
                  labelText="Tỉnh/Thành phố"
                  options={[
                    {
                      value: "quoc-te",
                      text: "Quốc Tế",
                      isGroup: true,
                      subItems: this.state.stateListOfNational.map((item) => {
                        return {
                          value: item.code,
                          text: item.name,
                        };
                      }),
                    },
                    {
                      value: "viet-nam",
                      text: "Việt Nam",
                      isGroup: true,
                      subItems: this.state.stateListOfDistrictCity.map(
                        (item) => {
                          return {
                            value: item.code,
                            text: item.name,
                          };
                        }
                      ),
                    },
                  ]}
                  selectedValue={
                    this.props.values.typicalFace.styleConfig &&
                      this.props.values.typicalFace.styleConfig.districtId
                      ? this.props.values.typicalFace.styleConfig.districtId
                      : this.state.stateListOfDistrictCity[0].code
                  }
                  onChange={this.handleOnChangeTinhThanhPho}
                  isRequired={true}
                />
                <br />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="admin-product-detail-title-col">
                {/* <FormTextAreaInput
                                labelText='Lời giới thiệu'
                                value={this.props.values.typicalFace.description}
                                onChange={this.changeTypicalFaceDescription}
                            /> */}
                <label className="form-components-text-input-label">
                  Lời giới thiệu
                </label>
                <FormSunEditor
                  data={
                    this.props.values.typicalFace.description
                      ? this.props.values.typicalFace.description
                      : ""
                  }
                  onChange={this.changeTypicalFaceDescription}
                // isSimple={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Đường dẫn"
                  value={this.props.values.typicalFace.url}
                  onChange={this.changeTypicalFaceUrl}
                />
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="tabs-container-div form-input-tabs">
              <Tabs>
                <Tab eventKey="informationDescription" title="Nội dung">
                  <FormSunEditor
                    data={
                      this.props.values.typicalFace.content
                        ? this.props.values.typicalFace.content
                        : ""
                    }
                    onChange={this.changeTypicalFaceContent}
                  />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col lg={12}>
            <FormButton
              type="save"
              onClick={this.save}
              isRequesting={
                this.props.insertTypicalFaceRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.props.updateTypicalFaceRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
              disabled={
                this.props.insertTypicalFaceRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.props.updateTypicalFaceRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </>
    );
    return html;
  }
}

const myEnhancedForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    console.log("mapPropsToValues - props", props);
    let values = {
      typicalFace: props.typicalFace,
      errorImage: null,
      errorTypicalFaceName: null,
      errorTypicalFaceDisplayName: null,
    };
    return values;
  },

  validate: (values, props) => {
    const errors = {};

    if (!values.typicalFace.image || !values.typicalFace.image.id) {
      errors.errorImage = "Chưa chọn hình gương mặt tiêu biểu";
    }

    if (
      BaseService.stringIsNullOrWhiteSpace(values.typicalFace.name) ||
      values.typicalFace.name === "<p><br></p>"
    ) {
      errors.errorTypicalFaceName = "Tên gương mặt tiêu biểu không được rỗng";
    }

    if (
      !values.typicalFace.styleConfig ||
      BaseService.stringIsNullOrWhiteSpace(
        values.typicalFace.styleConfig.displayName
      ) ||
      values.typicalFace.styleConfig.displayName === "<p><br></p>"
    ) {
      errors.errorTypicalFaceDisplayName = "Tên hiển thị không được rỗng";
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    // console.log('values', values);
    let typicalFace = TypicalFaceService.getTypicalFaceInput(
      values.typicalFace
    );
    typicalFace.isHot =
      values.typicalFace.id === null
        ? props.isHotFace
        : values.typicalFace.isHot;
    console.log("typicalFace", typicalFace);
    // return;
    props.save(typicalFace);
  },

  displayName: "FormikAdminTypicalFaceDetail",
});

export const AdminTypicalFaceDetail = withRouter(
  myEnhancedForm(FormikAdminTypicalFaceDetail)
);
