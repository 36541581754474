const getSubState = (state) => state.bannerPageReducer;

export const getBannerListRequestStatus = (state) => getSubState(state).bannerListRequestStatus;
export const getBannerList = (state) => getSubState(state).bannerList;
export const getBannerDetailRequestStatus = (state) => getSubState(state).bannerDetailRequestStatus;
export const getBannerDetail = (state) => getSubState(state).bannerDetail;
export const getInsertBannerRequestStatus = (state) => getSubState(state).insertBannerRequestStatus;
export const getInsertedBanner = (state) => getSubState(state).insertedBanner;
export const getUpdateBannerRequestStatus = (state) => getSubState(state).updateBannerRequestStatus;
export const getUpdatedBanner = (state) => getSubState(state).updatedBanner;
export const getUpdateBannerOrderNumberRequestStatus = (state) => getSubState(state).updateBannerOrderNumberRequestStatus;
export const getUpdatedBannerOrderNumber = (state) => getSubState(state).updatedBannerOrderNumber;
export const getUpdateBannerIsDeletedRequestStatus = (state) => getSubState(state).updateBannerIsDeletedRequestStatus;
export const getUpdatedBannerIsDeleted = (state) => getSubState(state).updatedBannerIsDeleted;
export const getDeleteBannerRequestStatus = (state) => getSubState(state).deleteBannerRequestStatus;
export const getDeletedBanner = (state) => getSubState(state).deletedBanner;
export const getBannerItemListRequestStatus = (state) => getSubState(state).bannerItemListRequestStatus;
export const getBannerItemList = (state) => getSubState(state).bannerItemList;
export const getBannerItemDetailRequestStatus = (state) => getSubState(state).bannerItemDetailRequestStatus;
export const getBannerItemDetail = (state) => getSubState(state).bannerItemDetail;
export const getInsertBannerItemRequestStatus = (state) => getSubState(state).insertBannerItemRequestStatus;
export const getInsertedBannerItem = (state) => getSubState(state).insertedBannerItem;
export const getUpdateBannerItemRequestStatus = (state) => getSubState(state).updateBannerItemRequestStatus;
export const getUpdatedBannerItem = (state) => getSubState(state).updatedBannerItem;
export const getUpdateBannerItemOrderNumberRequestStatus = (state) => getSubState(state).updateBannerItemOrderNumberRequestStatus;
export const getUpdatedBannerItemOrderNumber = (state) => getSubState(state).updatedBannerItemOrderNumber;
export const getUpdateBannerItemIsDeletedRequestStatus = (state) => getSubState(state).updateBannerItemIsDeletedRequestStatus;
export const getUpdatedBannerItemIsDeleted = (state) => getSubState(state).updatedBannerItemIsDeleted;
export const getDeleteBannerItemRequestStatus = (state) => getSubState(state).deleteBannerItemRequestStatus;
export const getDeletedBannerItem = (state) => getSubState(state).deletedBannerItem;
export const getErrorMessage = (state) => getSubState(state).errorMessage;