import React from "react";
import "./admin-news-detail.css";
//
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import {
  FormTextInput,
  FormTextAreaInput,
  FormImageUploader,
  FormButton,
  FormSunEditor,
} from "../public/form-components";
import { BaseService } from "../../services/base-service";
import { NewsService } from "../../services/news-service";
import { RequestStatus } from "../../constants";

class FormikAdminNewsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      originNews: this.props.news,
      newsNameTimerId: BaseService.getRandomString(),
    };
  }

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  changeNewsName = (value) => {
    const titleId = BaseService.transformVNIToHyphenCase(value);
    this.props.setFieldValue("news.name", value);
    this.props.setFieldValue("news.titleId", titleId);
    this.trackIsDataHasUpdated();
    BaseService.clearTimer(this.state.newsNameTimerId);
    BaseService.setTimmer(this.state.newsNameTimerId, () => {
      // console.log(value);
      this.props.checkTitleIsAvailable(titleId);
    });
  };

  blurNewsName = (e) => {
    this.props.setFieldTouched("errorNewsName", true);
  };

  changeNewsTitleId = (value) => {
    this.props.setFieldValue("news.titleId", value);
    this.trackIsDataHasUpdated();
    BaseService.clearTimer(this.state.newsNameTimerId);
    BaseService.setTimmer(this.state.newsNameTimerId, () => {
      // console.log(value);
      this.props.checkTitleIsAvailable(value);
    });
  };

  blurNewsTitleId = (e) => {
    this.props.setFieldTouched("errorNewsTitleId", true);
  };

  changeNewsDescription = (value) => {
    this.props.setFieldValue("news.description", value);
    if (value !== this.props.news.description) {
      this.trackIsDataHasUpdated();
    }
  };

  changeNewsContent = (value) => {
    this.props.setFieldValue("news.content", value);
    if (value !== this.props.news.content) {
      this.trackIsDataHasUpdated();
    }
  };

  onChangeNewsImage = (res) => {
    this.props.setFieldTouched("errorImage", true);
    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    const imageURL = res ? res.data.url : null;
    let image = this.props.values.news.image;
    if (!image) image = {};
    image.id = imageId;
    image.name = imageName;
    image.url = imageURL;
    this.props.setFieldValue("news.image", image);
    this.trackIsDataHasUpdated();
  };

  changeNewsSeoTitle = (value) => {
    let styleConfig = this.props.values.news.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    styleConfig.seoTitle = value;
    this.props.setFieldValue("news.styleConfig", styleConfig);
    if (value !== originStyleConfig.displayName) {
      this.trackIsDataHasUpdated();
    }
  };

  changeNewsSeoKeyWords = (value) => {
    let styleConfig = this.props.values.news.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    styleConfig.seoKeyWords = value;
    this.props.setFieldValue("news.styleConfig", styleConfig);
    if (value !== originStyleConfig.displayName) {
      this.trackIsDataHasUpdated();
    }
  };

  changeNewsSeoDescription = (value) => {
    let styleConfig = this.props.values.news.styleConfig;
    if (!styleConfig) styleConfig = {};
    const originStyleConfig = { ...styleConfig };
    styleConfig.seoDescription = value;
    this.props.setFieldValue("news.styleConfig", styleConfig);
    if (value !== originStyleConfig.displayName) {
      this.trackIsDataHasUpdated();
    }
  };

  save = () => {
    if (this.props.insertNewsRequestStatus === RequestStatus.RQ_REQUEST) return;
    // console.log('save', this.props.values.news);
    this.props.handleSubmit();
  };

  render() {
    const html = (
      <>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormImageUploader
                  labelText="Ảnh bìa"
                  guide={this.props.isHotFace ? "(~700x663, < 2MB)" : "(< 2MB)"}
                  isRequired={true}
                  type="news-background"
                  imgUrl={
                    this.props.values.news.image
                      ? BaseService.getImageUrlFromServer(
                        this.props.values.news.image.id,
                        this.props.values.news.image.name,
                        this.props.values.news.image.url
                      )
                      : null
                  }
                  onChangeImageInput={this.onChangeNewsImage}
                  error={
                    this.props.touched.errorImage &&
                      this.props.errors.errorImage
                      ? this.props.errors.errorImage
                      : null
                  }
                />
              </Col>
            </Row>
            <br />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Tên"
                  value={this.props.values.news.name}
                  onChange={this.changeNewsName}
                  onBlur={this.blurNewsName}
                  error={
                    this.props.touched.errorNewsName &&
                      this.props.errors.errorNewsName
                      ? this.props.errors.errorNewsName
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Slug cẩm nang"
                  value={this.props.values.news.titleId}
                  onChange={this.changeNewsTitleId}
                  onBlur={this.blurNewsTitleId}
                  error={
                    // this.props.touched.errorNewsTitleId &&
                    this.props.errors.errorNewsTitleId
                      ? this.props.errors.errorNewsTitleId
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12} className="admin-product-detail-title-col">
                {/* <FormTextAreaInput
                                labelText='Lời giới thiệu'
                                value={this.props.values.news.description}
                                onChange={this.changeNewsDescription}
                            /> */}
                <label className="form-components-text-input-label">
                  Lời giới thiệu
                </label>
                <FormSunEditor
                  data={
                    this.props.values.news.description
                      ? this.props.values.news.description
                      : ""
                  }
                  onChange={this.changeNewsDescription}
                // // isSimple={true}
                />
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="tabs-container-div form-input-tabs">
              <Tabs>
                <Tab eventKey="informationDescription" title="Nội dung">
                  <FormSunEditor
                    data={
                      this.props.values.news.content
                        ? this.props.values.news.content
                        : ""
                    }
                    onChange={this.changeNewsContent}
                  />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <FormTextInput
              labelText="Tiêu đề (SEO)"
              value={
                this.props.values.news.styleConfig &&
                  this.props.values.news.styleConfig.seoTitle
                  ? this.props.values.news.styleConfig.seoTitle
                  : ""
              }
              onChange={this.changeNewsSeoTitle}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            <FormTextAreaInput
              labelText="Từ khóa (SEO)"
              guide="(Mỗi từ bắt đầu bằng dấu #)"
              placeholder="Ví dụ: #Kem dưỡng da#Kem trị nám..."
              value={
                this.props.values.news.styleConfig &&
                  this.props.values.news.styleConfig.seoKeyWords
                  ? this.props.values.news.styleConfig.seoKeyWords
                  : ""
              }
              onChange={this.changeNewsSeoKeyWords}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            <FormTextAreaInput
              labelText="Mô tả (SEO)"
              value={
                this.props.values.news.styleConfig &&
                  this.props.values.news.styleConfig.seoDescription
                  ? this.props.values.news.styleConfig.seoDescription
                  : ""
              }
              onChange={this.changeNewsSeoDescription}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col lg={12}>
            <FormButton
              type="save"
              onClick={this.save}
              isRequesting={
                this.props.insertNewsRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.props.updateNewsRequestStatus === RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
              disabled={
                this.props.insertNewsRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                  this.props.updateNewsRequestStatus === RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </>
    );
    return html;
  }
}

const myEnhancedForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    console.log("mapPropsToValues - props", props);
    let values = {
      news: props.news,
      errorImage: null,
      errorNewsName: null,
      errorNewsTitleId: null,
    };
    return values;
  },

  validate: (values, props) => {
    const errors = {};

    if (!values.news.image || !values.news.image.id) {
      errors.errorImage = "Chưa chọn hình tin";
    }

    if (BaseService.stringIsNullOrWhiteSpace(values.news.name)) {
      errors.errorNewsName = "Tiêu đề tin không được rỗng";
    }

    if (BaseService.stringIsNullOrWhiteSpace(values.news.titleId)) {
      errors.errorNewsTitleId = "Slug cẩm nang không được rỗng";
    }

    if (
      values.news.name !== props.newsDetail_originName &&
      props.titleIsAvailableRequestResult === false
    ) {
      errors.errorNewsTitleId = "Slug tồn tại trước đó";
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    // console.log('values', values);
    let news = NewsService.getNewsInput(values.news);
    news.titleId = BaseService.stringIsNullOrWhiteSpace(values.news.titleId)
      ? BaseService.transformVNIToHyphenCase(values.news.name)
      : values.news.titleId;
    news.isHot = values.news.id === null ? props.isHotFace : values.news.isHot;
    console.log("news", news);
    // return;
    props.save(news);
  },

  displayName: "FormikAdminNewsDetail",
});

export const AdminNewsDetail = withRouter(
  myEnhancedForm(FormikAdminNewsDetail)
);
