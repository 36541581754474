import {
    OleeApiURL,
    ImageApiUrl
} from '../constants';
import { AuthenticationService } from './authentication-service';
import { BaseService } from './base-service';

export class UploadService {
    static uploadImage = (name, file) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
            var data = new FormData();
            data.append('name', name);
            data.append('file', file);

            return fetch(`${OleeApiURL}${ImageApiUrl}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: data
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static updateImage = (image) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(image);
            return fetch(`${OleeApiURL}${ImageApiUrl}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }
}