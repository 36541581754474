import { connect } from 'react-redux';
import { AdminVideoPage } from '../pages/admin-video-page';
import { videoPageSelectors } from '../redux/selectors';
import { videoPageActions } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        videoListRequestStatus: videoPageSelectors.getVideoListRequestStatus(state),
        videoList: videoPageSelectors.getVideoList(state),
        videoDetailRequestStatus: videoPageSelectors.getVideoDetailRequestStatus(state),
        videoDetail: videoPageSelectors.getVideoDetail(state),
        insertVideoRequestStatus: videoPageSelectors.getInsertVideoRequestStatus(state),
        insertedVideo: videoPageSelectors.getInsertedVideo(state),
        updateVideoRequestStatus: videoPageSelectors.getUpdateVideoRequestStatus(state),
        updatedVideo: videoPageSelectors.getUpdatedVideo(state),        
        updateVideoOrderNumberRequestStatus: videoPageSelectors.getUpdateVideoOrderNumberRequestStatus(state),
        updatedVideoOrderNumber: videoPageSelectors.getUpdatedVideoOrderNumber(state),        
        updateVideoIsDeletedRequestStatus: videoPageSelectors.getUpdateVideoIsDeletedRequestStatus(state),
        updatedVideoIsDeleted: videoPageSelectors.getUpdatedVideoIsDeleted(state),
        deleteVideoRequestStatus: videoPageSelectors.getDeleteVideoRequestStatus(state),
        deletedVideo: videoPageSelectors.getDeletedVideo(state),        
        videoErrorMessage: videoPageSelectors.getErrorMessage(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    getVideoList: () => {
        dispatch(videoPageActions.getVideoList());
    },

    getVideoDetail: (videoId) => {
        dispatch(videoPageActions.getVideoDetail(videoId));
    },
    
    insertVideo: (video) => {
        dispatch(videoPageActions.insertVideo(video));
    },
    
    updateVideo: (video) => {
        dispatch(videoPageActions.updateVideo(video));
    },
    
    updateVideo_OrderNumber: (videoId, value) => {
        dispatch(videoPageActions.updateVideo_OrderNumber(videoId, value));
    },
    
    updateVideo_IsDeleted: (videoId, value) => {
        dispatch(videoPageActions.updateVideo_IsDeleted(videoId, value));
    },
    
    deleteVideo: (video) => {
        dispatch(videoPageActions.deleteVideo(video));
    }
})

const AdminVideoPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminVideoPage)

export default AdminVideoPageContainer;