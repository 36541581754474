import React from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { BaseService } from "../../services/base-service";
import "./admin-dynamic-page-content.css";
import {
  FormTextInput,
  FormSunEditor,
  FormButton,
} from "../public/form-components";
import { UIConfigsService } from "../../services/ui-configns-service";
import { RequestStatus } from "../../constants";

export class FormikAdminDynamicPageContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  handleOnChangeAdminDynamicPageName = (value) => {
    const url = BaseService.transformVNIToHyphenCase(value);
    this.props.setFieldValue("adminDynamicPage.name", value);
    this.props.setFieldValue("adminDynamicPage.content.url", `/${url}`);
    this.trackIsDataHasUpdated();
  };

  handleOnBlurAdminDynamicPageName = () => {
    this.props.setFieldTouched("errorAdminDynamicPageName", true);
  };

  handleOnChangeAdminDynamicPageHtml = (value) => {
    if (value !== this.props.values.adminDynamicPage.content.html)
      this.trackIsDataHasUpdated();
    this.props.setFieldValue("adminDynamicPage.content.html", value);
  };

  renderAdminDynamicPageContentForm = () => {
    const element = (
      <>
        <Row>
          <Col lg={12}>
            <FormTextInput
              labelText="Tên trang"
              value={this.props.values.adminDynamicPage.name}
              onChange={this.handleOnChangeAdminDynamicPageName}
              onBlur={this.handleOnBlurAdminDynamicPageName}
              error={
                this.props.touched.errorAdminDynamicPageName &&
                this.props.errors.errorAdminDynamicPageName
                  ? this.props.errors.errorAdminDynamicPageName
                  : null
              }
              isRequired={true}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <FormTextInput
              labelText="Đường dẫn"
              value={this.props.values.adminDynamicPage.content.url}
              onChange={() => {}}
              isRequired={true}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <label className="form-components-text-input-label">Nội dung</label>
            <FormSunEditor
              data={
                this.props.values.adminDynamicPage.content.html
                  ? this.props.values.adminDynamicPage.content.html
                  : ""
              }
              onChange={this.handleOnChangeAdminDynamicPageHtml}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col lg={12}>
            <FormButton
              type="save"
              onClick={this.save}
              isRequesting={
                this.props.insertUIConfigsRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                this.props.updateUIConfigsRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
              disabled={
                this.props.insertUIConfigsRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                this.props.updateUIConfigsRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </>
    );
    return element;
  };

  save = () => {
    this.props.handleSubmit();
  };

  render() {
    const html = <>{this.renderAdminDynamicPageContentForm()}</>;
    return html;
  }
}

const myEnhancedForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    console.log("mapPropsToValues - props", props);
    let values = {
      adminDynamicPage: {
        ...props.adminDynamicPage,
        content: !BaseService.stringIsNullOrWhiteSpace(
          props.adminDynamicPage.content
        )
          ? JSON.parse(props.adminDynamicPage.content)
          : {
              url: "",
              html: "",
            },
      },
      errorAdminDynamicPageName: null,
    };
    return values;
  },

  validate: (values) => {
    const errors = {};

    if (BaseService.stringIsNullOrWhiteSpace(values.adminDynamicPage.name)) {
      errors.errorAdminDynamicPageName = "Tên trang không được rỗng";
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);

    const adminDynamicPage = values.adminDynamicPage;
    let uiConfigs = UIConfigsService.getUIConfigsInput({
      ...adminDynamicPage,
      content: JSON.stringify(adminDynamicPage.content),
    });
    props.save(uiConfigs);
  },

  displayName: "FormikAdminDynamicPageContent",
});

export const AdminDynamicPageContent = withRouter(
  myEnhancedForm(FormikAdminDynamicPageContent)
);
