import {
  adminlayout_GET_LOGIN_DATA_REQUEST,
  adminlayout_GET_LOGIN_DATA_SUCCESS,
  adminlayout_LOGOUT_REQUEST,
  adminlayout_LOGOUT_SUCCESS,
} from "../actions/admin-layout-actions";
import { RequestStatus } from "../../constants";

const initialState = {
  getLoginDataRequestStatus: RequestStatus.RQ_NEW,
  loginData: null,
  //
  logoutRequestStatus: RequestStatus.RQ_NEW,
};

export function adminLayoutReducer(state = initialState, actions) {
  switch (actions.type) {
    case adminlayout_GET_LOGIN_DATA_REQUEST: {
      return {
        ...state,
        getLoginDataRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case adminlayout_GET_LOGIN_DATA_SUCCESS: {
      return {
        ...state,
        logoutRequestStatus: RequestStatus.RQ_NEW,
        getLoginDataRequestStatus: RequestStatus.RQ_SUCCESS,
        loginData: actions.payload.loginData,
      };
    }
    case adminlayout_LOGOUT_REQUEST: {
      return {
        ...state,
        logoutRequestStatus: RequestStatus.RQ_REQUEST,
      };
    }
    case adminlayout_LOGOUT_SUCCESS: {
      return {
        ...state,
        logoutRequestStatus: RequestStatus.RQ_SUCCESS,
      };
    }
    default: {
      return state;
    }
  }
}
