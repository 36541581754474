import React from 'react';
import './admin-banner-list.css';
// 
import { RequestStatus } from '../../constants';
import { LoadingComponent } from '../../components/public/loading-component';
import { TableComponent } from '../../components/public/table-component';
import { FormButton, FormModal, FormConfirmationModal,
    // FormNumberInput,
    FormToggle } from '../../components/public/form-components';
import { AdminBannerDetail } from '../../components/admin-layout/admin-banner-detail';
import { BannerService } from '../../services/banner-service';
import { BaseService } from '../../services/base-service';

export class AdminBannerList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenBannerDetailModal: false,
            isOpenBannerConfirmDeleteModal: false,
            modalTitle: '',
            confirmModalInfo: {
                modalTitle: '',
                modalContent: ''
            },
            targetBanner: null,
            deletingBannerId: null,
            bannerList: [],
            bannerOrderNumberTimerId: BaseService.getRandomString(),
            bannerUpdateOrderNumberTargetId: null,
            bannerIsDeletedTimerId: BaseService.getRandomString(),
            bannerUpdateIsDeletedTargetId: null,
            // 
            isDataHasUpdated: false,
            headers: [
                // { title: 'Sắp xếp', width: '111' },
                { title: 'Kích hoạt', width: '99' },
                { title: 'Banner' },
                // { title: 'Ghi chú' },
                { title: '', width: '120' }
            ]
        }
    }

    componentDidMount() {
        this.props.getBannerList();
        this.props.getBannerItemList();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.bannerListRequestStatus !== nextProps.bannerListRequestStatus &&
        nextProps.bannerListRequestStatus === RequestStatus.RQ_SUCCESS) {
            let bannerList = nextProps.bannerList;
            this.reOrderBannerList(bannerList);
        }

        console.log('AdminBannerPage - componentWillReceiveProps - nextProps', nextProps);
        if(
            (this.props.insertBannerRequestStatus !== nextProps.insertBannerRequestStatus &&
            nextProps.insertBannerRequestStatus === RequestStatus.RQ_SUCCESS) ||
            (this.props.updateBannerRequestStatus !== nextProps.updateBannerRequestStatus &&
            nextProps.updateBannerRequestStatus === RequestStatus.RQ_SUCCESS) ||
            (this.props.deleteBannerRequestStatus !== nextProps.deleteBannerRequestStatus &&
            nextProps.deleteBannerRequestStatus === RequestStatus.RQ_SUCCESS)
        ) {
            this.setState({
                isOpenBannerDetailModal: false
            });
            // setTimeout(() => {
                this.props.getBannerList();
                this.props.getBannerItemList();
            // }, 5000);
        }

        if(this.props.bannerDetailRequestStatus !== nextProps.bannerDetailRequestStatus &&
        nextProps.bannerDetailRequestStatus === RequestStatus.RQ_SUCCESS) {
            if(nextProps.bannerDetail) {
                const banner = nextProps.bannerDetail;
                this.setState({
                    targetBanner: banner
                })
            }
        }
    }

    reOrderBannerList = (bannerList) => {
        let res = BaseService.getOrderListWithName(bannerList);
        this.setState({
            bannerList: res
        })
    }

    getTableHeaders = () => {
        const headers = this.state.headers;
        return headers;
    }

    onChangeBannerOrderNumber = (bannerId, value) => {
        const idx = this.state.bannerList.findIndex(t => t.id === bannerId);
        if(idx !== -1) {
            let bannerList = this.state.bannerList;
            bannerList[idx].orderNumber = value;
            this.setState({
                bannerList: bannerList
            })
            BaseService.clearTimer(this.state.bannerOrderNumberTimerId);
            BaseService.setTimmer(this.state.bannerOrderNumberTimerId, () => {
                this.setState({
                    bannerUpdateOrderNumberTargetId: bannerId
                });
                this.props.updateBanner_OrderNumber(bannerId, value);
            })
        }
    }

    onChangeBannerIsDeleted = (bannerId, value) => {
        const idx = this.state.bannerList.findIndex(t => t.id === bannerId);
        if(idx !== -1) {
            let bannerList = this.state.bannerList;
            bannerList[idx].isDeleted = !value;
            this.setState({
                bannerList: bannerList
            })
            BaseService.clearTimer(this.state.bannerIsDeletedTimerId);
            BaseService.setTimmer(this.state.bannerIsDeletedTimerId, () => {
                this.setState({
                    bannerUpdateIsDeletedTargetId: bannerId
                });
                this.props.updateBanner_IsDeleted(bannerId, !value);
            })
        }
    }

    getTableRows = () => {
        const rows = [];
        if(this.state.bannerList.length > 0) {
            const firstItemIdx = this.state.bannerList.findIndex(t => t.id === 1);
            let allItems = [...this.state.bannerList];
            if(firstItemIdx !== -1) {
                rows.push(this.setRow(allItems[firstItemIdx]));
                allItems.splice(firstItemIdx, 1);
            }
            if(allItems.length > 0) {
                allItems.forEach((item, index) => {
                    rows.push(this.setRow(item));
                });
            }
        }
        return rows;
    }

    setRow = (item) => {
        const row = {
            columns: [
                // <>
                //     <div>
                //         <FormNumberInput
                //             value={item.orderNumber}
                //             min='0'
                //             onChange={(value) => {
                //                 this.onChangeBannerOrderNumber(item.id, value);
                //             }}
                //         />
                //         {
                //             (this.props.updateBannerOrderNumberRequestStatus === RequestStatus.RQ_REQUEST && this.state.bannerUpdateOrderNumberTargetId === item.id)?(
                //                 <div className='form-component-submitting-message-div'>
                //                     <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                //                 </div>
                //             ):(<></>)
                //         }
                //     </div>
                // </>,
                <>
                    {
                        (item.id !== 1)?(
                            <>
                                <FormToggle
                                    value={(item.isDeleted)?(!item.isDeleted):(true)}
                                    onChange={(value) => {
                                        this.onChangeBannerIsDeleted(item.id, value);
                                    }}
                                />
                                {
                                    (this.props.updateBannerIsDeletedRequestStatus === RequestStatus.RQ_REQUEST && this.state.bannerUpdateIsDeletedTargetId === item.id)?(
                                        <div className='form-component-submitting-message-div'>
                                            <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                                        </div>
                                    ):(<></>)
                                }
                            </>
                        ):(
                            <></>
                        )
                    }
                </>,
                <>
                    <div>
                        <div className='table-component-title-link-div' onClick={() => { this.openEditBanner(item); }}>
                            {item.name}
                        </div>
                    </div>
                </>,
                <>
                    {
                        (item.id !== 1)?(
                            <div style={{ width: 'max-content' }}>
                                <FormButton
                                    type='delete'
                                    onClick={() => { this.onClickDeleteBanner(item); }}
                                    isRequesting={(item.id === this.state.deletingBannerId)?(true):(false)}
                                />
                            </div>
                        ):(
                            <></>
                        )
                    }
                </>
            ]
        };
        return row;
    }

    openAddNewBanner = () => {
        this.setState({
            modalTitle: 'Thêm mới banner',
            targetBanner: BannerService.getNewBanner(),
            isOpenBannerDetailModal: true,
            isDataHasUpdated: false
        });
    }

    openEditBanner = (banner) => {
        this.props.getBannerDetail(banner.id);
        this.setState({
            modalTitle: 'Chỉnh sửa banner',
            targetBanner: null,
            isOpenBannerDetailModal: true,
            isDataHasUpdated: false
        });
    }

    onClickDeleteBanner = (banner) => {
        if(this.props.deleteBannerRequestStatus === RequestStatus.RQ_REQUEST) return;
        const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail('Banner', banner.name);
        this.setState({
            targetBanner: banner,
            isOpenBannerConfirmDeleteModal: true,
            confirmModalInfo: {
                modalTitle: confirmModalInfo.title,
                modalContent: confirmModalInfo.content
            }
        });
    }

    onConfirmYesOrNo = (res) => {
        this.setState({
            isOpenBannerConfirmDeleteModal: false
        });
        if(res) {
            this.setState({
                deletingBannerId: this.state.targetBanner.id
            })
            this.props.deleteBanner(this.state.targetBanner);
        }
    }

    closeModal = () => {
        this.setState({
            isOpenBannerDetailModal: false
        });
    }
    
    saveBannerDetail = (banner, bannerItems, deletedBannerItems) => {
        if(banner.id === null) {
            this.props.insertBanner(banner, bannerItems);
        } else {
            this.props.updateBanner(banner, bannerItems, deletedBannerItems);
        }
    }

    trackIsDataHasUpdated = (isDataHasUpdated) => {
        this.setState({
            isDataHasUpdated: isDataHasUpdated
        })
    }

    render() {
        const html = <>
            {
                (this.props.bannerListRequestStatus === RequestStatus.RQ_SUCCESS &&
                this.props.bannerItemListRequestStatus === RequestStatus.RQ_SUCCESS)?(
                    <>
                        <div>
                            <FormButton
                                type='add'
                                onClick={this.openAddNewBanner}
                            />
                        </div>
                        <TableComponent
                            headers={this.getTableHeaders()}
                            rows={this.getTableRows()}
                            noItemRow={
                                <td colSpan={this.state.headers.length}>Chưa có banner</td>
                            }
                        />
                        <FormModal
                            id='BannerModal'
                            size='lg'
                            isShowModal={this.state.isOpenBannerDetailModal}
                            modalTitle={this.state.modalTitle}
                            modalContent={
                                (this.state.targetBanner)?(
                                    <AdminBannerDetail
                                        save={this.saveBannerDetail}
                                        banner={this.state.targetBanner}
                                        trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                                        insertBannerRequestStatus={this.props.insertBannerRequestStatus}
                                        updateBannerRequestStatus={this.props.updateBannerRequestStatus}
                                    />
                                ):(
                                    <div style={{ height: '450px' }}>
                                        <LoadingComponent />
                                    </div>
                                )
                            }
                            isDataHasUpdated={this.state.isDataHasUpdated}
                            closeModal={this.closeModal}
                        />
                        <FormConfirmationModal
                            isShowModal={this.state.isOpenBannerConfirmDeleteModal}
                            modalTitle={this.state.confirmModalInfo.modalTitle}
                            modalContent={this.state.confirmModalInfo.modalContent}
                            okText='Xóa'
                            cancelText='Hủy'
                            onConfirmYesOrNo={this.onConfirmYesOrNo}
                        />
                    </>
                ):(
                    <>
                        <LoadingComponent />
                    </>
                )
            }
        </>;
        return (
            html
        );
    }
}