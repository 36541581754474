const getSubState = (state) => state.productPageReducer;

export const getProductListRequestStatus = (state) => getSubState(state).productListRequestStatus;
export const getProductList = (state) => getSubState(state).productList;
export const getProductDetailRequestStatus = (state) => getSubState(state).productDetailRequestStatus;
export const getProductDetail = (state) => getSubState(state).productDetail;
export const getProductDetailOriginName = (state) => getSubState(state).productDetail_originName;
export const getInsertProductRequestStatus = (state) => getSubState(state).insertProductRequestStatus;
export const getInsertedProduct = (state) => getSubState(state).insertedProduct;
export const getUpdateProductRequestStatus = (state) => getSubState(state).updateProductRequestStatus;
export const getUpdatedProduct = (state) => getSubState(state).updatedProduct;
export const getUpdateProductOrderNumberRequestStatus = (state) => getSubState(state).updateProductOrderNumberRequestStatus;
export const getUpdatedProductOrderNumber = (state) => getSubState(state).updatedProductOrderNumber;
export const getUpdateProductIsDeletedRequestStatus = (state) => getSubState(state).updateProductIsDeletedRequestStatus;
export const getUpdatedProductIsDeleted = (state) => getSubState(state).updatedProductIsDeleted;
export const getDeleteProductRequestStatus = (state) => getSubState(state).deleteProductRequestStatus;
export const getDeletedProduct = (state) => getSubState(state).deletedProduct;
export const getErrorMessage = (state) => getSubState(state).errorMessage;
export const getAllHashTagsRequestStatus = (state) => getSubState(state).allHashTagsRequestStatus;
export const getAllHashTags = (state) => getSubState(state).allHashTags;
export const getProductListByCategoryRequestStatus = (state) => getSubState(state).productListByCategoryRequestStatus;
export const getProductListByCategory = (state) => getSubState(state).productListByCategory;