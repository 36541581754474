import React from "react";
import "./typical-face-detail-page.css";
//
import { withRouter } from "react-router-dom";
import { TypicalFaceDetail } from "../components/web-user-layout/typical-face-detail";
import { RequestStatus } from "../constants";

class TypicalFaceDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typicalFaceId: null,
    };
  }

  componentDidMount() {
    const typicalFaceId = this.props.match.params.typicalfaceid;
    if (typicalFaceId) {
      this.setState({
        typicalFaceId: typicalFaceId,
      });
      this.props.getTypicalFaceDetail(typicalFaceId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.typicalFaceDetailRequestStatus !==
        nextProps.typicalFaceDetailRequestStatus &&
      nextProps.typicalFaceDetailRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState({
        typicalFaceId: nextProps.typicalFaceDetail.id,
      });
    }
  }

  render() {
    const html = (
      <>
        <div className="typical-face-detail-page-wrapper-div">
          <TypicalFaceDetail
            typicalFaceId={this.state.typicalFaceId}
            typicalFaceDetailRequestStatus={
              this.props.typicalFaceDetailRequestStatus
            }
            typicalFaceDetail={this.props.typicalFaceDetail}
          />
        </div>
      </>
    );
    return html;
  }
}

export default withRouter(TypicalFaceDetailPage);
