import {
    OleeApiURL,
    LocalStorageName,
    AuthLoginAPIURL
} from '../constants';
import { BaseService } from './base-service';

export class AuthenticationService {
    static getInitialLoginModel = () => {
        return {
            username: '',
            password: ''
        };
    };

    static login = (username, password) => {
        const bodyJson = JSON.stringify({
            username: username,
            password: password
        });

        return fetch(`${OleeApiURL}${AuthLoginAPIURL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: bodyJson
        })
        .then(res => res.text());
    };

    static storageLoginData = (data) => {
        window.localStorage.setItem(LocalStorageName.LOGIN_DATA, data);
    };

    static getLoginData = () => {
        let loginData = window.localStorage.getItem(LocalStorageName.LOGIN_DATA);
        if(loginData) {
            loginData = JSON.parse(loginData);
        }
        return loginData;
    };

    static clearLoginData = () => {
        window.localStorage.removeItem(LocalStorageName.LOGIN_DATA);
    };

    static isLoggedIn = () => {
        const isLoggedIn = !BaseService.stringIsNullOrWhiteSpace(window.localStorage.getItem(LocalStorageName.LOGIN_DATA));
        return isLoggedIn;
    };
}