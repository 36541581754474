import { OleeApiURL } from "../constants";

export class AdminLayoutService {
  static getAdminMenuList = () => {
    const adminMenuList = [
      {
        text: "Giao diện",
        iconClass: "fas fa-desktop",
        link: "/quan-tri/giao-dien",
      },
      // {
      //   text: "Thống kê",
      //   iconClass: "fas fa-chart-line",
      //   link: "/quan-tri/thong-ke",
      // },
      // {
      //   text: "Yêu cầu tư vấn",
      //   iconClass: "fas fa-headphones",
      //   link: "/quan-tri/yeu-cau-tu-van",
      // },
      // {
      //   text: "Đơn đặt hàng",
      //   iconClass: "fas fa-shopping-cart",
      //   link: "/quan-tri/don-dat-hang",
      // },
      // {
      //   text: "Voucher",
      //   iconClass: "fas fa-percent",
      //   link: "/quan-tri/guong-mat-tieu-bieu",
      // },
      // {
      //     text: 'Menu',
      //     iconClass: 'fas fa-bars',
      //     link: '/quan-tri/menu'
      // },
      {
        text: "Banner",
        iconClass: "far fa-credit-card",
        link: "/quan-tri/banner",
      },
      // {
      //     text: 'Dịch vụ',
      //     iconClass: 'fas fa-server',
      //     link: '/quan-tri/dich-vu'
      // },
      {
        text: "Sản phẩm",
        iconClass: "fas fa-shopping-bag",
        link: "/quan-tri/san-pham",
      },
      // {
      //     text: 'Chính sách',
      //     iconClass: 'fas fa-award',
      //     link: '/quan-tri/chinh-sach'
      // },
      {
        text: "Gương mặt tiêu biểu",
        iconClass: "fas fa-users",
        link: "/quan-tri/guong-mat-tieu-bieu",
      },
      {
        text: "Video",
        iconClass: "fab fa-youtube",
        link: "/quan-tri/video",
      },
      {
        text: "Cẩm nang làm đẹp",
        iconClass: "fas fa-newspaper",
        link: "/quan-tri/tin-tuc",
      },
      // {
      //     text: 'Bottom',
      //     iconClass: 'fas fa-ellipsis-h',
      //     link: '/quan-tri/bottom'
      // },
      // {
      //     text: 'Hệ thống',
      //     iconClass: 'fas fa-cogs',
      //     link: '/quan-tri/he-thong'
      // }
    ];
    return adminMenuList;
  };

  static getTitleIds = () => {
    return fetch(
      `${OleeApiURL}/api/title_ids`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
  };
}
