import {
    OleeApiURL,
    VideoApiUrl
} from '../constants';
import { AuthenticationService } from './authentication-service';
import { BaseService } from './base-service';

export class VideoService {
    static getNewVideo = () => {
        return {
            id: null,
            name: null,
            description: null,
            url: null,
            orderNumber: 0,
            isDeleted: false
        }
    }

    static getVideoInput = (video) => {
        const videoInput = {
            id: video.id,
            name: video.name,
            description: video.description,
            url: video.url,
            orderNumber: video.orderNumber,
            isDeleted: video.isDeleted
        }
        return videoInput;
    }

    static getVideoList = () => {
        return fetch(`${OleeApiURL}${VideoApiUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    static getVideoDetail = (videoId) => {
        return fetch(`${OleeApiURL}${VideoApiUrl}/${videoId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    static insertVideo = (video) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(video);
            return fetch(`${OleeApiURL}${VideoApiUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static updateVideo = (video) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(video);
            return fetch(`${OleeApiURL}${VideoApiUrl}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static deleteVideo = (video) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
            return fetch(`${OleeApiURL}${VideoApiUrl}/${video.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${loginData.token}`
                }
            })
            .then(res => res.json());
        } else {
            return;
        }
    }
}