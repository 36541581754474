import React from "react";
import "./typical-face-list-distributor.css";
//
import { Row, Col } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import { RequestStatus } from "../../constants";
import { Link } from "react-router-dom";
import { FormSelect, ReadMoreButton } from "../public/form-components";

export class TypicalFaceDistributorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: "_blank",
      typicalFaceDistributorList: [],
      typicalFaceDistributorByDistrictList: [],
      districtList: [],
      districtSelectedList: [],
      districtSelectedValue: "-1",
      limitDisplayNumber: this.getLimitDisplayNumber(this.props.windowWidth),
    };
  }

  componentDidMount() {
    this.setImageHeight();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.typicalFaceListRequestStatus !==
        nextProps.typicalFaceListRequestStatus &&
      nextProps.typicalFaceListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      //   const typicalFaceDistributorList = nextProps.typicalFaceDistributorList;
      //   let res = BaseService.getOrderListWithName(typicalFaceDistributorList);
      //   this.setState({
      //     typicalFaceDistributorList: res,
      //   });

      //   setTimeout(() => {
      //     console.log("TypicalFaceList - componentWillReceiveProps");
      //     this.handleResize();
      //     window.addEventListener("resize", this.handleResize);
      //   }, 0);
      setTimeout(() => {
        this.setState(
          (state) => {
            return {
              typicalFaceDistributorByDistrictList: [
                ...nextProps.typicalFaceDistributorList,
              ],
            };
          },
          () => {
            this.setTypicalFaceDistributorList(0);
            window.addEventListener("resize", this.handleResize);
          }
        );
      }, 0);

      let districtList = [];
      nextProps.typicalFaceDistributorList.forEach((t) => {
        if (
          t.district &&
          !districtList.some((y) => y.code === t.district.code)
        ) {
          districtList.push({
            ...t.district,
            id: t.district.code, // parseInt(t.district.code),
            orderNumber: 0,
          });
        }
      });
      this.setState((state) => {
        return {
          districtList: BaseService.getOrderListWithName(districtList),
        };
      });
    }

    if (this.props.windowWidth !== nextProps.windowWidth) {
      this.setState({
        limitDisplayNumber: this.getLimitDisplayNumber(nextProps.windowWidth),
      });
    }
  }

  getLimitDisplayNumber = (windowWidth) => {
    return windowWidth >= 1199 ? 6 : 4;
  };

  isReadMoreAvailable = () => {
    const res =
      this.state.typicalFaceDistributorList.length <
      this.state.typicalFaceDistributorByDistrictList.length;
    return res;
  };

  setTypicalFaceDistributorList = (star) => {
    let propsTypicalFaceDistributorList = BaseService.getOrderListWithName([
      ...this.state.typicalFaceDistributorByDistrictList,
    ]);
    let stateTypicalFaceDistributorList = [
      ...this.state.typicalFaceDistributorList,
    ];
    if (stateTypicalFaceDistributorList < propsTypicalFaceDistributorList) {
      const newItems = propsTypicalFaceDistributorList.splice(
        star,
        this.state.limitDisplayNumber
      );
      stateTypicalFaceDistributorList = stateTypicalFaceDistributorList.concat(
        newItems
      );
      this.setState(
        {
          typicalFaceDistributorList: [...stateTypicalFaceDistributorList],
        },
        () => {
          this.handleResize();
        }
      );
    }
  };

  readMoreTypicalFaceDistributorList = () => {
    this.setTypicalFaceDistributorList(
      this.state.typicalFaceDistributorList.length
    );
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setImageHeight();
  };

  setImageHeight = () => {
    BaseService.setHeightByWidthOfClass("typical-face-img-boder-div");
    // BaseService.setHeightByWidthOfClass('typical-face-description-div');
    BaseService.setMaxHeightByClassName("typical-face-distributor-item-div");
  };

  getNumberOfDistributorOfDistrict = (code) => {
    let res = 0;
    this.props.typicalFaceDistributorList.forEach((t) => {
      if (t.district && t.district.code === code) {
        res += 1;
      }
    });
    return res;
  };

  handleOnChangeTinhThanhPho = (value) => {
    if (value !== "-1") {
      let typicalFaceDistributorByDistrictList = [];
      this.props.typicalFaceDistributorList.forEach((y) => {
        if (y.district && y.district.code === value) {
          typicalFaceDistributorByDistrictList.push(y);
        }
      });
      this.setState(
        (state) => {
          return {
            typicalFaceDistributorList: [],
            typicalFaceDistributorByDistrictList,
          };
        },
        () => {
          this.setTypicalFaceDistributorList(0);
        }
      );
    } else {
      this.setState(
        (state) => {
          return {
            typicalFaceDistributorList: [],
            typicalFaceDistributorByDistrictList: [
              ...this.props.typicalFaceDistributorList,
            ],
          };
        },
        () => {
          this.setTypicalFaceDistributorList(0);
        }
      );
    }
    this.setState((state) => ({
      districtSelectedValue: value,
    }));
  };

  // handleOnClickDistrict = (districtCode) => {
  //   const districtSelectedList = this.state.districtSelectedList;
  //   const idx = districtSelectedList.findIndex((t) => t === districtCode);
  //   if (idx === -1) {
  //     districtSelectedList.push(districtCode);
  //   } else {
  //     districtSelectedList.splice(idx, 1);
  //   }
  //   if (districtSelectedList.length > 0) {
  //     let typicalFaceDistributorByDistrictList = [];
  //     districtSelectedList.forEach((t) => {
  //       this.props.typicalFaceDistributorList.forEach((y) => {
  //         if (y.district && y.district.code === t) {
  //           typicalFaceDistributorByDistrictList.push(y);
  //         }
  //       });
  //     });
  //     this.setState(
  //       (state) => {
  //         return {
  //           typicalFaceDistributorList: [],
  //           typicalFaceDistributorByDistrictList,
  //         };
  //       },
  //       () => {
  //         this.setTypicalFaceDistributorList(0);
  //       }
  //     );
  //   } else {
  //     this.setState(
  //       (state) => {
  //         return {
  //           typicalFaceDistributorList: [],
  //           typicalFaceDistributorByDistrictList: [
  //             ...this.props.typicalFaceDistributorList,
  //           ],
  //         };
  //       },
  //       () => {
  //         this.setTypicalFaceDistributorList(0);
  //       }
  //     );
  //   }
  //   this.setState((state) => {
  //     return {
  //       districtSelectedList,
  //     };
  //   });

  //   // this.setState((state) => {
  //   //   const districtSelectedList = state.districtSelectedList;
  //   //   const idx = districtSelectedList.findIndex((t) => t === districtCode);
  //   //   if (idx === -1) {
  //   //     districtSelectedList.push(districtCode);
  //   //   } else {
  //   //     districtSelectedList.splice(idx, 1);
  //   //   }
  //   //   let typicalFaceDistributorList = [];
  //   //   if (districtSelectedList.length > 0) {
  //   //     districtSelectedList.forEach((t) => {
  //   //       this.props.typicalFaceDistributorList.forEach((y) => {
  //   //         if (y.district && y.district.code === t) {
  //   //           typicalFaceDistributorList.push(y);
  //   //         }
  //   //       });
  //   //     });
  //   //   } else {
  //   //     typicalFaceDistributorList = this.props.typicalFaceDistributorList;
  //   //   }
  //   //   return {
  //   //     typicalFaceDistributorList,
  //   //     districtSelectedList,
  //   //   };
  //   // });
  // };

  getNationalDistrictList = () => {
    const list = [
      {
        value: "quoc-te",
        text: "Quốc Tế",
        isGroup: true,
        subItems: this.state.districtList
          .filter((t) => t.is_national === true)
          .map((item) => ({
            value: item.code,
            text: `${item.name} - ${this.getNumberOfDistributorOfDistrict(
              item.code
            )} NPP`,
          })),
      },
      {
        value: "viet-nam",
        text: "Việt Nam",
        isGroup: true,
        subItems: this.state.districtList
          .filter((t) => !t.is_national)
          .map((item) => ({
            value: item.code,
            text: `${item.name} - ${this.getNumberOfDistributorOfDistrict(
              item.code
            )} NPP`,
          })),
      },
    ];
    return list;
  };

  renderDistrictList = () => {
    const html = (
      <div className="typical-face-distributor-district-list-div">
        <div>Tỉnh/Thành phố:</div>
        <FormSelect
          options={[
            {
              value: "-1",
              text: "Toàn Bộ",
            },
          ].concat(this.getNationalDistrictList())}
          selectedValue={
            this.state.districtSelectedValue
              ? this.state.districtSelectedValue
              : ""
          }
          onChange={this.handleOnChangeTinhThanhPho}
        />
        {/* {this.state.districtList.map((t) => (
          <div
            className={`product-list-catrgory-tag-item-div${
              this.state.districtSelectedList.some((y) => y === t.code)
                ? " product-list-catrgory-tag-selected-item-div"
                : ""
            }`}
            onClick={() => {
              this.handleOnClickDistrict(t.code);
            }}
          >
            {t.name} - {this.getNumberOfDistributorOfDistrict(t.code)} NPP
          </div>
        ))} */}
      </div>
    );
    return html;
  };

  render() {
    const html = (
      <>
        {/* <b>{this.props.windowWidth}</b> */}
        <div
          id="typicalFaceListContainerDiv"
          className="component-container-div"
        >
          <div
            id="typicalFaceListContainerTitleDiv"
            className="component-title-div"
            style={{
              backgroundImage: `url('${this.props.home_page_title_jpg}')`,
            }}
          >
            <div className="component-title-text-div">
              <div>
                Nhà phân phối tiêu biểu
                <div className="component-title-hr"></div>
              </div>
            </div>
          </div>
          <div>
            <div className="product-list-catrgory-tag-div">
              {this.renderDistrictList()}
            </div>
            {/* <Row>
              <Col md={3} lg={3} xl={2}>
                <div className="typical-face-distributor-district-list-wrapper-div">
                  {this.renderDistrictList()}
                </div>
              </Col>
              <Col md={9} lg={9} xl={10}> */}
            <div>
              <Row>
                {this.state.typicalFaceDistributorList.map(
                  (typicalFaceItem, index) => (
                    <React.Fragment key={index}>
                      <Col xs={6} md={3} lg={3} xl={2}>
                        <div className="typical-face-distributor-item-div">
                          <Link
                            to={`/nha-phan-phoi/${typicalFaceItem.id}`}
                            className="typical-face-distributor-item-link"
                          >
                            <div className="typical-face-img-div">
                              <div className="typical-face-img-boder-div">
                                <img
                                  className="typical-face-img"
                                  alt=""
                                  src={typicalFaceItem.imgURL}
                                />
                              </div>
                            </div>
                            {!BaseService.stringIsNullOrWhiteSpace(
                              typicalFaceItem.url
                            ) ? (
                              <a
                                href={typicalFaceItem.url}
                                target={this.state.target}
                                className="typical-face-facebook-a"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <i className="fab fa-facebook"></i> Trang
                                Facebook
                              </a>
                            ) : (
                              <></>
                            )}
                            <div
                              className="typical-face-title-div"
                              dangerouslySetInnerHTML={{
                                __html: typicalFaceItem.displayName,
                              }}
                            ></div>
                            <div className="typical-face-description-div">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: typicalFaceItem.description,
                                }}
                              ></div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </React.Fragment>
                  )
                )}
              </Row>
              {this.isReadMoreAvailable() ? (
                <Row>
                  <Col>
                    <ReadMoreButton
                      readMoreFunction={() => {
                        this.readMoreTypicalFaceDistributorList();
                      }}
                    />
                    <br />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
            {/* </Col>
            </Row> */}
          </div>
        </div>
      </>
    );
    return html;
  }
}
