import React from "react";
import "./admin-banner-detail.css";
//
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { Row, Col } from "react-bootstrap";
import {
  FormTextInput,
  // FormTextAreaInput,
  // FormImageUploader,
  FormButton,
  FormSunEditor,
  FormModal,
  FormNumberInput,
  FormToggle,
  FormConfirmationModal,
  FormVideo,
} from "../public/form-components";
import { TableComponent } from "../../components/public/table-component";
import { BaseService } from "../../services/base-service";
import { BannerService } from "../../services/banner-service";
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../../components/public/loading-component";
import { AdminBannerItemDetail } from "./admin-banner-item-detail";

export class FormikAdminBannerDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      originBanner: this.props.banner,
      originBannerItemList: [],
      //
      modalTitle: "",
      targetBannerItem: null,
      targetBannerItemIndex: null,
      isOpenBannerItemDetailModal: false,
      bannerItemList: [],
      isOpenBannerItemConfirmDeleteModal: false,
      confirmModalInfo: {
        modalTitle: "",
        modalContent: "",
      },
      deletedBannerItems: [],
      headers: [
        { title: "Sắp xếp", width: "111" },
        { title: "Kích hoạt", width: "99" },
        { title: "Ảnh/Video" },
        { title: "", width: "120" },
      ],
    };
  }

  componentDidMount() {
    this.reOrderBannerItemList(this.props.banner.banner_items);
  }

  reOrderBannerItemList = (bannerItemList) => {
    let res = BaseService.getOrderListWithName(bannerItemList);
    this.setState({
      originBannerItemList: BaseService.copyAnObject(res),
      bannerItemList: BaseService.copyAnObject(res),
    });
  };

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      // console.log('trackIsDataHasUpdated');
      let isDataHasUpdated = true; // false;
      // const originBannerItemListJson = JSON.stringify(this.state.originBannerItemList.map(t => {
      //     return {
      //         ...t,
      //         image: (!t.image)?('none'):(t.image),
      //         description: (!t.description || t.description === '<p><br></p>')?(''):(t.description)
      //     }
      // }))
      // const bannerItemListJson = JSON.stringify(this.state.bannerItemList.map(t => {
      //     return {
      //         ...t,
      //         image: (!t.image)?('none'):(t.image),
      //         description: (!t.description || t.description === '<p><br></p>')?(''):(t.description)
      //     }
      // }));
      // if(
      //     this.state.originBanner && this.props.values.banner && (
      //         BaseService.compareAreStringsDifferent(this.props.values.banner.name, this.state.originBanner.name) === true ||
      //         BaseService.compareAreStringsDifferent(
      //             (!this.props.values.banner.description || this.props.values.banner.description === '<p><br></p>')?(''):(this.props.values.banner.description),
      //             (!this.state.originBanner.description || this.state.originBanner.description === '<p><br></p>')?(''):(this.state.originBanner.description)
      //         ) === true ||
      //         originBannerItemListJson !== bannerItemListJson
      //     )
      // ) {
      //     isDataHasUpdated = true;
      // }
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  changeBannerName = (value) => {
    this.props.setFieldValue("banner.name", value);
    this.trackIsDataHasUpdated();
  };

  blurBannerName = (e) => {
    this.props.setFieldTouched("errorBannerName", true);
  };

  changeBannerDescription = (value) => {
    this.props.setFieldValue("banner.description", value);
    if (value !== this.props.banner.description) {
      this.trackIsDataHasUpdated();
    }
  };

  save = () => {
    if (this.props.insertBannerRequestStatus === RequestStatus.RQ_REQUEST)
      return;
    this.props.setFieldValue("banner.bannerItems", this.state.bannerItemList);
    this.props.setFieldValue(
      "banner.deletedBannerItems",
      this.state.deletedBannerItems
    );
    this.props.handleSubmit();
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  onChangeBannerItemOrderNumber = (index, value) => {
    let bannerItemList = this.state.bannerItemList;
    bannerItemList[index].orderNumber = value;
    this.setState(
      {
        bannerItemList: bannerItemList,
      },
      () => {
        this.trackIsDataHasUpdated();
      }
    );
  };

  onChangeBannerItemIsDeleted = (index, value) => {
    let bannerItemList = this.state.bannerItemList;
    bannerItemList[index].isDeleted = !value;
    this.setState(
      {
        bannerItemList: bannerItemList,
      },
      () => {
        this.trackIsDataHasUpdated();
      }
    );
  };

  onClickDeleteBannerItem = (bannerItem, index) => {
    const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail(
      "Ảnh/Video",
      bannerItem.name
    );
    this.setState({
      targetBannerItemIndex: index,
      isOpenBannerItemConfirmDeleteModal: true,
      confirmModalInfo: {
        modalTitle: confirmModalInfo.title,
        modalContent: confirmModalInfo.content,
      },
    });
    this.displayBannerItem("none");
  };

  onConfirmYesOrNo = (res) => {
    this.setState({
      isOpenBannerItemConfirmDeleteModal: false,
    });
    this.displayBannerItem("block");
    if (res) {
      let bannerItemList = this.state.bannerItemList;
      const deleteBannerItem = bannerItemList[this.state.targetBannerItemIndex];
      if (deleteBannerItem) {
        let deletedBannerItems = this.state.deletedBannerItems;
        deletedBannerItems.push(deleteBannerItem);
        this.setState({
          deletedBannerItems: deletedBannerItems,
        });
      }
      bannerItemList.splice(this.state.targetBannerItemIndex, 1);
      this.setState(
        {
          bannerItemList: bannerItemList,
        },
        () => {
          this.trackIsDataHasUpdated();
        }
      );
    }
  };

  getTableRows = () => {
    const rows = [];
    this.state.bannerItemList.forEach((item, index) => {
      rows.push({
        columns: [
          <>
            <div>
              <FormNumberInput
                value={item.orderNumber}
                min="0"
                onChange={(value) => {
                  this.onChangeBannerItemOrderNumber(index, value);
                }}
              />
            </div>
          </>,
          <>
            <FormToggle
              value={item.isDeleted ? !item.isDeleted : true}
              onChange={(value) => {
                this.onChangeBannerItemIsDeleted(index, value);
              }}
            />
          </>,
          <>
            <div>
              <div
                className="table-component-title-link-div"
                onClick={() => {
                  this.openEditBannerItem(item, index);
                }}
              >
                {item.name}
                {/* {
                                    (item.image.id)
                                } */}
                {item.image && item.image.id ? (
                  <div className="table-component-image-large-div">
                    <img
                      alt=""
                      src={
                        item.image
                          ? BaseService.getImageUrlFromServer(
                              item.image.id,
                              item.image.name,
                              item.image.url
                            )
                          : null
                      }
                    />
                  </div>
                ) : (
                  <div className="table-component-video-large-div">
                    <FormVideo
                      videoUrl={item.videoUrl}
                      canAccess={true}
                      autoPlay={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </>,
          <>
            <div style={{ width: "max-content" }}>
              <div
                className="form-link-button-div"
                onClick={() => {
                  this.onClickDeleteBannerItem(item, index);
                }}
              >
                Xóa
              </div>
            </div>
          </>,
        ],
      });
    });
    return rows;
  };

  displayBannerItem = (value) => {
    let bannerModal = document.getElementById("BannerModal");
    if (bannerModal) {
      value = value === "block" ? 1 : 0;
      bannerModal.style.opacity = value;
    }
  };

  openAddNewBannerItem = () => {
    this.setState({
      modalTitle: "Thêm mới ảnh/video",
      targetBannerItem: BannerService.getNewBannerItem(),
      targetBannerItemIndex: -1,
      isOpenBannerItemDetailModal: true,
    });
    this.displayBannerItem("none");
  };

  openEditBannerItem = (bannerItem, index) => {
    this.setState({
      modalTitle: "Chỉnh sửa ảnh/video",
      targetBannerItem: bannerItem,
      targetBannerItemIndex: index,
      isOpenBannerItemDetailModal: true,
    });
    this.displayBannerItem("none");
  };

  closeModal = () => {
    this.setState({
      isOpenBannerItemDetailModal: false,
    });
    this.displayBannerItem("block");
  };

  inserOrUpdateBannerItem = (bannerItem) => {
    console.log("inserOrUpdateBannerItem - bannerItem", bannerItem);
    let bannerItemList = this.state.bannerItemList;
    const idx = this.state.targetBannerItemIndex;
    if (idx !== -1) {
      bannerItemList[idx] = bannerItem;
    } else {
      bannerItemList.push(bannerItem);
    }
    this.setState(
      {
        bannerItemList: bannerItemList,
      },
      () => {
        this.trackIsDataHasUpdated();
      }
    );
    this.closeModal();
  };

  render() {
    const html = (
      <>
        <Row>
          <Col>
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Tên"
                  value={this.props.values.banner.name}
                  onChange={this.changeBannerName}
                  onBlur={this.blurBannerName}
                  error={
                    this.props.touched.errorBannerName &&
                    this.props.errors.errorBannerName
                      ? this.props.errors.errorBannerName
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <label className="form-components-text-input-label">
                  Ghi Chú
                </label>
                <FormSunEditor
                  data={
                    this.props.values.banner.description
                      ? this.props.values.banner.description
                      : ""
                  }
                  onChange={this.changeBannerDescription}
                  // isSimple={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <label className="form-components-text-input-label">
                  Ảnh/Video
                </label>
                <div>
                  <TableComponent
                    headers={this.getTableHeaders()}
                    rows={this.getTableRows()}
                    noItemRow={
                      <td colSpan={this.state.headers.length}>
                        Chưa có ảnh/video
                      </td>
                    }
                  />
                  <div>
                    <br />
                    <div
                      className="form-link-button-div"
                      onClick={this.openAddNewBannerItem}
                    >
                      Thêm
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <FormButton
              type="save"
              onClick={this.save}
              isRequesting={
                this.props.insertBannerRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                this.props.updateBannerRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
              disabled={
                this.props.insertBannerRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                this.props.updateBannerRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
        <FormModal
          size="lg"
          isShowModal={this.state.isOpenBannerItemDetailModal}
          modalTitle={this.state.modalTitle}
          modalContent={
            this.state.targetBannerItem ? (
              <AdminBannerItemDetail
                bannerItem={this.state.targetBannerItem}
                save={this.inserOrUpdateBannerItem}
              />
            ) : (
              // <AdminBannerDetail
              //     save={this.saveBannerDetail}
              //     banner={this.state.targetBanner}
              //     insertBannerRequestStatus={this.props.insertBannerRequestStatus}
              //     updateBannerRequestStatus={this.props.updateBannerRequestStatus}
              // />
              <div style={{ height: "450px" }}>
                <LoadingComponent />
              </div>
            )
          }
          closeModal={this.closeModal}
        />
        <FormConfirmationModal
          isShowModal={this.state.isOpenBannerItemConfirmDeleteModal}
          modalTitle={this.state.confirmModalInfo.modalTitle}
          modalContent={this.state.confirmModalInfo.modalContent}
          okText="Xóa"
          cancelText="Hủy"
          onConfirmYesOrNo={this.onConfirmYesOrNo}
        />
      </>
    );
    return html;
  }
}

const myEnhancedForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    console.log("mapPropsToValues - props", props);
    let values = {
      banner: props.banner,
      errorBannerName: null,
    };
    return values;
  },

  validate: (values) => {
    const errors = {};

    if (BaseService.stringIsNullOrWhiteSpace(values.banner.name)) {
      errors.errorBannerName = "Tên banner hiệu không được rỗng";
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    console.log("values", values);
    const banner = values.banner;
    const bannerInput = BannerService.getBannerInput(banner);
    let bannerItemInputs = [];
    banner.bannerItems.forEach((item) => {
      bannerItemInputs.push(BannerService.getBannerItemInput(item));
    });
    props.save(bannerInput, bannerItemInputs, banner.deletedBannerItems);
  },

  displayName: "FormikAdminBannerDetail",
});

export const AdminBannerDetail = withRouter(
  myEnhancedForm(FormikAdminBannerDetail)
);
