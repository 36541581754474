import React from "react";
import "./news-detail-page.css";
//
import { withRouter } from "react-router-dom";
import { NewsDetail } from "../components/web-user-layout/news-detail";
import { RequestStatus } from "../constants";

class NewsDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newsId: null,
      titleid: null,
    };
  }

  componentDidMount() {
    // const newsId = this.props.match.params.newsid;
    // if (newsId) {
    //   this.setState({
    //     newsId: newsId,
    //   });
    //   this.props.getNewsDetail(newsId);
    // }
    const titleid = this.props.match.params.titleid;
    if (titleid) {
      this.setState({
        titleid: titleid,
      });
      this.props.getNewsDetailByTitleId(titleid);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.titleid !== nextProps.match.params.titleid) {
      const titleId = nextProps.match.params.titleid;
      if (titleId) {
        this.setState({
          titleId: titleId,
        });
        this.props.getNewsDetailByTitleId(titleId);
      }
    }

    if (
      this.props.newsDetailRequestStatus !==
        nextProps.newsDetailRequestStatus &&
      nextProps.newsDetailRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.setState({
        newsId: nextProps.newsDetail.id,
      });
    }
  }

  render() {
    const html = (
      <>
        <div className="news-detail-page-wrapper-div">
          <NewsDetail
            newsId={this.state.newsId}
            newsDetailRequestStatus={this.props.newsDetailRequestStatus}
            newsDetail={this.props.newsDetail}
          />
        </div>
      </>
    );
    return html;
  }
}

export default withRouter(NewsDetailPage);
