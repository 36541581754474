import { connect } from "react-redux";
import { AdminOrderPage } from "../pages/admin-order-page";
import { webUserLayoutSelectors } from "../redux/selectors";
import { webUserLayoutActions } from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    getSaleRequestsListOrderRequestStatus: webUserLayoutSelectors.getGetSaleRequestsListOrderRequestStatus(
      state
    ),
    getSaleRequestsListOrderRequestResult: webUserLayoutSelectors.getGetSaleRequestsListOrderRequestResult(
      state
    ),
    updateSaleRequestsRequestStatus: webUserLayoutSelectors.getUpdateSaleRequestsRequestStatus(
      state
    ),
    updateSaleRequestsRequestResult: webUserLayoutSelectors.getUpdateSaleRequestsRequestResult(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSaleRequests: (saleRequests) => {
    dispatch(webUserLayoutActions.updateSaleRequests(saleRequests));
  },
});

const AdminOrderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminOrderPage);

export default AdminOrderContainer;
