import {
    OleeApiURL,
    BannerApiUrl,
    BannerItemApiUrl
} from '../constants';
import { AuthenticationService } from './authentication-service';
import { BaseService } from './base-service';

export class BannerService {
    static getNewBanner = () => {
        return {
            id: null,
            name: null,
            description: null,
            orderNumber: 0,
            isDeleted: false,
            banner_items: []
        }
    }
    
    static getNewBannerItem = () => {
        return {
            id: null,
            name: null,
            description: null,
            imageId: null,
            videoUrl: null,
            linkUrl: null,
            bannerId: null,
            orderNumber: 0,
            isDeleted: false
        }
    }

    static getBannerInput = (banner) => {
        const bannerInput = {
            id: banner.id,
            name: banner.name,
            description: banner.description,
            orderNumber: banner.orderNumber,
            isDeleted: banner.isDeleted
        }
        return bannerInput;
    }

    static getBannerItemInput = (bannerItem) => {
        const bannerItemInput = {
            id: bannerItem.id,
            name: bannerItem.name,
            description: bannerItem.description,
            imageId: (bannerItem.image)?(bannerItem.image.id):(null),
            videoUrl: bannerItem.videoUrl,
            linkUrl: bannerItem.linkUrl,
            bannerId: (bannerItem.banner)?(bannerItem.banner.id):(null),
            orderNumber: bannerItem.orderNumber,
            isDeleted: bannerItem.isDeleted
        }
        return bannerItemInput;
    }

    static getBannerList = () => {
        return fetch(`${OleeApiURL}${BannerApiUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    static getBannerDetail = (bannerId) => {
        return fetch(`${OleeApiURL}${BannerApiUrl}/${bannerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    static insertBanner = (banner) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(banner);
            return fetch(`${OleeApiURL}${BannerApiUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static updateBanner = (banner) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(banner);
            return fetch(`${OleeApiURL}${BannerApiUrl}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static deleteBanner = (banner) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
            return fetch(`${OleeApiURL}${BannerApiUrl}/${banner.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${loginData.token}`
                }
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static getBannerItemList = () => {
        return fetch(`${OleeApiURL}${BannerItemApiUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    static getBannerItemDetail = (bannerItemId) => {
        return fetch(`${OleeApiURL}${BannerItemApiUrl}/${bannerItemId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json());
    }

    static insertBannerItem = (bannerItem) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(bannerItem);
            return fetch(`${OleeApiURL}${BannerItemApiUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static updateBannerItem = (bannerItem) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {

            const bodyJson = JSON.stringify(bannerItem);
            return fetch(`${OleeApiURL}${BannerItemApiUrl}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.token}`
                },
                body: bodyJson
            })
            .then(res => res.json());
        } else {
            return;
        }
    }

    static deleteBannerItem = (bannerItem) => {
        const loginData = AuthenticationService.getLoginData();
        if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
            return fetch(`${OleeApiURL}${BannerItemApiUrl}/${bannerItem.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${loginData.token}`
                }
            })
            .then(res => res.json());
        } else {
            return;
        }
    }
}