import { connect } from "react-redux";
import { AdminProductPage } from "../pages/admin-product-page";
import {
  productPageSelectors,
  categoryPageSelectors,
  webUserLayoutSelectors,
} from "../redux/selectors";
import {
  productPageActions,
  categoryPageActions,
  webUserLayoutActions,
} from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    //
    categoryListRequestStatus: categoryPageSelectors.getCategoryListRequestStatus(
      state
    ),
    categoryList: categoryPageSelectors.getCategoryList(state),
    categoryDetailRequestStatus: categoryPageSelectors.getCategoryDetailRequestStatus(
      state
    ),
    categoryDetail: categoryPageSelectors.getCategoryDetail(state),
    categoryDetail_originName: categoryPageSelectors.getCategoryDetailOriginName(
      state
    ),
    insertCategoryRequestStatus: categoryPageSelectors.getInsertCategoryRequestStatus(
      state
    ),
    insertedCategory: categoryPageSelectors.getInsertedCategory(state),
    updateCategoryRequestStatus: categoryPageSelectors.getUpdateCategoryRequestStatus(
      state
    ),
    updatedCategory: categoryPageSelectors.getUpdatedCategory(state),
    updateCategoryOrderNumberRequestStatus: categoryPageSelectors.getUpdateCategoryOrderNumberRequestStatus(
      state
    ),
    updatedCategoryOrderNumber: categoryPageSelectors.getUpdatedCategoryOrderNumber(
      state
    ),
    updateCategoryIsDeletedRequestStatus: categoryPageSelectors.getUpdateCategoryIsDeletedRequestStatus(
      state
    ),
    updatedCategoryIsDeleted: categoryPageSelectors.getUpdatedCategoryIsDeleted(
      state
    ),
    deleteCategoryRequestStatus: categoryPageSelectors.getDeleteCategoryRequestStatus(
      state
    ),
    deletedCategory: categoryPageSelectors.getDeletedCategory(state),
    categoryErrorMessage: categoryPageSelectors.getErrorMessage(state),
    //
    productListRequestStatus: productPageSelectors.getProductListRequestStatus(
      state
    ),
    productList: productPageSelectors.getProductList(state),
    insertProductRequestStatus: productPageSelectors.getInsertProductRequestStatus(
      state
    ),
    insertedProduct: productPageSelectors.getInsertedProduct(state),
    updateProductRequestStatus: productPageSelectors.getUpdateProductRequestStatus(
      state
    ),
    updatedProduct: productPageSelectors.getUpdatedProduct(state),
    updateProductOrderNumberRequestStatus: productPageSelectors.getUpdateProductOrderNumberRequestStatus(
      state
    ),
    updatedProductOrderNumber: productPageSelectors.getUpdatedProductOrderNumber(
      state
    ),
    updateProductIsDeletedRequestStatus: productPageSelectors.getUpdateProductIsDeletedRequestStatus(
      state
    ),
    updatedProductIsDeleted: productPageSelectors.getUpdatedProductIsDeleted(
      state
    ),
    deleteProductRequestStatus: productPageSelectors.getDeleteProductRequestStatus(
      state
    ),
    deletedProduct: productPageSelectors.getDeletedProduct(state),
    productErrorMessage: productPageSelectors.getErrorMessage(state),
    allHashTagsRequestStatus: productPageSelectors.getAllHashTagsRequestStatus(
      state
    ),
    allHashTags: productPageSelectors.getAllHashTags(state),
    productDetailRequestStatus: productPageSelectors.getProductDetailRequestStatus(
      state
    ),
    productDetail: productPageSelectors.getProductDetail(state),
    productDetail_originName: productPageSelectors.getProductDetailOriginName(
      state
    ),
    //
    titleIsAvailableRequestStatus: webUserLayoutSelectors.getTitleIsAvailableRequestStatus(
      state
    ),
    titleIsAvailableRequestResult: webUserLayoutSelectors.getTitleIsAvailableRequestResult(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCategoryList: () => {
    dispatch(categoryPageActions.getCategoryList());
  },

  getCategoryDetail: (categoryId) => {
    dispatch(categoryPageActions.getCategoryDetail(categoryId));
  },

  insertCategory: (category) => {
    dispatch(categoryPageActions.insertCategory(category));
  },

  updateCategory: (category) => {
    dispatch(categoryPageActions.updateCategory(category));
  },

  updateCategory_OrderNumber: (categoryId, imageId, value) => {
    dispatch(
      categoryPageActions.updateCategory_OrderNumber(categoryId, imageId, value)
    );
  },

  updateCategory_IsDeleted: (categoryId, imageId, value) => {
    dispatch(
      categoryPageActions.updateCategory_IsDeleted(categoryId, imageId, value)
    );
  },

  deleteCategory: (category) => {
    dispatch(categoryPageActions.deleteCategory(category));
  },

  getProductList: () => {
    dispatch(productPageActions.getProductList());
  },

  getProductDetail: (productId) => {
    dispatch(productPageActions.getProductDetail(productId));
  },

  insertProductWithSaveProductImageList: (product, imageList) => {
    dispatch(
      productPageActions.insertProductWithSaveProductImageList(
        product,
        imageList
      )
    );
  },

  updateProductWithSaveProductImageList: (product, imageList) => {
    dispatch(
      productPageActions.updateProductWithSaveProductImageList(
        product,
        imageList
      )
    );
  },

  updateProduct_OrderNumber: (productId, imageId, value) => {
    dispatch(
      productPageActions.updateProduct_OrderNumber(productId, imageId, value)
    );
  },

  updateProduct_IsDeleted: (productId, imageId, value) => {
    dispatch(
      productPageActions.updateProduct_IsDeleted(productId, imageId, value)
    );
  },

  deleteProduct: (product) => {
    dispatch(productPageActions.deleteProduct(product));
  },

  getAllHashTags: () => {
    dispatch(productPageActions.getAllHashTags());
  },

  checkTitleIsAvailable: (titleId) => {
    dispatch(webUserLayoutActions.checkTitleIsAvailable(titleId));
  },

  getAllSlugs: () => {
    dispatch(webUserLayoutActions.getAllSlugs());
  },
});

const AdminProductPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProductPage);

export default AdminProductPageContainer;
