import React from "react";
import "./admin-layout.css";
//
import { withRouter } from "react-router";
import { AdminLayoutMenu } from "../components/admin-layout/admin-layout-menu";
import { InDevelopmentComponent } from "../components/public/in-development-component";
import { RequestStatus } from "../constants";
import { BaseService } from "../services/base-service";

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdminMenuCollapse: null,
    };
  }

  componentWillMount() {
    this.props.getLoginData();
    this.getSaleRequests();
    setInterval(() => {
      this.getSaleRequests();
    }, 5000);
  }

  getSaleRequests = () => {
    if (!this.props.loginData || !this.props.loginData.token) return;
    this.props.getSaleRequestsListByType(
      "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM"
    );
    this.props.getSaleRequestsListByType("SALE_REQUEST_1_DAT_HANG");
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.isAdminMenuCollapse && this.props.windowWidth !== 0) {
      // console.log("AdminLayout - componentWillReceiveProps");
      this.setState({
        isAdminMenuCollapse: this.props.windowWidth >= 992 ? false : true,
      });
    }

    if (
      this.props.getLoginDataRequestStatus !==
        nextProps.getLoginDataRequestStatus &&
      nextProps.getLoginDataRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      // if(this.props.loginData !== nextProps.loginData) {
      console.log("this.props", this.props);
      console.log("nextProps", nextProps);
      if (
        !nextProps.loginData ||
        !BaseService.isObjectHasKey(nextProps.loginData, "token")
      ) {
        this.props.history.push("/dang-nhap");
      } else {
        console.log("Data change");
      }
    }

    if (
      this.props.logoutRequestStatus !== nextProps.logoutRequestStatus &&
      nextProps.logoutRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      this.props.history.push("/dang-nhap");
    }
  }

  handleResize = () => {
    const adminLayoutComponentTitleDiv = document.getElementById(
      "adminLayoutComponentTitleDiv"
    );
    const adminLayoutComponentContentDiv = document.getElementById(
      "adminLayoutComponentContentDiv"
    );
    if (adminLayoutComponentTitleDiv && adminLayoutComponentContentDiv) {
      adminLayoutComponentContentDiv.style.paddingTop = `${
        adminLayoutComponentTitleDiv.offsetHeight + 15
      }px`;
    }
  };

  adminMenuElement = () => {
    const element = (
      <>
        <AdminLayoutMenu
          loginData={this.props.loginData}
          isAdminMenuCollapse={this.state.isAdminMenuCollapse}
          collpaseAdminMenu={this.collpaseAdminMenu}
          logout={this.props.logout}
          getSaleRequestsListAdvisoryRequestResult={
            this.props.getSaleRequestsListAdvisoryRequestResult
          }
          getSaleRequestsListOrderRequestResult={
            this.props.getSaleRequestsListOrderRequestResult
          }
        />
      </>
    );
    return element;
  };

  collpaseAdminMenu = () => {
    this.setState({
      isAdminMenuCollapse: !this.state.isAdminMenuCollapse,
    });
  };

  render() {
    const html = (
      <>
        <div
          className={`admin-layout-container-div${
            this.state.isAdminMenuCollapse
              ? " admin-layout-menu-container-div-collapse"
              : ""
          }`}
        >
          {this.adminMenuElement()}
          {this.props.bodyContent ? (
            <div className="admin-layout-component-div">
              <div>
                {this.props.bodyTitle ? (
                  <div
                    id="adminLayoutComponentTitleDiv"
                    className="admin-layout-component-title-div"
                  >
                    {this.props.bodyTitle}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  id="adminLayoutComponentContentDiv"
                  className="admin-layout-component-content-div"
                >
                  <div>{this.props.bodyContent}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="admin-layout-component-div">
              <InDevelopmentComponent />
            </div>
          )}
        </div>
      </>
    );
    return html;
  }
}
export default withRouter(AdminLayout);
