import { connect } from "react-redux";
import { typicalFacePageSelectors } from "../redux/selectors";
import { typicalFacePageActions } from "../redux/actions";
import StarFaceDetailPage from "../pages/star-face-detail-page";
import { BaseService } from "../services/base-service";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    typicalFaceDetailRequestStatus: typicalFacePageSelectors.getTypicalFaceDetailRequestStatus(
      state
    ),
    typicalFaceDetail: typicalFacePageSelectors.getTypicalFaceDetail(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTypicalFaceDetail: (typicalFaceId) => {
    BaseService.setGAPageView(`/guong-mat-nghe-si/${typicalFaceId}`);
    dispatch(typicalFacePageActions.getTypicalFaceDetail(typicalFaceId));
  },
});

const StarFacePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StarFaceDetailPage);

export default StarFacePageContainer;
