import {
    categorypage_GET_CATEGORY_LIST_REQUEST,
    categorypage_GET_CATEGORY_LIST_SUCCESS,
    categorypage_GET_CATEGORY_LIST_ERROR,
    categorypage_GET_CATEGORY_DETAIL_REQUEST,
    categorypage_GET_CATEGORY_DETAIL_SUCCESS,
    categorypage_GET_CATEGORY_DETAIL_ERROR,
    categorypage_INSERT_CATEGORY_REQUEST,
    categorypage_INSERT_CATEGORY_SUCCESS,
    categorypage_INSERT_CATEGORY_ERROR,
    categorypage_UPDATE_CATEGORY_REQUEST,
    categorypage_UPDATE_CATEGORY_SUCCESS,
    categorypage_UPDATE_CATEGORY_ERROR,
    categorypage_UPDATE_CATEGORY_ORDER_NUMBER_REQUEST,
    categorypage_UPDATE_CATEGORY_ORDER_NUMBER_SUCCESS,
    categorypage_UPDATE_CATEGORY_ORDER_NUMBER_ERROR,
    categorypage_UPDATE_CATEGORY_IS_DELETED_REQUEST,
    categorypage_UPDATE_CATEGORY_IS_DELETED_SUCCESS,
    categorypage_UPDATE_CATEGORY_IS_DELETED_ERROR,
    categorypage_DELETE_CATEGORY_REQUEST,
    categorypage_DELETE_CATEGORY_SUCCESS,
    categorypage_DELETE_CATEGORY_ERROR
} from '../actions/category-page-actions';
import {
    RequestStatus
} from '../../constants';

const initialState  = {
    categoryListRequestStatus: RequestStatus.RQ_NEW,
    categoryList: [],
    // 
    categoryDetailRequestStatus: RequestStatus.RQ_NEW,
    categoryDetail: null,
    categoryDetail_originName: null,
    // 
    insertCategoryRequestStatus: RequestStatus.RQ_NEW,
    insertedCategory: null,
    // 
    updateCategoryRequestStatus:  RequestStatus.RQ_NEW,
    updatedCategory: null,
    // 
    updateCategoryOrderNumberRequestStatus: RequestStatus.RQ_NEW,
    updatedCategoryOrderNumber: null,
    // 
    updateCategoryIsDeletedRequestStatus: RequestStatus.RQ_NEW,
    updatedCategoryIsDeleted: null,
    // 
    errorMessage: null
}

export function categoryPageReducer(state = initialState, actions) {
    switch(actions.type) {
        case categorypage_GET_CATEGORY_LIST_REQUEST: {
            return {
                ...state,
                categoryListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_GET_CATEGORY_LIST_SUCCESS: {
            return {
                ...state,
                categoryListRequestStatus: RequestStatus.RQ_SUCCESS,
                categoryList: actions.payload.categoryList
            }
        }
        case categorypage_GET_CATEGORY_LIST_ERROR: {
            return {
                ...state,
                categoryListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case categorypage_GET_CATEGORY_DETAIL_REQUEST: {
            return {
                ...state,
                categoryDetailRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_GET_CATEGORY_DETAIL_SUCCESS: {
            const categoryDetail = actions.payload.categoryDetail;
            return {
                ...state,
                categoryDetailRequestStatus: RequestStatus.RQ_SUCCESS,
                categoryDetail: categoryDetail,
                categoryDetail_originName: categoryDetail.name,
            }
        }
        case categorypage_GET_CATEGORY_DETAIL_ERROR: {
            return {
                ...state,
                categoryDetailRequestStatus: RequestStatus.RQ_ERROR
            }
        }
        case categorypage_INSERT_CATEGORY_REQUEST: {
            return {
                ...state,
                insertCategoryRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_INSERT_CATEGORY_SUCCESS: {
            return {
                ...state,
                insertCategoryRequestStatus: RequestStatus.RQ_SUCCESS,
                insertedCategory: actions.payload.insertedCategory
            }
        }
        case categorypage_INSERT_CATEGORY_ERROR: {
            return {
                ...state,
                insertCategoryRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case categorypage_UPDATE_CATEGORY_REQUEST: {
            return {
                ...state,
                updateCategoryRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_UPDATE_CATEGORY_SUCCESS: {
            return {
                ...state,
                updateCategoryRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedCategory: actions.payload.updatedCategory
            }
        }
        case categorypage_UPDATE_CATEGORY_ERROR: {
            return {
                ...state,
                updateCategoryRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case categorypage_UPDATE_CATEGORY_ORDER_NUMBER_REQUEST: {
            return {
                ...state,
                updateCategoryOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_UPDATE_CATEGORY_ORDER_NUMBER_SUCCESS: {
            return {
                ...state,
                updateCategoryOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedCategoryOrderNumber: actions.payload.updatedCategory
            }
        }
        case categorypage_UPDATE_CATEGORY_ORDER_NUMBER_ERROR: {
            return {
                ...state,
                updateCategoryOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case categorypage_UPDATE_CATEGORY_IS_DELETED_REQUEST: {
            return {
                ...state,
                updateCategoryIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_UPDATE_CATEGORY_IS_DELETED_SUCCESS: {
            return {
                ...state,
                updateCategoryIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedCategoryIsDeleted: actions.payload.updatedCategory
            }
        }
        case categorypage_UPDATE_CATEGORY_IS_DELETED_ERROR: {
            return {
                ...state,
                updateCategoryIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case categorypage_DELETE_CATEGORY_REQUEST: {
            return {
                ...state,
                deleteCategoryRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case categorypage_DELETE_CATEGORY_SUCCESS: {
            return {
                ...state,
                deleteCategoryRequestStatus: RequestStatus.RQ_SUCCESS,
                deletedCategory: actions.payload.deletedCategory
            }
        }
        case categorypage_DELETE_CATEGORY_ERROR: {
            return {
                ...state,
                deleteCategoryRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        default: {
            return state;
        }
    }
}