import { OleeApiURL, SaleRequestsApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

export class SaleRequestsService {
  // Sale Requests Type:
  // 1. SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM
  // 0. SALE_REQUEST_1_DAT_HANG

  static getSaleRequestsTypeInfo = (saleRequestsType) => {
    switch (saleRequestsType) {
      case "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM": {
        return {
          text: "Yêu cầu tư vấn sản phẩm",
          value: "SALE_REQUEST_0_YEU_CAU_TU_VAN_SAN_PHAM",
        };
      }
      case "SALE_REQUEST_1_DAT_HANG": {
        return {
          text: "Đặt hàng",
          value: "SALE_REQUEST_1_DAT_HANG",
        };
      }
      default: {
      }
    }
  };

  static getNewSaleRequests = () => {
    return {
      id: null,
      name: "",
      content: "",
      orderNumber: 0,
      type: "",
      status: "",
    };
  };

  static getSaleRequestsInput = (saleRequests) => {
    const saleRequestsInput = {
      id: saleRequests.id,
      name: saleRequests.name,
      content: saleRequests.content,
      orderNumber: saleRequests.orderNumber,
      type: saleRequests.type,
      status: saleRequests.status,
    };
    return saleRequestsInput;
  };

  static getSaleRequestsList = () => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${SaleRequestsApiUrl}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    }
  };

  static getSaleRequestsListByType = (saleRequestsType) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(
        `${OleeApiURL}${SaleRequestsApiUrl}/type/${saleRequestsType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginData.token}`,
          },
        }
      ).then((res) => res.json());
    }
  };

  static getSaleRequestsDetail = (saleRequestsId) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${SaleRequestsApiUrl}/${saleRequestsId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    }
  };

  static insertSaleRequests = (saleRequests) => {
    // const loginData = AuthenticationService.getLoginData();
    // if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(saleRequests);
      return fetch(`${OleeApiURL}${SaleRequestsApiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    // } else {
    //   return;
    // }
  };

  static updateSaleRequests = (saleRequests) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(saleRequests);
      return fetch(`${OleeApiURL}${SaleRequestsApiUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static deleteSaleRequests = (saleRequests) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${SaleRequestsApiUrl}/${saleRequests.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };
}
