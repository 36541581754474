import { AuthenticationService } from "../../services/authentication-service";

export const adminlayout_GET_LOGIN_DATA_REQUEST =
  "adminlayout_GET_LOGIN_DATA_REQUEST";
export const adminlayout_GET_LOGIN_DATA_SUCCESS =
  "adminlayout_GET_LOGIN_DATA_SUCCESS";
export const adminlayout_GET_LOGIN_DATA_ERROR =
  "adminlayout_GET_LOGIN_DATA_ERROR";
export const adminlayout_LOGOUT_REQUEST = "adminlayout_LOGOUT_REQUEST";
export const adminlayout_LOGOUT_SUCCESS = "adminlayout_LOGOUT_SUCCESS";
export const adminlayout_LOGOUT_ERROR = "adminlayout_LOGOUT_ERROR";

export const getLoginData = () => {
  return (dispatch) => {
    dispatch({
      type: adminlayout_GET_LOGIN_DATA_REQUEST,
    });

    setTimeout(() => {
      const loginData = AuthenticationService.getLoginData();
      dispatch({
        type: adminlayout_GET_LOGIN_DATA_SUCCESS,
        payload: {
          loginData: loginData,
        },
      });
    }, 0);
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: adminlayout_LOGOUT_REQUEST,
    });

    AuthenticationService.clearLoginData();
    dispatch({
      type: adminlayout_LOGOUT_SUCCESS,
    });
  };
};
