import React from "react";
import "./facebook-page-plugin.css";

export const FacebookPagePlugin = (props) => {
  const html = (
    <>
      <div
        class="fb-page"
        data-href={`https://www.facebook.com/${props.faceBookName}`}
        data-tabs=""
        data-width="500"
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite={`https://www.facebook.com/${props.faceBookName}`}
          class="fb-xfbml-parse-ignore"
        >
          <a href={`https://www.facebook.com/${props.faceBookName}`}>
            Facebook
          </a>
        </blockquote>
      </div>
      {/* <div
        class="fb-page"
        data-href={`https://www.facebook.com/${props.faceBookName}`}
        data-tabs="timeline"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite={`https://www.facebook.com/${props.faceBookName}`}
          class="fb-xfbml-parse-ignore"
        >
          <a href={`https://www.facebook.com/${props.faceBookName}`}>
            Facebook
          </a>
        </blockquote>
      </div> */}
    </>
  );
  return html;
};
