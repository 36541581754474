import {
  uic_GET_UI_CONFIGS_LIST_REQUEST,
  uic_GET_UI_CONFIGS_LIST_SUCCESS,
  uic_GET_UI_CONFIGS_LIST_FAILURE,
  uic_GET_UI_CONFIGS_DETAIL_REQUEST,
  uic_GET_UI_CONFIGS_DETAIL_SUCCESS,
  uic_GET_UI_CONFIGS_DETAIL_FAILURE,
  uic_INSERT_UI_CONFIGS_REQUEST,
  uic_INSERT_UI_CONFIGS_SUCCESS,
  uic_INSERT_UI_CONFIGS_FAILURE,
  uic_UPDATE_UI_CONFIGS_REQUEST,
  uic_UPDATE_UI_CONFIGS_SUCCESS,
  uic_UPDATE_UI_CONFIGS_FAILURE,
  uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_REQUEST,
  uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_SUCCESS,
  uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_FAILURE,
  uic_UPDATE_UI_CONFIGS_IS_DELETED_REQUEST,
  uic_UPDATE_UI_CONFIGS_IS_DELETED_SUCCESS,
  uic_UPDATE_UI_CONFIGS_IS_DELETED_FAILURE,
  uic_DELETE_UI_CONFIGS_REQUEST,
  uic_DELETE_UI_CONFIGS_SUCCESS,
  uic_DELETE_UI_CONFIGS_FAILURE,
} from "../actions/ui-configs-page-actions";
import { RequestStatus } from "../../constants";

const initialState = {
  uiConfigsListRequestStatus: RequestStatus.RQ_NEW,
  uiConfigsListRequestResult: [],
  //
  uiConfigsDetailRequestStatus: RequestStatus.RQ_NEW,
  uiConfigsDetailRequestResult: null,
  //
  insertUIConfigsRequestStatus: RequestStatus.RQ_NEW,
  insertUIConfigsRequestResult: null,
  //
  updateUIConfigsRequestStatus: RequestStatus.RQ_NEW,
  updateUIConfigsRequestResult: null,
  //
  updateUIConfigsOrderNumberRequestStatus: RequestStatus.RQ_NEW,
  updateUIConfigsOrderNumberRequestResult: null,
  //
  updateUIConfigsIsDeletedRequestStatus: RequestStatus.RQ_NEW,
  updateUIConfigsIsDeletedRequestResult: null,
  //
  deleteUIConfigsRequestStatus: RequestStatus.RQ_NEW,
  deleteUIConfigsRequestResult: null,
  //
  errorMessage: null,
};

export function uiConfigsPageReducer(state = initialState, actions) {
  switch (actions.type) {
    case uic_GET_UI_CONFIGS_LIST_REQUEST: {
        return {
            ...state,
            uiConfigsListRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_GET_UI_CONFIGS_LIST_SUCCESS: {
        return {
            ...state,
            uiConfigsListRequestStatus: RequestStatus.RQ_SUCCESS,
            uiConfigsListRequestResult: actions.payload.uiConfigsListRequestResult
        }
    }
    case uic_GET_UI_CONFIGS_LIST_FAILURE: {
        return {
            ...state,
            uiConfigsListRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    case uic_GET_UI_CONFIGS_DETAIL_REQUEST: {
        return {
            ...state,
            uiConfigsDetailRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_GET_UI_CONFIGS_DETAIL_SUCCESS: {
        return {
            ...state,
            uiConfigsDetailRequestStatus: RequestStatus.RQ_SUCCESS,
            uiConfigsDetailRequestResult: actions.payload.uiConfigsDetailRequestResult
        }
    }
    case uic_GET_UI_CONFIGS_DETAIL_FAILURE: {
        return {
            ...state,
            uiConfigsDetailRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    case uic_INSERT_UI_CONFIGS_REQUEST: {
        return {
            ...state,
            insertUIConfigsRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_INSERT_UI_CONFIGS_SUCCESS: {
        return {
            ...state,
            insertUIConfigsRequestStatus: RequestStatus.RQ_SUCCESS,
            insertUIConfigsRequestResult: actions.payload.insertUIConfigsRequestResult
        }
    }
    case uic_INSERT_UI_CONFIGS_FAILURE: {
        return {
            ...state,
            insertUIConfigsRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    case uic_UPDATE_UI_CONFIGS_REQUEST: {
        return {
            ...state,
            updateUIConfigsRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_UPDATE_UI_CONFIGS_SUCCESS: {
        return {
            ...state,
            updateUIConfigsRequestStatus: RequestStatus.RQ_SUCCESS,
            updateUIConfigsRequestResult: actions.payload.updateUIConfigsRequestResult
        }
    }
    case uic_UPDATE_UI_CONFIGS_FAILURE: {
        return {
            ...state,
            updateUIConfigsRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    case uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_REQUEST: {
        return {
            ...state,
            updateUIConfigsOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_SUCCESS: {
        return {
            ...state,
            updateUIConfigsOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
            updateUIConfigsOrderNumberRequestResult: actions.payload.updateUIConfigsRequestResult
        }
    }
    case uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_FAILURE: {
        return {
            ...state,
            updateUIConfigsOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    case uic_UPDATE_UI_CONFIGS_IS_DELETED_REQUEST: {
        return {
            ...state,
            updateUIConfigsIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_UPDATE_UI_CONFIGS_IS_DELETED_SUCCESS: {
        return {
            ...state,
            updateUIConfigsIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
            updateUIConfigsIsDeletedRequestResult: actions.payload.updateUIConfigsRequestResult
        }
    }
    case uic_UPDATE_UI_CONFIGS_IS_DELETED_FAILURE: {
        return {
            ...state,
            updateUIConfigsIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    case uic_DELETE_UI_CONFIGS_REQUEST: {
        return {
            ...state,
            deleteUIConfigsRequestStatus: RequestStatus.RQ_REQUEST
        }
    }
    case uic_DELETE_UI_CONFIGS_SUCCESS: {
        return {
            ...state,
            deleteUIConfigsRequestStatus: RequestStatus.RQ_SUCCESS,
            deleteUIConfigsRequestResult: actions.payload.deleteUIConfigsRequestResult
        }
    }
    case uic_DELETE_UI_CONFIGS_FAILURE: {
        return {
            ...state,
            deleteUIConfigsRequestStatus: RequestStatus.RQ_ERROR,
            errorMessage: actions.payload.errorMessage
        }
    }
    default: {
      return state;
    }
  }
}
