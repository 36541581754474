import React from 'react';
import './news-detail.css';
// 
import { RequestStatus } from '../../constants';
import { LoadingComponent } from '../public/loading-component';
import { Container, Row, Col } from 'react-bootstrap';
import { BaseService } from '../../services/base-service';

export class NewsDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    getNewsDetailElement = () => {
        const element = <>
            <Container className='news-detail-container-div'>
                <Row>
                    <Col>
                        <div className='news-detail-content-div read-more-container-div'>
                            <div className='news-detail-name-div'>
                                <h1 className='news-detail-name-h1'>{this.props.newsDetail.name}</h1>
                            </div>
                            <div className='news-detail-created-time-div'><i className="far fa-calendar-alt event-item-info-date-icon-i"></i> {BaseService.convertCreatedTimeToDateString(this.props.newsDetail.createdTime)}</div>
                            {/* <div className='news-detail-description-div' dangerouslySetInnerHTML={{ __html: this.props.newsDetail.description }}></div>     */}
                            <div dangerouslySetInnerHTML={{ __html: this.props.newsDetail.description }}></div>
                            <div dangerouslySetInnerHTML={{ __html: this.props.newsDetail.content }}></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
        return element;
    }

    render() {
        const html = <>
            <div>
                {
                    (this.props.newsDetailRequestStatus === RequestStatus.RQ_SUCCESS)?(
                        this.getNewsDetailElement()
                    ):(
                        <LoadingComponent />
                    )
                }
            </div>
        </>;
        return (
            html
        );
    }
}