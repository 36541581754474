import React from "react";
import "../components/web-user-layout/product-detail.css";
import "./contact-form-page.css";
import { Container, Row, Col } from "react-bootstrap";
import { GoogleMap } from "../components/web-user-layout/google-map";
import { FormButton, FormTextInput } from "../components/public/form-components";
import phone_png from "../assets/images/icons/phone.png";
import zalo_png from "../assets/images/icons/zalo.png";
import facebook_messenge_png from "../assets/images/icons/facebook-messenge.png";

export default class ContactFormPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: "_blank",
    };
  }

  // getSaleContactElement = () => {
  //   const element = (
  //     <>
  //       <div className="product-detail-sale-contact-title-div">
  //         Liên hệ trực tiếp hoặc gửi thông tin của bạn về cho chúng để được tư
  //         vấn miễn phí trong thời gian sớm nhất:
  //       </div>
  //       <div className="product-detail-sale-contact-wrapper-div">
  //         <div className="product-detail-sale-contact-contact-div">
  //           <div>
  //             <div className="product-detail-sale-contact-item-div product-detail-sale-contact-phone-div">
  //               <a href="tel:+84979838777" title="Bấm để gọi cho chúng tôi.">
  //                 <img alt="" src={phone_png} />
  //                 0979 838 777
  //               </a>
  //             </div>
  //             <div className="product-detail-sale-contact-item-div product-detail-sale-contact-zalo-div">
  //               <a href="https://zalo.me/0979838777" target={this.state.target}>
  //                 <img alt="" src={zalo_png} />
  //                 Zalo
  //               </a>
  //             </div>
  //             <div className="product-detail-sale-contact-item-div product-detail-sale-contact-messenger-div">
  //               <a
  //                 href="https://www.messenger.com/t/113951036687515"
  //                 target={this.state.target}
  //               >
  //                 <img alt="" src={facebook_messenge_png} />
  //                 Messenger
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="product-detail-sale-contact-line-div">
  //           <div></div>
  //           <div>hoặc</div>
  //         </div>
  //         <div className="product-detail-sale-contact-form-div">
  //           <FormTextInput labelText="Tên Anh/Chị" />
  //           <FormTextInput labelText="Số điện thoại" />
  //           <br />
  //           <FormButton faIconClass="far fa-paper-plane" text="Gửi" />
  //         </div>
  //       </div>
  //     </>
  //   );
  //   return element;
  // };

  renderContactInfo = () => {
    const element = (
      <>
        <Container className="Container contact-form-wrapper-div">
          <Row>
            <Col>
              <div className="contact-form-title-wrapper-div">
                <div>Thông tin liên hệ</div>
                <div />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="contact-form-page-title-div">
                CÔNG TY TNHH MỘT THÀNH VIÊN SẢN XUẤT THƯƠNG MẠI MỸ PHẨM SEVEN NINE
              </div>
              <div className="contact-form-page-description-div">
                Độc quyền phân phối mỹ phẩm sạch thương hiệu Olee - Besu - HARI
                WON - Topcare
              </div>
              <div className="contact-form-page-info-wrapper-div">
                <div className="contact-form-page-info-div">
                  <b>Địa chỉ:</b> 175 Lê Đình Cẩn, Phường Tân Tạo, Bình Tân,
                  Thành phố Hồ Chí Minh, Vietnam
                </div>
                <div className="contact-form-page-info-div">
                  <b>MST:</b> 0312576913
                </div>
                {/* <div className="contact-form-page-info-div">
                  <b>Hotline:</b> 0979.838.777
                </div> */}
                <div className="contact-form-page-info-div">
                  <b>Email:</b> myphamsacholee@gmail.com
                </div>
                {/* <div className="contact-form-page-info-div">
                  <b>Website:</b>{" "}
                  <a
                    href="https://myphamsevennine.com/"
                    target={this.state.target}
                  >
                    myphamsevennine.com
                  </a>
                </div> */}
                {/* <div className="contact-form-page-info-div">
                  <b>Facebook:</b>{" "}
                  <a
                    href="https://www.facebook.com/luongminhdatfanpage/"
                    target={this.state.target}
                  >
                    Lương Minh Đạt Fanpage
                  </a>
                </div> */}
              </div>
            </Col>
            <Col md={6}>
              {this.props.footerInfoData ? (
                <>
                  <div className="contact-form-page-info-google-map-div">
                    <GoogleMap src={this.props.footerInfoData.googleMapSrc} />
                  </div>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col className='contact-form-page-contact-form-col'>{this.getSaleContactElement()}</Col>
          </Row> */}
        </Container>
      </>
    );
    return element;
  };

  render() {
    const html = <>{this.renderContactInfo()}</>;
    return html;
  }
}
