import React from 'react';
import './admin-video-page.css';
// 
import { AdminVideoList } from '../components/admin-layout/admin-video-list';

export class AdminVideoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <AdminVideoList
                windowWidth={this.props.windowWidth}
                videoListRequestStatus={this.props.videoListRequestStatus}
                videoList={this.props.videoList}
                videoDetailRequestStatus={this.props.videoDetailRequestStatus}
                videoDetail={this.props.videoDetail}
                insertVideoRequestStatus={this.props.insertVideoRequestStatus}
                insertedVideo={this.props.insertedVideo}
                updateVideoRequestStatus={this.props.updateVideoRequestStatus}
                updatedVideo={this.props.updatedVideo}
                updateVideoOrderNumberRequestStatus={this.props.updateVideoOrderNumberRequestStatus}
                updatedVideoOrderNumber={this.props.updatedVideoOrderNumber}
                updateVideoIsDeletedRequestStatus={this.props.updateVideoIsDeletedRequestStatus}
                updatedVideoIsDeleted={this.props.updatedVideoIsDeleted}
                deleteVideoRequestStatus={this.props.deleteVideoRequestStatus}
                deletedVideo={this.props.deletedVideo}        
                errorMessage={this.props.videoErrorMessage}
                // 
                getVideoList={this.props.getVideoList}
                getVideoDetail={this.props.getVideoDetail}
                insertVideo={this.props.insertVideo}
                updateVideo={this.props.updateVideo}
                updateVideo_OrderNumber={this.props.updateVideo_OrderNumber}
                updateVideo_IsDeleted={this.props.updateVideo_IsDeleted}
                deleteVideo={this.props.deleteVideo}
            />
        </>;
        return (
            html
        );
    }
}