import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import { UIConfigsService } from "../../services/ui-configns-service";
import {
  FormButton,
  FormNumberInput,
  FormTextInput,
  FormToggle,
  FormConfirmationModal,
} from "../public/form-components";
import "./admin-menu-list.css";
import { RequestStatus } from "../../constants";

export class AdminMenuList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminMenuList: [],
      targetAdminMenuItem: {
        text: "",
      },
      isOpenAdminModalItemConfirmDeleteModal: false,
    };
  }

  componentDidMount() {
    const content = this.props.adminMenuListConfigs.content;
    const adminMenuList = !BaseService.stringIsNullOrWhiteSpace(content)
      ? BaseService.getOrderListWithText(JSON.parse(content))
      : [];
    this.setAdminMenuList(adminMenuList);
  }

  componentWillReceiveProps(nextProps) {
    const content = nextProps.adminMenuListConfigs.content;
    const adminMenuList = !BaseService.stringIsNullOrWhiteSpace(content)
      ? BaseService.getOrderListWithText(JSON.parse(content))
      : [];
    this.setAdminMenuList(adminMenuList);
  }

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  setAdminMenuList = (adminMenuList) => {
    if (adminMenuList) {
      this.setState((state) => {
        return {
          adminMenuList,
        };
      });
    }
  };

  handleOnClickAddMenuListItem = () => {
    this.trackIsDataHasUpdated();
    this.setState((state) => {
      const numberOfPrimaryMenu = this.state.adminMenuList.filter((t) =>
        BaseService.stringIsNullOrWhiteSpace(t.parentId)
      ).length;
      const newMenuItem = {
        ...UIConfigsService.getNewMenuItem(),
      };
      newMenuItem.orderNumber = numberOfPrimaryMenu;
      return {
        adminMenuList: state.adminMenuList.concat(newMenuItem),
      };
    });
  };

  handleOnChangeMenuItemOrderNumber = (id, value) => {
    this.trackIsDataHasUpdated();
    let adminMenuList = this.state.adminMenuList;
    const index = adminMenuList.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminMenuList[index].orderNumber = value;
      this.setState({
        adminMenuList,
      });
    }
  };

  handleOnChangeMenuItemIsDelete = (id, value) => {
    this.trackIsDataHasUpdated();
    let adminMenuList = this.state.adminMenuList;
    const index = adminMenuList.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminMenuList[index].isDeleted = !value;
      this.setState({
        adminMenuList,
      });
    }
  };

  handleOnChangeMenuItemText = (id, value) => {
    this.trackIsDataHasUpdated();
    let adminMenuList = this.state.adminMenuList;
    const index = adminMenuList.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminMenuList[index].text = value;
      this.setState({
        adminMenuList,
      });
    }
  };

  handleOnChangeMenuItemPath = (id, value) => {
    this.trackIsDataHasUpdated();
    let adminMenuList = this.state.adminMenuList;
    const index = adminMenuList.findIndex((t) => t.id === id);
    if (index !== -1) {
      adminMenuList[index].path = value;
      this.setState({
        adminMenuList,
      });
    }
  };

  handleOnClickRemoveSubmenuItem = (menu) => {
    this.setState(
      (state) => {
        return {
          targetAdminMenuItem: menu,
          isOpenAdminModalItemConfirmDeleteModal: true,
        };
      },
      () => {
        this.displayModalItem("none");
      }
    );
  };

  displayModalItem = (value) => {
    let adminUIConfigsModal = document.getElementById("AdminUIConfigsModal");
    if (adminUIConfigsModal) {
      value = value === "block" ? 1 : 0;
      adminUIConfigsModal.style.opacity = value;
    }
  };

  getDeleteAdminMenuItemIds = (menu) => {
    let ids = [menu.id];
    const subMenuItems = this.state.adminMenuList.filter(
      (t) => t.parentId === menu.id
    );
    if (subMenuItems.length > 0) {
      subMenuItems.forEach((t) => {
        ids = ids.concat(this.getDeleteAdminMenuItemIds(t));
      });
    }
    return ids;
  };

  onConfirmYesOrNo = (res) => {
    this.setState(
      {
        isOpenAdminModalItemConfirmDeleteModal: false,
      },
      () => {
        this.displayModalItem("block");
      }
    );
    if (res && this.state.targetAdminMenuItem) {
      const ids = this.getDeleteAdminMenuItemIds(
        this.state.targetAdminMenuItem
      );
      const adminMenuList = this.state.adminMenuList.filter(
        (t) => ids.findIndex((k) => k === t.id) === -1
      );
      this.setState(
        (state) => {
          return {
            adminMenuList: [],
            targetAdminMenuItem: {
              text: "",
            },
          };
        },
        () => {
          this.trackIsDataHasUpdated();
          this.setAdminMenuList(adminMenuList);
        }
      );

      //   const idx = this.state.adminMenuList.findIndex(
      //     (t) => t.id === this.state.targetAdminMenuItem.id
      //   );
      //   if (idx !== -1) {
      //     const adminMenuList = this.state.adminMenuList;
      //     // adminMenuList.splice(idx, 1);
      //     const getDeleteItemIds = this.getDeleteItemIds(idx);
      //     this.setState(
      //       (state) => {
      //         return {
      //           adminMenuList: [],
      //           targetAdminMenuItem: {
      //             text: "",
      //           },
      //         };
      //       },
      //       () => {
      //         this.trackIsDataHasUpdated();
      //         this.setAdminMenuList(adminMenuList);
      //       }
      //     );
      //   }
    }
  };

  handleOnClickAddSubMenu = (menu) => {
    this.trackIsDataHasUpdated();
    this.setState((state) => {
      const numberOfSubMenu = this.state.adminMenuList.filter(
        (t) => t.parentId === menu.id
      ).length;
      const newSubItem = {
        ...UIConfigsService.getNewMenuItem(),
      };
      newSubItem.orderNumber = numberOfSubMenu;
      newSubItem.parentId = menu.id;
      return {
        adminMenuList: state.adminMenuList.concat(newSubItem),
      };
    });
  };

  getAdminMenuItem = (menu) => {
    const element = (
      <>
        <Row className="admin-menu-list-item-row">
          <Col xs={6} lg={2}>
            <FormNumberInput
              value={menu.orderNumber}
              min="0"
              onChange={(value) => {
                this.handleOnChangeMenuItemOrderNumber(menu.id, value);
              }}
            />
          </Col>
          <Col xs={6} lg={1}>
            <FormToggle
              value={menu.isDeleted ? !menu.isDeleted : true}
              onChange={(value) => {
                this.handleOnChangeMenuItemIsDelete(menu.id, value);
              }}
            />
          </Col>
          <Col sm={6} lg={4}>
            <FormTextInput
              value={menu.text}
              onChange={(value) => {
                this.handleOnChangeMenuItemText(menu.id, value);
              }}
              placeholder="Tiêu đề"
            />
          </Col>
          <Col sm={5} lg={4}>
            <FormTextInput
              value={menu.path}
              onChange={(value) => {
                this.handleOnChangeMenuItemPath(menu.id, value);
              }}
              placeholder="Đường dẫn"
            />
          </Col>
          <Col sm={1} lg={1} className="admin-menu-list-delete-col">
            <div
              className="admin-menu-list-delete-button-div"
              onClick={() => {
                this.handleOnClickRemoveSubmenuItem(menu);
              }}
            >
              Xóa
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="admin-menu-list-add-submenu-col">
            <div>
              <div
                className="admin-menu-list-add-menu-button-div admin-menu-list-add-submenu-button-div"
                onClick={() => {
                  this.handleOnClickAddSubMenu(menu);
                }}
              >
                <span>
                  Thêm submenu{" "}
                  {!BaseService.stringIsNullOrWhiteSpace(menu.text) ? (
                    <>{" cho "}</>
                  ) : (
                    <></>
                  )}
                  {!BaseService.stringIsNullOrWhiteSpace(menu.text) ? (
                    <b>{menu.text}</b>
                  ) : (
                    <></>
                  )}
                </span>
                {/* {`Thêm submenu ${
                  !BaseService.stringIsNullOrWhiteSpace(menu.text)
                    ? ` cho ${menu.text}`
                    : ""
                }`} */}
              </div>
              <div className="admin-menu-list-submenu-wrapper-div">
                <Container fluid={true}>
                  {this.state.adminMenuList
                    .filter((t) => t.parentId === menu.id)
                    .map((menu) => this.getAdminMenuItem(menu))}
                </Container>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
    return element;
  };

  renderAdminMenuList = () => {
    const element = (
      <>
        <div className="admin-menu-list-note-div">
          <div>
            *** Lưu ý: Đối với <b>Đường dẫn</b> chỉ nhập một trong hai loại giá
            trị <b>/san-pham-kem-sach-1</b> hoặc{" "}
            <b>https://www.trangkemsach.com.vn/</b>.
          </div>
        </div>
        <div className="admin-menu-list-title-div">
          <div className="admin-menu-list-title-text-div">Danh sách menu</div>
          <div className="admin-menu-list-title-hr-div"></div>
          {/* <br />
          Số lượng phần tử: <b>{this.state.adminMenuList.length}</b> */}
        </div>
        {/* <div
          className="admin-menu-list-add-button-div"
          onClick={this.handleOnClickAddMenuListItem}
        >
          <i class="fas fa-plus"></i>
          Thêm mới menu
        </div> */}
        <Container fluid={true}>
          {/* <Row>
            <Col sm={2}></Col>
            <Col sm={1}></Col>
            <Col sm={6}></Col>
            <Col sm={3}></Col>
          </Row> */}
          {this.state.adminMenuList
            .filter((t) => BaseService.stringIsNullOrWhiteSpace(t.parentId))
            .map((menu) => this.getAdminMenuItem(menu))}
        </Container>
        <div
          className="admin-menu-list-add-menu-button-div"
          onClick={this.handleOnClickAddMenuListItem}
        >
          {/* <i class="fas fa-plus"></i> */}
          Thêm menu mới
        </div>
      </>
    );
    return element;
  };

  handleOnClickSaveAdminMenuList = () => {
    const adminMenuList = this.state.adminMenuList;
    let uiConfigs = UIConfigsService.getUIConfigsInput({
      ...this.props.adminMenuListConfigs,
      content: JSON.stringify(adminMenuList),
    });
    this.props.save(uiConfigs);
  };

  render() {
    const html = (
      <>
        {this.renderAdminMenuList()}
        <br />
        <FormButton
          type="save"
          isRequesting={
            this.props.insertUIConfigsRequestStatus ===
              RequestStatus.RQ_REQUEST ||
            this.props.updateUIConfigsRequestStatus === RequestStatus.RQ_REQUEST
              ? true
              : false
          }
          disabled={
            this.props.insertUIConfigsRequestStatus ===
              RequestStatus.RQ_REQUEST ||
            this.props.updateUIConfigsRequestStatus === RequestStatus.RQ_REQUEST
              ? true
              : false
          }
          onClick={this.handleOnClickSaveAdminMenuList}
        />
        <FormConfirmationModal
          isShowModal={this.state.isOpenAdminModalItemConfirmDeleteModal}
          modalTitle="Xóa nhận xóa"
          modalContent={
            <>
              Bạn có chắc muốn xóa Menu{" "}
              <b>{this.state.targetAdminMenuItem.text}</b>, các submenu của menu
              này cũng sẽ bị xóa?
            </>
          }
          okText="Xóa"
          cancelText="Hủy"
          onConfirmYesOrNo={this.onConfirmYesOrNo}
        />
      </>
    );
    return html;
  }
}
