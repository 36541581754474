import { VoucherService } from "../../services/voucher-service";
import { BaseService } from "../../services/base-service";
import { Messages } from "../../constants";

export const voucherpage_GET_VOUCHER_LIST_REQUEST = "voucherpage_GET_VOUCHER_LIST_REQUEST";
export const voucherpage_GET_VOUCHER_LIST_SUCCESS = "voucherpage_GET_VOUCHER_LIST_SUCCESS";
export const voucherpage_GET_VOUCHER_LIST_ERROR = "voucherpagee_GET_VOUCHER_LIST_ERROR";

export const voucherpage_CHECK_VOUCHER_STATUC_REQUEST = "voucherpage_CHECK_VOUCHER_STATUC_REQUEST";
export const voucherpage_CHECK_VOUCHER_STATUC_SUCCESS = "voucherpage_CHECK_VOUCHER_STATUC_SUCCESS";
export const voucherpage_CHECK_VOUCHER_STATUC_ERROR = "voucherpagee_CHECK_VOUCHER_STATUC_ERROR";

export const getVoucherList = () => {
    return (dispatch) => {
        dispatch({
            type: voucherpage_GET_VOUCHER_LIST_REQUEST,
        });

        const request = VoucherService.getVoucherList();
        if (request) {
            request.then(
                (data) => {
                    if (data.length > 0) {
                        data = data.map((item) => {
                            return {
                                ...item,
                                styleConfig: !BaseService.stringIsNullOrWhiteSpace(item.styleConfig)
                                    ? JSON.parse(item.styleConfig)
                                    : {},
                            };
                        });
                    }
                    dispatch({
                        type: voucherpage_GET_VOUCHER_LIST_SUCCESS,
                        payload: {
                            voucherList: data,
                        },
                    });
                },
                (error) => {
                    dispatch({
                        type: voucherpage_GET_VOUCHER_LIST_ERROR,
                        payload: {
                            errorMessage: Messages.REQUEST_ERROR,
                        },
                    });
                }
            );
        }
    };
};

export const checkVoucherStatus = (voucherCode) => {
    return (dispatch) => {
        dispatch({
            type: voucherpage_CHECK_VOUCHER_STATUC_REQUEST,
        });

        const request = VoucherService.checkVoucherStatus(voucherCode);
        if (request) {
            request.then(
                (data) => {
                    const { isValid } = data;
                    if (isValid) {
                        const voucherRequest = VoucherService.getVoucherInfo(voucherCode);
                        if (voucherRequest) {
                            voucherRequest.then(
                                (voucherRequestData) => {
                                    const voucherRequestDt = voucherRequestData;
                                    let voucher = null;
                                    if (voucherRequestDt && voucherRequestDt.length > 0) voucher = voucherRequestDt[0];
                                    dispatch({
                                        type: voucherpage_CHECK_VOUCHER_STATUC_SUCCESS,
                                        payload: {
                                            voucherStatus: { isValid, voucher },
                                        },
                                    });
                                },
                                (voucherRequestError) => {
                                    dispatch({
                                        type: voucherpage_CHECK_VOUCHER_STATUC_REQUEST,
                                        payload: {
                                            errorMessage: Messages.REQUEST_ERROR,
                                        },
                                    });
                                }
                            );
                        }
                    } else {
                        dispatch({
                            type: voucherpage_CHECK_VOUCHER_STATUC_SUCCESS,
                            payload: {
                                voucherStatus: { isValid },
                            },
                        });
                    }
                },
                (error) => {
                    dispatch({
                        type: voucherpage_CHECK_VOUCHER_STATUC_REQUEST,
                        payload: {
                            errorMessage: Messages.REQUEST_ERROR,
                        },
                    });
                }
            );
        }
    };
}