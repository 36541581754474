const getSubState = (state) => state.homePageReducer;

export const getImageSliderImagesListRequestStatus = (state) => getSubState(state).imageSliderImagesListRequestStatus;
export const getImageSliderImagesList = (state) => getSubState(state).imageSliderImagesList;
export const getServiceListRequestStatus = (state) => getSubState(state).serviceListRequestStatus;
export const getServiceList = (state) => getSubState(state).serviceList;
export const getProductCategoryListRequestStatus = (state) => getSubState(state).productCategoryListRequestStatus;
export const getProductCategoryList = (state) => getSubState(state).productCategoryList;
export const getProductListRequestStatus = (state) => getSubState(state).productListRequestStatus;
export const getProductList = (state) => getSubState(state).productList;
export const getVideoListRequestStatus = (state) => getSubState(state).videoListRequestStatus;
export const getVideoList = (state) => getSubState(state).videoList;
export const getPolicyListRequestStatus = (state) => getSubState(state).policyListRequestStatus;
export const getPolicyList = (state) => getSubState(state).policyList;
export const getTypicalFaceListRequestStatus = (state) => getSubState(state).typicalFaceListRequestStatus;
export const getTypicalFaceStarList = (state) => getSubState(state).typicalFaceStarList;
export const getTypicalFaceDistributorList = (state) => getSubState(state).typicalFaceDistributorList;
export const getEventListRequestStatus = (state) => getSubState(state).eventListRequestStatus;
export const getEventList = (state) => getSubState(state).eventList;