import { connect } from 'react-redux';
import { AdminBannerPage } from '../pages/admin-banner-page';
import { bannerPageSelectors } from '../redux/selectors';
import { bannerPageActions } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        bannerListRequestStatus: bannerPageSelectors.getBannerListRequestStatus(state),
        bannerList: bannerPageSelectors.getBannerList(state),
        bannerDetailRequestStatus: bannerPageSelectors.getBannerDetailRequestStatus(state),
        bannerDetail: bannerPageSelectors.getBannerDetail(state),
        insertBannerRequestStatus: bannerPageSelectors.getInsertBannerRequestStatus(state),
        insertedBanner: bannerPageSelectors.getInsertedBanner(state),
        updateBannerRequestStatus: bannerPageSelectors.getUpdateBannerRequestStatus(state),
        updatedBanner: bannerPageSelectors.getUpdatedBanner(state),        
        updateBannerOrderNumberRequestStatus: bannerPageSelectors.getUpdateBannerOrderNumberRequestStatus(state),
        updatedBannerOrderNumber: bannerPageSelectors.getUpdatedBannerOrderNumber(state),        
        updateBannerIsDeletedRequestStatus: bannerPageSelectors.getUpdateBannerIsDeletedRequestStatus(state),
        updatedBannerIsDeleted: bannerPageSelectors.getUpdatedBannerIsDeleted(state),
        deleteBannerRequestStatus: bannerPageSelectors.getDeleteBannerRequestStatus(state),
        deletedBanner: bannerPageSelectors.getDeletedBanner(state),  
        bannerItemListRequestStatus: bannerPageSelectors.getBannerItemListRequestStatus(state),
        bannerItemList: bannerPageSelectors.getBannerItemList(state),
        bannerItemDetailRequestStatus: bannerPageSelectors.getBannerItemDetailRequestStatus(state),
        bannerItemDetail: bannerPageSelectors.getBannerItemDetail(state),
        insertBannerItemRequestStatus: bannerPageSelectors.getInsertBannerItemRequestStatus(state),
        insertedBannerItem: bannerPageSelectors.getInsertedBannerItem(state),
        updateBannerItemRequestStatus: bannerPageSelectors.getUpdateBannerItemRequestStatus(state),
        updatedBannerItem: bannerPageSelectors.getUpdatedBannerItem(state),        
        updateBannerItemOrderNumberRequestStatus: bannerPageSelectors.getUpdateBannerItemOrderNumberRequestStatus(state),
        updatedBannerItemOrderNumber: bannerPageSelectors.getUpdatedBannerItemOrderNumber(state),        
        updateBannerItemIsDeletedRequestStatus: bannerPageSelectors.getUpdateBannerItemIsDeletedRequestStatus(state),
        updatedBannerItemIsDeleted: bannerPageSelectors.getUpdatedBannerItemIsDeleted(state),
        deleteBannerItemRequestStatus: bannerPageSelectors.getDeleteBannerItemRequestStatus(state),
        deletedBannerItem: bannerPageSelectors.getDeletedBannerItem(state), 
        bannerErrorMessage: bannerPageSelectors.getErrorMessage(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    getBannerList: () => {
        dispatch(bannerPageActions.getBannerList());
    },

    getBannerDetail: (bannerId) => {
        dispatch(bannerPageActions.getBannerDetail(bannerId));
    },
    
    insertBanner: (banner, bannerItems) => {
        dispatch(bannerPageActions.insertBanner(banner, bannerItems));
    },
    
    updateBanner: (banner, bannerItems, deletedBannerItems) => {
        dispatch(bannerPageActions.updateBanner(banner, bannerItems, deletedBannerItems));
    },
    
    updateBanner_OrderNumber: (bannerId, value) => {
        dispatch(bannerPageActions.updateBanner_OrderNumber(bannerId, value));
    },
    
    updateBanner_IsDeleted: (bannerId, value) => {
        dispatch(bannerPageActions.updateBanner_IsDeleted(bannerId, value));
    },
    
    deleteBanner: (banner) => {
        dispatch(bannerPageActions.deleteBanner(banner));
    },
    
    getBannerItemList: () => {
        dispatch(bannerPageActions.getBannerItemList());
    },

    getBannerItemDetail: (bannerItemId) => {
        dispatch(bannerPageActions.getBannerItemDetail(bannerItemId));
    },
    
    insertBannerItem: (bannerItem) => {
        dispatch(bannerPageActions.insertBannerItem(bannerItem));
    },
    
    updateBannerItem: (bannerItem) => {
        dispatch(bannerPageActions.updateBannerItem(bannerItem));
    },
    
    updateBannerItem_OrderNumber: (bannerItemId, value) => {
        dispatch(bannerPageActions.updateBannerItem_OrderNumber(bannerItemId, value));
    },
    
    updateBannerItem_IsDeleted: (bannerItemId, value) => {
        dispatch(bannerPageActions.updateBannerItem_IsDeleted(bannerItemId, value));
    },
    
    deleteBannerItem: (bannerItem) => {
        dispatch(bannerPageActions.deleteBannerItem(bannerItem));
    }
})

const AdminBannerPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminBannerPage)

export default AdminBannerPageContainer;