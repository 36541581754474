import { UIConfigsService } from "../../services/ui-configns-service";
import { Messages } from "../../constants";

// Get UI Configs List
export const uic_GET_UI_CONFIGS_LIST_REQUEST = "uic_GET_UI_CONFIGS_LIST_REQUEST";
export const uic_GET_UI_CONFIGS_LIST_SUCCESS = "uic_GET_UI_CONFIGS_LIST_SUCCESS";
export const uic_GET_UI_CONFIGS_LIST_FAILURE = "uic_GET_UI_CONFIGS_LIST_FAILURE";

// Get UI Configs Detail
export const uic_GET_UI_CONFIGS_DETAIL_REQUEST = "uic_GET_UI_CONFIGS_DETAIL_REQUEST";
export const uic_GET_UI_CONFIGS_DETAIL_SUCCESS = "uic_GET_UI_CONFIGS_DETAIL_SUCCESS";
export const uic_GET_UI_CONFIGS_DETAIL_FAILURE = "uic_GET_UI_CONFIGS_DETAIL_FAILURE";

// Inset UI Configs
export const uic_INSERT_UI_CONFIGS_REQUEST = "uic_INSERT_UI_CONFIGS_REQUEST";
export const uic_INSERT_UI_CONFIGS_SUCCESS = "uic_INSERT_UI_CONFIGS_SUCCESS";
export const uic_INSERT_UI_CONFIGS_FAILURE = "uic_INSERT_UI_CONFIGS_FAILURE";

// Update UI Configs
export const uic_UPDATE_UI_CONFIGS_REQUEST = "uic_UPDATE_UI_CONFIGS_REQUEST";
export const uic_UPDATE_UI_CONFIGS_SUCCESS = "uic_UPDATE_UI_CONFIGS_SUCCESS";
export const uic_UPDATE_UI_CONFIGS_FAILURE = "uic_UPDATE_UI_CONFIGS_FAILURE";

export const uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_REQUEST = 'uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_REQUEST';
export const uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_SUCCESS = 'uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_SUCCESS';
export const uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_FAILURE = 'uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_FAILURE';

export const uic_UPDATE_UI_CONFIGS_IS_DELETED_REQUEST = 'uic_UPDATE_UI_CONFIGS_IS_DELETED_REQUEST';
export const uic_UPDATE_UI_CONFIGS_IS_DELETED_SUCCESS = 'uic_UPDATE_UI_CONFIGS_IS_DELETED_SUCCESS';
export const uic_UPDATE_UI_CONFIGS_IS_DELETED_FAILURE = 'uic_UPDATE_UI_CONFIGS_IS_DELETED_FAILURE';

// Delete UI Configs
export const uic_DELETE_UI_CONFIGS_REQUEST = "uic_DELETE_UI_CONFIGS_REQUEST";
export const uic_DELETE_UI_CONFIGS_SUCCESS = "uic_DELETE_UI_CONFIGS_SUCCESS";
export const uic_DELETE_UI_CONFIGS_FAILURE = "uic_DELETE_UI_CONFIGS_FAILURE";

export const getUIConfigsList = () => {
    return(dispatch) => {
        dispatch({
            type: uic_GET_UI_CONFIGS_LIST_REQUEST
        });

        const request = UIConfigsService.getUIConfigsList();
        if(request) {
            request.then(data => {
                dispatch({
                    type: uic_GET_UI_CONFIGS_LIST_SUCCESS,
                    payload: {
                        uiConfigsListRequestResult: data
                    }
                });
            }, error => {
                dispatch({
                    type: uic_GET_UI_CONFIGS_LIST_FAILURE,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const getUIConfigsDetail = (uiConfigsId) => {
    return(dispatch) => {
        dispatch({
            type: uic_GET_UI_CONFIGS_DETAIL_REQUEST
        });

        const request = UIConfigsService.getUIConfigsDetail(uiConfigsId);
        if(request) {
            request.then(data => {
                const uiConfigsDetail = data;
                console.log('UIConfigsService.getUIConfigsDetail(uiConfigsId);', data);
                dispatch({
                    type: uic_GET_UI_CONFIGS_DETAIL_SUCCESS,
                    payload: {
                        uiConfigsDetailRequestResult: uiConfigsDetail
                    }
                });
            }, error => {
                dispatch({
                    type: uic_GET_UI_CONFIGS_DETAIL_FAILURE,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }   
}

export const insertUIConfigs = (uiConfigs) => {
    return(dispatch) => {
        dispatch({
            type: uic_INSERT_UI_CONFIGS_REQUEST
        });

        const request = UIConfigsService.insertUIConfigs(uiConfigs);
        if(request) {
            request.then(data => {
                dispatch({
                    type: uic_INSERT_UI_CONFIGS_SUCCESS,
                    payload: {
                        insertUIConfigsRequestResult: data
                    }
                });
            }, error => {
                dispatch({
                    type: uic_INSERT_UI_CONFIGS_FAILURE,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const updateUIConfigs = (uiConfigs) => {
    return (dispatch) => {
        return updateUIConfigsCore(dispatch, uiConfigs, uic_UPDATE_UI_CONFIGS_REQUEST, uic_UPDATE_UI_CONFIGS_SUCCESS, uic_UPDATE_UI_CONFIGS_FAILURE);
    }
}

export const updateUIConfigs_OrderNumber = (uiConfigsId, value) => {
    return async (dispatch) => {
        return updateUIConfigsCore(dispatch, {
             id: uiConfigsId,
             orderNumber: value
        } , uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_REQUEST, uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_SUCCESS, uic_UPDATE_UI_CONFIGS_ORDER_NUMBER_FAILURE);
    }
}

export const updateUIConfigs_IsDeleted = (uiConfigsId, value) => {
    return async (dispatch) => {
        return updateUIConfigsCore(dispatch, {
            id: uiConfigsId,
            isDeleted: value
        } , uic_UPDATE_UI_CONFIGS_IS_DELETED_REQUEST, uic_UPDATE_UI_CONFIGS_IS_DELETED_SUCCESS, uic_UPDATE_UI_CONFIGS_IS_DELETED_FAILURE);
    }
}

const updateUIConfigsCore = (dispatch, uiConfigs, requestStatus, successStatus, errorStatus) => {
    dispatch({
        type: requestStatus
    });

    const request = UIConfigsService.updateUIConfigs(uiConfigs);
    if(request) {
        request.then(data => {
            dispatch({
                type: successStatus,
                payload: {
                    updateUIConfigsRequestResult: data
                }
            });
        }, error => {
            dispatch({
                type: errorStatus,
                payload: {
                    errorMessage: Messages.REQUEST_ERROR
                }
            });
        });
    }
}

export const deleteUIConfigs = (uiConfigs) => {
    return(dispatch) => {
        dispatch({
            type: uic_DELETE_UI_CONFIGS_REQUEST
        });

        const request = UIConfigsService.deleteUIConfigs(uiConfigs);
        if(request) {
            request.then(data => {
                dispatch({
                    type: uic_DELETE_UI_CONFIGS_SUCCESS,
                    payload: {
                        deleteUIConfigsRequestResult: uiConfigs
                    }
                });
            }, error => {
                dispatch({
                    type: uic_DELETE_UI_CONFIGS_FAILURE,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}