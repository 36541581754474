import React from "react";
import "./admin-news-list.css";
//
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../../components/public/loading-component";
import { TableComponent } from "../../components/public/table-component";
import {
  FormButton,
  FormModal,
  FormConfirmationModal,
  FormNumberInput,
  FormToggle,
} from "../../components/public/form-components";
import { AdminNewsDetail } from "../../components/admin-layout/admin-news-detail";
import { NewsService } from "../../services/news-service";
import { BaseService } from "../../services/base-service";

export class AdminNewsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenNewsDetailModal: false,
      isOpenNewsConfirmDeleteModal: false,
      modalTitle: "",
      confirmModalInfo: {
        modalTitle: "",
        modalContent: "",
      },
      targetNews: null,
      deletingNewsId: null,
      newsList: [],
      newsOrderNumberTimerId: BaseService.getRandomString(),
      newsUpdateOrderNumberTargetId: null,
      newsIsDeletedTimerId: BaseService.getRandomString(),
      newsUpdateIsDeletedTargetId: null,
      //
      isDataHasUpdated: false,
      headers: [
        { title: "Sắp xếp", width: "111" },
        { title: "Kích hoạt", width: "99" },
        { title: "Cẩm nang" },
        // { title: 'Ghi chú' },
        { title: "", width: "120" },
      ],
    };
  }

  componentDidMount() {
    this.props.getNewsList();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.newsListRequestStatus !== nextProps.newsListRequestStatus &&
      nextProps.newsListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      let newsList = nextProps.newsList;
      this.reOrderNewsList(newsList);
    }

    console.log(
      "AdminNewsPage - componentWillReceiveProps - nextProps",
      nextProps
    );
    if (
      (this.props.insertNewsRequestStatus !==
        nextProps.insertNewsRequestStatus &&
        nextProps.insertNewsRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.updateNewsRequestStatus !==
        nextProps.updateNewsRequestStatus &&
        nextProps.updateNewsRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.deleteNewsRequestStatus !==
        nextProps.deleteNewsRequestStatus &&
        nextProps.deleteNewsRequestStatus === RequestStatus.RQ_SUCCESS)
    ) {
      this.props.getAllSlugs();
      this.setState({
        isOpenNewsDetailModal: false,
      });
      // setTimeout(() => {
      this.props.getNewsList();
      // }, 5000);
    }

    if (
      this.props.newsDetailRequestStatus !==
      nextProps.newsDetailRequestStatus &&
      nextProps.newsDetailRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      if (nextProps.newsDetail) {
        this.setState({
          targetNews: nextProps.newsDetail,
        });
      }
    }
  }

  reOrderNewsList = (newsList) => {
    let res = BaseService.getOrderListWithCreatedTime(newsList);
    this.setState({
      newsList: res,
    });
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  onChangeNewsOrderNumber = (newsId, value) => {
    const idx = this.state.newsList.findIndex((t) => t.id === newsId);
    if (idx !== -1) {
      let newsList = this.state.newsList;
      newsList[idx].orderNumber = value;
      this.setState({
        newsList: newsList,
      });
      BaseService.clearTimer(this.state.newsOrderNumberTimerId);
      BaseService.setTimmer(this.state.newsOrderNumberTimerId, () => {
        this.setState({
          newsUpdateOrderNumberTargetId: newsId,
        });
        this.props.updateNews_OrderNumber(
          newsId,
          newsList[idx].image ? newsList[idx].image.id : null,
          value
        );
      });
    }
  };

  onChangeNewsIsDeleted = (newsId, value) => {
    const idx = this.state.newsList.findIndex((t) => t.id === newsId);
    if (idx !== -1) {
      let newsList = this.state.newsList;
      newsList[idx].isDeleted = !value;
      this.setState({
        newsList: newsList,
      });
      BaseService.clearTimer(this.state.newsIsDeletedTimerId);
      BaseService.setTimmer(this.state.newsIsDeletedTimerId, () => {
        this.setState({
          newsUpdateIsDeletedTargetId: newsId,
        });
        this.props.updateNews_IsDeleted(
          newsId,
          newsList[idx].image ? newsList[idx].image.id : null,
          !value
        );
      });
    }
  };

  getTableRows = () => {
    const rows = [];
    this.state.newsList.forEach((item) => {
      rows.push({
        columns: [
          <>
            <div>
              <FormNumberInput
                value={item.orderNumber}
                min="0"
                onChange={(value) => {
                  this.onChangeNewsOrderNumber(item.id, value);
                }}
              />
              {this.props.updateNewsOrderNumberRequestStatus ===
                RequestStatus.RQ_REQUEST &&
                this.state.newsUpdateOrderNumberTargetId === item.id ? (
                <div className="form-component-submitting-message-div">
                  <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                </div>
              ) : (
                <></>
              )}
            </div>
          </>,
          <>
            <FormToggle
              value={item.isDeleted ? !item.isDeleted : true}
              onChange={(value) => {
                this.onChangeNewsIsDeleted(item.id, value);
              }}
            />
            {this.props.updateNewsIsDeletedRequestStatus ===
              RequestStatus.RQ_REQUEST &&
              this.state.newsUpdateIsDeletedTargetId === item.id ? (
              <div className="form-component-submitting-message-div">
                <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
              </div>
            ) : (
              <></>
            )}
          </>,
          <>
            <div>
              <div
                className="table-component-title-link-div"
                onClick={() => {
                  this.openEditNews(item);
                }}
              >
                {item.name}
                <div className="table-component-image-div">
                  <img
                    alt=""
                    src={
                      item.image
                        ? BaseService.getImageUrlFromServer(
                          item.image.id,
                          item.image.name,
                          item.image.url
                        )
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </>,
          // <>
          //     <div style={{ width: '300px' }}>
          //         {item.description}
          //     </div>
          // </>,
          <>
            <div style={{ width: "max-content" }}>
              {/* <FormButton
                                text='Danh sách sản phẩm'
                                faIconClass='fas fa-clipboard-list'
                                isDisabled={true}
                            /> */}
              <FormButton
                type="delete"
                onClick={() => {
                  this.onClickDeleteNews(item);
                }}
                isRequesting={
                  item.id === this.state.deletingNewsId ? true : false
                }
              />
            </div>
          </>,
        ],
      });
    });
    return rows;
  };

  openAddNewNews = () => {
    this.setState({
      modalTitle: "Thêm mới news",
      targetNews: NewsService.getNewNews(),
      isOpenNewsDetailModal: true,
      isDataHasUpdated: false,
    });
  };

  openEditNews = (news) => {
    this.props.getNewsDetail(news.id);
    this.setState({
      modalTitle: "Chỉnh sửa news",
      targetNews: null,
      isOpenNewsDetailModal: true,
      isDataHasUpdated: false,
    });
  };

  onClickDeleteNews = (news) => {
    if (this.props.deleteNewsRequestStatus === RequestStatus.RQ_REQUEST) return;
    const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail(
      "News",
      news.name
    );
    this.setState({
      targetNews: news,
      isOpenNewsConfirmDeleteModal: true,
      confirmModalInfo: {
        modalTitle: confirmModalInfo.title,
        modalContent: confirmModalInfo.content,
      },
    });
  };

  onConfirmYesOrNo = (res) => {
    this.setState({
      isOpenNewsConfirmDeleteModal: false,
    });
    if (res) {
      this.setState({
        deletingNewsId: this.state.targetNews.id,
      });
      this.props.deleteNews(this.state.targetNews);
    }
  };

  closeModal = () => {
    this.setState({
      isOpenNewsDetailModal: false,
    });
  };

  saveNewsDetail = (news) => {
    if (news.id === null) {
      this.props.insertNews(news);
    } else {
      this.props.updateNews(news);
    }
  };

  trackIsDataHasUpdated = (isDataHasUpdated) => {
    this.setState({
      isDataHasUpdated: isDataHasUpdated,
    });
  };

  render() {
    const html = (
      <>
        {this.props.newsListRequestStatus === RequestStatus.RQ_SUCCESS ? (
          <>
            <div>
              <FormButton type="add" onClick={this.openAddNewNews} />
            </div>
            <TableComponent
              headers={this.getTableHeaders()}
              rows={this.getTableRows()}
              noItemRow={
                <td colSpan={this.state.headers.length}>Chưa có cẩm nang</td>
              }
            />
            <FormModal
              size="xl"
              isShowModal={this.state.isOpenNewsDetailModal}
              modalTitle={this.state.modalTitle}
              modalContent={
                this.state.targetNews ? (
                  <AdminNewsDetail
                    save={this.saveNewsDetail}
                    news={this.state.targetNews}
                    newsDetail_originName={this.props.newsDetail_originName}
                    trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                    insertNewsRequestStatus={this.props.insertNewsRequestStatus}
                    updateNewsRequestStatus={this.props.updateNewsRequestStatus}
                    //
                    titleIsAvailableRequestStatus={
                      this.props.titleIsAvailableRequestStatus
                    }
                    titleIsAvailableRequestResult={
                      this.props.titleIsAvailableRequestResult
                    }
                    checkTitleIsAvailable={this.props.checkTitleIsAvailable}
                  />
                ) : (
                  <div style={{ height: "450px" }}>
                    <LoadingComponent />
                  </div>
                )
              }
              isDataHasUpdated={this.state.isDataHasUpdated}
              closeModal={this.closeModal}
            />
            <FormConfirmationModal
              isShowModal={this.state.isOpenNewsConfirmDeleteModal}
              modalTitle={this.state.confirmModalInfo.modalTitle}
              modalContent={this.state.confirmModalInfo.modalContent}
              okText="Xóa"
              cancelText="Hủy"
              onConfirmYesOrNo={this.onConfirmYesOrNo}
            />
          </>
        ) : (
          <>
            <LoadingComponent />
          </>
        )}
      </>
    );
    return html;
  }
}
