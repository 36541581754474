import { OleeApiURL, TypicalFaceApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

export class TypicalFaceService {
  static getNewTypicalFace = () => {
    return {
      id: null,
      name: null,
      imageId: null,
      district: null,
      districtId: null,
      description: null,
      content: null,
      url: null,
      isHot: false,
      orderNumber: 0,
      isDeleted: false,
      styleConfig: {},
    };
  };

  static getTypicalFaceInput = (typicalFace) => {
    const typicalFaceInput = {
      id: typicalFace.id,
      name: typicalFace.name,
      imageId: typicalFace.image ? typicalFace.image.id : null,
      description: typicalFace.description,
      district: typicalFace.district,
      districtId: typicalFace.districtId,
      url: typicalFace.url,
      content: typicalFace.content,
      isHot: typicalFace.isHot,
      orderNumber: typicalFace.orderNumber,
      isDeleted: typicalFace.isDeleted,
      styleConfig: JSON.stringify(typicalFace.styleConfig),
    };
    return typicalFaceInput;
  };

  static getTypicalFaceList = () => {
    return fetch(`${OleeApiURL}${TypicalFaceApiUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getTypicalFaceDetail = (typicalFaceId) => {
    return fetch(`${OleeApiURL}${TypicalFaceApiUrl}/${typicalFaceId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static insertTypicalFace = (typicalFace) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(typicalFace);
      return fetch(`${OleeApiURL}${TypicalFaceApiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static updateTypicalFace = (typicalFace) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(typicalFace);
      return fetch(`${OleeApiURL}${TypicalFaceApiUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static deleteTypicalFace = (typicalFace) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${TypicalFaceApiUrl}/${typicalFace.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };
}
