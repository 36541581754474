import { BannerService } from '../../services/banner-service';
import {
    Messages
} from '../../constants';

export const bannerpage_GET_BANNER_LIST_REQUEST = 'bannerpage_GET_BANNER_LIST_REQUEST';
export const bannerpage_GET_BANNER_LIST_SUCCESS = 'bannerpage_GET_BANNER_LIST_SUCCESS';
export const bannerpage_GET_BANNER_LIST_ERROR = 'bannerpage_GET_BANNER_LIST_ERROR';

export const bannerpage_GET_BANNER_DETAIL_REQUEST = 'bannerpage_GET_BANNER_DETAIL_REQUEST';
export const bannerpage_GET_BANNER_DETAIL_SUCCESS = 'bannerpage_GET_BANNER_DETAIL_SUCCESS';
export const bannerpage_GET_BANNER_DETAIL_ERROR = 'bannerpage_GET_BANNER_DETAIL_ERROR';

export const bannerpage_INSERT_BANNER_REQUEST = 'bannerpage_INSERT_BANNER_REQUEST';
export const bannerpage_INSERT_BANNER_SUCCESS = 'bannerpage_INSERT_BANNER_SUCCESS';
export const bannerpage_INSERT_BANNER_ERROR = 'bannerpage_INSERT_BANNER_ERROR';

export const bannerpage_UPDATE_BANNER_REQUEST = 'bannerpage_UPDATE_BANNER_REQUEST';
export const bannerpage_UPDATE_BANNER_SUCCESS = 'bannerpage_UPDATE_BANNER_SUCCESS';
export const bannerpage_UPDATE_BANNER_ERROR = 'bannerpage_UPDATE_BANNER_ERROR';

export const bannerpage_UPDATE_BANNER_ORDER_NUMBER_REQUEST = 'bannerpage_UPDATE_BANNER_ORDER_NUMBER_REQUEST';
export const bannerpage_UPDATE_BANNER_ORDER_NUMBER_SUCCESS = 'bannerpage_UPDATE_BANNER_ORDER_NUMBER_SUCCESS';
export const bannerpage_UPDATE_BANNER_ORDER_NUMBER_ERROR = 'bannerpage_UPDATE_BANNER_ORDER_NUMBER_ERROR';

export const bannerpage_UPDATE_BANNER_IS_DELETED_REQUEST = 'bannerpage_UPDATE_BANNER_IS_DELETED_REQUEST';
export const bannerpage_UPDATE_BANNER_IS_DELETED_SUCCESS = 'bannerpage_UPDATE_BANNER_IS_DELETED_SUCCESS';
export const bannerpage_UPDATE_BANNER_IS_DELETED_ERROR = 'bannerpage_UPDATE_BANNER_IS_DELETED_ERROR';

export const bannerpage_DELETE_BANNER_REQUEST = 'bannerpage_DELETE_BANNER_REQUEST';
export const bannerpage_DELETE_BANNER_SUCCESS = 'bannerpage_DELETE_BANNER_SUCCESS';
export const bannerpage_DELETE_BANNER_ERROR = 'bannerpage_DELETE_BANNER_ERROR';

export const bannerpage_GET_BANNER_ITEM_LIST_REQUEST = 'bannerpage_GET_BANNER_ITEM_LIST_REQUEST';
export const bannerpage_GET_BANNER_ITEM_LIST_SUCCESS = 'bannerpage_GET_BANNER_ITEM_LIST_SUCCESS';
export const bannerpage_GET_BANNER_ITEM_LIST_ERROR = 'bannerpage_GET_BANNER_ITEM_LIST_ERROR';

export const bannerpage_GET_BANNER_ITEM_DETAIL_REQUEST = 'bannerpage_GET_BANNER_ITEM_DETAIL_REQUEST';
export const bannerpage_GET_BANNER_ITEM_DETAIL_SUCCESS = 'bannerpage_GET_BANNER_ITEM_DETAIL_SUCCESS';
export const bannerpage_GET_BANNER_ITEM_DETAIL_ERROR = 'bannerpage_GET_BANNER_ITEM_DETAIL_ERROR';

export const bannerpage_INSERT_BANNER_ITEM_REQUEST = 'bannerpage_INSERT_BANNER_ITEM_REQUEST';
export const bannerpage_INSERT_BANNER_ITEM_SUCCESS = 'bannerpage_INSERT_BANNER_ITEM_SUCCESS';
export const bannerpage_INSERT_BANNER_ITEM_ERROR = 'bannerpage_INSERT_BANNER_ITEM_ERROR';

export const bannerpage_UPDATE_BANNER_ITEM_REQUEST = 'bannerpage_UPDATE_BANNER_ITEM_REQUEST';
export const bannerpage_UPDATE_BANNER_ITEM_SUCCESS = 'bannerpage_UPDATE_BANNER_ITEM_SUCCESS';
export const bannerpage_UPDATE_BANNER_ITEM_ERROR = 'bannerpage_UPDATE_BANNER_ITEM_ERROR';

export const bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_REQUEST = 'bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_REQUEST';
export const bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_SUCCESS = 'bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_SUCCESS';
export const bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_ERROR = 'bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_ERROR';

export const bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_REQUEST = 'bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_REQUEST';
export const bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_SUCCESS = 'bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_SUCCESS';
export const bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_ERROR = 'bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_ERROR';

export const bannerpage_DELETE_BANNER_ITEM_REQUEST = 'bannerpage_DELETE_BANNER_ITEM_REQUEST';
export const bannerpage_DELETE_BANNER_ITEM_SUCCESS = 'bannerpage_DELETE_BANNER_ITEM_SUCCESS';
export const bannerpage_DELETE_BANNER_ITEM_ERROR = 'bannerpage_DELETE_BANNER_ITEM_ERROR';

export const getBannerList = () => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_GET_BANNER_LIST_REQUEST
        });

        const request = BannerService.getBannerList();
        if(request) {
            request.then(data => {
                dispatch({
                    type: bannerpage_GET_BANNER_LIST_SUCCESS,
                    payload: {
                        bannerList: data
                    }
                });
            }, error => {
                dispatch({
                    type: bannerpage_GET_BANNER_LIST_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const getBannerItemList = () => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_GET_BANNER_ITEM_LIST_REQUEST
        });

        const request = BannerService.getBannerItemList();
        if(request) {
            request.then(data => {
                dispatch({
                    type: bannerpage_GET_BANNER_ITEM_LIST_SUCCESS,
                    payload: {
                        bannerItemList: data
                    }
                });
            }, error => {
                dispatch({
                    type: bannerpage_GET_BANNER_ITEM_LIST_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const getBannerDetail = (bannerId) => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_GET_BANNER_DETAIL_REQUEST
        });

        const request = BannerService.getBannerDetail(bannerId);
        if(request) {
            request.then(data => {
                const bannerDetail = data;
                dispatch({
                    type: bannerpage_GET_BANNER_DETAIL_SUCCESS,
                    payload: {
                        bannerDetail: bannerDetail
                    }
                });
            }, error => {
                dispatch({
                    type: bannerpage_GET_BANNER_DETAIL_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }   
}

export const getBannerItemDetail = (bannerItemId) => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_GET_BANNER_ITEM_DETAIL_REQUEST
        });

        const request = BannerService.getBannerItemDetail(bannerItemId);
        if(request) {
            request.then(data => {
                const bannerItemDetail = data;
                dispatch({
                    type: bannerpage_GET_BANNER_ITEM_DETAIL_SUCCESS,
                    payload: {
                        bannerItemDetail: bannerItemDetail
                    }
                });
            }, error => {
                dispatch({
                    type: bannerpage_GET_BANNER_ITEM_DETAIL_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }   
}

export const insertBanner = (banner, bannerItems) => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_INSERT_BANNER_REQUEST
        });

        const request = BannerService.insertBanner(banner);
        if(request) {
            request.then((data) => {
                bannerItems.forEach(async (item) => {
                    item.bannerId = (data.data)?(data.data.id):(null);
                    await BannerService.insertBannerItem(item);
                })
                dispatch({
                    type: bannerpage_INSERT_BANNER_SUCCESS,
                    payload: {
                        insertedBanner: data
                    }
                });
            }, error => {
                console.log('login - error', error);
                dispatch({
                    type: bannerpage_INSERT_BANNER_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const insertBannerItem = (bannerItem) => {
    return (dispatch) => {
        dispatch({
            type: bannerpage_INSERT_BANNER_ITEM_REQUEST
        });

        const request = BannerService.insertBannerItem(bannerItem);
        if(request) {
            request.then(data => {
                dispatch({
                    type: bannerpage_INSERT_BANNER_ITEM_SUCCESS,
                    payload: {
                        insertedBannerItem: data
                    }
                });
            }, error => {
                console.log('login - error', error);
                dispatch({
                    type: bannerpage_INSERT_BANNER_ITEM_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const updateBanner = (banner, bannerItems, deletedBannerItems) => {
    return (dispatch) => {
        console.log(banner);
        console.log(bannerItems);
        console.log(deletedBannerItems);

        dispatch({
            type: bannerpage_UPDATE_BANNER_REQUEST
        });
        
        deletedBannerItems.forEach(async (item) => {
            await BannerService.deleteBannerItem(item);
        })

        bannerItems.forEach(async (item) => {
            if(item.id) {
                await BannerService.updateBannerItem(item);
            } else {
                item.bannerId = banner.id;
                await BannerService.insertBannerItem(item);
            }
        })
    
        const request = BannerService.updateBanner(banner);
        if(request) {
            request.then(data => {
                dispatch({
                    type: bannerpage_UPDATE_BANNER_SUCCESS,
                    payload: {
                        updatedBanner: data
                    }
                });
            }, error => {
                dispatch({
                    type: bannerpage_UPDATE_BANNER_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const updateBannerItem = (bannerItem) => {
    return (dispatch) => {
        return updateBannerItemCore(dispatch, bannerItem, bannerpage_UPDATE_BANNER_ITEM_REQUEST, bannerpage_UPDATE_BANNER_ITEM_SUCCESS, bannerpage_UPDATE_BANNER_ITEM_ERROR);
    }
}

export const updateBanner_OrderNumber = (bannerId, value) => {
    return async (dispatch) => {
        return updateBannerCore(dispatch, {
            id: bannerId,
            orderNumber: value
        }, bannerpage_UPDATE_BANNER_ORDER_NUMBER_REQUEST, bannerpage_UPDATE_BANNER_ORDER_NUMBER_SUCCESS, bannerpage_UPDATE_BANNER_ORDER_NUMBER_ERROR);
    }
}

export const updateBannerItem_OrderNumber = (bannerItemId, value) => {
    return async (dispatch) => {
        return updateBannerItemCore(dispatch, {
            id: bannerItemId,
            orderNumber: value
        }, bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_REQUEST, bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_SUCCESS, bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_ERROR);
    }
}

export const updateBanner_IsDeleted = (bannerId, value) => {
    return async (dispatch) => {
        return updateBannerCore(dispatch, {
            id: bannerId,
            isDeleted: value
        }, bannerpage_UPDATE_BANNER_IS_DELETED_REQUEST, bannerpage_UPDATE_BANNER_IS_DELETED_SUCCESS, bannerpage_UPDATE_BANNER_IS_DELETED_ERROR);
    }
}

export const updateBannerItem_IsDeleted = (bannerItemId, value) => {
    return async (dispatch) => {
        return updateBannerItemCore(dispatch, {
            id: bannerItemId,
            isDeleted: value
        }, bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_REQUEST, bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_SUCCESS, bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_ERROR);
    }
}

const updateBannerCore = (dispatch, banner, requestStatus, successStatus, errorStatus) => {
    dispatch({
        type: requestStatus
    });

    const request = BannerService.updateBanner(banner);
    if(request) {
        request.then(data => {
            dispatch({
                type: successStatus,
                payload: {
                    updatedBanner: data
                }
            });
        }, error => {
            console.log('login - error', error);
            dispatch({
                type: errorStatus,
                payload: {
                    errorMessage: Messages.REQUEST_ERROR
                }
            });
        });
    }
}

const updateBannerItemCore = (dispatch, bannerItem, requestStatus, successStatus, errorStatus) => {
    dispatch({
        type: requestStatus
    });

    const request = BannerService.updateBannerItem(bannerItem);
    if(request) {
        request.then(data => {
            dispatch({
                type: successStatus,
                payload: {
                    updatedBannerItem: data
                }
            });
        }, error => {
            console.log('login - error', error);
            dispatch({
                type: errorStatus,
                payload: {
                    errorMessage: Messages.REQUEST_ERROR
                }
            });
        });
    }
}

export const deleteBanner = (banner) => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_DELETE_BANNER_REQUEST
        });

        const deletedBannerItems = banner.banner_items;
        deletedBannerItems.forEach(async (item) => {
            await BannerService.deleteBannerItem(item);
        })

        const request = BannerService.deleteBanner(banner);
        if(request) {
            request.then(data => {
                dispatch({
                    type: bannerpage_DELETE_BANNER_SUCCESS,
                    payload: {
                        deletedBanner: banner
                    }
                });
            }, error => {
                console.log('login - error', error);
                dispatch({
                    type: bannerpage_DELETE_BANNER_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const deleteBannerItem = (bannerItem) => {
    return(dispatch) => {
        dispatch({
            type: bannerpage_DELETE_BANNER_ITEM_REQUEST
        });

        const request = BannerService.deleteBannerItem(bannerItem);
        if(request) {
            request.then(data => {
                dispatch({
                    type: bannerpage_DELETE_BANNER_ITEM_SUCCESS,
                    payload: {
                        deletedBannerItem: bannerItem
                    }
                });
            }, error => {
                console.log('login - error', error);
                dispatch({
                    type: bannerpage_DELETE_BANNER_ITEM_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}