import { connect } from "react-redux";
import AdminLayout from "../layouts/admin-layout";
import {
  adminLayoutSelectors,
  webUserLayoutSelectors,
} from "../redux/selectors";
import { adminLayoutActions, webUserLayoutActions } from "../redux/actions";

const mapStateToProps = (state) => {
  return {
    getLoginDataRequestStatus: adminLayoutSelectors.getGetLoginDataRequestStatus(
      state
    ),
    loginData: adminLayoutSelectors.getLoginData(state),
    logoutRequestStatus: adminLayoutSelectors.getLogoutRequestStatus(state),
    getSaleRequestsListAdvisoryRequestStatus: webUserLayoutSelectors.getGetSaleRequestsListAdvisoryRequestStatus(
      state
    ),
    getSaleRequestsListAdvisoryRequestResult: webUserLayoutSelectors.getGetSaleRequestsListAdvisoryRequestResult(
      state
    ),
    getSaleRequestsListOrderRequestStatus: webUserLayoutSelectors.getGetSaleRequestsListOrderRequestStatus(
      state
    ),
    getSaleRequestsListOrderRequestResult: webUserLayoutSelectors.getGetSaleRequestsListOrderRequestResult(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLoginData: () => {
    dispatch(adminLayoutActions.getLoginData());
  },

  logout: () => {
    dispatch(adminLayoutActions.logout());
  },

  getSaleRequestsListByType: (saleRequestsType) => {
    dispatch(webUserLayoutActions.getSaleRequestsListByType(saleRequestsType));
  },
});

const AdminLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLayout);

export default AdminLayoutContainer;
