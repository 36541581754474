import React from 'react';
import './admin-video-list.css';
// 
import { RequestStatus } from '../../constants';
import { LoadingComponent } from '../../components/public/loading-component';
import { TableComponent } from '../../components/public/table-component';
import { FormButton, FormModal, FormConfirmationModal, FormNumberInput, FormToggle, FormVideo } from '../../components/public/form-components';
import { AdminVideoDetail } from '../../components/admin-layout/admin-video-detail';
import { VideoService } from '../../services/video-service';
import { BaseService } from '../../services/base-service';

export class AdminVideoList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenVideoDetailModal: false,
            isOpenVideoConfirmDeleteModal: false,
            modalTitle: '',
            confirmModalInfo: {
                modalTitle: '',
                modalContent: ''
            },
            targetVideo: null,
            deletingVideoId: null,
            videoList: [],
            videoOrderNumberTimerId: BaseService.getRandomString(),
            videoUpdateOrderNumberTargetId: null,
            videoIsDeletedTimerId: BaseService.getRandomString(),
            videoUpdateIsDeletedTargetId: null,
            // 
            isDataHasUpdated: false,
            headers: [
                { title: 'Sắp xếp', width: '111' },
                { title: 'Kích hoạt', width: '99' },
                { title: 'Video' },
                // { title: 'Ghi chú' },
                { title: '', width: '120' }
            ]
        }
    }

    componentDidMount() {
        this.props.getVideoList();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.videoListRequestStatus !== nextProps.videoListRequestStatus &&
        nextProps.videoListRequestStatus === RequestStatus.RQ_SUCCESS) {
            let videoList = nextProps.videoList;
            this.reOrderVideoList(videoList);
        }

        console.log('AdminVideoPage - componentWillReceiveProps - nextProps', nextProps);
        if(
            (this.props.insertVideoRequestStatus !== nextProps.insertVideoRequestStatus &&
            nextProps.insertVideoRequestStatus === RequestStatus.RQ_SUCCESS) ||
            (this.props.updateVideoRequestStatus !== nextProps.updateVideoRequestStatus &&
            nextProps.updateVideoRequestStatus === RequestStatus.RQ_SUCCESS) ||
            (this.props.deleteVideoRequestStatus !== nextProps.deleteVideoRequestStatus &&
            nextProps.deleteVideoRequestStatus === RequestStatus.RQ_SUCCESS)
        ) {
            this.setState({
                isOpenVideoDetailModal: false
            });
            // setTimeout(() => {
                this.props.getVideoList();
            // }, 5000);
        }

        if(this.props.videoDetailRequestStatus !== nextProps.videoDetailRequestStatus &&
        nextProps.videoDetailRequestStatus === RequestStatus.RQ_SUCCESS) {
            if(nextProps.videoDetail) {
                this.setState({
                    targetVideo: nextProps.videoDetail
                })
            }
        }
    }

    reOrderVideoList = (videoList) => {
        let res = BaseService.getOrderListWithName(videoList);
        this.setState({
            videoList: res
        })
    }

    getTableHeaders = () => {
        const headers = this.state.headers;
        return headers;
    }

    onChangeVideoOrderNumber = (videoId, value) => {
        const idx = this.state.videoList.findIndex(t => t.id === videoId);
        if(idx !== -1) {
            let videoList = this.state.videoList;
            videoList[idx].orderNumber = value;
            this.setState({
                videoList: videoList
            })
            BaseService.clearTimer(this.state.videoOrderNumberTimerId);
            BaseService.setTimmer(this.state.videoOrderNumberTimerId, () => {
                this.setState({
                    videoUpdateOrderNumberTargetId: videoId
                });
                this.props.updateVideo_OrderNumber(videoId, value);
            })
        }
    }

    onChangeVideoIsDeleted = (videoId, value) => {
        const idx = this.state.videoList.findIndex(t => t.id === videoId);
        if(idx !== -1) {
            let videoList = this.state.videoList;
            videoList[idx].isDeleted = !value;
            this.setState({
                videoList: videoList
            })
            BaseService.clearTimer(this.state.videoIsDeletedTimerId);
            BaseService.setTimmer(this.state.videoIsDeletedTimerId, () => {
                this.setState({
                    videoUpdateIsDeletedTargetId: videoId
                });
                this.props.updateVideo_IsDeleted(videoId, !value);
            })
        }
    }

    getTableRows = () => {
        const rows = [];
        this.state.videoList.forEach((item) => {
            rows.push({
                columns: [
                    <>
                        <div>
                            <FormNumberInput
                                value={item.orderNumber}
                                min='0'
                                onChange={(value) => {
                                    this.onChangeVideoOrderNumber(item.id, value);
                                }}
                            />
                            {
                                (this.props.updateVideoOrderNumberRequestStatus === RequestStatus.RQ_REQUEST && this.state.videoUpdateOrderNumberTargetId === item.id)?(
                                    <div className='form-component-submitting-message-div'>
                                        <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                                    </div>
                                ):(<></>)
                            }
                        </div>
                    </>,
                    <>
                        <FormToggle
                            value={(item.isDeleted)?(!item.isDeleted):(true)}
                            onChange={(value) => {
                                this.onChangeVideoIsDeleted(item.id, value);
                            }}
                        />
                        {
                            (this.props.updateVideoIsDeletedRequestStatus === RequestStatus.RQ_REQUEST && this.state.videoUpdateIsDeletedTargetId === item.id)?(
                                <div className='form-component-submitting-message-div'>
                                    <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                                </div>
                            ):(<></>)
                        }
                    </>,
                    <>
                        <div>
                            <div className='table-component-title-link-div' onClick={() => { this.openEditVideo(item); }}>
                                {item.name}                                
                                <div className='table-component-video-large-div'>
                                    <FormVideo
                                        videoUrl={item.url}
                                        canAccess={true}
                                        autoPlay={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </>,
                    // <>
                    //     <div style={{ width: '300px' }}>
                    //         {item.description}
                    //     </div>
                    // </>,
                    <>
                        <div style={{ width: 'max-content' }}>
                            {/* <FormButton
                                text='Danh sách sản phẩm'
                                faIconClass='fas fa-clipboard-list'
                                isDisabled={true}
                            /> */}
                            <FormButton
                                type='delete'
                                onClick={() => { this.onClickDeleteVideo(item); }}
                                isRequesting={(item.id === this.state.deletingVideoId)?(true):(false)}
                            />
                        </div>
                    </>
                ]
            });
        });
        return rows;
    }

    openAddNewVideo = () => {
        this.setState({
            modalTitle: 'Thêm mới video',
            targetVideo: VideoService.getNewVideo(),
            isOpenVideoDetailModal: true,
            isDataHasUpdated: false
        });
    }

    openEditVideo = (video) => {
        this.props.getVideoDetail(video.id);
        this.setState({
            modalTitle: 'Chỉnh sửa video',
            targetVideo: null,
            isOpenVideoDetailModal: true,
            isDataHasUpdated: false
        });
    }

    onClickDeleteVideo = (video) => {
        if(this.props.deleteVideoRequestStatus === RequestStatus.RQ_REQUEST) return;
        const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail('Video', video.name);
        this.setState({
            targetVideo: video,
            isOpenVideoConfirmDeleteModal: true,
            confirmModalInfo: {
                modalTitle: confirmModalInfo.title,
                modalContent: confirmModalInfo.content
            }
        });
    }

    onConfirmYesOrNo = (res) => {
        this.setState({
            isOpenVideoConfirmDeleteModal: false
        });
        if(res) {
            this.setState({
                deletingVideoId: this.state.targetVideo.id
            })
            this.props.deleteVideo(this.state.targetVideo);
        }
    }

    closeModal = () => {
        this.setState({
            isOpenVideoDetailModal: false
        });
    }
    
    saveVideoDetail = (video) => {
        if(video.id === null) {
            this.props.insertVideo(video);
        } else {
            this.props.updateVideo(video);
        }
    }

    trackIsDataHasUpdated = (isDataHasUpdated) => {
        this.setState({
            isDataHasUpdated: isDataHasUpdated
        })
    }

    render() {
        const html = <>
            {
                (this.props.videoListRequestStatus === RequestStatus.RQ_SUCCESS)?(
                    <>
                        <div>
                            <FormButton
                                type='add'
                                onClick={this.openAddNewVideo}
                            />
                        </div>
                        <TableComponent
                            headers={this.getTableHeaders()}
                            rows={this.getTableRows()}
                            noItemRow={
                                <td colSpan={this.state.headers.length}>Chưa có video</td>
                            }
                        />
                        <FormModal
                            size='lg'
                            isShowModal={this.state.isOpenVideoDetailModal}
                            modalTitle={this.state.modalTitle}
                            modalContent={
                                (this.state.targetVideo)?(
                                    <AdminVideoDetail
                                        save={this.saveVideoDetail}
                                        video={this.state.targetVideo}
                                        trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                                        insertVideoRequestStatus={this.props.insertVideoRequestStatus}
                                        updateVideoRequestStatus={this.props.updateVideoRequestStatus}
                                    />
                                ):(
                                    <div style={{ height: '450px' }}>
                                        <LoadingComponent />
                                    </div>
                                )
                            }
                            isDataHasUpdated={this.state.isDataHasUpdated}
                            closeModal={this.closeModal}
                        />
                        <FormConfirmationModal
                            isShowModal={this.state.isOpenVideoConfirmDeleteModal}
                            modalTitle={this.state.confirmModalInfo.modalTitle}
                            modalContent={this.state.confirmModalInfo.modalContent}
                            okText='Xóa'
                            cancelText='Hủy'
                            onConfirmYesOrNo={this.onConfirmYesOrNo}
                        />
                    </>
                ):(
                    <>
                        <LoadingComponent />
                    </>
                )
            }
        </>;
        return (
            html
        );
    }
}