import React from "react";
import "./header.css";
//
import { BaseService } from "../../services/base-service";
import {
  Container,
  // , Row, Col
} from "react-bootstrap";
// import { TopBranch } from './top-branch';
import { TopBranchBar } from "./top-branch-bar";
import { TopMenuBar } from "./top-menu-bar";
// import { NavMenu } from './nav-menu';

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenedReponseMenu: false,
    };
  }

  componentDidMount() {}

  toggleResponseMenu = () => {
    const isOpenedReponseMenu = !this.state.isOpenedReponseMenu;
    if (isOpenedReponseMenu) {
      BaseService.setBodyScroll(false);
    } else {
      BaseService.setBodyScroll(true);
    }
    this.setState({
      isOpenedReponseMenu: isOpenedReponseMenu,
    });
  };

  render() {
    const html = (
      <>
        {/* <Container id='userLayoutHeaderContainerDiv' fluid={true} className='header-container-div'>
                <Container fluid={true} className='fluid-container'>
                    <TopBranchBar
                        windowWidth={this.props.windowWidth}
                        toggleResponseMenu={this.toggleResponseMenu}
                        isOpenedReponseMenu={this.state.isOpenedReponseMenu}
                    />
                    <TopMenuBar
                        windowWidth={this.props.windowWidth}
                        navMenuList={this.props.navMenuList}
                        isOpenedReponseMenu={this.state.isOpenedReponseMenu}
                    />
                </Container>
            </Container> */}
        <div id="userLayoutHeaderContainerDiv" className="header-container-div">
          <Container fluid={true}>
            <Container fluid={true} className="fluid-container">
              <TopBranchBar
                windowWidth={this.props.windowWidth}
                toggleResponseMenu={this.toggleResponseMenu}
                isOpenedReponseMenu={this.state.isOpenedReponseMenu}
                productItemsInCart={this.props.productItemsInCart}
                productItemsInCart_LastestModifyTime={
                  this.props.productItemsInCart_LastestModifyTime
                }
                footerInfoData={this.props.footerInfoData}
              />
              {/* <TopBranch 
                            windowWidth={this.props.windowWidth}
                            navMenuList={this.props.navMenuList}
                        />
                        {
                            (this.props.windowWidth >= 992)?(
                                // <Container>
                                    <Row>
                                        <Col>
                                            <NavMenu
                                                navMenuList={this.props.navMenuList}
                                            />
                                        </Col>
                                    </Row>
                                // </Container>
                            ):(
                                <></>
                            )
                        } */}
            </Container>
          </Container>
          <Container fluid={true} className="header-menu-container">
            <TopMenuBar
              windowWidth={this.props.windowWidth}
              navMenuList={this.props.navMenuList}
              closeMenu={this.toggleResponseMenu}
              isOpenedReponseMenu={this.state.isOpenedReponseMenu}
            />
          </Container>
        </div>
      </>
    );
    return html;
  }
}
