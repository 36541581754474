import {
    newspage_GET_NEWS_LIST_REQUEST,
    newspage_GET_NEWS_LIST_SUCCESS,
    newspage_GET_NEWS_LIST_ERROR,
    newspage_GET_NEWS_DETAIL_REQUEST,
    newspage_GET_NEWS_DETAIL_SUCCESS,
    newspage_GET_NEWS_DETAIL_ERROR,
    newspage_INSERT_NEWS_SUCCESS,
    newspage_INSERT_NEWS_REQUEST,
    newspage_INSERT_NEWS_ERROR,
    newspage_UPDATE_NEWS_REQUEST,
    newspage_UPDATE_NEWS_SUCCESS,
    newspage_UPDATE_NEWS_ERROR,
    newspage_UPDATE_NEWS_ORDER_NUMBER_REQUEST,
    newspage_UPDATE_NEWS_ORDER_NUMBER_SUCCESS,
    newspage_UPDATE_NEWS_ORDER_NUMBER_ERROR,
    newspage_UPDATE_NEWS_IS_DELETED_REQUEST,
    newspage_UPDATE_NEWS_IS_DELETED_SUCCESS,
    newspage_UPDATE_NEWS_IS_DELETED_ERROR,
    newspage_DELETE_NEWS_REQUEST,
    newspage_DELETE_NEWS_SUCCESS,
    newspage_DELETE_NEWS_ERROR
} from '../actions/news-page-actions';
import {
    RequestStatus
} from '../../constants';

const initialState  = {
    newsListRequestStatus: RequestStatus.RQ_NEW,
    newsList: [],
    // 
    newsDetailRequestStatus: RequestStatus.RQ_NEW,
    newsDetail: null,
    newsDetail_originName: null,
    // 
    insertNewsRequestStatus: RequestStatus.RQ_NEW,
    insertedNews: null,
    // 
    updateNewsRequestStatus: RequestStatus.RQ_NEW,
    updatedNews: null,
    // 
    updateNewsOrderNumberRequestStatus: RequestStatus.RQ_NEW,
    updatedNewsOrderNumber: null,
    // 
    updateNewsIsDeletedRequestStatus: RequestStatus.RQ_NEW,
    updatedNewsIsDeleted: null,
    // 
    deleteNewsRequestStatus: RequestStatus.RQ_NEW,
    deletedNews: null,
    // 
    errorMessage: null
}

export function newsPageReducer(state = initialState, actions) {
    switch(actions.type) {
        case newspage_GET_NEWS_LIST_REQUEST: {
            return {
                ...state,
                newsListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_GET_NEWS_LIST_SUCCESS: {
            return {
                ...state,
                newsListRequestStatus: RequestStatus.RQ_SUCCESS,
                newsList: actions.payload.newsList
            }
        }
        case newspage_GET_NEWS_LIST_ERROR: {
            return {
                ...state,
                newsListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case newspage_GET_NEWS_DETAIL_REQUEST: {
            return {
                ...state,
                newsDetailRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_GET_NEWS_DETAIL_SUCCESS: {
            const newsDetail = actions.payload.newsDetail;
            return {
                ...state,
                newsDetailRequestStatus: RequestStatus.RQ_SUCCESS,
                newsDetail: newsDetail,
                newsDetail_originName: newsDetail.name,
            }
        }
        case newspage_GET_NEWS_DETAIL_ERROR: {
            return {
                ...state,
                newsDetailRequestStatus: RequestStatus.RQ_ERROR
            }
        }
        case newspage_INSERT_NEWS_REQUEST: {
            return {
                ...state,
                insertNewsRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_INSERT_NEWS_SUCCESS: {
            return {
                ...state,
                insertNewsRequestStatus: RequestStatus.RQ_SUCCESS,
                insertedNews: actions.payload.insertedNews
            }
        }
        case newspage_INSERT_NEWS_ERROR: {
            return {
                ...state,
                insertNewsRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case newspage_UPDATE_NEWS_REQUEST: {
            return {
                ...state,
                updateNewsRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_UPDATE_NEWS_SUCCESS: {
            return {
                ...state,
                updateNewsRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedNews: actions.payload.updatedNews
            }
        }
        case newspage_UPDATE_NEWS_ERROR: {
            return {
                ...state,
                updateNewsRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case newspage_UPDATE_NEWS_ORDER_NUMBER_REQUEST: {
            return {
                ...state,
                updateNewsOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_UPDATE_NEWS_ORDER_NUMBER_SUCCESS: {
            return {
                ...state,
                updateNewsOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedNewsOrderNumber: actions.payload.updatedNews
            }
        }
        case newspage_UPDATE_NEWS_ORDER_NUMBER_ERROR: {
            return {
                ...state,
                updateNewsOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case newspage_UPDATE_NEWS_IS_DELETED_REQUEST: {
            return {
                ...state,
                updateNewsIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_UPDATE_NEWS_IS_DELETED_SUCCESS: {
            return {
                ...state,
                updateNewsIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedNewsIsDeleted: actions.payload.updatedNews
            }
        }
        case newspage_UPDATE_NEWS_IS_DELETED_ERROR: {
            return {
                ...state,
                updateNewsIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case newspage_DELETE_NEWS_REQUEST: {
            return {
                ...state,
                deleteNewsRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case newspage_DELETE_NEWS_SUCCESS: {
            return {
                ...state,
                deleteNewsRequestStatus: RequestStatus.RQ_SUCCESS,
                deletedNews: actions.payload.deletedNews
            }
        }
        case newspage_DELETE_NEWS_ERROR: {
            return {
                ...state,
                deleteNewsRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }       
        default: {
            return state;
        }
    }
}