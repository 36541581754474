import React from 'react';
import './admin-video-detail.css';
// 
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { FormTextInput,
    FormButton, FormSunEditor, FormVideo } from '../public/form-components';
import { BaseService } from  '../../services/base-service';
import { RequestStatus } from '../../constants';
import { VideoService } from '../../services/video-service';

export class FormikAdminVideoDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    trackIsDataHasUpdatedTimeout = null;
    trackIsDataHasUpdated = () => {
        clearTimeout(this.trackIsDataHasUpdatedTimeout);
        this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
            let isDataHasUpdated = true;
            this.props.trackIsDataHasUpdated(isDataHasUpdated);
        }, 100);
    }

    changeVideoName = (value) => {
        this.props.setFieldValue('video.name', value);
        this.trackIsDataHasUpdated();
    }

    blurVideoName = (e) => {
        this.props.setFieldTouched('errorVideoName', true);
    }

    changeVideoDescription = (value) => {
        this.props.setFieldValue('video.description', value);
        if(value !== this.props.video.description) {
            this.trackIsDataHasUpdated();
        }
    }

    changeVideoUrl = (value) => {
        this.props.setFieldValue('video.url', value);
        this.trackIsDataHasUpdated();
    }

    blurVideoUrl = (e) => {
        this.props.setFieldTouched('errorVideoUrl', true);
    }

    save = () => {
        if(this.props.insertVideoRequestStatus === RequestStatus.RQ_REQUEST) return;
        this.props.handleSubmit();
    }

    render() {
        const html = <>
            <Row>
                <Col>
                    <Row>
                        <Col lg={12}>
                            <FormTextInput
                                labelText='Tên'
                                value={this.props.values.video.name}
                                onChange={this.changeVideoName}
                                onBlur={this.blurVideoName}
                                error={(this.props.touched.errorVideoName && this.props.errors.errorVideoName)?(this.props.errors.errorVideoName):(null)}
                                isRequired={true}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={12}>
                            <label className='form-components-text-input-label'>Ghi Chú</label>                           
                            <FormSunEditor
                                data={(this.props.values.video.description)?(this.props.values.video.description):('')}
                                onChange={this.changeVideoDescription}
                                // isSimple={true}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={12}>
                            <FormTextInput
                                labelText='Đường dẫn'
                                value={this.props.values.video.url}
                                onChange={this.changeVideoUrl}
                                onBlur={this.blurVideoUrl}
                                error={(this.props.touched.errorVideoUrl && this.props.errors.errorVideoUrl)?(this.props.errors.errorVideoUrl):(null)}
                                isRequired={true}
                            />
                            {
                                (!BaseService.stringIsNullOrWhiteSpace(this.props.values.video.url))?(
                                    <FormVideo
                                        videoUrl={this.props.values.video.url}
                                        canAccess={true}
                                        autoPlay={false}
                                    />
                                ):(<></>)
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
            <br />
            <Row>
                <Col lg={12}>
                    <FormButton
                        type='save'
                        onClick={this.save}
                        isRequesting={(
                            this.props.insertVideoRequestStatus === RequestStatus.RQ_REQUEST ||
                            this.props.updateVideoRequestStatus === RequestStatus.RQ_REQUEST
                        )?(true):(false)}
                        disabled={(
                            this.props.insertVideoRequestStatus === RequestStatus.RQ_REQUEST ||
                            this.props.updateVideoRequestStatus === RequestStatus.RQ_REQUEST
                        )?(true):(false)}
                    />
                </Col>
            </Row>
        </>;
        return (
            html
        );
    }
}

const myEnhancedForm = withFormik({
    enableReinitialize: true,

    mapPropsToValues: (props) => {
        console.log('mapPropsToValues - props', props);
        let values = {
            video: props.video,
            errorVideoName: null,
            errorVideoUrl: null
        };
        return values;
    },

    validate: values => {
        const errors = {};

        if(BaseService.stringIsNullOrWhiteSpace(values.video.name)) {
            errors.errorVideoName = 'Tên video không được rỗng';
        };

        if(BaseService.stringIsNullOrWhiteSpace(values.video.url)) {
            errors.errorVideoUrl = 'Đường dẫn video không được rỗng';
        };

        return errors;
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(true);
        let video = VideoService.getVideoInput(values.video);
        console.log('video', video);
        props.save(video);
    },

    displayName: 'FormikAdminVideoDetail'
});

export const AdminVideoDetail = withRouter(myEnhancedForm(FormikAdminVideoDetail));