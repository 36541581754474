import { connect } from "react-redux";
import { AdminAdvisoryPage } from "../pages/admin-advisory-page";
import { webUserLayoutSelectors } from "../redux/selectors";
import { webUserLayoutActions } from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    getSaleRequestsListAdvisoryRequestStatus: webUserLayoutSelectors.getGetSaleRequestsListAdvisoryRequestStatus(
      state
    ),
    getSaleRequestsListAdvisoryRequestResult: webUserLayoutSelectors.getGetSaleRequestsListAdvisoryRequestResult(
      state
    ),
    updateSaleRequestsRequestStatus: webUserLayoutSelectors.getUpdateSaleRequestsRequestStatus(
      state
    ),
    updateSaleRequestsRequestResult: webUserLayoutSelectors.getUpdateSaleRequestsRequestResult(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSaleRequests: (saleRequests) => {
    dispatch(webUserLayoutActions.updateSaleRequests(saleRequests));
  },
});

const AdminAdvisoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAdvisoryPage);

export default AdminAdvisoryContainer;
