import React from 'react';
import './in-development-component.css';
// 

export class InDevelopmentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div className='in-develop-container-div'>
                <div>
                    <div>
                        <i className='fas fa-layer-group in-develop-component-icon-i'></i>
                    </div>
                    <div>Chức năng đang được phát triển.</div>
                    <div>Xin vui lòng quay lại sau.</div>
                </div>
            </div>
        </>;
        return (
            html
        );
    }
}