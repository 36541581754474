import {
    bannerpage_GET_BANNER_LIST_REQUEST,
    bannerpage_GET_BANNER_LIST_SUCCESS,
    bannerpage_GET_BANNER_LIST_ERROR,
    bannerpage_GET_BANNER_DETAIL_REQUEST,
    bannerpage_GET_BANNER_DETAIL_SUCCESS,
    bannerpage_GET_BANNER_DETAIL_ERROR,
    bannerpage_INSERT_BANNER_REQUEST,
    bannerpage_INSERT_BANNER_SUCCESS,
    bannerpage_INSERT_BANNER_ERROR,
    bannerpage_UPDATE_BANNER_REQUEST,
    bannerpage_UPDATE_BANNER_SUCCESS,
    bannerpage_UPDATE_BANNER_ERROR,
    bannerpage_UPDATE_BANNER_ORDER_NUMBER_REQUEST,
    bannerpage_UPDATE_BANNER_ORDER_NUMBER_SUCCESS,
    bannerpage_UPDATE_BANNER_ORDER_NUMBER_ERROR,
    bannerpage_UPDATE_BANNER_IS_DELETED_REQUEST,
    bannerpage_UPDATE_BANNER_IS_DELETED_SUCCESS,
    bannerpage_UPDATE_BANNER_IS_DELETED_ERROR,
    bannerpage_DELETE_BANNER_REQUEST,
    bannerpage_DELETE_BANNER_SUCCESS,
    bannerpage_DELETE_BANNER_ERROR,    
    bannerpage_GET_BANNER_ITEM_LIST_REQUEST,
    bannerpage_GET_BANNER_ITEM_LIST_SUCCESS,
    bannerpage_GET_BANNER_ITEM_LIST_ERROR,
    bannerpage_GET_BANNER_ITEM_DETAIL_REQUEST,
    bannerpage_GET_BANNER_ITEM_DETAIL_SUCCESS,
    bannerpage_GET_BANNER_ITEM_DETAIL_ERROR,
    bannerpage_INSERT_BANNER_ITEM_REQUEST,
    bannerpage_INSERT_BANNER_ITEM_SUCCESS,
    bannerpage_INSERT_BANNER_ITEM_ERROR,
    bannerpage_UPDATE_BANNER_ITEM_REQUEST,
    bannerpage_UPDATE_BANNER_ITEM_SUCCESS,
    bannerpage_UPDATE_BANNER_ITEM_ERROR,
    bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_REQUEST,
    bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_SUCCESS,
    bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_ERROR,
    bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_REQUEST,
    bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_SUCCESS,
    bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_ERROR,
    bannerpage_DELETE_BANNER_ITEM_REQUEST,
    bannerpage_DELETE_BANNER_ITEM_SUCCESS,
    bannerpage_DELETE_BANNER_ITEM_ERROR
} from '../actions/banner-page-actions';
import {
    RequestStatus
} from '../../constants';

const initialState  = {
    bannerListRequestStatus: RequestStatus.RQ_NEW,
    bannerList: [],
    // 
    bannerDetailRequestStatus: RequestStatus.RQ_NEW,
    bannerDetail: null,
    // 
    insertBannerRequestStatus: RequestStatus.RQ_NEW,
    insertedBanner: null,
    // 
    updateBannerRequestStatus:  RequestStatus.RQ_NEW,
    updatedBanner: null,
    // 
    updateBannerOrderNumberRequestStatus: RequestStatus.RQ_NEW,
    updatedBannerOrderNumber: null,
    // 
    updateBannerIsDeletedRequestStatus: RequestStatus.RQ_NEW,
    updatedBannerIsDeleted: null,
    //     
    bannerItemListRequestStatus: RequestStatus.RQ_NEW,
    bannerItemList: [],
    // 
    bannerItemDetailRequestStatus: RequestStatus.RQ_NEW,
    bannerItemDetail: null,
    // 
    insertBannerItemRequestStatus: RequestStatus.RQ_NEW,
    insertedBannerItem: null,
    // 
    updateBannerItemRequestStatus:  RequestStatus.RQ_NEW,
    updatedBannerItem: null,
    // 
    updateBannerItemOrderNumberRequestStatus: RequestStatus.RQ_NEW,
    updatedBannerItemOrderNumber: null,
    // 
    updateBannerItemIsDeletedRequestStatus: RequestStatus.RQ_NEW,
    updatedBannerItemIsDeleted: null,
    // 
    errorMessage: null
}

export function bannerPageReducer(state = initialState, actions) {
    switch(actions.type) {
        case bannerpage_GET_BANNER_LIST_REQUEST: {
            return {
                ...state,
                bannerListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_GET_BANNER_LIST_SUCCESS: {
            return {
                ...state,
                bannerListRequestStatus: RequestStatus.RQ_SUCCESS,
                bannerList: actions.payload.bannerList
            }
        }
        case bannerpage_GET_BANNER_LIST_ERROR: {
            return {
                ...state,
                bannerListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_GET_BANNER_DETAIL_REQUEST: {
            return {
                ...state,
                bannerDetailRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_GET_BANNER_DETAIL_SUCCESS: {
            const bannerDetail = actions.payload.bannerDetail;
            return {
                ...state,
                bannerDetailRequestStatus: RequestStatus.RQ_SUCCESS,
                bannerDetail: bannerDetail
            }
        }
        case bannerpage_GET_BANNER_DETAIL_ERROR: {
            return {
                ...state,
                bannerDetailRequestStatus: RequestStatus.RQ_ERROR
            }
        }
        case bannerpage_INSERT_BANNER_REQUEST: {
            return {
                ...state,
                insertBannerRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_INSERT_BANNER_SUCCESS: {
            return {
                ...state,
                insertBannerRequestStatus: RequestStatus.RQ_SUCCESS,
                insertedBanner: actions.payload.insertedBanner
            }
        }
        case bannerpage_INSERT_BANNER_ERROR: {
            return {
                ...state,
                insertBannerRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_UPDATE_BANNER_REQUEST: {
            return {
                ...state,
                updateBannerRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_UPDATE_BANNER_SUCCESS: {
            return {
                ...state,
                updateBannerRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedBanner: actions.payload.updatedBanner
            }
        }
        case bannerpage_UPDATE_BANNER_ERROR: {
            return {
                ...state,
                updateBannerRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_UPDATE_BANNER_ORDER_NUMBER_REQUEST: {
            return {
                ...state,
                updateBannerOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_UPDATE_BANNER_ORDER_NUMBER_SUCCESS: {
            return {
                ...state,
                updateBannerOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedBannerOrderNumber: actions.payload.updatedBanner
            }
        }
        case bannerpage_UPDATE_BANNER_ORDER_NUMBER_ERROR: {
            return {
                ...state,
                updateBannerOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_UPDATE_BANNER_IS_DELETED_REQUEST: {
            return {
                ...state,
                updateBannerIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_UPDATE_BANNER_IS_DELETED_SUCCESS: {
            return {
                ...state,
                updateBannerIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedBannerIsDeleted: actions.payload.updatedBanner
            }
        }
        case bannerpage_UPDATE_BANNER_IS_DELETED_ERROR: {
            return {
                ...state,
                updateBannerIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_DELETE_BANNER_REQUEST: {
            return {
                ...state,
                deleteBannerRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_DELETE_BANNER_SUCCESS: {
            return {
                ...state,
                deleteBannerRequestStatus: RequestStatus.RQ_SUCCESS,
                deletedBanner: actions.payload.deletedBanner
            }
        }
        case bannerpage_DELETE_BANNER_ERROR: {
            return {
                ...state,
                deleteBannerRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_GET_BANNER_ITEM_LIST_REQUEST: {
            return {
                ...state,
                bannerItemListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_GET_BANNER_ITEM_LIST_SUCCESS: {
            return {
                ...state,
                bannerItemListRequestStatus: RequestStatus.RQ_SUCCESS,
                bannerItemList: actions.payload.bannerItemList
            }
        }
        case bannerpage_GET_BANNER_ITEM_LIST_ERROR: {
            return {
                ...state,
                bannerItemListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_GET_BANNER_ITEM_DETAIL_REQUEST: {
            return {
                ...state,
                bannerItemDetailRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_GET_BANNER_ITEM_DETAIL_SUCCESS: {
            const bannerItemDetail = actions.payload.bannerItemDetail;
            return {
                ...state,
                bannerItemDetailRequestStatus: RequestStatus.RQ_SUCCESS,
                bannerItemDetail: bannerItemDetail
            }
        }
        case bannerpage_GET_BANNER_ITEM_DETAIL_ERROR: {
            return {
                ...state,
                bannerItemDetailRequestStatus: RequestStatus.RQ_ERROR
            }
        }
        case bannerpage_INSERT_BANNER_ITEM_REQUEST: {
            return {
                ...state,
                insertBannerItemRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_INSERT_BANNER_ITEM_SUCCESS: {
            return {
                ...state,
                insertBannerItemRequestStatus: RequestStatus.RQ_SUCCESS,
                insertedBannerItem: actions.payload.insertedBannerItem
            }
        }
        case bannerpage_INSERT_BANNER_ITEM_ERROR: {
            return {
                ...state,
                insertBannerItemRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_REQUEST: {
            return {
                ...state,
                updateBannerItemRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_SUCCESS: {
            return {
                ...state,
                updateBannerItemRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedBannerItem: actions.payload.updatedBannerItem
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_ERROR: {
            return {
                ...state,
                updateBannerItemRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_REQUEST: {
            return {
                ...state,
                updateBannerItemOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_SUCCESS: {
            return {
                ...state,
                updateBannerItemOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedBannerItemOrderNumber: actions.payload.updatedBannerItem
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_ORDER_NUMBER_ERROR: {
            return {
                ...state,
                updateBannerItemOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_REQUEST: {
            return {
                ...state,
                updateBannerItemIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_SUCCESS: {
            return {
                ...state,
                updateBannerItemIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedBannerItemIsDeleted: actions.payload.updatedBannerItem
            }
        }
        case bannerpage_UPDATE_BANNER_ITEM_IS_DELETED_ERROR: {
            return {
                ...state,
                updateBannerItemIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case bannerpage_DELETE_BANNER_ITEM_REQUEST: {
            return {
                ...state,
                deleteBannerItemRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case bannerpage_DELETE_BANNER_ITEM_SUCCESS: {
            return {
                ...state,
                deleteBannerItemRequestStatus: RequestStatus.RQ_SUCCESS,
                deletedBannerItem: actions.payload.deletedBannerItem
            }
        }
        case bannerpage_DELETE_BANNER_ITEM_ERROR: {
            return {
                ...state,
                deleteBannerItemRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        default: {
            return state;
        }
    }
}