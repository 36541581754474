import React from "react";
import "./form-components.css";
//
import { Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import default_image_jpg from "../../assets/images/default_image.jpg";
import { UploadService } from "../../services/upload-service";
import { BaseService } from "../../services/base-service";
import { AuthenticationService } from "../../services/authentication-service";

import SunEditor from "suneditor-react";
import ReactPlayer from "react-player";
import ReactPaginate from "react-paginate";

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/vi';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// //
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// //
// import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
// import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
// import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
// import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
// //
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// //
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// //
// import Link from '@ckeditor/ckeditor5-link/src/link';
// import Image from '@ckeditor/ckeditor5-image/src/image';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
// import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
// // import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
// import Table from '@ckeditor/ckeditor5-table/src/table';
// import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
// import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
// //
// import List from '@ckeditor/ckeditor5-list/src/list';
// import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
// //
// import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
// import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows';
// import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency';
// import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
// import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin';
// import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical';
// import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext';
// import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
// //
// import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';

import { RequestStatus, OleeApiURL, ImageApiUrl } from "../../constants";
import { TableComponent } from "./table-component";

export class FormHR extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <hr className="form-components-hr" />
      </>
    );
    return html;
  }
}

export class FormLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: "_blank",
    };
  }

  render() {
    const html = (
      <>
        <Link
          target={this.state.target}
          className="form-link-link"
          to={this.props.path}
        >
          {this.props.text}
        </Link>
      </>
    );
    return html;
  }
}

export class FormInfoBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOnClickFormInfoBox = () => {
    if (this.props.isDisabled === true) return;
    if (this.props.handleOnClickFormInfoBox) {
      this.props.handleOnClickFormInfoBox();
    }
  };

  render() {
    const html = (
      <>
        <div
          className={`form-info-box-wrapper-div${this.props.isDisabled === true
            ? " form-info-box-wrapper-disabled-div"
            : ""
            }`}
          onClick={this.handleOnClickFormInfoBox}
        >
          <div className="form-info-box-icon-div">
            <i class={`form-info-box-icon-i ${this.props.iconClass}`}></i>
          </div>
          <div className="form-info-box-title-div">{this.props.title}</div>
          <div className="form-info-box-description-div">
            {this.props.description}
          </div>
        </div>
      </>
    );
    return html;
  }
}

export class FormTitle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <div className="form-components-title-div">{this.props.text}</div>
      </>
    );
    return html;
  }
}

export class FormDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <div className="form-components-description-div">
          {this.props.element}
        </div>
      </>
    );
    return html;
  }
}

export class FormLoadingText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <div className="form-components-loading-text-div">
          <i className="fas fa-spinner fa-spin form-components-button-icon"></i>{" "}
          Xin vui lòng đợi trong giây lát.
        </div>
      </>
    );
    return html;
  }
}

export class FormToggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value !== null ? this.props.value : true,
    };
  }

  toggle = () => {
    const value = !this.state.value;
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  render() {
    const html = (
      <>
        <div className="form-toggle-component" onClick={this.toggle}>
          {this.state.value ? (
            <i class="fas fa-toggle-on on"></i>
          ) : (
            <i class="fas fa-toggle-off off"></i>
          )}
        </div>
      </>
    );
    return html;
  }
}

export class FormNumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? this.props.value : 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  increaseNumber = () => {
    let value = this.state.value + 1;
    if (this.props.max && +this.props.max < value) {
      return;
    }
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  reduceNumber = () => {
    let value = this.state.value - 1;
    if (this.props.min && +this.props.min > value) {
      return;
    }
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  render() {
    const html = (
      <>
        <div className="form-components-number-input-component">
          <div
            className="form-components-number-input-button-div left"
            onClick={this.reduceNumber}
          >
            <i class="fas fa-caret-left"></i>
          </div>
          <div className="form-components-number-input-value-div">
            {this.state.value}
          </div>
          <div
            className="form-components-number-input-button-div right"
            onClick={this.increaseNumber}
          >
            <i class="fas fa-caret-right"></i>
          </div>
        </div>
      </>
    );
    return html;
  }
}

export class FormTextInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <div
          className={`form-components-text-input-component${this.props.error
            ? " form-components-text-input-component-error"
            : ""
            }`}
        >
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
              {this.props.guide ? (
                <span className="form-components-img-guide-span">
                  {this.props.guide}
                </span>
              ) : (
                <></>
              )}
            </label>
          ) : (
            <></>
          )}
          <input
            className="form-components-text-input"
            value={this.props.value}
            onChange={(e) => {
              if (this.props.onChange) {
                this.props.onChange(e.target.value);
              }
            }}
            onBlur={(e) => {
              if (this.props.onBlur) {
                this.props.onBlur(e);
              }
            }}
            type={this.props.type ? this.props.type : "text"}
            // min={(this.props.min)?(this.props.min):(null)}
            // max={(this.props.max)?(this.props.max):(null)}
            id={this.props.id}
            name={this.props.labelName}
            placeholder={this.props.placeholder}
          />
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormTextAreaInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <div
          className={`form-components-text-input-component${this.props.error
            ? " form-components-text-input-component-error"
            : ""
            }`}
        >
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
              {this.props.guide ? (
                <span className="form-components-img-guide-span">
                  {this.props.guide}
                </span>
              ) : (
                <></>
              )}
            </label>
          ) : (
            <></>
          )}
          <textarea
            className="form-components-text-input form-components-textare"
            onChange={(e) => {
              this.props.onChange(e.target.value);
            }}
            onBlur={(e) => {
              if (this.props.onBlur) {
                this.props.onBlur(e);
              }
            }}
            id={this.props.id}
            name={this.props.labelName}
            placeholder={this.props.placeholder}
            rows={this.props.rows ? this.props.rows : 3}
          >
            {this.props.value}
          </textarea>
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let html;
    switch (this.props.type) {
      case "add": {
        html = (
          <>
            <button
              className={`form-components-button${this.props.isDisabled ? " form-components-button-disabled" : ""
                }${this.props.isRequesting
                  ? " form-components-button-requesting"
                  : ""
                }`}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.onClick) {
                  this.props.onClick();
                }
              }}
              type={this.props.isSubmitButton ? "submit" : "button"}
              disabled={
                this.props.isDisabled || this.props.isRequesting ? true : false
              }
            >
              {this.props.isRequesting ? (
                <>
                  <i className="fas fa-spinner fa-spin form-components-button-icon"></i>
                  Đang lưu ...
                </>
              ) : (
                <>
                  <i
                    className={`form-components-button-icon fas fa-plus-circle`}
                  ></i>
                  Thêm mới
                </>
              )}
            </button>
          </>
        );
        break;
      }
      case "edit": {
        html = (
          <>
            <button
              className={`form-components-button${this.props.isDisabled ? " form-components-button-disabled" : ""
                }${this.props.isRequesting
                  ? " form-components-button-requesting"
                  : ""
                }`}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.onClick) {
                  this.props.onClick();
                }
              }}
              type={this.props.isSubmitButton ? "submit" : "button"}
              disabled={
                this.props.isDisabled || this.props.isRequesting ? true : false
              }
            >
              {this.props.isRequesting ? (
                <>
                  <i className="fas fa-spinner fa-spin form-components-button-icon"></i>
                  Đang lưu ...
                </>
              ) : (
                <>
                  <i className={`form-components-button-icon fas fa-edit`}></i>
                  Chỉnh sửa
                </>
              )}
            </button>
          </>
        );
        break;
      }
      case "delete": {
        html = (
          <>
            <button
              className={`form-components-button${this.props.isDisabled ? " form-components-button-disabled" : ""
                }${this.props.isRequesting
                  ? " form-components-button-requesting"
                  : ""
                }`}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.onClick) {
                  this.props.onClick();
                }
              }}
              type={this.props.isSubmitButton ? "submit" : "button"}
              disabled={
                this.props.isDisabled || this.props.isRequesting ? true : false
              }
            >
              {this.props.isRequesting ? (
                <>
                  <i className="fas fa-spinner fa-spin form-components-button-icon"></i>
                  Đang xóa ...
                </>
              ) : (
                <>
                  <i className={`form-components-button-icon fas fa-trash`}></i>
                  Xóa
                </>
              )}
            </button>
          </>
        );
        break;
      }
      case "save": {
        html = (
          <>
            <button
              className={`form-components-button${this.props.isDisabled ? " form-components-button-disabled" : ""
                }${this.props.isRequesting
                  ? " form-components-button-requesting"
                  : ""
                }`}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.onClick) {
                  this.props.onClick();
                }
              }}
              type={this.props.isSubmitButton ? "submit" : "button"}
              disabled={
                this.props.isDisabled || this.props.isRequesting ? true : false
              }
            >
              {this.props.isRequesting ? (
                <>
                  <i className="fas fa-spinner fa-spin form-components-button-icon"></i>
                  Đang lưu ...
                </>
              ) : (
                <>
                  <i className={`form-components-button-icon fas fa-save`}></i>
                  Lưu
                </>
              )}
            </button>
          </>
        );
        break;
      }
      default: {
        html = (
          <>
            <button
              className={`form-components-button${this.props.isDisabled ? " form-components-button-disabled" : ""
                }${this.props.isRequesting
                  ? " form-components-button-requesting"
                  : ""
                }`}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.onClick) {
                  this.props.onClick();
                }
              }}
              type={this.props.isSubmitButton ? "submit" : "button"}
              disabled={
                this.props.isDisabled || this.props.isRequesting ? true : false
              }
            >
              {this.props.isRequesting ? (
                <>
                  <i className="fas fa-spinner fa-spin form-components-button-icon"></i>
                  Đang tải ...
                </>
              ) : (
                <>
                  {this.props.faIconClass ? (
                    <i
                      className={`form-components-button-icon ${this.props.faIconClass}`}
                    ></i>
                  ) : (
                    <></>
                  )}
                  {this.props.text}
                </>
              )}
            </button>
          </>
        );
      }
    }
    return html;
  }
}

export class FormError extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        {this.props.text ? (
          <div className="form-components-errror-div">{this.props.text}</div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowModal: false,
    };
  }

  displayBannerItem = (value) => {
    let modal = document.getElementById(
      this.props.id ? this.props.id : "OleeModal"
    );
    if (modal) {
      value = value === "block" ? 1 : 0;
      modal.style.opacity = value;
    }
  };

  closeModal = () => {
    if (this.props.isDataHasUpdated === true) {
      this.setState({
        isShowModal: true,
      });
      this.displayBannerItem("none");
    } else {
      this.props.closeModal();
    }
  };

  onConfirmYesOrNo = (yesOrNo) => {
    this.setState(
      {
        isShowModal: false,
      },
      () => {
        if (yesOrNo === true) {
          this.props.closeModal();
        } else {
          this.displayBannerItem("block");
        }
      }
    );
  };

  getConfirmationExitModal = () => {
    const modal = (
      <>
        <FormConfirmationModal
          isShowModal={this.state.isShowModal}
          modalTitle="Lưu ý"
          modalContent="Hiện có dữ liệu chưa được lưu, bạn có muốn thoát?"
          okText="Thoát"
          cancelText="Hủy"
          onConfirmYesOrNo={this.onConfirmYesOrNo}
        />
        {/* <Con show={this.state.isShowModal}>
                <Modal.Header>Lưu ý</Modal.Header>
                <Modal.Body>Hiện có dữ liệu chưa được lưu, bạn có muốn thoát?</Modal.Body>
                <Modal.Footer>
                    <div onClick={this.cancelClose} className='form-components-modal-close-button'>Hủy</div>
                </Modal.Footer>
            </Modal> */}
      </>
    );
    return modal;
  };

  render() {
    const html = (
      <>
        <Modal
          id={this.props.id ? this.props.id : "OleeModal"}
          enforceFocus={false}
          size={this.props.size ? this.props.size : "md"}
          show={this.props.isShowModal}
          onHide={() => { }}
          className="form-components-modal"
        >
          <Modal.Header>
            <div>{this.props.modalTitle}</div>
            <div
              className="form-components-modal-header-close-button"
              onClick={this.closeModal}
            >
              <i class="far fa-times-circle"></i>
            </div>
          </Modal.Header>
          <Modal.Body>{this.props.modalContent}</Modal.Body>
          <Modal.Footer>
            <div
              onClick={this.closeModal}
              className="form-components-modal-close-button"
            >
              {this.props.closeText ? this.props.closeText : "Đóng"}
            </div>
          </Modal.Footer>
        </Modal>
        {this.getConfirmationExitModal()}
      </>
    );
    return html;
  }
}

export class FormConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <Modal
          size={this.props.size ? this.props.size : "md"}
          show={this.props.isShowModal}
          onHide={() => { }}
          className="form-components-modal form-components-confirmation-modal"
        >
          <Modal.Header>
            <div>{this.props.modalTitle}</div>
          </Modal.Header>
          <Modal.Body>{this.props.modalContent}</Modal.Body>
          <Modal.Footer>
            <div
              onClick={() => {
                this.props.onConfirmYesOrNo(true);
              }}
              className="form-components-confirmation-modal-button-div"
            >
              <i class="far fa-check-circle"></i>
              {this.props.okText}
            </div>
            <div
              onClick={() => {
                this.props.onConfirmYesOrNo(false);
              }}
              className="form-components-confirmation-modal-button-div"
            >
              <i class="far fa-times-circle"></i>
              {this.props.cancelText}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
    return html;
  }
}

export class FormImageListUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputId: `formImageListUploader_${Math.floor(
        Math.random() * 100000
      )}_Input`,
      selectedIndex: 0,
      isUploading: false,
      colorList: [],
    };
  }

  componentDidMount() {
    let colorList = BaseService.getInitialColorList();
    if (this.props.colorTags) {
      const propsColorTags = this.props.colorTags;
      colorList = BaseService.getOrderListWithName(propsColorTags);
    }
    this.setState({
      colorList: colorList,
    });
    this.setColorList(colorList);
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "FormImageListUploader - componentWillReceiveProps - nextProps",
      nextProps
    );
    if (this.props.colorTags !== nextProps.colorTags) {
      this.setColorList(nextProps.colorTags);
    }
  }

  setColorList = (colorTags) => {
    let colorList = [];
    if (colorTags) {
      const propsColorTags = colorTags;
      colorList = BaseService.getOrderListWithName(propsColorTags);
    }
    this.setState({
      colorList: colorList,
    });
  };

  onChangeImageInput = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        isUploading: true,
      });
      const file = e.target.files[0];
      console.log("file", file);
      document.getElementById(this.state.inputId).value = "";

      const request = UploadService.uploadImage(
        `${this.props.type ? this.props.type : "image"
        }-${BaseService.getDateTimeString()}`,
        file
      );
      if (request) {
        request.then(
          (data) => {
            const input = document.getElementById(this.state.inputId);
            if (input) {
              input.value = "";
            }
            console.log("data", data);
            this.props.onChangeImageInput(data, this.state.selectedIndex);
            this.setState({
              isUploading: false,
            });
          },
          (error) => {
            console.log("error", error);
            this.setState({
              isUploading: false,
            });
          }
        );
      }
    }
  };

  onClickAddImage = () => {
    if (this.state.isUploading) return;
    const input = document.getElementById(this.state.inputId);
    this.setState({
      selectedIndex: this.props.imageList.length,
    });
    if (input) {
      input.click();
    }
  };

  onClickEditImage = (index) => {
    if (this.state.isUploading) return;
    const input = document.getElementById(this.state.inputId);
    this.setState({
      selectedIndex: index,
    });
    if (input) {
      input.click();
    }
  };

  onRemoveImage = (index) => {
    if (this.state.isUploading) return;
    this.props.onRemoveImage(index);
  };

  getIsColorItemChecked = (imageItem, colorItem) => {
    let res = false;
    let colorList = imageItem.colorTags
      ? imageItem.colorTags
      : BaseService.getInitialColorList();
    if (!imageItem.colorTags) {
      imageItem.colorTags = colorList;
      this.props.onUpdateProductItemImageInput(imageItem);
    }
    const idx = colorList.findIndex((t) => t.id === colorItem.id);
    if (idx !== -1) {
      res = true;
    }
    return res;
  };

  toggleColorItem = (imageItem, colorItem) => {
    const colorList = imageItem.colorTags
      ? imageItem.colorTags
      : BaseService.getInitialColorList();
    const idx = colorList.findIndex((t) => t.id === colorItem.id);
    if (idx === -1) {
      colorList.push(colorItem);
    } else {
      colorList.splice(idx, 1);
    }
    imageItem.colorTags = colorList;
    this.props.onUpdateProductItemImageInput(imageItem);
  };

  render() {
    const html = (
      <>
        <div>
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
              {this.props.guide ? (
                <span className="form-components-img-guide-span">
                  {this.props.guide}
                </span>
              ) : (
                <></>
              )}
            </label>
          ) : (
            <></>
          )}
          {this.state.isUploading ? (
            <div className="form-image-list-uploader-add-button-div form-image-list-uploader-add-disabled-button-div">
              Đang tải ...
            </div>
          ) : (
            <div
              className="form-image-list-uploader-add-button-div"
              onClick={this.onClickAddImage}
            >
              <i class="fas fa-plus"></i>
              Thêm mới
            </div>
          )}
          <Row>
            {this.props.imageList.map((imageItem, imageIndex) => (
              <Col
                lg={4}
                key={imageIndex}
                className="form-image-uploader-img-col"
              >
                <div className="form-image-uploader-img-container-div">
                  {!BaseService.stringIsNullOrWhiteSpace(
                    BaseService.getImageUrlFromServer(
                      imageItem.id,
                      imageItem.name,
                      imageItem.url
                    )
                  ) ? (
                    <img
                      alt=""
                      className="form-image-uploader-img form-image-list-uploader-img"
                      src={BaseService.getImageUrlFromServer(
                        imageItem.id,
                        imageItem.name,
                        imageItem.url
                      )}
                    />
                  ) : (
                    <></>
                  )}
                  <div
                    className="form-image-uploader-command-div"
                    key={imageIndex}
                  >
                    {this.state.isUploading ? (
                      <div className="form-image-uploader-command-button-div form-image-uploader-command-loading-div">
                        ...
                      </div>
                    ) : (
                      <>
                        <div
                          className="form-image-uploader-command-button-div"
                          onClick={() => {
                            this.onClickEditImage(imageIndex);
                          }}
                        >
                          Chỉnh sửa
                        </div>
                        {!BaseService.stringIsNullOrWhiteSpace(
                          BaseService.getImageUrlFromServer(
                            imageItem.id,
                            imageItem.name,
                            imageItem.url
                          )
                        ) ? (
                          <div
                            className="form-image-uploader-command-button-div"
                            onClick={() => {
                              this.onRemoveImage(imageIndex);
                            }}
                          >
                            Xóa
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="form-image-uploader-color-list-wrapper-div">
                  {this.state.colorList.map((colorItem, index) => (
                    <>
                      <div className="form-image-uploader-color-item-div">
                        <FormCheckbox
                          isChecked={this.getIsColorItemChecked(
                            imageItem,
                            colorItem
                          )}
                          checkboxLabel={colorItem.name}
                          onChange={() => {
                            this.toggleColorItem(imageItem, colorItem);
                          }}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <input
          id={this.state.inputId}
          style={{ display: "none" }}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          onChange={this.onChangeImageInput}
        />
      </>
    );
    return html;
  }
}

export class FormImageUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputId: `formImageUploader_${Math.floor(Math.random() * 100000)}_Input`,
      isUploading: false,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //     console.log('nextProps', nextProps);
  // }

  onClickImageInput = () => {
    const input = document.getElementById(this.state.inputId);
    if (input) {
      input.click();
    }
  };

  onChangeImageInput = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        isUploading: true,
      });
      const file = e.target.files[0];
      console.log("file", file);
      document.getElementById(this.state.inputId).value = "";

      const request = UploadService.uploadImage(
        `${this.props.type ? this.props.type : "image"
        }-${BaseService.getDateTimeString()}`,
        file
      );
      if (request) {
        request.then(
          (data) => {
            console.log("data", data);
            this.props.onChangeImageInput(data);
            this.setState({
              isUploading: false,
            });
          },
          (error) => {
            console.log("error", error);
            this.setState({
              isUploading: false,
            });
          }
        );
      }
    }
  };

  clearImage = () => {
    this.props.onChangeImageInput(null);
  };

  render() {
    const html = (
      <>
        <div>
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
              {this.props.guide ? (
                <span className="form-components-img-guide-span">
                  {this.props.guide}
                </span>
              ) : (
                <></>
              )}
            </label>
          ) : (
            <></>
          )}
          <div
            className="form-image-uploader-img-container-div"
          // style={{
          //     backgroundImage: `url(${default_image_jpg})`
          // }}
          >
            {this.props.imgUrl ? (
              <img
                alt=""
                className="form-image-uploader-img"
                src={this.props.imgUrl}
              />
            ) : (
              <img
                alt=""
                className="form-image-uploader-img"
                src={default_image_jpg}
              />
            )}
            <div className="form-image-uploader-command-div">
              {this.state.isUploading ? (
                <div className="form-image-uploader-command-button-div form-image-uploader-command-loading-div">
                  Đang tải lên ...
                </div>
              ) : (
                <>
                  <div
                    className="form-image-uploader-command-button-div"
                    onClick={this.onClickImageInput}
                  >
                    {!BaseService.stringIsNullOrWhiteSpace(this.props.imgUrl)
                      ? "Chỉnh sửa"
                      : "Thêm"}
                  </div>
                  {!BaseService.stringIsNullOrWhiteSpace(this.props.imgUrl) ? (
                    <div
                      className="form-image-uploader-command-button-div"
                      onClick={this.clearImage}
                    >
                      Xóa
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
        <input
          id={this.state.inputId}
          style={{ display: "none" }}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          onChange={this.onChangeImageInput}
        />
      </>
    );
    return html;
  }
}

export class FormRadioList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onChangeValue = (e) => {
    const value = e.currentTarget.value;
    this.props.onChangeValue(value);
  };

  render() {
    const html = (
      <>
        <div
          className={`form-components-text-input-component${this.props.error
            ? " form-components-text-input-component-error"
            : ""
            }`}
        >
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
            </label>
          ) : (
            <></>
          )}
          {this.props.options.map((item, index) => (
            <>
              <input
                id={`Raido_${this.props.name}_${index}`}
                type="radio"
                name={this.props.name}
                value={item.value}
                checked={item.value === this.props.selectedValue}
                onChange={this.onChangeValue}
                className="form-components-checkbox-input"
              />
              <label
                for={`Raido_${this.props.name}_${index}`}
                className={"form-radio-list-item-label"}
              >
                {item.text}
              </label>
            </>
          ))}
        </div>
      </>
    );
    return html;
  }
}

export class FormCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputId: `formCheckbox_${Math.floor(Math.random() * 100000)}_Input`,
    };
  }

  // componentDidMount() {
  //     const index = this.props.options.findIndex(t => t.value === t.selectedValue);
  //     if(index !== -1) {
  //         this.setState({
  //             selectedIndex: index
  //         });
  //     }
  // }

  onChangeValue = (e) => {
    this.props.onChange(e.target.checked);
  };

  render() {
    const html = (
      <>
        <div
          className={`form-components-text-input-component${this.props.error
            ? " form-components-text-input-component-error"
            : ""
            }`}
        >
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
            </label>
          ) : (
            <></>
          )}
          <input
            type="checkbox"
            checked={this.props.isChecked}
            id={this.state.inputId}
            onChange={this.onChangeValue}
            className="form-components-checkbox-input"
          />
          {this.props.checkboxLabel ? (
            <label for={this.state.inputId}>{this.props.checkboxLabel}</label>
          ) : (
            <></>
          )}
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormColorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colorList: [],
      headers: [
        { title: "Sắp xếp", width: "111" },
        { title: "Tên" },
        { title: "Mã màu", width: "120" },
        { title: "", width: "45" },
      ],
    };
  }

  componentDidMount() {
    let colorList = this.initialColorList();
    if (this.props.colorTags) {
      const propsColorTags = this.props.colorTags;
      colorList = BaseService.getOrderListWithName(propsColorTags);
    }
    this.setState({
      colorList: colorList,
    });
  }

  initialColorList = () => {
    return BaseService.getInitialColorList();
  };

  addNewColorItem = () => {
    const colorItem = {
      id: BaseService.getTimeStamp(),
      name: null,
      colorCode: null,
      orderNumber: 0,
    };
    const colorList = this.state.colorList;
    colorList.push(colorItem);
    this.updateColorList(colorList);
  };

  changeColorItemOrderNumber = (value, itemId) => {
    const colorList = this.state.colorList;
    const itemIndex = colorList.findIndex((t) => t.id === itemId);
    colorList[itemIndex].orderNumber = value;
    this.updateColorList(colorList);
  };

  changeColorItemName = (value, itemId) => {
    const colorList = this.state.colorList;
    const itemIndex = colorList.findIndex((t) => t.id === itemId);
    colorList[itemIndex].name = value;
    this.updateColorList(colorList);
  };

  changeColorItemCode = (value, itemId) => {
    value = value.replace("#", "");
    const colorList = this.state.colorList;
    const itemIndex = colorList.findIndex((t) => t.id === itemId);
    colorList[itemIndex].colorCode = value;
    this.updateColorList(colorList);
  };

  getElementBackgroundColor = (itemId) => {
    let color = "#fff";
    const colorList = this.state.colorList;
    const colorItem = colorList.find((t) => t.id === itemId);
    if (colorItem) {
      return `#${!BaseService.stringIsNullOrWhiteSpace(colorItem.colorCode)
        ? colorItem.colorCode
        : "fff"
        }`;
    }
    return color;
  };

  deleteColorItem = (itemId) => {
    const colorList = this.state.colorList;
    const itemIndex = colorList.findIndex((t) => t.id === itemId);
    colorList.splice(itemIndex, 1);
    this.updateColorList(colorList);
  };

  updateColorList = (colorList) => {
    this.setState(
      (state) => {
        return {
          colorList: [...colorList],
        };
      },
      () => {
        this.props.onChange(colorList);
      }
    );
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  getTableRows = () => {
    const rows = [];
    this.state.colorList
      .filter((t) => t.id !== -1)
      .forEach((item, index) => {
        rows.push({
          columns: [
            <>
              <FormNumberInput
                value={item.orderNumber}
                min="0"
                onChange={(value) => {
                  this.changeColorItemOrderNumber(value, item.id);
                }}
              />
            </>,
            <>
              <FormTextInput
                value={item.name}
                onChange={(value) => {
                  this.changeColorItemName(value, item.id);
                }}
              />
            </>,
            <>
              <div className="form-color-list-table-item-color-code-wrapper-div">
                <div
                  id={`ColorItemColor_${item.id}`}
                  className="form-color-list-table-item-color-code-color-div"
                  style={{
                    backgroundColor: this.getElementBackgroundColor(item.id),
                  }}
                ></div>
                <div className="form-color-list-table-item-color-code-tag-div">
                  #
                </div>
                <FormTextInput
                  value={item.colorCode}
                  onChange={(value) => {
                    this.changeColorItemCode(value, item.id);
                  }}
                />
              </div>
            </>,
            <>
              <div className="form-hash-tag-selector-content-search-command-div form-color-list-table-header-row-delete-button-div">
                <>
                  <div
                    onClick={() => {
                      this.deleteColorItem(item.id);
                    }}
                  >
                    Xóa
                  </div>
                </>
              </div>
            </>,
          ],
        });
      });
    return rows;
  };

  getColorListElement = () => {
    const element = (
      <>
        <div className="form-hash-tag-selector-content-search-command-div">
          <>
            <div onClick={this.addNewColorItem}>Thêm mới</div>
          </>
        </div>
        <TableComponent
          headers={this.getTableHeaders()}
          rows={this.getTableRows()}
          noItemRow={<td colSpan={this.state.headers.length}>Chưa có màu</td>}
        />
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        {this.props.labelText ? (
          <label
            className="form-components-text-input-label"
            htmlFor={this.props.labelName}
          >
            {this.props.labelText}{" "}
            {this.props.isRequired ? (
              <span className="form-components-text-input-required-span">
                *
              </span>
            ) : (
              ""
            )}
          </label>
        ) : (
          <></>
        )}
        <div className="form-hash-tag-selector-content-div">
          {this.getColorListElement()}
        </div>
        {/* <div>
                {JSON.stringify(this.state.colorList)}
            </div> */}
      </>
    );
    return html;
  }
}

export class FormHashTagSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hashTags: this.props.hashTags
        ? BaseService.convertArrayStringToArray(this.props.hashTags, "#")
        : [],
      allHashTags: [],
      originAllHashTags: [],
      hashTagValue: "",
      hashTagMode: "search", // 'search', 'addNew'
      error: null,
    };
  }

  // componentDidMount() {
  // }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.allHashTagsRequestStatus !==
      nextProps.allHashTagsRequestStatus &&
      nextProps.allHashTagsRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const hashTagByCategory = BaseService.convertArrayStringToArray(
        nextProps.allHashTags,
        "#"
      );
      if (hashTagByCategory && hashTagByCategory.length > 0) {
        let allHashTags = this.state.allHashTags;
        const hashTags = this.state.hashTags;

        hashTagByCategory.forEach((item) => {
          if (
            allHashTags.findIndex((t) => t === item) === -1 &&
            hashTags.findIndex((t) => t === item) === -1
          ) {
            allHashTags.push(item);
          }
        });

        this.setState({
          allHashTags: allHashTags,
        });
      }
    }
  }

  toSearchMode = () => {
    this.setState({
      hashTagMode: "search",
    });
  };

  toAddNewMode = () => {
    this.setState({
      hashTagMode: "addNew",
    });
  };

  recoverAllHashTags = () => {
    const originAllHashTags = this.state.originAllHashTags;
    if (originAllHashTags) {
      this.setState({
        allHashTags: originAllHashTags,
        originAllHashTags: null,
      });
    }
  };

  setOriginAllHashTags = () => {
    const originAllHashTags = this.state.originAllHashTags;
    if (!originAllHashTags) {
      this.setState({
        originAllHashTags: this.state.allHashTags,
      });
    }
  };

  updateHashTagValue = (e) => {
    const value = e.target.value;

    if (BaseService.stringIsNullOrWhiteSpace(value)) {
      this.recoverAllHashTags();
    } else {
      this.setOriginAllHashTags();
    }

    let allHashTags = this.state.originAllHashTags
      ? this.state.originAllHashTags
      : this.state.allHashTags;
    allHashTags = allHashTags.filter((item) => item.indexOf(value) !== -1);
    this.setState({
      hashTagValue: value,
      allHashTags: allHashTags,
    });
  };

  resetHashTagValue = () => {
    this.setState({
      error: null,
      hashTagValue: "",
    });
    this.recoverAllHashTags();
  };

  addNewHashTag = () => {
    this.setState({
      error: null,
    });

    let allHashTags = this.state.allHashTags;
    let hashTags = this.state.hashTags;

    const newHashTagValue = this.state.hashTagValue.trim();

    if (BaseService.stringIsNullOrWhiteSpace(newHashTagValue)) {
      this.setState({
        error: "Tab phân loại không được để rỗng.",
      });
      return;
    }

    const itemInAllHashTagsIndex = allHashTags.findIndex(
      (t) => t === newHashTagValue
    );
    const item = hashTags.find((t) => t === newHashTagValue);

    if (!item) {
      if (itemInAllHashTagsIndex !== -1) {
        allHashTags.splice(itemInAllHashTagsIndex, 1);
      }

      hashTags.push(newHashTagValue);
      this.setState({
        allHashTags: allHashTags,
        hashTags: hashTags,
        hashTagValue: "",
      });
    } else {
      this.setState({
        error: "Tab phân loại đã tồn tại trong thông tin sản phẩm.",
      });
    }

    this.props.updateHashTag(
      BaseService.convertArrayToArrayString(hashTags, "#")
    );
  };

  chooseHashTag = (index) => {
    let originAllHashTags = this.state.originAllHashTags;
    let allHashTags = this.state.allHashTags;
    let hashTags = this.state.hashTags;

    if (!BaseService.stringIsNullOrWhiteSpace(allHashTags[index])) {
      hashTags.push(allHashTags[index]);
    }

    if (originAllHashTags) {
      const originIndex = originAllHashTags.findIndex(
        (t) => t === allHashTags[index]
      );
      if (originIndex !== -1) {
        originAllHashTags.splice(originIndex, 1);
      }
    }
    allHashTags.splice(index, 1);

    this.setState({
      hashTags: hashTags,
      allHashTags: allHashTags,
      originAllHashTags: originAllHashTags,
    });

    this.props.updateHashTag(
      BaseService.convertArrayToArrayString(hashTags, "#")
    );
  };

  removeHashTag = (index) => {
    let originAllHashTags = this.state.originAllHashTags;
    let allHashTags = this.state.allHashTags;
    let hashTags = this.state.hashTags;

    const removeHashTags = hashTags[index];
    if (!BaseService.stringIsNullOrWhiteSpace(removeHashTags)) {
      originAllHashTags.push(removeHashTags);
      if (removeHashTags.indexOf(this.state.hashTagValue) !== -1) {
        allHashTags.push(removeHashTags);
      }
    }

    hashTags.splice(index, 1);

    this.setState({
      hashTags: hashTags,
      allHashTags: allHashTags,
    });

    this.props.updateHashTag(
      BaseService.convertArrayToArrayString(hashTags, "#")
    );
  };

  render() {
    const html = (
      <>
        <div
          className={`form-components-text-input-component${this.props.error
            ? " form-components-text-input-component-error"
            : ""
            }`}
        >
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
            </label>
          ) : (
            <></>
          )}
          <div className="form-hash-tag-selector-content-div">
            {/* <span>Đã chọn</span> */}
            <div className="form-hash-tag-selector-content-selected-items-div">
              <div>
                {this.state.hashTags.map((hashTagItem, hashTagIndex) => (
                  <React.Fragment key={hashTagIndex}>
                    <div
                      onClick={() => {
                        this.removeHashTag(hashTagIndex);
                      }}
                    >
                      {hashTagItem} <i class="fas fa-minus-circle"></i>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <br />
            <span className="form-hash-tag-selector-content-search-title-span">
              Gợi ý
            </span>
            <div className="form-hash-tag-selector-content-search-input-div">
              <input
                value={this.state.hashTagValue}
                onChange={this.updateHashTagValue}
                placeholder="Nhập tab phân loại (Ví dụ: Kem dưỡng da,...)"
              />
              {this.state.error ? (
                <div className="form-hash-tag-selector-content-search-error-div">
                  {this.state.error}
                </div>
              ) : (
                <></>
              )}
              <div className="form-hash-tag-selector-content-search-command-div">
                {/* <div onClick={this.toSearchMode}>Tìm</div> */}
                {
                  // (this.state.hashTagMode === 'search')?(
                  <>
                    <div onClick={this.addNewHashTag}>Thêm mới</div>
                  </>
                  // ):(
                  //     <>
                  //         <div onClick={this.addNewHashTag}>Lưu</div>
                  //         <div onClick={this.toSearchMode}>Hủy</div>
                  //     </>
                  // )
                }
                <div onClick={this.resetHashTagValue}>Làm mới</div>
              </div>
            </div>
            <div className="form-hash-tag-selector-content-selected-items-div">
              <div>
                {this.state.allHashTags.map((hashTagItem, hashTagIndex) => (
                  <React.Fragment key={hashTagIndex}>
                    <div
                      onClick={() => {
                        this.chooseHashTag(hashTagIndex);
                      }}
                    >
                      {hashTagItem}{" "}
                      <i className="fas fa-plus-circle form-hash-tag-selector-content-selected-items-add-i"></i>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onChangeValue = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  renderSelectElement = (item) => {
    const element = <option value={item.value}>{item.text}</option>;
    return element;
  };

  render() {
    const html = (
      <>
        <div
          className={`form-components-text-input-component${this.props.error
            ? " form-components-text-input-component-error"
            : ""
            }`}
        >
          {this.props.labelText ? (
            <label
              className="form-components-text-input-label"
              htmlFor={this.props.labelName}
            >
              {this.props.labelText}{" "}
              {this.props.isRequired ? (
                <span className="form-components-text-input-required-span">
                  *
                </span>
              ) : (
                ""
              )}
            </label>
          ) : (
            <></>
          )}
          <select
            className="form-components-text-input form-select"
            value={this.props.selectedValue}
            onChange={this.onChangeValue}
          >
            {this.props.options.map((item, index) =>
              item.isGroup ? (
                <optgroup label={item.text}>
                  {item.subItems.map((item) => this.renderSelectElement(item))}
                </optgroup>
              ) : (
                this.renderSelectElement(item)
              )
            )}
          </select>
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <div className="form-components-video-container-div">
          {this.props.canAccess === true ? (
            <></>
          ) : (
            <div className="form-components-video-background-div"></div>
          )}
          {this.props.canAccess === true ? (
            <ReactPlayer
              url={this.props.videoUrl}
              playing={this.props.autoPlay === false ? false : true}
              muted={false}
              loop={false}
              controls={true}
            />
          ) : (
            <ReactPlayer
              url={this.props.videoUrl}
              playing={true}
              muted={true}
              loop={true}
              controls={false}
            />
          )}
        </div>
      </>
    );
    return html;
  }
}

export class FormSunEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: `sunEditor_${Math.floor(Math.random() * 100000)}`,
      loginData: AuthenticationService.getLoginData(),
      sunEditorConfig: this.props.isSimple
        ? {
          buttonList: [
            [
              "undo",
              "redo",
              "formatBlock",
              "font",
              "fontSize",
              "fontColor",
              "hiliteColor",
              "blockquote",
              "bold",
              "italic",
              "underline",
              "strike",
              "subscript",
              "superscript",
              "removeFormat",
              "align",
              "horizontalRule",
              "list",
              "indent",
              "outdent",
              "lineHeight",
              // 'table', 'link', 'image',
              // 'imageGallery',
              // 'video',
              // 'showBlocks', 'codeView', 'preview', 'fullScreen', 'print'
            ],
          ],
          font: [
            "Gilroy",
            "Optima",
            "Tuv",
            "Arial, Helvetica, sans-serif",
            "Courier New, Courier, monospace",
            "Lucida Sans Unicode, Lucida Grande, sans-serif	",
            "Palatino Linotype, Book Antiqua, Palatino, serif",
            "Tahoma, Geneva, sans-serif",
            "Times New Roman, Times, serif",
            "Verdana, Geneva, sans-serif",
            "MS Sans Serif, Geneva, sans-serif",
            "MS Serif, New York, serif",
          ],
        }
        : {
          charCounter: true,
          buttonList: [
            [
              "undo",
              "redo",
              "formatBlock",
              "font",
              "fontSize",
              "fontColor",
              "hiliteColor",
              "blockquote",
              "bold",
              "italic",
              "underline",
              "strike",
              "subscript",
              "superscript",
              "removeFormat",
              "align",
              "horizontalRule",
              "list",
              "indent",
              "outdent",
              "lineHeight",
              "table",
              "link",
              "image",
              // 'imageGallery',
              "video",
              "showBlocks",
              "codeView",
              "preview",
              "fullScreen",
              "print",
            ],
          ],
          font: [
            // 'Arial, Helvetica, sans-serif',
            // 'Courier New, Courier, monospace',
            // 'Georgia, serif',
            // 'Lucida Sans Unicode, Lucida Grande, sans-serif',
            // 'Tahoma, Geneva, sans-serif',
            // 'Times New Roman, Times, serif',
            // 'Trebuchet MS, Helvetica, sans-serif',
            // 'Verdana, Geneva, sans-serif'
            "Gilroy",
            "Optima",
            "Tuv",
            "Arial, Helvetica, sans-serif",
            // 'Arial Black, Gadget, sans-serif',
            // 'Comic Sans MS, Textile, cursive',
            "Courier New, Courier, monospace",
            // 'Georgia, Times New Roman, Times, serif',
            // 'Impact, Charcoal, sans-serif',
            // 'Lucida Console, Monaco, monospace',
            "Lucida Sans Unicode, Lucida Grande, sans-serif	",
            "Palatino Linotype, Book Antiqua, Palatino, serif",
            "Tahoma, Geneva, sans-serif",
            "Times New Roman, Times, serif",
            // 'Trebuchet MS, Helvetica, sans-serif',
            "Verdana, Geneva, sans-serif",
            "MS Sans Serif, Geneva, sans-serif",
            "MS Serif, New York, serif",
          ],
          // 'imageGalleryUrl': 'https://etyswjpn79.execute-api.ap-northeast-1.amazonaws.com/suneditor-demo',
          imageUploadHeader: {
            Authorization: `Bearer ${AuthenticationService.getLoginData().token
              }`,
          },
          imageUploadUrl: `${OleeApiURL}${ImageApiUrl}/editor`,
        },
    };
  }

  render() {
    const html = (
      <>
        {this.props.labelText ? (
          <label
            className="form-components-text-input-label"
            htmlFor={this.props.labelName}
          >
            {this.props.labelText}{" "}
            {this.props.isRequired ? (
              <span className="form-components-text-input-required-span">
                *
              </span>
            ) : (
              ""
            )}
          </label>
        ) : (
          <></>
        )}
        <div
          className={`form-components-sun-editor${this.props.error ? " form-components-sun-editor-error" : ""
            }`}
        >
          <SunEditor
            setOptions={this.state.sunEditorConfig}
            height={
              this.props.height
                ? this.props.height
                : this.props.isSimple
                  ? "180"
                  : "450"
            }
            setContents={this.props.data}
            onChange={(content) => {
              this.props.onChange(content);
            }}
            onBlur={() => {
              if (this.props.onBlur) {
                this.props.onBlur();
              }
            }}
          />
        </div>
        {this.props.error ? (
          <div className="form-components-text-input-error">
            {this.props.error}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return html;
  }
}

export class FormPagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forcePage: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.pageIndex !== nextProps.pageIndex &&
      nextProps.pageIndex === 0
    ) {
      this.setState({
        forcePage: 0,
      });
    }
  }

  handleOnPageChange = (data) => {
    let pageIndex = data.selected;
    if (!pageIndex) pageIndex = 0;
    this.setState(
      {
        forcePage: pageIndex,
      },
      () => {
        this.props.handleOnPageChange(pageIndex);
      }
    );
  };

  render() {
    const html = (
      <>
        <ReactPaginate
          pageCount={this.props.pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          containerClassName={"form-pagination-ul"}
          activeClassName={"form-pagination-item-active-li"}
          previousLabel={<i class="fas fa-angle-left"></i>}
          nextLabel={<i class="fas fa-angle-right"></i>}
          onPageChange={this.handleOnPageChange}
          forcePage={this.state.forcePage}

        //   previousLabel={'previous'}
        //   nextLabel={'next'}
        //   breakLabel={'...'}
        //   breakClassName={'break-me'}
        //   pageCount={this.state.pageCount}
        //   marginPagesDisplayed={2}
        //   pageRangeDisplayed={5}
        //   onPageChange={this.handlePageClick}
        //   containerClassName={'pagination'}
        //   subContainerClassName={'pages pagination'}
        //   activeClassName={'active'}
        />
      </>
    );
    return html;
  }
}

export class FormNAEmement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forcePage: 0,
    };
  }

  render() {
    const html = (
      <>
        <div className="form-na-element-wrapper-div">
          <i class="fas fa-search"></i>
          <div>
            {this.props.message ? this.props.message : "Không có thông tin."}
          </div>
        </div>
      </>
    );
    return html;
  }
}

export class FormCountdownClock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.getCountDownClockTime();
    }, 1000);
  }

  getCountDownClockTime = () => {
    this.setState({
      startDate: BaseService.getTimeCountdown(
        this.props.startDate,
        this.props.diffDate
      ),
    });
  };

  render() {
    const html = (
      <>
        <div>
          {this.state.startDate ? (
            <>
              {/* {this.props.title ? (
                <div className="form-count-down-clock-title-div">
                  {this.props.title}
                </div>
              ) : (
                <></>
              )} */}
              <div className="form-count-down-clock-wrapper-div">
                <div className="form-count-down-clock-border-div">
                  <div className="form-count-down-clock-item-div form-count-down-clock-item-number-div form-count-down-clock-item-icon-div">
                    <div>
                      <i class="far fa-calendar-alt"></i>
                    </div>
                    <div className="form-count-down-clock-item-text-div">
                      Còn
                    </div>
                  </div>
                  {this.state.startDate && this.state.startDate.days > 0 ? (
                    <div className="form-count-down-clock-item-div form-count-down-clock-item-day-div">
                      <div>{this.state.startDate.days}</div>
                      <div className="form-count-down-clock-item-text-div">
                        Ngày
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="form-count-down-clock-item-div form-count-down-clock-item-number-div">
                    <div>
                      {BaseService.convertNumberTo2CharString(
                        this.state.startDate.hours
                      )}
                    </div>
                    <div className="form-count-down-clock-item-text-div">
                      Giờ
                    </div>
                  </div>
                  <div className="form-count-down-clock-item-div">:</div>
                  <div className="form-count-down-clock-item-div form-count-down-clock-item-number-div">
                    <div>
                      {BaseService.convertNumberTo2CharString(
                        this.state.startDate.minutes
                      )}
                    </div>
                    <div className="form-count-down-clock-item-text-div">
                      Phút
                    </div>
                  </div>
                  <div className="form-count-down-clock-item-div">:</div>
                  <div className="form-count-down-clock-item-div form-count-down-clock-item-number-div">
                    <div>
                      {BaseService.convertNumberTo2CharString(
                        this.state.startDate.seconds
                      )}
                    </div>
                    <div className="form-count-down-clock-item-text-div">
                      Giây
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
    return html;
  }
}

export class ReadMoreButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forcePage: 0,
    };
  }

  render() {
    const html = (
      <>
        <div className="form-read-more-button-wrapper-div">
          <div
            className="form-read-more-button-div"
            onClick={this.props.readMoreFunction}
          >
            {/* <i class="fas fa-sort-down"></i> */}
            <div>{this.props.title ? this.props.title : "Xem thêm"}</div>
            <i class="fas fa-sort-down"></i>
          </div>
        </div>
      </>
    );
    return html;
  }
}

// export class FormCKEditor extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             id: `ckEditor_${Math.floor(Math.random() * 100000)}`,
//             wordCount: 0,
//             characterCount: 0,
//             loginData: AuthenticationService.getLoginData()
//         }
//     }

//     render() {
//         const html = <>
//             <div className={`raw-html-format form-components-text-input-component form-components-ck-editor${(this.props.error)?(' form-components-text-input-component-error'):('')}`}>
//                 {
//                     (this.props.labelText)?(
//                         <label className='form-components-text-input-label' htmlFor={this.props.labelName}>
//                             {this.props.labelText} {(this.props.isRequired)?(<span className='form-components-text-input-required-span'>*</span>):('')}
//                         </label>
//                     ):(<></>)
//                 }
//                 <CKEditor
//                     editor={ClassicEditor}
//                     onInit={editor => {
//                         console.log('Editor is ready to use!', editor);
//                         // const wordCountPlugin = editor.plugins.get('WordCount');
//                         // // console.log('wordCountPlugin.wordCountContainer', wordCountPlugin.wordCountContainer);
//                         // const wordCountWrapper = document.getElementById(this.state.id);
//                         // wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
//                     }}
//                     data={this.props.data}
//                     onChange={(event, editor) => {
//                         const data = editor.getData();
//                         this.props.onChange(data);
//                     }}
//                     config={{
//                         language: 'vi',
//                         plugins: [
//                             Paragraph,
//                             Essentials,
//                             //
//                             Heading,
//                             //
//                             FontFamily,
//                             FontSize,
//                             FontColor,
//                             FontBackgroundColor,
//                             //
//                             Bold,
//                             Italic,
//                             Underline,
//                             Strikethrough,
//                             //
//                             Alignment,
//                             //
//                             Link,
//                             Image,
//                             ImageCaption,
//                             ImageResize,
//                             ImageStyle,
//                             ImageToolbar,
//                             ImageUpload,
//                             // SimpleUploadAdapter,
//                             Base64UploadAdapter,
//                             MediaEmbed,
//                             Table,
//                             TableToolbar,
//                             TableProperties,
//                             TableCellProperties,
//                             //
//                             List,
//                             Indent,
//                             IndentBlock,
//                             //
//                             SpecialCharacters,
//                             SpecialCharactersArrows,
//                             SpecialCharactersCurrency,
//                             SpecialCharactersEssentials,
//                             SpecialCharactersLatin,
//                             SpecialCharactersMathematical,
//                             SpecialCharactersText,
//                             BlockQuote,
//                             //
//                             WordCount
//                         ],
//                         toolbar: [
//                             'heading',
//                             '|',
//                             'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
//                             '|',
//                             'bold', 'italic', 'underline', 'strikeThrough',
//                             '|',
//                             'alignment',
//                             '|',
//                             'link', 'imageUpload', 'mediaEmbed', 'insertTable',
//                             '|',
//                             'numberedList', 'bulletedList', 'outdent', 'indent',
//                             '|',
//                             'specialCharacters', 'blockQuote',
//                             '|',
//                             'undo', 'redo'
//                         ],
//                         // simpleUpload: {
//                         //     uploadUrl: `${OleeApiURL}${ImageApiUrl}`,
//                         //     headers: {
//                         //         Authorization: `Bearer ${this.state.loginData.token}`
//                         //     }
//                         // },
//                         fontFamily: {
//                             options: [
//                                 'default',
//                                 // 'Arial, Helvetica, sans-serif',
//                                 // 'Courier New, Courier, monospace',
//                                 // 'Georgia, serif',
//                                 // 'Lucida Sans Unicode, Lucida Grande, sans-serif',
//                                 // 'Tahoma, Geneva, sans-serif',
//                                 // 'Times New Roman, Times, serif',
//                                 // 'Trebuchet MS, Helvetica, sans-serif',
//                                 // 'Verdana, Geneva, sans-serif'
//                                 'Arial, Helvetica, sans-serif',
//                                 // 'Arial Black, Gadget, sans-serif',
//                                 // 'Comic Sans MS, Textile, cursive',
//                                 'Courier New, Courier, monospace',
//                                 // 'Georgia, Times New Roman, Times, serif',
//                                 // 'Impact, Charcoal, sans-serif',
//                                 // 'Lucida Console, Monaco, monospace',
//                                 'Lucida Sans Unicode, Lucida Grande, sans-serif	',
//                                 'Palatino Linotype, Book Antiqua, Palatino, serif',
//                                 'Tahoma, Geneva, sans-serif',
//                                 'Times New Roman, Times, serif',
//                                 // 'Trebuchet MS, Helvetica, sans-serif',
//                                 'Verdana, Geneva, sans-serif',
//                                 'MS Sans Serif, Geneva, sans-serif',
//                                 'MS Serif, New York, serif'
//                             ],
//                             supportAllValues: true
//                         },
//                         image: {
//                             toolbar: [
//                                 'imageStyle:alignLeft',
//                                 'imageStyle:full',
//                                 'imageStyle:alignRight',
//                                 '|',
//                                 'imageTextAlternative'
//                             ],
//                             styles: [
//                                 'full',
//                                 'alignLeft',
//                                 'alignRight'
//                             ]
//                         },
//                         mediaEmbed: {
//                             previewsInData: true
//                         },
//                         table: {
//                             contentToolbar: [
//                                 'tableColumn',
//                                 'tableRow',
//                                 'mergeTableCells',
//                                 'tableProperties',
//                                 'tableCellProperties'
//                             ]
//                         },
//                         wordCount: {
//                             onUpdate: state => {
//                                 this.setState({
//                                     wordCount: state.words,
//                                     characterCount: state.characters
//                                 })
//                             }
//                         }
//                     }}
//                 />
//                 <div id={this.state.id} className='form-ck-editor-word-count-container-div'>
//                     <div>Chữ: <span>{this.state.wordCount}</span></div>
//                     <div>Ký tự: <span>{this.state.characterCount}</span></div>
//                 </div>
//             </div>
//             {
//                 (this.props.error)?(
//                     <div className='form-components-text-input-error'>{this.props.error}</div>
//                 ):(<></>)
//             }
//         </>;
//         return (
//             html
//         );
//     }
// }
