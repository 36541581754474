import React from 'react';
import './google-map.css';

export class GoogleMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const html = <>
            <div className='google-map-container-div'>
                <iframe src={this.props.src}
                    title="google-map"
                    frameborder="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0">
                </iframe>
            </div>
        </>;
        return (
            html
        );
    }
}