import {
    typicalfacepage_GET_TYPICAL_FACE_LIST_REQUEST,
    typicalfacepage_GET_TYPICAL_FACE_LIST_SUCCESS,
    typicalfacepage_GET_TYPICAL_FACE_LIST_ERROR,
    typicalfacepage_GET_TYPICAL_FACE_DETAIL_REQUEST,
    typicalfacepage_GET_TYPICAL_FACE_DETAIL_SUCCESS,
    typicalfacepage_GET_TYPICAL_FACE_DETAIL_ERROR,
    typicalfacepage_INSERT_TYPICAL_FACE_SUCCESS,
    typicalfacepage_INSERT_TYPICAL_FACE_REQUEST,
    typicalfacepage_INSERT_TYPICAL_FACE_ERROR,
    typicalfacepage_UPDATE_TYPICAL_FACE_REQUEST,
    typicalfacepage_UPDATE_TYPICAL_FACE_SUCCESS,
    typicalfacepage_UPDATE_TYPICAL_FACE_ERROR,
    typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_REQUEST,
    typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_SUCCESS,
    typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_ERROR,
    typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_REQUEST,
    typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_SUCCESS,
    typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_ERROR,
    typicalfacepage_DELETE_TYPICAL_FACE_REQUEST,
    typicalfacepage_DELETE_TYPICAL_FACE_SUCCESS,
    typicalfacepage_DELETE_TYPICAL_FACE_ERROR
} from '../actions/typical-face-page-actions';
import {
    RequestStatus
} from '../../constants';

const initialState  = {
    typicalFaceListRequestStatus: RequestStatus.RQ_NEW,
    typicalFaceList: [],
    // 
    typicalFaceDetailRequestStatus: RequestStatus.RQ_NEW,
    typicalFaceDetail: null,
    typicalFaceDetail_originName: null,
    // 
    insertTypicalFaceRequestStatus: RequestStatus.RQ_NEW,
    insertedTypicalFace: null,
    // 
    updateTypicalFaceRequestStatus: RequestStatus.RQ_NEW,
    updatedTypicalFace: null,
    // 
    updateTypicalFaceOrderNumberRequestStatus: RequestStatus.RQ_NEW,
    updatedTypicalFaceOrderNumber: null,
    // 
    updateTypicalFaceIsDeletedRequestStatus: RequestStatus.RQ_NEW,
    updatedTypicalFaceIsDeleted: null,
    // 
    deleteTypicalFaceRequestStatus: RequestStatus.RQ_NEW,
    deletedTypicalFace: null,
    // 
    errorMessage: null
}

export function typicalFacePageReducer(state = initialState, actions) {
    switch(actions.type) {
        case typicalfacepage_GET_TYPICAL_FACE_LIST_REQUEST: {
            return {
                ...state,
                typicalFaceListRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_GET_TYPICAL_FACE_LIST_SUCCESS: {
            return {
                ...state,
                typicalFaceListRequestStatus: RequestStatus.RQ_SUCCESS,
                typicalFaceList: actions.payload.typicalFaceList
            }
        }
        case typicalfacepage_GET_TYPICAL_FACE_LIST_ERROR: {
            return {
                ...state,
                typicalFaceListRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case typicalfacepage_GET_TYPICAL_FACE_DETAIL_REQUEST: {
            return {
                ...state,
                typicalFaceDetailRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_GET_TYPICAL_FACE_DETAIL_SUCCESS: {
            const typicalFaceDetail = actions.payload.typicalFaceDetail;
            return {
                ...state,
                typicalFaceDetailRequestStatus: RequestStatus.RQ_SUCCESS,
                typicalFaceDetail: typicalFaceDetail
            }
        }
        case typicalfacepage_GET_TYPICAL_FACE_DETAIL_ERROR: {
            return {
                ...state,
                typicalFaceDetailRequestStatus: RequestStatus.RQ_ERROR
            }
        }
        case typicalfacepage_INSERT_TYPICAL_FACE_REQUEST: {
            return {
                ...state,
                insertTypicalFaceRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_INSERT_TYPICAL_FACE_SUCCESS: {
            return {
                ...state,
                insertTypicalFaceRequestStatus: RequestStatus.RQ_SUCCESS,
                insertedTypicalFace: actions.payload.insertedTypicalFace
            }
        }
        case typicalfacepage_INSERT_TYPICAL_FACE_ERROR: {
            return {
                ...state,
                insertTypicalFaceRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_REQUEST: {
            return {
                ...state,
                updateTypicalFaceRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_SUCCESS: {
            return {
                ...state,
                updateTypicalFaceRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedTypicalFace: actions.payload.updatedTypicalFace
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_ERROR: {
            return {
                ...state,
                updateTypicalFaceRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_REQUEST: {
            return {
                ...state,
                updateTypicalFaceOrderNumberRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_SUCCESS: {
            return {
                ...state,
                updateTypicalFaceOrderNumberRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedTypicalFaceOrderNumber: actions.payload.updatedTypicalFace
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_ORDER_NUMBER_ERROR: {
            return {
                ...state,
                updateTypicalFaceOrderNumberRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_REQUEST: {
            return {
                ...state,
                updateTypicalFaceIsDeletedRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_SUCCESS: {
            return {
                ...state,
                updateTypicalFaceIsDeletedRequestStatus: RequestStatus.RQ_SUCCESS,
                updatedTypicalFaceIsDeleted: actions.payload.updatedTypicalFace
            }
        }
        case typicalfacepage_UPDATE_TYPICAL_FACE_IS_DELETED_ERROR: {
            return {
                ...state,
                updateTypicalFaceIsDeletedRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }
        case typicalfacepage_DELETE_TYPICAL_FACE_REQUEST: {
            return {
                ...state,
                deleteTypicalFaceRequestStatus: RequestStatus.RQ_REQUEST
            }
        }
        case typicalfacepage_DELETE_TYPICAL_FACE_SUCCESS: {
            return {
                ...state,
                deleteTypicalFaceRequestStatus: RequestStatus.RQ_SUCCESS,
                deletedTypicalFace: actions.payload.deletedTypicalFace
            }
        }
        case typicalfacepage_DELETE_TYPICAL_FACE_ERROR: {
            return {
                ...state,
                deleteTypicalFaceRequestStatus: RequestStatus.RQ_ERROR,
                errorMessage: actions.payload.errorMessage
            }
        }       
        default: {
            return state;
        }
    }
}