import React from "react";
import "./product-list-page.css";
//
import { ProductPagingList } from "../components/web-user-layout/product-paging-list";
import { RequestStatus } from "../constants";
import { LoadingComponent } from "../components/public/loading-component";
import { BaseService } from "../services/base-service";

export class ProductListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.loadProductList();
    this.props.loadCategoryList();
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    setInterval(() => {
      BaseService.setMaxHeightByClassName(
        "product-list-catrgory-background-img"
      );
      BaseService.setMaxHeightByClassName("product-list-carousel-title-div");
      // BaseService.setMaxHeightByClassName('product-list-catrgory-tag-div');
      BaseService.setMaxHeightByClassName("product-list-carousel-price-div");
      BaseService.setMaxHeightByClassName(
        "product-list-carousel-promo-text-div"
      );
      BaseService.setHeightByWidthOfClass("product-list-carousel-item-img");
      BaseService.setHeightByWidthOfClass("product-list-carousel-item-img");
      // BaseService.setMaxWidthByClassName('product-item-cart-item-div');
    }, 500);
  };

  render() {
    const html = (
      <>
        {this.props.productListRequestStatus === RequestStatus.RQ_REQUEST ||
        this.props.categoryListRequestStatus === RequestStatus.RQ_REQUEST ? (
          <LoadingComponent />
        ) : (
          <ProductPagingList
            categoryList={this.props.categoryListRequestResult}
            productList={this.props.productListRequestResult}
            addProductItemToCart={this.props.addProductItemToCart}
          />
        )}
      </>
    );
    return html;
  }
}
