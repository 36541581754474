const getSubState = (state) => state.webUserLayoutReducer;

export const getNavMenuListRequestStatus = (state) =>
  getSubState(state).navMenuListRequestStatus;
export const getNavMenuList = (state) => getSubState(state).navMenuList;
export const getFooterInfoRequest = (state) =>
  getSubState(state).footerInfoRequest;
export const getFooterInfoData = (state) => getSubState(state).footerInfoData;
export const getProductItemsInCart = (state) =>
  getSubState(state).productItemsInCart;
export const getProductItemsInCart_LastestModifyTime = (state) =>
  getSubState(state).productItemsInCart_LastestModifyTime;
export const getProductItem = (state) => getSubState(state).productItem;
export const getProductItemsInCart_GetRequestStatus = (state) =>
  getSubState(state).productItemsInCart_GetRequestStatus;
export const getProductItemsInCart_AddRequestStatus = (state) =>
  getSubState(state).productItemsInCart_AddRequestStatus;
export const getProductItemsInCart_UpdateRequestStatus = (state) =>
  getSubState(state).productItemsInCart_UpdateRequestStatus;
export const getGetSaleRequestsListRequestStatus = (state) =>
  getSubState(state).getSaleRequestsListRequestStatus;
export const getGetSaleRequestsListRequestResult = (state) =>
  getSubState(state).getSaleRequestsListRequestResult;
export const getGetSaleRequestsDetailRequestStatus = (state) =>
  getSubState(state).getSaleRequestsDetailRequestStatus;
export const getGetSaleRequestsDetailRequestResult = (state) =>
  getSubState(state).getSaleRequestsDetailRequestResult;
export const getGetSaleRequestsListAdvisoryRequestStatus = (state) =>
  getSubState(state).getSaleRequestsListAdvisoryRequestStatus;
export const getGetSaleRequestsListAdvisoryRequestResult = (state) =>
  getSubState(state).getSaleRequestsListAdvisoryRequestResult;
export const getGetSaleRequestsListOrderRequestStatus = (state) =>
  getSubState(state).getSaleRequestsListOrderRequestStatus;
export const getGetSaleRequestsListOrderRequestResult = (state) =>
  getSubState(state).getSaleRequestsListOrderRequestResult;
export const getInsertSaleRequestsRequestStatus = (state) =>
  getSubState(state).insertSaleRequestsRequestStatus;
export const getInsertSaleRequestsRequestResult = (state) =>
  getSubState(state).insertSaleRequestsRequestResult;
export const getUpdateSaleRequestsRequestStatus = (state) =>
  getSubState(state).updateSaleRequestsRequestStatus;
export const getUpdateSaleRequestsRequestResult = (state) =>
  getSubState(state).updateSaleRequestsRequestResult;
export const getTitleIsAvailableRequestStatus = (state) =>
  getSubState(state).titleIsAvailableRequestStatus;
export const getTitleIsAvailableRequestResult = (state) =>
  getSubState(state).titleIsAvailableRequestResult;
export const getGetAllSlugsRequestStatus = (state) =>
  getSubState(state).getAllSlugsRequestStatus;
export const getGetAllSlugsRequestResult = (state) =>
  getSubState(state).getAllSlugsRequestResult;
