import { connect } from "react-redux";
import {
  productPageSelectors,
  webUserLayoutSelectors,
} from "../redux/selectors";
import { productPageActions, webUserLayoutActions } from "../redux/actions";
import ProductDetailPage from "../pages/product-detail-page";
// import { BaseService } from "../services/base-service";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    productDetailRequestStatus:
      productPageSelectors.getProductDetailRequestStatus(state),
    productDetail: productPageSelectors.getProductDetail(state),
    productListByCategoryRequestStatus:
      productPageSelectors.getProductListByCategoryRequestStatus(state),
    productListByCategory: productPageSelectors.getProductListByCategory(state),
    insertSaleRequestsRequestStatus:
      webUserLayoutSelectors.getInsertSaleRequestsRequestStatus(state),
    insertSaleRequestsRequestResult:
      webUserLayoutSelectors.getInsertSaleRequestsRequestResult(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProductDetailByTitleId: (titleId) => {
    // BaseService.setGAPageView(`/san-pham/${titleId}`);
    dispatch(productPageActions.getProductDetailByTitleId(titleId));
  },

  // loadProductDetail: (productId) => {
  //     dispatch(productPageActions.getProductDetail(productId));
  // },

  loadProductListByCaterogy: (category) => {
    dispatch(productPageActions.loadProductListByCaterogy(category));
  },

  addProductItemToCart: (productItem) => {
    dispatch(webUserLayoutActions.addProductItemToCart(productItem));
  },

  insertSaleRequests: (saleRequests) => {
    dispatch(webUserLayoutActions.insertSaleRequests(saleRequests));
  },
});

const ProductPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailPage);

export default ProductPageContainer;
