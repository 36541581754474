import React from "react";
//
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormVideo } from "../public/form-components";
import "./video-list.css";
import { BaseService } from "../../services/base-service";
import { RequestStatus } from "../../constants";
import { ReadMoreButton } from "../public/form-components";

export class VideoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoList: [],
      limitDisplayNumber: 4,
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.videoListRequestStatus !== nextProps.videoListRequestStatus &&
      nextProps.videoListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      setTimeout(() => {
        this.setStateVideoList(0);
      }, 0);
    }
  }

  setStateVideoList = (start) => {
    let propsVideoList = BaseService.getOrderListWithName([
      ...this.props.videoList,
    ]);
    let stateVideoList = [...this.state.videoList];
    if (stateVideoList < propsVideoList) {
      const newItems = propsVideoList.splice(
        start,
        this.state.limitDisplayNumber
      );
      stateVideoList = stateVideoList.concat(newItems);
      this.setState({
        videoList: stateVideoList,
      });
    }
  };

  readMoreVideoList = () => {
    this.setStateVideoList(this.state.videoList.length);
  };

  isReadMoreAvailable = () => {
    const res = this.state.videoList.length < this.props.videoList.length;
    return res;
  };

  getVideoElement = (videoItem) => {
    const element = (
      <>
        <FormVideo videoUrl={videoItem.url} canAccess={true} autoPlay={false} />
      </>
    );
    return element;

    // if(videoItem && videoItem.embedLink) {
    //     const embedLinkFIndex = videoItem.embedLink.indexOf('https://www.youtube.com/embed/');
    //     const embedLinkLIndex = videoItem.embedLink.indexOf('"', embedLinkFIndex);
    //     const embedLink = videoItem.embedLink.substring(embedLinkFIndex, embedLinkLIndex);
    //     const element = <>
    //         <iframe
    //             title={videoItem.embedLink}
    //             width="100%"
    //             height="100%"
    //             src={embedLink}
    //             frameBorder="0"
    //             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    //             allowFullScreen >
    //         </iframe>
    //     </>;
    //     return element;
    // } else {
    //     return <></>;
    // }
  };

  render() {
    const html = (
      <>
        <div id="videoListContainerDiv" className="component-container-div">
          <div
            id="videoListContainerTitleDiv"
            className="component-title-div"
            style={{
              backgroundImage: `url('${this.props.home_page_title_jpg}')`,
            }}
          >
            <div className="component-title-text-div">
              <div>
                Video
                <div className="component-title-hr"></div>
              </div>
            </div>
          </div>
          <div>
            <Row className="video-list-row">
              {this.state.videoList.map((videoItem, index) => (
                <React.Fragment key={index}>
                  <Col md={6} lg={6} xl={3}>
                    <div className="video-list-item-div">
                      {this.getVideoElement(videoItem)}
                    </div>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            {this.isReadMoreAvailable() ? (
              <Row>
                <Col>
                  <br />
                  <ReadMoreButton
                    readMoreFunction={() => {
                      this.readMoreVideoList();
                    }}
                  />
                  <br />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col className="video-list-link-to-youtube-col">
                <Link className="video-list-link-to-youtube-link" to="/">
                  <i className="fab fa-youtube video-list-link-to-youtube-i"></i>
                  Kênh Youtube
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
    return html;
  }
}
