import React from "react";
import "./admin-analytics-page.css";
// import ReactGA from "react-ga";

export class AdminAnalyticsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const html = <>Trang thống kê</>;
    return html;
  }
}
