import React from "react";
import "./admin-product-page.css";
//
import { Tabs, Tab } from "react-bootstrap";
import { AdminProductList } from "../components/admin-layout/admin-product-list";
import { AdminCategoryList } from "../components/admin-layout/admin-category-list";
// import { RequestStatus } from '../constants';

export class AdminProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // componentWillReceiveProps(nextProps) {
  //     if(this.props.productDetailRequestStatus !== nextProps.productDetailRequestStatus &&
  //     nextProps.productDetailRequestStatus === RequestStatus.RQ_SUCCESS) {
  //     }
  // }

  getAdminProductList = () => {
    const element = (
      <>
        <AdminProductList
          windowWidth={this.props.windowWidth}
          productListRequestStatus={this.props.productListRequestStatus}
          productList={this.props.productList}
          insertProductRequestStatus={this.props.insertProductRequestStatus}
          insertedProduct={this.props.insertedProduct}
          updateProductRequestStatus={this.props.updateProductRequestStatus}
          updatedProduct={this.props.updatedProduct}
          updateProductOrderNumberRequestStatus={
            this.props.updateProductOrderNumberRequestStatus
          }
          updatedProductOrderNumber={this.props.updatedProductOrderNumber}
          updateProductIsDeletedRequestStatus={
            this.props.updateProductIsDeletedRequestStatus
          }
          updatedProductIsDeleted={this.props.updatedProductIsDeleted}
          deleteProductRequestStatus={this.props.deleteProductRequestStatus}
          deletedProduct={this.props.deletedProduct}
          errorMessage={this.props.productErrorMessage}
          //
          getProductList={this.props.getProductList}
          insertProductWithSaveProductImageList={
            this.props.insertProductWithSaveProductImageList
          }
          updateProductWithSaveProductImageList={
            this.props.updateProductWithSaveProductImageList
          }
          updateProduct_OrderNumber={this.props.updateProduct_OrderNumber}
          updateProduct_IsDeleted={this.props.updateProduct_IsDeleted}
          deleteProduct={this.props.deleteProduct}
          //
          categoryListRequestStatus={this.props.categoryListRequestStatus}
          categoryList={this.props.categoryList}
          getCategoryList={this.props.getCategoryList}
          //
          allHashTagsRequestStatus={this.props.allHashTagsRequestStatus}
          allHashTags={this.props.allHashTags}
          getAllHashTags={this.props.getAllHashTags}
          //
          getProductDetail={this.props.getProductDetail}
          productDetailRequestStatus={this.props.productDetailRequestStatus}
          productDetail={this.props.productDetail}
          productDetail_originName={this.props.productDetail_originName}
          //
          titleIsAvailableRequestStatus={
            this.props.titleIsAvailableRequestStatus
          }
          titleIsAvailableRequestResult={
            this.props.titleIsAvailableRequestResult
          }
          checkTitleIsAvailable={this.props.checkTitleIsAvailable}
          //
          getAllSlugs={this.props.getAllSlugs}
        />
      </>
    );
    return element;
  };

  getAdminCategoryList = () => {
    const element = (
      <>
        <AdminCategoryList
          windowWidth={this.props.windowWidth}
          categoryListRequestStatus={this.props.categoryListRequestStatus}
          categoryList={this.props.categoryList}
          categoryDetailRequestStatus={this.props.categoryDetailRequestStatus}
          categoryDetail={this.props.categoryDetail}
          categoryDetail_originName={this.props.categoryDetail_originName}
          insertCategoryRequestStatus={this.props.insertCategoryRequestStatus}
          insertedCategory={this.props.insertedCategory}
          updateCategoryRequestStatus={this.props.updateCategoryRequestStatus}
          updatedCategory={this.props.updatedCategory}
          updateCategoryOrderNumberRequestStatus={
            this.props.updateCategoryOrderNumberRequestStatus
          }
          updatedCategoryOrderNumber={this.props.updatedCategoryOrderNumber}
          updateCategoryIsDeletedRequestStatus={
            this.props.updateCategoryIsDeletedRequestStatus
          }
          updatedCategoryIsDeleted={this.props.updatedCategoryIsDeleted}
          deleteCategoryRequestStatus={this.props.deleteCategoryRequestStatus}
          deletedCategory={this.props.deletedCategory}
          errorMessage={this.props.categoryErrorMessage}
          //
          getCategoryList={this.props.getCategoryList}
          getCategoryDetail={this.props.getCategoryDetail}
          insertCategory={this.props.insertCategory}
          updateCategory={this.props.updateCategory}
          updateCategory_OrderNumber={this.props.updateCategory_OrderNumber}
          updateCategory_IsDeleted={this.props.updateCategory_IsDeleted}
          deleteCategory={this.props.deleteCategory}
          //
          titleIsAvailableRequestStatus={
            this.props.titleIsAvailableRequestStatus
          }
          titleIsAvailableRequestResult={
            this.props.titleIsAvailableRequestResult
          }
          checkTitleIsAvailable={this.props.checkTitleIsAvailable}
          //
          getAllSlugs={this.props.getAllSlugs}
        />
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div className="tabs-container-div">
          <Tabs>
            <Tab eventKey="productList" title="Sản phẩm">
              {this.getAdminProductList()}
            </Tab>
            <Tab eventKey="categoryList" title="Thương hiệu">
              {this.getAdminCategoryList()}
            </Tab>
          </Tabs>
        </div>
      </>
    );
    return html;
  }
}
