import { connect } from 'react-redux';
import { AdminTypicalFacePage } from '../pages/admin-typical-face-page';
import { typicalFacePageSelectors } from '../redux/selectors';
import { typicalFacePageActions } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
    return {
        typicalFaceListRequestStatus: typicalFacePageSelectors.getTypicalFaceListRequestStatus(state),
        typicalFaceList: typicalFacePageSelectors.getTypicalFaceList(state),
        typicalFaceDetailRequestStatus: typicalFacePageSelectors.getTypicalFaceDetailRequestStatus(state),
        typicalFaceDetail: typicalFacePageSelectors.getTypicalFaceDetail(state),
        insertTypicalFaceRequestStatus: typicalFacePageSelectors.getInsertTypicalFaceRequestStatus(state),
        insertedTypicalFace: typicalFacePageSelectors.getInsertedTypicalFace(state),
        updateTypicalFaceRequestStatus: typicalFacePageSelectors.getUpdateTypicalFaceRequestStatus(state),
        updatedTypicalFace: typicalFacePageSelectors.getUpdatedTypicalFace(state),        
        updateTypicalFaceOrderNumberRequestStatus: typicalFacePageSelectors.getUpdateTypicalFaceOrderNumberRequestStatus(state),
        updatedTypicalFaceOrderNumber: typicalFacePageSelectors.getUpdatedTypicalFaceOrderNumber(state),        
        updateTypicalFaceIsDeletedRequestStatus: typicalFacePageSelectors.getUpdateTypicalFaceIsDeletedRequestStatus(state),
        updatedTypicalFaceIsDeleted: typicalFacePageSelectors.getUpdatedTypicalFaceIsDeleted(state),
        deleteTypicalFaceRequestStatus: typicalFacePageSelectors.getDeleteTypicalFaceRequestStatus(state),
        deletedTypicalFace: typicalFacePageSelectors.getDeletedTypicalFace(state),        
        typicalFaceErrorMessage: typicalFacePageSelectors.getErrorMessage(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    getTypicalFaceList: () => {
        dispatch(typicalFacePageActions.getTypicalFaceList());
    },

    getTypicalFaceDetail: (typicalFaceId) => {
        dispatch(typicalFacePageActions.getTypicalFaceDetail(typicalFaceId));
    },
    
    insertTypicalFace: (typicalFace) => {
        dispatch(typicalFacePageActions.insertTypicalFace(typicalFace));
    },
    
    updateTypicalFace: (typicalFace) => {
        dispatch(typicalFacePageActions.updateTypicalFace(typicalFace));
    },
    
    updateTypicalFace_OrderNumber: (typicalFaceId, imageId, value) => {
        dispatch(typicalFacePageActions.updateTypicalFace_OrderNumber(typicalFaceId, imageId, value));
    },
    
    updateTypicalFace_IsDeleted: (typicalFaceId, imageId, value) => {
        dispatch(typicalFacePageActions.updateTypicalFace_IsDeleted(typicalFaceId, imageId, value));
    },
    
    deleteTypicalFace: (typicalFace) => {
        dispatch(typicalFacePageActions.deleteTypicalFace(typicalFace));
    }
})

const AdminTypicalFacePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminTypicalFacePage)

export default AdminTypicalFacePageContainer;