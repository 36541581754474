import React from 'react';
import './button-component.css';
// 

export class ButtonComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    onClickButton = () => {
        if(this.props.clickFunction) {
            this.props.clickFunction();
        }
    }

    render() {
        const html = <>
            <div className={`button-component-container-div${(this.props.iconClass)?(' button-component-has-icon-container-div'):('')}`} onClick={this.onClickButton}>
                {
                    (this.props.iconClass)?(
                        <i className={`${this.props.iconClass}`}></i>
                    ):(<></>)
                }
                {this.props.text}
            </div>
        </>;
        return (
            html
        );
    }
}