import { NewsService } from "../../services/news-service";
// import { BaseService } from '../../services/base-service';
import { Messages } from "../../constants";
import { BaseService } from "../../services/base-service";

export const newspage_GET_NEWS_LIST_REQUEST = "newspage_GET_NEWS_LIST_REQUEST";
export const newspage_GET_NEWS_LIST_SUCCESS = "newspage_GET_NEWS_LIST_SUCCESS";
export const newspage_GET_NEWS_LIST_ERROR = "newspage_GET_NEWS_LIST_ERROR";

export const newspage_GET_NEWS_DETAIL_REQUEST =
  "newspage_GET_NEWS_DETAIL_REQUEST";
export const newspage_GET_NEWS_DETAIL_SUCCESS =
  "newspage_GET_NEWS_DETAIL_SUCCESS";
export const newspage_GET_NEWS_DETAIL_ERROR = "newspage_GET_NEWS_DETAIL_ERROR";

export const newspage_INSERT_NEWS_SUCCESS = "newspage_INSERT_NEWS_SUCCESS";
export const newspage_INSERT_NEWS_REQUEST = "newspage_INSERT_NEWS_REQUEST";
export const newspage_INSERT_NEWS_ERROR = "newspage_INSERT_NEWS_ERROR";

export const newspage_UPDATE_NEWS_REQUEST = "newspage_UPDATE_NEWS_REQUEST";
export const newspage_UPDATE_NEWS_SUCCESS = "newspage_UPDATE_NEWS_SUCCESS";
export const newspage_UPDATE_NEWS_ERROR = "newspage_UPDATE_NEWS_ERROR";

export const newspage_UPDATE_NEWS_ORDER_NUMBER_REQUEST =
  "newspage_UPDATE_NEWS_ORDER_NUMBER_REQUEST";
export const newspage_UPDATE_NEWS_ORDER_NUMBER_SUCCESS =
  "newspage_UPDATE_NEWS_ORDER_NUMBER_SUCCESS";
export const newspage_UPDATE_NEWS_ORDER_NUMBER_ERROR =
  "newspage_UPDATE_NEWS_ORDER_NUMBER_ERROR";

export const newspage_UPDATE_NEWS_IS_DELETED_REQUEST =
  "newspage_UPDATE_NEWS_IS_DELETED_REQUEST";
export const newspage_UPDATE_NEWS_IS_DELETED_SUCCESS =
  "newspage_UPDATE_NEWS_IS_DELETED_SUCCESS";
export const newspage_UPDATE_NEWS_IS_DELETED_ERROR =
  "newspage_UPDATE_NEWS_IS_DELETED_ERROR";

export const newspage_DELETE_NEWS_REQUEST = "newspage_DELETE_NEWS_REQUEST";
export const newspage_DELETE_NEWS_SUCCESS = "newspage_DELETE_NEWS_SUCCESS";
export const newspage_DELETE_NEWS_ERROR = "newspage_DELETE_NEWS_ERROR";

export const getNewsList = () => {
  return (dispatch) => {
    dispatch({
      type: newspage_GET_NEWS_LIST_REQUEST,
    });

    const request = NewsService.getNewsList();
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: newspage_GET_NEWS_LIST_SUCCESS,
            payload: {
              newsList: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: newspage_GET_NEWS_LIST_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getNewsDetailByTitleId = (titleId) => {
  return (dispatch) => {
    dispatch({
      type: newspage_GET_NEWS_DETAIL_REQUEST,
    });

    const request = NewsService.getNewsDetailByTitleId(titleId);
    if (request) {
      request.then(
        (data) => {
          const newsDetail = data;
          if (!BaseService.stringIsNullOrWhiteSpace(newsDetail.styleConfig)) {
            const styleConfig = JSON.parse(newsDetail.styleConfig);
            newsDetail.styleConfig = styleConfig;
          }
          console.log("NewsService.getNewsDetailByTitleId(titleId);", data);
          dispatch({
            type: newspage_GET_NEWS_DETAIL_SUCCESS,
            payload: {
              newsDetail: newsDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: newspage_GET_NEWS_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const getNewsDetail = (newsId) => {
  return (dispatch) => {
    dispatch({
      type: newspage_GET_NEWS_DETAIL_REQUEST,
    });

    const request = NewsService.getNewsDetail(newsId);
    if (request) {
      request.then(
        (data) => {
          const newsDetail = data;
          if (!BaseService.stringIsNullOrWhiteSpace(newsDetail.styleConfig)) {
            const styleConfig = JSON.parse(newsDetail.styleConfig);
            newsDetail.styleConfig = styleConfig;
          }
          console.log("NewsService.getNewsDetail(newsId);", data);
          dispatch({
            type: newspage_GET_NEWS_DETAIL_SUCCESS,
            payload: {
              newsDetail: newsDetail,
            },
          });
        },
        (error) => {
          dispatch({
            type: newspage_GET_NEWS_DETAIL_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const insertNews = (news) => {
  return (dispatch) => {
    dispatch({
      type: newspage_INSERT_NEWS_REQUEST,
    });

    const request = NewsService.insertNews(news);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: newspage_INSERT_NEWS_SUCCESS,
            payload: {
              insertedNews: data,
            },
          });
        },
        (error) => {
          dispatch({
            type: newspage_INSERT_NEWS_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};

export const updateNews = (news) => {
  return (dispatch) => {
    return updateNewsCore(
      dispatch,
      news,
      newspage_UPDATE_NEWS_REQUEST,
      newspage_UPDATE_NEWS_SUCCESS,
      newspage_UPDATE_NEWS_ERROR
    );
  };
};

export const updateNews_OrderNumber = (newsId, imageId, value) => {
  return async (dispatch) => {
    return updateNewsCore(
      dispatch,
      {
        id: newsId,
        imageId: imageId,
        orderNumber: value,
      },
      newspage_UPDATE_NEWS_ORDER_NUMBER_REQUEST,
      newspage_UPDATE_NEWS_ORDER_NUMBER_SUCCESS,
      newspage_UPDATE_NEWS_ORDER_NUMBER_ERROR
    );

    // const data = await NewsService.getNewsDetail(newsId);
    // if(data) {
    //     let news = NewsService.getNewsInput(data);
    //     news.orderNumber = value;
    //     return updateNewsCore(dispatch, news, newspage_UPDATE_NEWS_ORDER_NUMBER_REQUEST, newspage_UPDATE_NEWS_ORDER_NUMBER_SUCCESS, newspage_UPDATE_NEWS_ORDER_NUMBER_ERROR);
    // }
  };
};

export const updateNews_IsDeleted = (newsId, imageId, value) => {
  return async (dispatch) => {
    return updateNewsCore(
      dispatch,
      {
        id: newsId,
        imageId: imageId,
        isDeleted: value,
      },
      newspage_UPDATE_NEWS_IS_DELETED_REQUEST,
      newspage_UPDATE_NEWS_IS_DELETED_SUCCESS,
      newspage_UPDATE_NEWS_IS_DELETED_ERROR
    );

    // const data = await NewsService.getNewsDetail(newsId);
    // if(data) {
    //     let news = NewsService.getNewsInput(data);
    //     news.isDeleted = value;
    //     return updateNewsCore(dispatch, news, newspage_UPDATE_NEWS_IS_DELETED_REQUEST, newspage_UPDATE_NEWS_IS_DELETED_SUCCESS, newspage_UPDATE_NEWS_IS_DELETED_ERROR);
    // }
  };
};

const updateNewsCore = (
  dispatch,
  news,
  requestStatus,
  successStatus,
  errorStatus
) => {
  dispatch({
    type: requestStatus,
  });

  const request = NewsService.updateNews(news);
  if (request) {
    request.then(
      (data) => {
        dispatch({
          type: successStatus,
          payload: {
            updatedNews: data,
          },
        });
      },
      (error) => {
        dispatch({
          type: errorStatus,
          payload: {
            errorMessage: Messages.REQUEST_ERROR,
          },
        });
      }
    );
  }
};

export const deleteNews = (news) => {
  return (dispatch) => {
    dispatch({
      type: newspage_DELETE_NEWS_REQUEST,
    });

    const request = NewsService.deleteNews(news);
    if (request) {
      request.then(
        (data) => {
          dispatch({
            type: newspage_DELETE_NEWS_SUCCESS,
            payload: {
              deletedNews: news,
            },
          });
        },
        (error) => {
          dispatch({
            type: newspage_DELETE_NEWS_ERROR,
            payload: {
              errorMessage: Messages.REQUEST_ERROR,
            },
          });
        }
      );
    }
  };
};
