import React from "react";
import "./top-menu-bar.css";
//
// import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { BaseService } from "../../services/base-service";
// import { Row, Col } from "react-bootstrap";
// import SubMenuChamSocDaMat from "../../assets/images/sub-menu-img/sub-menu-cham-soc-da-mat.jpg";

export class TopMenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openResponsiveMenuIds: [],
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.windowWidth !== nextProps.windowWidth) {
      setTimeout(() => {
        this.handleResize();
        this.handleScroll();
      }, 0);
    }
  }

  handleResize = () => {
    this.setMenuHeight();
  };

  handleScroll = () => {
    this.setMenuHeight();
  };

  setMenuHeight = () => {
    const topMenuBarContainerDiv = document.getElementById(
      "TopMenuBarContainerDiv"
    );
    const topMenuBarContainerBackgroundDiv = document.getElementById(
      "TopMenuBarContainerBackgroundDiv"
    );
    const topMenuBarContainerContentDiv = document.getElementById(
      "TopMenuBarContainerContentDiv"
    );
    if (
      topMenuBarContainerDiv &&
      topMenuBarContainerBackgroundDiv &&
      topMenuBarContainerContentDiv
    ) {
      if (this.props.windowWidth < 1200) {
        const topBranchBarContainerDiv = document.getElementById(
          "TopBranchBarContainerDiv"
        );
        if (topBranchBarContainerDiv) {
          const windowHeight = BaseService.getWindowHeight();
          const heightString = `${windowHeight + 4}px`;
          topMenuBarContainerDiv.style.height = heightString;
          topMenuBarContainerBackgroundDiv.style.height = heightString;
          topMenuBarContainerContentDiv.style.height = heightString;
        }
      } else {
        topMenuBarContainerDiv.style.height = "auto";
        topMenuBarContainerBackgroundDiv.style.height = "auto";
        topMenuBarContainerContentDiv.style.height = "auto";
      }
    }
  };

  getFullWidthSubMenu = (navMenuListItem) => {
    const element = (
      <>
        {navMenuListItem.subItems.length > 0 ? (
          <div className="top-menu-bar-main-item-sub-new-div">
            {navMenuListItem.subItems.map((navMenuListSubItem) => (
              <>
                {this.getNavLink(navMenuListSubItem)}
                {navMenuListSubItem.subItems.length > 0 ? (
                  <div className="top-menu-bar-main-item-sub-new-div top-menu-bar-main-item-sub-menu-sub-menu-div">
                    {this.getFullWidthSubMenu(navMenuListSubItem)}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  handleOnClickToggleResposiveSubMenu = (navMenuListItem) => {
    const openResponsiveMenuIds = this.state.openResponsiveMenuIds;
    const idx = openResponsiveMenuIds.findIndex(
      (t) => t === navMenuListItem.id
    );
    if (idx === -1) {
      openResponsiveMenuIds.push(navMenuListItem.id);
    } else {
      openResponsiveMenuIds.splice(idx, 1);
    }
    this.setState((state) => {
      return {
        openResponsiveMenuIds,
      };
    });
  };

  getResponsiveSubMenu = (navMenuListItem) => {
    const element = (
      <>
        {navMenuListItem.subItems.length > 0 ? (
          <div
            id={`SubMenu${navMenuListItem.id}`}
            className={`top-menu-bar-main-item-responsive-menu-wrapper-div top-menu-bar-main-item-responsive-menu-wrapper-sub-menu-div${
              this.state.openResponsiveMenuIds.includes(navMenuListItem.id)
                ? " top-menu-bar-main-item-responsive-menu-wrapper-sub-menu-opened-div"
                : ""
            }`}
          >
            {navMenuListItem.subItems.map((navMenuListSubItem) => (
              <>
                {this.getResponsiveNavLink(navMenuListSubItem)}
                {this.getResponsiveSubMenu(navMenuListSubItem)}
              </>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getResponsiveNavLink = (navMenuListItem) => {
    const element = (
      <>
        {BaseService.stringIsNullOrWhiteSpace(navMenuListItem.url) ? (
          <>
            <div className="top-menu-bar-main-item-element top-menu-bar-main-item-responsive-element">
              {navMenuListItem.title}
              {navMenuListItem.subItems.length > 0 ? (
                <i
                  className="fas fa-caret-down top-menu-bar-main-item-element-collapse-icon-i"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleOnClickToggleResposiveSubMenu(navMenuListItem);
                  }}
                ></i>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            {navMenuListItem.url.includes("http:") ||
            navMenuListItem.url.includes("https:") ? (
              <>
                <a
                  href={navMenuListItem.url}
                  className="top-menu-bar-main-item-element top-menu-bar-main-item-responsive-element"
                  onClick={() => { this.props.closeMenu(); }}
                >
                  {navMenuListItem.title}
                  {navMenuListItem.subItems.length > 0 ? (
                    <i
                      className="fas fa-caret-down top-menu-bar-main-item-element-collapse-icon-i"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleOnClickToggleResposiveSubMenu(
                          navMenuListItem
                        );
                      }}
                    ></i>
                  ) : (
                    <></>
                  )}
                  <div className="top-menu-bar-main-item-element-bottom-border-div"></div>
                </a>
              </>
            ) : (
              <>
                <NavLink
                  to={`${navMenuListItem.url}`}
                  className="top-menu-bar-main-item-element top-menu-bar-main-item-responsive-element"
                  onClick={() => { this.props.closeMenu(); }}
                >
                  {navMenuListItem.title}
                  {navMenuListItem.subItems.length > 0 ? (
                    <i
                      className="fas fa-caret-down top-menu-bar-main-item-element-collapse-icon-i"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleOnClickToggleResposiveSubMenu(
                          navMenuListItem
                        );
                      }}
                    ></i>
                  ) : (
                    <></>
                  )}
                  <div className="top-menu-bar-main-item-element-bottom-border-div"></div>
                </NavLink>
              </>
            )}
          </>
        )}
      </>
    );
    return element;
  };

  getNavLink = (navMenuListItem) => {
    const element = (
      <>
        {BaseService.stringIsNullOrWhiteSpace(navMenuListItem.url) ? (
          <>
            <div className="top-menu-bar-main-item-element">
              {navMenuListItem.title}
              {navMenuListItem.subItems.length > 0 ? (
                <i className="fas fa-caret-down top-menu-bar-main-item-element-collapse-icon-i"></i>
              ) : (
                <></>
              )}
              <div className="top-menu-bar-main-item-element-bottom-border-div"></div>
            </div>
          </>
        ) : (
          <>
            {navMenuListItem.url.includes("http:") ||
            navMenuListItem.url.includes("https:") ? (
              <>
                <a
                  href={navMenuListItem.url}
                  className="top-menu-bar-main-item-element"
                >
                  {navMenuListItem.title}
                  {navMenuListItem.subItems.length > 0 ? (
                    <i className="fas fa-caret-down top-menu-bar-main-item-element-collapse-icon-i"></i>
                  ) : (
                    <></>
                  )}
                  <div className="top-menu-bar-main-item-element-bottom-border-div"></div>
                </a>
              </>
            ) : (
              <>
                <NavLink
                  to={`${navMenuListItem.url}`}
                  className="top-menu-bar-main-item-element"
                >
                  {navMenuListItem.title}
                  {navMenuListItem.subItems.length > 0 ? (
                    <i className="fas fa-caret-down top-menu-bar-main-item-element-collapse-icon-i"></i>
                  ) : (
                    <></>
                  )}
                  <div className="top-menu-bar-main-item-element-bottom-border-div"></div>
                </NavLink>
              </>
            )}
          </>
        )}
      </>
    );
    return element;
  };

  getFullWidthMenu = () => {
    const elemet = (
      <>
        <div id="TopMenuBarContainerDiv" className="top-menu-bar-container-div">
          {this.props.navMenuList.map((navMenuListItem, index) => (
            <>
              <div className="top-menu-bar-main-item-element-container-div">
                {this.getNavLink(navMenuListItem)}
                {this.getFullWidthSubMenu(navMenuListItem)}
              </div>
            </>
          ))}
        </div>
      </>
    );
    return elemet;
  };

  getResponsiveMenu = () => {
    const elemet = (
      <>
        <div
          className={`top-menu-bar-responsive-container-outer-div${
            this.props.isOpenedReponseMenu
              ? " top-menu-bar-responsive-opened-container-outer-div"
              : ""
          }`}
        >
          <div
            id="TopMenuBarContainerDiv"
            className={`top-menu-bar-responsive-container-div${
              this.props.isOpenedReponseMenu
                ? " top-menu-bar-responsive-opened-container-div"
                : ""
            }`}
          >
            <div
              onClick={() => {
                this.setState((state) => {
                  return {
                    openResponsiveMenuIds: [],
                  };
                });
                this.props.closeMenu();
              }}
              id="TopMenuBarContainerBackgroundDiv"
              className="top-menu-bar-responsive-container-background-div"
            ></div>
            <div
              id="TopMenuBarContainerContentDiv"
              className="top-menu-bar-responsive-container-content-div"
            >
              <div className="top-menu-bar-responsive-container-top-bar-div">
                <div className="top-branch-bar-search-div">
                  <div className="top-branch-bar-search-input-div">
                    <input
                      type="text"
                      className="top-branch-bar-search-input"
                      placeholder="Tìm kiếm ..."
                    />
                    <i className="fas fa-search top-branch-bar-search-input-icon-i"></i>
                  </div>
                  <div
                    onClick={() => {
                      this.setState((state) => {
                        return {
                          openResponsiveMenuIds: [],
                        };
                      });
                      this.props.closeMenu();
                    }}
                    className="top-branch-bar-close-button-div"
                  >
                    <i
                      id="topMenuBarResponsiveLogo"
                      className="fas fa-times"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="top-menu-bar-main-item-responsive-menu-wrapper-div">
                {this.props.navMenuList.map((navMenuListItem, index) => (
                  <>
                    {this.getResponsiveNavLink(navMenuListItem)}
                    {this.getResponsiveSubMenu(navMenuListItem)}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
    return elemet;
  };

  render() {
    const html = (
      <>
        {this.props.windowWidth >= 1200
          ? this.getFullWidthMenu()
          : this.getResponsiveMenu()}
      </>
    );
    return html;
  }
}
