import React from 'react';
import './table-component.css';
// 

export class TableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnWidth: this.props.headers.map(t => {
                return {
                    width: t.width
                }
            })
        }
    }

    render() {
        const html = <>
            <div className='table-component-container-div'>
                <table className='table-component-table'>
                    <tr className='table-component-header'>
                        {
                            this.props.headers.map((item, index) =>
                                <th key={index}
                                    style={{width: (item.width)?(`${item.width}px`):('auto')}}
                                >
                                    {item.title}
                                </th>
                            )
                        }
                    </tr>
                    {
                        (this.props.rows.length > 0)?(
                            <>
                                {
                                    this.props.rows.map((row, index) =>
                                    <tr key={index} className='table-component-row'>
                                        {
                                            row.columns.map((column, index) =>
                                            <td
                                                style={{
                                                    width: (this.state.columnWidth[index].width)?(`${this.state.columnWidth[index].width}px`):('auto')
                                                }}
                                            >
                                                {column}
                                            </td>)
                                        }
                                    </tr>)
                                }
                            </>
                        ):(
                            <>
                                <tr className='table-component-no-item-row'>
                                    {this.props.noItemRow}
                                </tr>
                            </>
                        )
                    }
                </table>
            </div>
        </>;
        return (
            html
        );
    }
}