import React from "react";
import "./admin-advisory-page.css";
//
import { AdminAdvisoryList } from "../components/admin-layout/admin-advisory-list";

export class AdminAdvisoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const html = (
      <>
        <AdminAdvisoryList
          windowWidth={this.props.windowWidth}
          getSaleRequestsListAdvisoryRequestStatus={
            this.props.getSaleRequestsListAdvisoryRequestStatus
          }
          getSaleRequestsListAdvisoryRequestResult={
            this.props.getSaleRequestsListAdvisoryRequestResult
          }
          updateSaleRequests={this.props.updateSaleRequests}
        />
      </>
    );
    return html;
  }
}
