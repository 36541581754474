import { connect } from "react-redux";
import { AdminNewsPage } from "../pages/admin-news-page";
import { newsPageSelectors, webUserLayoutSelectors } from "../redux/selectors";
import { newsPageActions, webUserLayoutActions } from "../redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    newsListRequestStatus: newsPageSelectors.getNewsListRequestStatus(state),
    newsList: newsPageSelectors.getNewsList(state),
    newsDetailRequestStatus: newsPageSelectors.getNewsDetailRequestStatus(
      state
    ),
    newsDetail: newsPageSelectors.getNewsDetail(state),
    newsDetail_originName: newsPageSelectors.getNewsDetailOriginName(state),
    insertNewsRequestStatus: newsPageSelectors.getInsertNewsRequestStatus(
      state
    ),
    insertedNews: newsPageSelectors.getInsertedNews(state),
    updateNewsRequestStatus: newsPageSelectors.getUpdateNewsRequestStatus(
      state
    ),
    updatedNews: newsPageSelectors.getUpdatedNews(state),
    updateNewsOrderNumberRequestStatus: newsPageSelectors.getUpdateNewsOrderNumberRequestStatus(
      state
    ),
    updatedNewsOrderNumber: newsPageSelectors.getUpdatedNewsOrderNumber(state),
    updateNewsIsDeletedRequestStatus: newsPageSelectors.getUpdateNewsIsDeletedRequestStatus(
      state
    ),
    updatedNewsIsDeleted: newsPageSelectors.getUpdatedNewsIsDeleted(state),
    deleteNewsRequestStatus: newsPageSelectors.getDeleteNewsRequestStatus(
      state
    ),
    deletedNews: newsPageSelectors.getDeletedNews(state),
    newsErrorMessage: newsPageSelectors.getErrorMessage(state),
    //
    titleIsAvailableRequestStatus: webUserLayoutSelectors.getTitleIsAvailableRequestStatus(
      state
    ),
    titleIsAvailableRequestResult: webUserLayoutSelectors.getTitleIsAvailableRequestResult(
      state
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewsList: () => {
    dispatch(newsPageActions.getNewsList());
  },

  getNewsDetail: (newsId) => {
    dispatch(newsPageActions.getNewsDetail(newsId));
  },

  insertNews: (news) => {
    dispatch(newsPageActions.insertNews(news));
  },

  updateNews: (news) => {
    dispatch(newsPageActions.updateNews(news));
  },

  updateNews_OrderNumber: (newsId, imageId, value) => {
    dispatch(newsPageActions.updateNews_OrderNumber(newsId, imageId, value));
  },

  updateNews_IsDeleted: (newsId, imageId, value) => {
    dispatch(newsPageActions.updateNews_IsDeleted(newsId, imageId, value));
  },

  deleteNews: (news) => {
    dispatch(newsPageActions.deleteNews(news));
  },

  checkTitleIsAvailable: (titleId) => {
    dispatch(webUserLayoutActions.checkTitleIsAvailable(titleId));
  },

  getAllSlugs: () => {
    dispatch(webUserLayoutActions.getAllSlugs());
  },
});

const AdminNewsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNewsPage);

export default AdminNewsPageContainer;
