const getSubState = (state) => state.typicalFacePageReducer;

export const getTypicalFaceListRequestStatus = (state) => getSubState(state).typicalFaceListRequestStatus;
export const getTypicalFaceList = (state) => getSubState(state).typicalFaceList;
export const getTypicalFaceDetailRequestStatus = (state) => getSubState(state).typicalFaceDetailRequestStatus;
export const getTypicalFaceDetail = (state) => getSubState(state).typicalFaceDetail;
export const getInsertTypicalFaceRequestStatus = (state) => getSubState(state).insertTypicalFaceRequestStatus;
export const getInsertedTypicalFace = (state) => getSubState(state).insertedTypicalFace;
export const getUpdateTypicalFaceRequestStatus = (state) => getSubState(state).updateTypicalFaceRequestStatus;
export const getUpdatedTypicalFace = (state) => getSubState(state).updatedTypicalFace;
export const getUpdateTypicalFaceOrderNumberRequestStatus = (state) => getSubState(state).updateTypicalFaceOrderNumberRequestStatus;
export const getUpdatedTypicalFaceOrderNumber = (state) => getSubState(state).updatedTypicalFaceOrderNumber;
export const getUpdateTypicalFaceIsDeletedRequestStatus = (state) => getSubState(state).updateTypicalFaceIsDeletedRequestStatus;
export const getUpdatedTypicalFaceIsDeleted = (state) => getSubState(state).updatedTypicalFaceIsDeleted;
export const getDeleteTypicalFaceRequestStatus = (state) => getSubState(state).deleteTypicalFaceRequestStatus;
export const getDeletedTypicalFace = (state) => getSubState(state).deletedTypicalFace;
export const getErrorMessage = (state) => getSubState(state).errorMessage;