import { OleeApiURL, NewsApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

export class NewsService {
  static getNewNews = () => {
    return {
      id: null,
      name: null,
      titleId: null,
      imageId: null,
      description: null,
      content: null,
      isHot: false,
      styleConfig: {},
      orderNumber: 0,
      isDeleted: false,
    };
  };

  static getNewsInput = (news) => {
    const newsInput = {
      id: news.id,
      name: news.name,
      titleId: news.titleId,
      imageId: news.image ? news.image.id : null,
      description: news.description,
      content: news.content,
      isHot: news.isHot,
      styleConfig: JSON.stringify(news.styleConfig),
      orderNumber: news.orderNumber,
      isDeleted: news.isDeleted,
    };
    return newsInput;
  };

  static getNewsList = () => {
    return fetch(`${OleeApiURL}${NewsApiUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getNewsDetailByTitleId = (titleId) => {
    return fetch(`${OleeApiURL}${NewsApiUrl}/title/${titleId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getNewsDetail = (newsId) => {
    return fetch(`${OleeApiURL}${NewsApiUrl}/${newsId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static insertNews = (news) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(news);
      return fetch(`${OleeApiURL}${NewsApiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static updateNews = (news) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(news);
      return fetch(`${OleeApiURL}${NewsApiUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static deleteNews = (news) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${NewsApiUrl}/${news.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };
}
