import React from "react";
import { Row, Col } from "react-bootstrap";
import { RequestStatus } from "../../constants";
import { BaseService } from "../../services/base-service";
import { UIConfigsService } from "../../services/ui-configns-service";
import {
  FormButton,
  // FormHR,
  FormTextInput,
  FormToggle,
  FormNumberInput,
} from "../public/form-components";
import { GoogleMap } from "../web-user-layout/google-map";
import "./admin-footer-form.css";
import "./admin-menu-list.css";
import shopeeIcon from "../../assets/images/icons/shopee-48.png";
//

export class AdminFooterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminFooterFormContent: null,
    };
  }

  componentDidMount() {
    const content = this.props.adminFooterConfigs.content;
    this.setAdminFooterFormContent(content);
  }

  componentWillReceiveProps(nextProps) {
    const content = nextProps.adminFooterConfigs.content;
    this.setAdminFooterFormContent(content);
  }

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  setAdminFooterFormContent = (content) => {
    let adminFooterFormContent = !BaseService.stringIsNullOrWhiteSpace(content)
      ? JSON.parse(content)
      : UIConfigsService.getNewFooterConfigs();
    adminFooterFormContent = {
      ...adminFooterFormContent,
      contactInfos: BaseService.getOrderListWithText(
        adminFooterFormContent.contactInfos
      ),
      aboutUs: BaseService.getOrderListWithText(adminFooterFormContent.aboutUs),
      socialLinks: BaseService.getOrderListWithText(
        adminFooterFormContent.socialLinks
      ),
    };
    this.setState((state) => {
      return {
        adminFooterFormContent,
      };
    });
  };

  handleOnChangeGoogleMapSrc = (value) => {
    this.trackIsDataHasUpdated();
    const idx = value.indexOf("<iframe");
    if (idx !== -1) {
      value = value.substring(
        value.indexOf('src="') + 5,
        value.indexOf('"', value.indexOf('"') + 5)
      );
    }
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          googleMapSrc: value,
        },
      };
    });
  };

  handleOnChangeContactInfoOrderNumber = (id, value) => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const index = contactInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      contactInfos[index].orderNumber = value;
      this.setState({
        contactInfos,
      });
    }
  };

  handleOnChangeContactInfoIsDelete = (id, value) => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const index = contactInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      contactInfos[index].isDeleted = !value;
      this.setState({
        contactInfos,
      });
    }
  };

  handleOnChangeContactInfoIsText = (id, value) => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const index = contactInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      contactInfos[index].text = value;
      this.setState({
        contactInfos,
      });
    }
  };

  handleOnChangeContactInfoIsValue = (id, value) => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const index = contactInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      contactInfos[index].value = value;
      this.setState({
        contactInfos,
      });
    }
  };

  handleOnChangeContactInfoIsPath = (id, value) => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const index = contactInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      contactInfos[index].path = value;
      this.setState({
        contactInfos,
      });
    }
  };

  handleOnClickRemoveContactInfo = (id) => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const index = contactInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      contactInfos.splice(index, 1);
      this.setState({
        contactInfos,
      });
    }
  };

  handleOnClickAddNewContactInfo = () => {
    this.trackIsDataHasUpdated();
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const newContactInfo = {
      id: BaseService.getRandomString(5),
      text: "",
      path: "",
      orderNumber: contactInfos.length,
      isDeleted: false,
    };
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          contactInfos: contactInfos.concat(newContactInfo),
        },
      };
    });
  };

  handleOnChangeAboutUsOrderNumber = (id, value) => {
    this.trackIsDataHasUpdated();
    const aboutUsInfos = this.state.adminFooterFormContent.aboutUs;
    const index = aboutUsInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      aboutUsInfos[index].orderNumber = value;
      this.setState({
        aboutUsInfos,
      });
    }
  };

  handleOnChangeAboutUsIsDelete = (id, value) => {
    this.trackIsDataHasUpdated();
    const aboutUsInfos = this.state.adminFooterFormContent.aboutUs;
    const index = aboutUsInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      aboutUsInfos[index].isDeleted = !value;
      this.setState({
        aboutUsInfos,
      });
    }
  };

  handleOnChangeAboutUsText = (id, value) => {
    this.trackIsDataHasUpdated();
    const aboutUsInfos = this.state.adminFooterFormContent.aboutUs;
    const index = aboutUsInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      aboutUsInfos[index].text = value;
      this.setState({
        aboutUsInfos,
      });
    }
  };

  handleOnChangeAboutUsIsPath = (id, value) => {
    this.trackIsDataHasUpdated();
    const aboutUsInfos = this.state.adminFooterFormContent.aboutUs;
    const index = aboutUsInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      aboutUsInfos[index].path = value;
      this.setState({
        aboutUsInfos,
      });
    }
  };

  handleOnClickRemoveAboutUs = (id) => {
    this.trackIsDataHasUpdated();
    const aboutUsInfos = this.state.adminFooterFormContent.aboutUs;
    const index = aboutUsInfos.findIndex((t) => t.id === id);
    if (index !== -1) {
      aboutUsInfos.splice(index, 1);
      this.setState({
        aboutUsInfos,
      });
    }
  };

  handleOnClickAddNewAboutUs = () => {
    this.trackIsDataHasUpdated();
    const aboutUs = this.state.adminFooterFormContent.aboutUs;
    const newAboutUs = {
      id: BaseService.getRandomString(5),
      text: "",
      path: "",
      orderNumber: aboutUs.length,
      isDeleted: false,
    };
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          aboutUs: aboutUs.concat(newAboutUs),
        },
      };
    });
  };

  renderGoogleMapInput = () => {
    const element = (
      <>
        <Row>
          <Col>
            <div className="admin-menu-list-title-div">
              <div className="admin-menu-list-title-text-div">Google map</div>
              <div className="admin-menu-list-title-hr-div"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormTextInput
              placeholder="Đường dẫn google map"
              value={this.state.adminFooterFormContent.googleMapSrc}
              onChange={this.handleOnChangeGoogleMapSrc}
            />
            {!BaseService.stringIsNullOrWhiteSpace(
              this.state.adminFooterFormContent.googleMapSrc
            ) ? (
              <>
                <div className="admin-footer-form-google-map-wrapper-div">
                  <GoogleMap
                    src={this.state.adminFooterFormContent.googleMapSrc}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
    return element;
  };

  renderContactInfoInput = () => {
    const contactInfos = this.state.adminFooterFormContent.contactInfos;
    const element = (
      <>
        <Row>
          <Col>
            <div className="admin-menu-list-title-div">
              <div className="admin-menu-list-title-text-div">
                Thông tin liên hệ
              </div>
              <div className="admin-menu-list-title-hr-div"></div>
            </div>
          </Col>
        </Row>
        <Row className="admin-footer-form-input-row">
          {contactInfos.map((contactInfo) => (
            <>
              <Col xs={6} lg={2} className="admin-footer-form-input-col">
                <FormNumberInput
                  value={contactInfo.orderNumber}
                  min="0"
                  onChange={(value) => {
                    this.handleOnChangeContactInfoOrderNumber(
                      contactInfo.id,
                      value
                    );
                  }}
                />
              </Col>
              <Col
                xs={6}
                lg={1}
                className="admin-footer-form-input-col admin-footer-form-input-delete-col"
              >
                <FormToggle
                  value={contactInfo.isDeleted ? !contactInfo.isDeleted : true}
                  onChange={(value) => {
                    this.handleOnChangeContactInfoIsDelete(
                      contactInfo.id,
                      value
                    );
                  }}
                />
              </Col>
              <Col sm={6} lg={2} className="admin-footer-form-input-col">
                <FormTextInput
                  value={contactInfo.text}
                  onChange={(value) => {
                    this.handleOnChangeContactInfoIsText(contactInfo.id, value);
                  }}
                  placeholder="Tiêu đề"
                />
              </Col>
              <Col sm={6} lg={3} className="admin-footer-form-input-col">
                <FormTextInput
                  value={contactInfo.value}
                  onChange={(value) => {
                    this.handleOnChangeContactInfoIsValue(
                      contactInfo.id,
                      value
                    );
                  }}
                  placeholder="Thông tin"
                />
              </Col>
              <Col sm={11} lg={3} className="admin-footer-form-input-col">
                <FormTextInput
                  value={contactInfo.path}
                  onChange={(value) => {
                    this.handleOnChangeContactInfoIsPath(contactInfo.id, value);
                  }}
                  placeholder="Đường dẫn (Nếu có)"
                />
              </Col>
              <Col
                sm={1}
                lg={1}
                className="admin-footer-form-input-col admin-footer-form-input-delete-col"
              >
                <div
                  className="admin-menu-list-delete-button-div"
                  onClick={() => {
                    this.handleOnClickRemoveContactInfo(contactInfo.id);
                  }}
                >
                  Xóa
                </div>
              </Col>
            </>
          ))}
        </Row>
        <Row>
          <Col>
            <div
              className="admin-menu-list-add-menu-button-div admin-footer-form-input-col"
              onClick={this.handleOnClickAddNewContactInfo}
            >
              Thêm thông tin liên hệ
            </div>
          </Col>
        </Row>
      </>
    );
    return element;
  };

  renderAboutUsInput = () => {
    const aboutUsInfos = this.state.adminFooterFormContent.aboutUs;
    const element = (
      <>
        <Row>
          <Col>
            <div className="admin-menu-list-title-div">
              <div className="admin-menu-list-title-text-div">
                Các trang giới thiệu
              </div>
              <div className="admin-menu-list-title-hr-div"></div>
            </div>
          </Col>
        </Row>
        <Row className="admin-footer-form-input-row">
          {aboutUsInfos.map((aboutUs) => (
            <>
              <Col xs={6} lg={2} className="admin-footer-form-input-col">
                <FormNumberInput
                  value={aboutUs.orderNumber}
                  min="0"
                  onChange={(value) => {
                    this.handleOnChangeAboutUsOrderNumber(aboutUs.id, value);
                  }}
                />
              </Col>
              <Col
                xs={6}
                lg={1}
                className="admin-footer-form-input-col admin-footer-form-input-delete-col"
              >
                <FormToggle
                  value={aboutUs.isDeleted ? !aboutUs.isDeleted : true}
                  onChange={(value) => {
                    this.handleOnChangeAboutUsIsDelete(aboutUs.id, value);
                  }}
                />
              </Col>
              <Col sm={5} lg={4} className="admin-footer-form-input-col">
                <FormTextInput
                  value={aboutUs.text}
                  onChange={(value) => {
                    this.handleOnChangeAboutUsText(aboutUs.id, value);
                  }}
                  placeholder="Tiêu đề"
                />
              </Col>
              <Col sm={6} lg={4} className="admin-footer-form-input-col">
                <FormTextInput
                  value={aboutUs.path}
                  onChange={(value) => {
                    this.handleOnChangeAboutUsIsPath(aboutUs.id, value);
                  }}
                  placeholder="Đường dẫn"
                />
              </Col>
              <Col
                sm={1}
                lg={1}
                className="admin-footer-form-input-col admin-footer-form-input-delete-col"
              >
                <div
                  className="admin-menu-list-delete-button-div"
                  onClick={() => {
                    this.handleOnClickRemoveAboutUs(aboutUs.id);
                  }}
                >
                  Xóa
                </div>
              </Col>
            </>
          ))}
        </Row>
        <Row>
          <Col>
            <div
              className="admin-menu-list-add-menu-button-div admin-footer-form-input-col"
              onClick={this.handleOnClickAddNewAboutUs}
            >
              Thêm trang giới thiệu
            </div>
          </Col>
        </Row>
      </>
    );
    return element;
  };

  handleOnChangeFacebookPath = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[0].path = value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeYoutubePath = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[1].path = value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeShopeePath = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[4].path = value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeTwitterPath = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[2].path = value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeInstagramPath = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[3].path = value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeFacebookIsDelete = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[0].isDeleted = !value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeYoutubeIsDelete = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[1].isDeleted = !value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeShopeeIsDelete = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[4].isDeleted = !value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeTwitterIsDelete = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[2].isDeleted = !value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  handleOnChangeInstagramIsDelete = (value) => {
    this.trackIsDataHasUpdated();
    const socialLinks = this.state.adminFooterFormContent.socialLinks;
    socialLinks[3].isDeleted = !value;
    this.setState((state) => {
      return {
        adminFooterFormContent: {
          ...state.adminFooterFormContent,
          socialLinks,
        },
      };
    });
  };

  getFacebookInput = () => {
    const element = (
      <>
        <Col sm={6} lg={6} xl={3} className="admin-footer-fomr-social-link-col">
          <div className="admin-footer-fomr-social-link-content-div admin-footer-fomr-social-link-content-facebook-div">
            <div className="admin-footer-fomr-social-link-icon-div">
              <i class="fab fa-facebook"></i>
              <div>Facebook</div>
            </div>
            <div className="admin-footer-fomr-social-link-input-div">
              <span>Đường dẫn Facebook</span>
              <FormTextInput
                value={this.state.adminFooterFormContent.socialLinks[0].path}
                onChange={(value) => {
                  this.handleOnChangeFacebookPath(value);
                }}
                placeholder="VD: KemSachOfficial"
              />
            </div>
            <br />
            <div>
              <FormToggle
                value={
                  this.state.adminFooterFormContent.socialLinks[0].isDeleted
                    ? !this.state.adminFooterFormContent.socialLinks[0]
                        .isDeleted
                    : true
                }
                onChange={(value) => {
                  this.handleOnChangeFacebookIsDelete(value);
                }}
              />
            </div>
          </div>
        </Col>
      </>
    );
    return element;
  };

  getYoutubeInput = () => {
    const element = (
      <>
        <Col sm={6} lg={6} xl={3} className="admin-footer-fomr-social-link-col">
          <div className="admin-footer-fomr-social-link-content-div admin-footer-fomr-social-link-content-youtube-div">
            <div className="admin-footer-fomr-social-link-icon-div">
              <i class="fab fa-youtube"></i>
              <div>Youtube</div>
            </div>
            <div className="admin-footer-fomr-social-link-input-div">
              <span>Đường dẫn Youtube</span>
              <FormTextInput
                value={this.state.adminFooterFormContent.socialLinks[1].path}
                onChange={(value) => {
                  this.handleOnChangeYoutubePath(value);
                }}
                placeholder="VD: https://www.youtube.com/user/kemsach"
              />
            </div>
            <br />
            <div>
              <FormToggle
                value={
                  this.state.adminFooterFormContent.socialLinks[1].isDeleted
                    ? !this.state.adminFooterFormContent.socialLinks[1]
                        .isDeleted
                    : true
                }
                onChange={(value) => {
                  this.handleOnChangeYoutubeIsDelete(value);
                }}
              />
            </div>
          </div>
        </Col>
      </>
    );
    return element;
  };

  getShopeeInput = () => {
    if (!this.state.adminFooterFormContent.socialLinks[4]) {
      this.setState(
        (state) => {
          const adminFooterFormContent = this.state.adminFooterFormContent;
          adminFooterFormContent.socialLinks.push({
            id: "seTBa",
            text: "Shopee",
            path: "",
            iconURL: "",
            orderNumber: 4,
            isDeleted: false,
          });
          return {
            adminFooterFormContent,
          };
        },
        () => {
          return this.renderShopeeInput();
        }
      );
    } else {
      return this.renderShopeeInput();
    }
  };

  renderShopeeInput = () => {
    const element = (
      <>
        <Col sm={6} lg={6} xl={3} className="admin-footer-fomr-social-link-col">
          <div className="admin-footer-fomr-social-link-content-div admin-footer-fomr-social-link-content-shopee-div">
            <div className="admin-footer-fomr-social-link-icon-div">
              {/* <i class="fab fa-youtube"></i> */}
              <img className='admin-footer-fomr-social-link-content-icon-img' alt='' src={shopeeIcon} />
              <div>Shopee</div>
            </div>
            <div className="admin-footer-fomr-social-link-input-div">
              <span>Đường dẫn Shopee</span>
              <FormTextInput
                value={this.state.adminFooterFormContent.socialLinks[4].path}
                onChange={(value) => {
                  this.handleOnChangeShopeePath(value);
                }}
                placeholder="VD: https://shopee.vn/kemsach"
              />
            </div>
            <br />
            <div>
              <FormToggle
                value={
                  this.state.adminFooterFormContent.socialLinks[4].isDeleted
                    ? !this.state.adminFooterFormContent.socialLinks[4]
                        .isDeleted
                    : true
                }
                onChange={(value) => {
                  this.handleOnChangeShopeeIsDelete(value);
                }}
              />
            </div>
          </div>
        </Col>
      </>
    );
    return element;
  };

  getTwitterInput = () => {
    const element = (
      <>
        <Col sm={6} lg={6} xl={3} className="admin-footer-fomr-social-link-col">
          <div className="admin-footer-fomr-social-link-content-div admin-footer-fomr-social-link-content-twitter-div">
            <div className="admin-footer-fomr-social-link-icon-div">
              <i class="fab fa-twitter"></i>
              <div>Twitter</div>
            </div>
            <div className="admin-footer-fomr-social-link-input-div">
              <span>Đường dẫn Twitter</span>
              <FormTextInput
                value={this.state.adminFooterFormContent.socialLinks[2].path}
                onChange={(value) => {
                  this.handleOnChangeTwitterPath(value);
                }}
                placeholder="VD: https://twitter.com/kemsach"
              />
            </div>
            <br />
            <div>
              <FormToggle
                value={
                  this.state.adminFooterFormContent.socialLinks[2].isDeleted
                    ? !this.state.adminFooterFormContent.socialLinks[2]
                        .isDeleted
                    : true
                }
                onChange={(value) => {
                  this.handleOnChangeTwitterIsDelete(value);
                }}
              />
            </div>
          </div>
        </Col>
      </>
    );
    return element;
  };

  getInstagramInput = () => {
    const element = (
      <>
        <Col sm={6} lg={6} xl={3} className="admin-footer-fomr-social-link-col">
          <div className="admin-footer-fomr-social-link-content-div admin-footer-fomr-social-link-content-instagram-div">
            <div className="admin-footer-fomr-social-link-icon-div">
              <i class="fab fa-instagram"></i>
              <div>Instagram</div>
            </div>
            <div className="admin-footer-fomr-social-link-input-div">
              <span>Đường dẫn Instagram</span>
              <FormTextInput
                value={this.state.adminFooterFormContent.socialLinks[3].path}
                onChange={(value) => {
                  this.handleOnChangeInstagramPath(value);
                }}
                placeholder="VD: https://www.instagram.com/trangkemsach/"
              />
            </div>
            <br />
            <div>
              <FormToggle
                value={
                  this.state.adminFooterFormContent.socialLinks[3].isDeleted
                    ? !this.state.adminFooterFormContent.socialLinks[3]
                        .isDeleted
                    : true
                }
                onChange={(value) => {
                  this.handleOnChangeInstagramIsDelete(value);
                }}
              />
            </div>
          </div>
        </Col>
      </>
    );
    return element;
  };

  renderSocialInput = () => {
    // const socialLinks = this.state.adminFooterFormContent.socialLinks;
    const element = (
      <>
        <Row>
          <Col>
            <div className="admin-menu-list-title-div">
              <div className="admin-menu-list-title-text-div">Mạng xã hội</div>
              <div className="admin-menu-list-title-hr-div"></div>
            </div>
          </Col>
        </Row>
        <Row className="admin-footer-form-input-row">
          {this.getFacebookInput()}
          {this.getYoutubeInput()}
          {this.getShopeeInput()}
          {this.getTwitterInput()}
          {this.getInstagramInput()}
        </Row>
      </>
    );
    return element;
  };

  renderAdminFooterFormContent = () => {
    const element = this.state.adminFooterFormContent ? (
      //   <Container fluid={true}>
      <>
        {this.renderGoogleMapInput()}
        <br /> <br />
        {this.renderContactInfoInput()}
        <br /> <br />
        {this.renderAboutUsInput()}
        <br /> <br />
        {this.renderSocialInput()}
      </>
    ) : (
      //   </Container>
      <></>
    );
    return element;
  };

  handleOnClickSaveAdminFooterForm = () => {
    const adminFooterFormContent = this.state.adminFooterFormContent;
    let uiConfigs = UIConfigsService.getUIConfigsInput({
      ...this.props.adminFooterConfigs,
      content: JSON.stringify(adminFooterFormContent),
    });
    this.props.save(uiConfigs);
  };

  render() {
    const html = (
      <>
        <div className="admin-menu-list-note-div">
          <div>
            *** Lưu ý: Đối với <b>Đường dẫn</b> chỉ nhập một trong hai loại giá
            trị <b>/san-pham-kem-sach-1</b> hoặc{" "}
            <b>https://www.trangkemsach.com.vn/</b>.
          </div>
        </div>
        {this.renderAdminFooterFormContent()}
        <br />
        <FormButton
          type="save"
          isRequesting={
            this.props.insertUIConfigsRequestStatus ===
              RequestStatus.RQ_REQUEST ||
            this.props.updateUIConfigsRequestStatus === RequestStatus.RQ_REQUEST
              ? true
              : false
          }
          disabled={
            this.props.insertUIConfigsRequestStatus ===
              RequestStatus.RQ_REQUEST ||
            this.props.updateUIConfigsRequestStatus === RequestStatus.RQ_REQUEST
              ? true
              : false
          }
          onClick={this.handleOnClickSaveAdminFooterForm}
        />
      </>
    );
    return html;
  }
}
