import { connect } from "react-redux";
import {
  productPageSelectors,
  categoryPageSelectors,
} from "../redux/selectors";
import {
  productPageActions,
  categoryPageActions,
  webUserLayoutActions,
} from "../redux/actions";
import { ProductListPage } from "../pages/product-list-page";

const mapStateToProps = (state, ownProps) => {
  return {
    windowWidth: ownProps.windowWidth,
    productListRequestStatus: productPageSelectors.getProductListRequestStatus(
      state
    ),
    productListRequestResult: productPageSelectors.getProductList(state),
    categoryListRequestStatus: categoryPageSelectors.getCategoryListRequestStatus(
      state
    ),
    categoryListRequestResult: categoryPageSelectors.getCategoryList(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadProductList: () => {
    dispatch(productPageActions.getProductList());
  },

  loadCategoryList: () => {
    dispatch(categoryPageActions.getCategoryList());
  },

  addProductItemToCart: (productItem) => {
    dispatch(webUserLayoutActions.addProductItemToCart(productItem));
  },
});

const ProductPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListPage);

export default ProductPageContainer;
