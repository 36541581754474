import { connect } from "react-redux";
import { AdminAnalyticsPage } from "../pages/admin-analytics-page";

const mapStateToProps = (state, ownProps) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => ({
});

const AdminAnalyticsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAnalyticsPage);

export default AdminAnalyticsContainer;
