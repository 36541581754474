import { OleeApiURL, CategoryApiUrl, TagsApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

export class CategoryService {
  static getNewCategory = () => {
    return {
      id: null,
      name: null,
      titleId: null,
      description: null,
      image_id: null,
      image_url: null,
      products: null,
      image: null,
      orderNumber: 0,
      isDeleted: false,
      styleConfig: {},
    };
  };

  static getCategoryInput = (category) => {
    const categoryInput = {
      id: category.id,
      name: category.name,
      titleId: category.titleId,
      description: category.description,
      imageId: category.image ? category.image.id : null,
      orderNumber: category.orderNumber,
      isDeleted: category.isDeleted,
      styleConfig: JSON.stringify(category.styleConfig),
    };
    return categoryInput;
  };

  static getCategoryList = () => {
    // const loginData = AuthenticationService.getLoginData();
    // if(loginData && BaseService.isObjectHasKey(loginData, 'token')) {
    //     return fetch(`${OleeApiURL}${CategoryApiUrl}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${loginData.token}`
    //         }
    //     })
    //     .then(res => res.json());
    // } else {
    //     return;
    // }

    return fetch(`${OleeApiURL}${CategoryApiUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getCategoryDetailByTitleId = (titleId) => {
    return fetch(`${OleeApiURL}${CategoryApiUrl}/title/${titleId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static getCategoryDetail = (categoryId) => {
    return fetch(`${OleeApiURL}${CategoryApiUrl}/${categoryId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  };

  static insertCategory = (category) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(category);
      return fetch(`${OleeApiURL}${CategoryApiUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static updateCategory = (category) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      const bodyJson = JSON.stringify(category);
      return fetch(`${OleeApiURL}${CategoryApiUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginData.token}`,
        },
        body: bodyJson,
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static deleteCategory = (category) => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${CategoryApiUrl}/${category.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static getAllHashTags = () => {
    const loginData = AuthenticationService.getLoginData();
    if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
      return fetch(`${OleeApiURL}${TagsApiUrl}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loginData.token}`,
        },
      }).then((res) => res.json());
    } else {
      return;
    }
  };

  static getHashTagByCategory = (category) => {
    return fetch(`${OleeApiURL}${TagsApiUrl}/${category.id}`, {
      method: "GET",
    }).then((res) => res.json());
  };
}
