import React from 'react';
import './login-page.css';
// 
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import home_page_title from '../assets/images/home_page_title.jpg';
import { BaseService } from '../services/base-service';
import { Form } from 'react-bootstrap';
import {
    FormHR,
    FormTitle,
    FormDescription,
    FormLoadingText,
    FormTextInput,
    FormError,
    FormButton
} from '../components/public/form-components';
import { RequestStatus, Messages } from '../constants';
import { withFormik } from 'formik';
import { AuthenticationService } from '../services/authentication-service';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.props.getLoginData();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.loginRequestStatus !== nextProps.loginRequestStatus && nextProps.loginRequestStatus === RequestStatus.RQ_SUCCESS) {
            console.log('LoginPage - componentWillReceiveProps');
            this.props.history.push('/quan-tri');
        }
    }

    changeUsername = (value) => {
        this.props.setFieldValue('username', value);
    }

    blurUsername = (e) => {
        this.props.setFieldTouched('username', true);
    }

    changePassword = (value) => {
        this.props.setFieldValue('password', value);
    }

    blurPassword = (e) => {
        this.props.setFieldTouched('password', true);
    }

    login = () => {
        this.props.handleSubmit();
    }

    resetForm = () => {
        this.props.setFieldValue('username', '');
        this.props.setFieldTouched('username', false);
        this.props.setFieldValue('password', '');
        this.props.setFieldTouched('password', false);
    }

    clearLoginData = () => {
        this.resetForm();
        this.props.clearLoginData();
    }
    
    getLoaddingForm = () => {
        const form = <>
            <Form className='login-page-form'>
                <FormHR />
                <FormTitle
                    text='Đang tải'
                />
                <FormLoadingText />
            </Form>
        </>;
        return form;
    }

    getLoginForm = () => {
        const form = <>
            <Form className='login-page-form'>
                <FormHR />
                <FormTitle
                    text='Đăng nhập'
                />
                <FormTextInput
                    value={this.props.values.username}
                    onChange={this.changeUsername}
                    onBlur={this.blurUsername}
                    error={(this.props.touched.username && this.props.errors.username)?(this.props.errors.username):(null)}
                    labelText='Tên đăng nhập/Email:'
                    labelName='username'
                    isRequired={true}
                />
                <FormTextInput
                    value={this.props.values.password}
                    onChange={this.changePassword}
                    onBlur={this.blurPassword}
                    error={(this.props.touched.password && this.props.errors.password)?(this.props.errors.password):(null)}
                    type='password'
                    labelText='Mật khẩu:'
                    labelName='password'
                    isRequired={true}
                />
                <div className='login-page-button-div'>
                    <FormError
                        text={this.props.errorMessage}
                    />
                    <FormButton
                        text='Gửi'
                        onClick={this.login}
                        faIconClass='fas fa-arrow-circle-right'
                        isSubmitButton={true}
                        isRequesting={(this.props.loginRequestStatus === RequestStatus.RQ_REQUEST)?(true):(false)}
                    />
                </div>
            </Form>
            <div>
                <div className='login-page-other-button-div'>
                    <NavLink className='login-page-other-button-NavLink' to='/trang-chu'><i className="login-page-other-button-icon-i fas fa-leaf"></i> Trang chủ</NavLink>
                </div>
                <div className='login-page-other-button-div'>
                    <NavLink className='login-page-other-button-NavLink' to='/trang-chu'><i className="login-page-other-button-icon-i fas fa-question"></i> Quên mật khẩu</NavLink>
                </div>
            </div>
        </>;
        return form;
    }

    getInfoForm = () => {
        const form = <>
            <Form className='login-page-form'>
                <FormHR />
                <FormTitle
                    text='Xin chào'
                />
                <FormDescription
                    element={<>
                        Chào mừng <span className='login-page-username-span'>{this.props.loginData.name}</span> đã quay trở lại, xin vui lòng chọn các mục bên dưới để tiếp tục.
                    </>}
                />
            </Form>
            <div>
                <div className='login-page-other-button-div'>
                    <NavLink className='login-page-other-button-NavLink' to='/trang-chu'><i className="login-page-other-button-icon-i fas fa-leaf"></i> Trang chủ</NavLink>
                </div>
                <div className='login-page-other-button-div'>
                    <NavLink className='login-page-other-button-NavLink' to='/quan-tri'><i className="login-page-other-button-icon-i fas fa-users-cog"></i> Trang quản trị</NavLink>
                </div>
                <div className='login-page-other-button-div'>
                    <div className='login-page-other-button-NavLink' onClick={this.clearLoginData}><i className="login-page-other-button-icon-i fas fa-sign-out-alt"></i> Thoát và đăng nhập bằng tài khoản khác</div>
                </div>
                <div className='login-page-other-button-div'>
                    <NavLink className='login-page-other-button-NavLink' to='/trang-chu'><i className="login-page-other-button-icon-i fas fa-question"></i> Quên mật khẩu</NavLink>
                </div>
            </div>
        </>;
        return form;
    }

    render() {
        const html = <>
            <div className='login-page-container-div'>
                <div style={{ backgroundImage: `url('${home_page_title}')` }}></div>
                <div>
                    <div className='login-page-form-div'>
                        <div>{BaseService.getLogo()}</div>
                        {
                            (this.props.getLoginDataRequestStatus === RequestStatus.RQ_REQUEST)?(
                                this.getLoaddingForm()
                            ):(
                                (this.props.loginData)?(
                                    this.getInfoForm()
                                ):(
                                    this.getLoginForm()
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>;
        return (
            html
        );
    }
}

const myEnhancedForm = withFormik({
    enableReinitialize: true,

    mapPropsToValues: (props) => {
        // console.log('mapPropsToValues - props', props);
        let values = AuthenticationService.getInitialLoginModel();
        return values;
    },

    validate: values => {
        const errors = {};

        if(BaseService.stringIsNullOrWhiteSpace(values.username)) {
            errors.username = Messages.LOGIN_USERNAME_REQUIRE;
        };

        if(BaseService.stringIsNullOrWhiteSpace(values.password)) {
            errors.password = Messages.LOGIN_PASSWORD_REQUIRE;
        };

        return errors;
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(true);
        props.login(values.username, values.password);
    },

    displayName: 'LoginPage'
});

export default withRouter(myEnhancedForm(LoginPage));