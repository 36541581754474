import { AuthenticationService } from '../../services/authentication-service';
import { BaseService } from '../../services/base-service';
import {
    Messages
} from '../../constants';

export const loginpage_LOGIN_REQUEST = 'loginpage_LOGIN_REQUEST';
export const loginpage_LOGIN_SUCCESS = 'loginpage_LOGIN_SUCCESS';
export const loginpage_LOGIN_ERROR = 'loginpage_LOGIN_ERROR';
export const loginpage_GET_LOGIN_DATA_REQUEST = 'loginpage_GET_LOGIN_DATA_REQUEST';
export const loginpage_GET_LOGIN_DATA_SUCCESS = 'loginpage_GET_LOGIN_DATA_SUCCESS';
export const loginpage_GET_LOGIN_DATA_ERROR = 'loginpage_GET_LOGIN_DATA_ERROR';
export const loginpage_CLEAR_LOGIN_DATA_REQUEST = 'loginpage_CLEAR_LOGIN_DATA_REQUEST';
export const loginpage_CLEAR_LOGIN_DATA_SUCCESS = 'loginpage_CLEAR_LOGIN_DATA_SUCCESS';
export const loginpage_CLEAR_LOGIN_DATA_ERROR = 'loginpage_CLEAR_LOGIN_DATA_ERROR';

export const login = (username, password) => {
    return(dispatch) => {
        dispatch({
            type: loginpage_LOGIN_REQUEST
        });
        
        // console.log(`username: '${username}', password: '${password}'`);
        const request = AuthenticationService.login(username, password);
        if(request) {
            request.then(data => {
                const dataObject = JSON.parse(data);
                // console.log('login - dataObject', dataObject);
                if(BaseService.isObjectHasKey(dataObject, 'token')) {
                    AuthenticationService.storageLoginData(data);
                    dispatch({
                        type: loginpage_LOGIN_SUCCESS,
                        payload: {
                            data: data,
                            dataObject: dataObject
                        }
                    });
                } else {
                    dispatch({
                        type: loginpage_LOGIN_ERROR,
                        payload: {
                            errorMessage: Messages.LOGIN_ERROR
                        }
                    });
                }
            }, error => {
                console.log('login - error', error);
                dispatch({
                    type: loginpage_LOGIN_ERROR,
                    payload: {
                        errorMessage: Messages.REQUEST_ERROR
                    }
                });
            });
        }
    }
}

export const getLoginData = () => {
    return(dispatch) => {
        dispatch({
            type: loginpage_GET_LOGIN_DATA_REQUEST
        });

        const loginData = AuthenticationService.getLoginData();
        dispatch({
            type: loginpage_GET_LOGIN_DATA_SUCCESS,
            payload: {
                loginData: loginData
            }
        });
    }
}

export const clearLoginData = () => {
    return(dispatch) => {
        dispatch({
            type: loginpage_CLEAR_LOGIN_DATA_REQUEST
        });

        AuthenticationService.clearLoginData();
        dispatch({
            type: loginpage_CLEAR_LOGIN_DATA_SUCCESS,
            payload: {
                data: null
            }
        });
    }
}