import { OleeApiURL, VoucherApiUrl } from "../constants";
import { AuthenticationService } from "./authentication-service";
import { BaseService } from "./base-service";

export class VoucherService {
    static getNewVoucher = () => {
        return {
            id: null,
            name: null,
            saleRequest: null,
            code: null,
            amount: null,
            isDeleted: false,
            styleConfig: {},
        };
    };

    static getVoucherInput = (voucher) => {
        const voucherInput = {
            id: voucher.id,
            name: voucher.name,
            saleRequest: voucher.saleRequest,
            code: voucher.code,
            amount: voucher.amount,
            isDeleted: voucher.isDeleted,
            styleConfig: JSON.stringify(voucher.styleConfig),
        };
        return voucherInput;
    };

    static getVoucherList = () => {
        const loginData = AuthenticationService.getLoginData();
        if (loginData && BaseService.isObjectHasKey(loginData, "token")) {
            return fetch(`${OleeApiURL}${VoucherApiUrl}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${loginData.token}`,
                }
            }).then((res) => res.json());
        } else {
            return;
        }
    };

    static checkVoucherStatus = (voucherCode) => {
        return fetch(`${OleeApiURL}${VoucherApiUrl}/is_valid_code/${voucherCode}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => res.json());
    }

    static getVoucherInfo = (voucherCode) => {
        return fetch(`${OleeApiURL}${VoucherApiUrl}/code/${voucherCode}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => res.json());
    }
}